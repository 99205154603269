import { ContextProviderName } from "shared/contexts/ContextWrappers";
import { useAttributesContext } from "shared/contexts/GenericAttributesContextWrapper";
import { REPAIRS_GENERIC_FILTER } from "shared/filterDefinitions";

import { SchemaEntry } from "features/ui/Table";

import { SchemaEntryOverride, SchemaRelations, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";
import useWorkOrdersSchema from "./workOrdersSchema";

export const useRepairsSchema = (accessorsToHide: string[] = []): UseSchema => {
  const attributes = useAttributesContext(ContextProviderName.Repair);

  const workOrdersSchema = useWorkOrdersSchema();

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(REPAIRS_GENERIC_FILTER, attributeMap);

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const overrides: SchemaEntryOverride = {
    ID: {
      schemaOverride: {
        label: "",
        filter: undefined,
        sortable: false,
      },
    },
  };

  const relations: SchemaRelations = {
    "/v1/workOrders": workOrdersSchema,
  };

  const schema: SchemaEntry[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    overrides,
    relations,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    attributes,
    getDisplayLabel,
    attributeMap,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaEntry,
    getSchemaForAccessor,
  };
};
