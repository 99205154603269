import Input from "features/ui/Input";
import { StepProps } from "features/ui/Stepper";

const StepNameGroup = ({ data, onDataUpdated }: StepProps) => (
  <div className="p-2">
    <div className="mb-3">
      <Input
        label="Name*"
        onChange={({ target: { value } }) =>
          onDataUpdated && onDataUpdated({ name: value })
        }
        placeholder="Name the group (minimum 5 characters)"
        characterLimit={50}
        value={data?.name || ""}
        testId="group-name-input"
      />
    </div>
    <div className="mb-3">
      <Input
        label="Description"
        onChange={({ target: { value } }) =>
          onDataUpdated && onDataUpdated({ description: value })
        }
        placeholder="Enter a description"
        characterLimit={300}
        value={data?.description || ""}
        testId="group-description-input"
      />
    </div>
  </div>
);

export default StepNameGroup;
