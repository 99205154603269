import { GPT4OMINI_MODEL_SPEC } from "duck/graph/constants";
import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import {
  createStrictToolCallingAgent,
  createToolMessageFromAIMessageToolCall,
  getEphemeralMessageForNode,
  getToolArgs,
  NodeOutputType,
  NodeType,
} from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import getRespondToUserTool from "duck/graph/tools/getRespondToUserTool";
import { DuckGraphParams } from "duck/graph/types";
import { getLLM, NodeNames } from "duck/graph/utils";
import { RunnableConfig } from "@langchain/core/runnables";

const getNode = async (params: DuckGraphParams): Promise<NodeType> => {
  const prompt = await loadPrompt(promptNames.ANALYZE_SCREENSHOT_AGENT);
  const llm = getLLM(GPT4OMINI_MODEL_SPEC);

  const agent = createStrictToolCallingAgent(
    llm,
    [
      getRespondToUserTool(
        params.uiHandlers.setAgentResponse,
        params.uiHandlers.setEphemeralMessage
      ),
    ],
    prompt
  );

  const name = NodeNames.ANALYZE_SCREENSHOT;
  const setEphemeralMessage = params.uiHandlers.setEphemeralMessage;

  return async (
    { messages, pageState, screenshotMessage }: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    setEphemeralMessage(getEphemeralMessageForNode(name));
    console.debug(`AnalyzeScreenshotAgent: ${name}`);

    // Check if the last message is an AIMessage with non-empty tool calls
    const toolMessage = createToolMessageFromAIMessageToolCall(messages);

    const { query } = getToolArgs(messages);

    // We narrow the scope of what this agent needs to consider.
    // No context (RAG documents). Only the screenshot message and the query.
    const agentMessage = await agent.invoke(
      {
        messages: [screenshotMessage],
        current_state: JSON.stringify(pageState),
        context: "",
        query,
      },
      config
    );
    agentMessage.name = name;

    return {
      messages: [...toolMessage, agentMessage],
    };
  };
};

export default getNode;
