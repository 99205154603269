import { GetIssuePopulationPaginatedRequest } from "shared/api/issues/api";
import { useIssueRelationshipAssociatedSignalEvents } from "shared/api/issues/hooks";
import { getSortFilter } from "shared/api/utils";
import { useCustomLocalStorageState } from "shared/hooks";
import {
  IssueChart,
  IssueTypes,
  IssueVehiclePopulation,
  SortBy,
} from "shared/types";
import { pluralize } from "shared/utils";

import { getAssociatedSignalEventsSchema } from "pages/ClaimAnalytics/tabPages/AssociatedSignalEvents";
import { RelationshipsCharts } from "pages/Issues/constants";
import {
  getBaseAPIRoute,
  getChartOptionsKey,
  getIssueCombinedID,
  getPopulationString,
  getSelectedPeriod,
} from "pages/Issues/utils";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import BarChart from "features/ui/charts/BarChart";
import {
  getAxisValue,
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import SectionTitle from "features/ui/SectionTitle";
import { OnSortParams } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";

const DEFAULT_SORT: SortBy = { associationStrength: "desc" };
const ROWS_PER_PAGE = 20;

interface Props {
  title?: string;
  issue: IssueTypes;
  vehiclePopulation: IssueVehiclePopulation;
  timePeriod: number;
}

const CHART_NAME: IssueChart = RelationshipsCharts.ASSOCIATED_SIGNAL_EVENTS;

const IssueRelationshipAssociatedSignalEvents = ({
  issue,
  timePeriod,
  vehiclePopulation,
}: Props) => {
  const { ID, updatedAt } = issue;
  const pageKey = `issue_relationship_associated_signal_events_${ID}_${vehiclePopulation}`;

  const {
    manageFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const requestParams: GetIssuePopulationPaginatedRequest = {
    IDs: getIssueCombinedID(issue),
    sort: getSortFilter(sort),
    filter: getFiltersQuery(filters),
    limit: ROWS_PER_PAGE,
    signalEventsTimeWindow: timePeriod,
    vehiclePopulation,
    baseRoute: getBaseAPIRoute(issue),
    updatedAt,
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useIssueRelationshipAssociatedSignalEvents(requestParams);

  const period = getSelectedPeriod(timePeriod).value;
  const selectedPeriod = `${period} ${pluralize(
    "day",
    parseInt(period.toString())
  )}`;

  const schema = getAssociatedSignalEventsSchema(selectedPeriod);

  const actions: ChartAction[] = [
    {
      id: "y",
      title: "Dimension",
      type: "dropdownSelect",
      options: schema
        .filter((schemaEntry) => schemaEntry.dataType === "number")
        .map((schemaEntry) => ({
          id: schemaEntry.accessor,
          value: schemaEntry.label,
          label: schemaEntry.label,
        })),
    },
  ];

  const chartOptionsKey = getChartOptionsKey(
    `${CHART_NAME}_${vehiclePopulation}`,
    ID
  );
  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(actions) });

  const yAxisKey = getSelectedOptionId(selectedOptions, "y");
  const yAxisValue = getAxisValue(actions, "y", yAxisKey);

  const title = `Associated Signal Events - ${getPopulationString(
    vehiclePopulation
  )}`;
  const chartTitle = `${yAxisValue} by Signal Events ID`;

  return (
    <div className="mt-8">
      <SectionTitle>{title}</SectionTitle>
      {!error && (
        <Card classNames="mt-4">
          <ChartActionsWrap id={CHART_NAME} chartTitle={chartTitle}>
            <ChartActions
              actions={actions}
              selectedOptions={selectedOptions}
              onOptionChange={setSelectedOptions}
            />
          </ChartActionsWrap>
          {data && data.length > 0 ? (
            <BarChart
              data={data}
              yAxisKey={yAxisKey}
              xAxisKey="signalEventID"
              yAxisLabel={yAxisValue}
              xAxisLabel="Signal Event ID"
            />
          ) : (
            <div className="py-4 text-gray-400 text-sm">No data.</div>
          )}
        </Card>
      )}
      <div className="flex items-center my-3">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
      </div>
      {!error && (
        <>
          <PaginatedTable
            {...paginationData}
            data={data}
            schema={schema}
            isLoading={isLoading}
            loadingRows={ROWS_PER_PAGE}
            sortBy={sort}
            onSort={handleSorting}
            filtersInitialized={filtersInitialized}
            onFiltersReset={resetFilters}
            onFilterChange={manageFilterChange}
            filters={filters}
            stickyFirstColumn={true}
            dense
          />
        </>
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && !isLoading && !data?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </div>
  );
};
export default IssueRelationshipAssociatedSignalEvents;
