import React from "react";
import qs from "qs";
import { useNavigate } from "react-router-dom";

import { IssuesOverview } from "shared/api/issues/api";
import { randomID } from "shared/utils";

import { EMPTY_CHART_LABEL, ISSUES_PAGE_KEY } from "pages/Issues/constants";

import BarChart from "features/ui/charts/BarChart";
import {
  BAR_COLOR_LAST_WEEK,
  BAR_COLOR_NOW,
} from "features/ui/charts/constants";
import { DataElement } from "features/ui/charts/types";
import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";

interface CustomXAxisTickProps {
  x?: number;
  y?: number;
  payload?: any;
}

// todo mbencina: make this dynamic for different number of issues
const CustomXAxisTick: React.FC<CustomXAxisTickProps> = ({ x, y, payload }) => {
  if (!payload || !x || !y) return null;

  // we want to show all labels - if they are too long, we display them in multiple lines
  const words = payload.value.split(" ");
  const MAX_LINE_LENGTH = 16;
  const lines: string[] = [];
  let currentLine = "";

  words.forEach((word: string) => {
    if (currentLine.length + word.length + 1 <= MAX_LINE_LENGTH) {
      currentLine += (currentLine ? " " : "") + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  });
  if (currentLine) {
    lines.push(currentLine);
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={10} textAnchor="middle" fill="#666" fontSize={10}>
        {lines.map((line, index) => (
          <tspan key={index} x="0" dy={index === 0 ? 0 : "1.2em"}>
            {line}
          </tspan>
        ))}
      </text>
    </g>
  );
};

interface Props {
  dashboardTitle: string;
  issuesData: IssuesOverview[];
  issuesDataPrev: IssuesOverview[];
  filters: FilterGroupState;
}

const DataDashboardChart = ({
  dashboardTitle,
  issuesData,
  issuesDataPrev,
  filters,
}: Props) => {
  const navigate = useNavigate();
  const issuesFilterKey = getFiltersKey(ISSUES_PAGE_KEY);

  const graphTitle = `${dashboardTitle}, Split by Status, Compared to 7 Days Ago`;

  const groupByKey = "groupByAttributeValue";
  const allGroupByValues = Array.from(
    new Set([
      ...issuesData.map((item) => item[groupByKey]),
      ...issuesDataPrev.map((item) => item[groupByKey]),
    ])
  );

  // process data to include all groupByAttributeValue
  const chartData = allGroupByValues.map((groupByValue) => {
    const currentItem = issuesData.find(
      (item) => item.groupByAttributeValue === groupByValue
    );
    const prevItem = issuesDataPrev.find(
      (item) => item.groupByAttributeValue === groupByValue
    );
    return {
      [groupByKey]: groupByValue === "" ? EMPTY_CHART_LABEL : groupByValue,
      currentCount: currentItem ? currentItem.count : 0,
      previousCount: prevItem ? prevItem.count : 0,
    };
  });

  const yAxisBars = {
    previousCount: "1 week ago",
    currentCount: "Now",
  };

  // todo mbencina: navigation - we should modify navigation to support specific chart settings
  const handleOnBarClick = (data: DataElement) => {
    // we have to reverse the empty label mapping
    const clickedStatus =
      data[groupByKey] === EMPTY_CHART_LABEL ? "" : data[groupByKey];

    const newStatusFilter: FilterRowState = {
      type: "row",
      id: `row-${randomID()}`,
      attribute: "statusObj.value",
      operator: FilterOperator.IN,
      values: [clickedStatus],
    };

    // remove all existing status filters and append the new one
    const updatedFilters: FilterGroupState = {
      ...filters,
      children: filters.children.filter(
        (child) =>
          !(child.type === "row" && child.attribute === "statusObj.value")
      ),
    };
    updatedFilters.children.push(newStatusFilter);

    navigate({
      pathname: routes.issues,
      search: qs.stringify({
        [issuesFilterKey]: getFiltersQuery(updatedFilters),
      }),
    });
  };

  return (
    <div className="mt-6">
      <h3 className="font-semibold mb-6">{graphTitle}</h3>
      <BarChart
        data={chartData}
        xAxisKey={groupByKey}
        yAxisBars={yAxisBars}
        barGap={1}
        barCategoryGap={15}
        height={200}
        colorPalette={[BAR_COLOR_LAST_WEEK, BAR_COLOR_NOW]}
        radius={[5, 5, 0, 0]}
        customTick={<CustomXAxisTick />}
        onBarClick={handleOnBarClick}
        enableBarGroupHover={true}
        xAxisProps={{
          interval: 0,
        }}
        margin={{ bottom: 15 }}
      />
    </div>
  );
};

export default DataDashboardChart;
