import { useState } from "react";
import {
  DraggableData,
  Position,
  ResizableDelta,
  Rnd,
  RndDragEvent,
} from "react-rnd";

import {
  DUCK_DRAG_HANDLE_CLASS_NAME,
  DUCK_FLOATING_POSITION_KEY,
  DUCK_FLOATING_SIZE_KEY,
  DUCK_POPPED_INITIAL_HEIGHT,
  DUCK_POPPED_INITIAL_WIDTH,
  DUCK_POPPED_INITIAL_X,
} from "./constants";
import Duck from "./Duck";

interface DraggableDuckProps {
  setIsDraggable: (draggable: boolean) => void;
}

interface DraggableEventHandler {
  (e: RndDragEvent, data: DraggableData): false | void;
}

interface ResizeEventHandler {
  (
    e: MouseEvent | TouchEvent,
    direction: string,
    elementRef: HTMLElement,
    delta: {
      height: number;
      width: number;
    }
  ): void;
}

const BOTTOM_MARGIN = 20;
const DEFAULT_POSITION: Position = {
  x: DUCK_POPPED_INITIAL_X,
  y: window.innerHeight - DUCK_POPPED_INITIAL_HEIGHT - BOTTOM_MARGIN,
};

const DEFAULT_SIZE = {
  width: DUCK_POPPED_INITIAL_WIDTH,
  height: DUCK_POPPED_INITIAL_HEIGHT,
};

const handleDragStop: DraggableEventHandler = (_event, data) => {
  sessionStorage.setItem(
    DUCK_FLOATING_POSITION_KEY,
    JSON.stringify({ x: data.x, y: data.y })
  );
};

const getInitialPosition = (): Position => {
  const initialPositionFromSS = sessionStorage.getItem(
    DUCK_FLOATING_POSITION_KEY
  );
  return initialPositionFromSS
    ? JSON.parse(initialPositionFromSS)
    : DEFAULT_POSITION;
};

// Funny name for the return type but Rnd gives us the exact structure we want.
const getInitialSize = (): ResizableDelta => {
  const initialSizeFromSS = sessionStorage.getItem(DUCK_FLOATING_SIZE_KEY);
  return initialSizeFromSS ? JSON.parse(initialSizeFromSS) : DEFAULT_SIZE;
};

/**
 * This component wraps Duck in a draggable container.
 * It allows the user to drag Duck around on the screen.
 * @param props.initialPosition The initial position of the draggable Duck.
 * @param props.setIsDraggable Call to let the SidebarNav container
 * know that Duck should no longer be draggable.
 */
const DraggableDuck = ({ setIsDraggable }: DraggableDuckProps) => {
  const [size, setSize] = useState(getInitialSize);

  const initialPosition = getInitialPosition();

  const handleResizeStop: ResizeEventHandler = (
    _event,
    _direction,
    _element,
    delta
  ) => {
    const newSize = {
      width: size.width + delta.width,
      height: size.height + delta.height,
    };
    setSize(newSize);
    sessionStorage.setItem(DUCK_FLOATING_SIZE_KEY, JSON.stringify(newSize));
  };

  return (
    <Rnd
      default={{
        x: initialPosition.x,
        y: initialPosition.y,
        width: size.width,
        height: size.height,
      }}
      minWidth={200}
      minHeight={200}
      bounds="window"
      style={{
        position: "fixed",
        zIndex: 99999,
      }}
      dragHandleClassName={DUCK_DRAG_HANDLE_CLASS_NAME}
      onDragStop={handleDragStop}
      onResizeStop={handleResizeStop}
    >
      <Duck
        isDraggable={true}
        setIsDraggable={setIsDraggable}
        forceOpen={true}
      />
    </Rnd>
  );
};

export default DraggableDuck;
