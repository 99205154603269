import { Step } from "features/ui/Stepper";

import S1Img1 from "./step-1-img-1.png";
import S1Img2 from "./step-1-img-2.png";
import S2Img1 from "./step-2-img-1.png";
import S3Img1 from "./step-3-img-1.png";
import S3Img2 from "./step-3-img-2.png";
import S3Img3 from "./step-3-img-3.png";
import S4Img2 from "./step-4-img-2.png";
import S4Img3 from "./step-4-img-3.png";
import S4Img1 from "./step-5-img-1.png";
import S5Img1 from "./step-5-img-1.png";

const Step1 = () => (
  <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-4 items-start">
    <div className="md:px-10">
      <div className="mb-6 pb-6 md:flex space-y-5 md:space-x-5 md:space-y-0 border-b">
        <div className="md:w-1/2 md:text-center">
          <img
            className="w-80 inline-block"
            src={S1Img1}
            alt="Table Column Settings"
          />
        </div>
        <div className="md:w-1/2">
          Use the "Choose Failure Mode Columns" button to select which failure
          modes appear in the table so you can view, filter, and sort vehicles
          by their associated risk.
        </div>
      </div>
      <div className="mb-6 md:flex space-y-5 md:space-x-5 md:space-y-0">
        <div className="md:w-1/2 md:text-center">
          <img
            className="w-80 inline-block"
            src={S1Img2}
            alt="Choose Failure Mode Column attributes"
          />
        </div>
        <div className="md:w-1/2">
          For each Failure Mode, you can also select whether you want to include
          the risk group, risk status (whether the vehicle is eligible for
          predictions), risk multiple (how much riskier the VIN is than the
          average) and repair status.
        </div>
      </div>
    </div>
  </div>
);

const Step2 = () => (
  <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-4 items-start">
    <div className="md:px-10">
      <div className="md:flex space-y-5 md:space-x-5 md:space-y-0">
        <div className="md:w-1/2 md:text-center">
          <img
            className="w-80 inline-block"
            src={S2Img1}
            alt="Failure Mode attributes"
          />
        </div>
        <div className="md:w-1/2">
          Each Failure Mode has associated Risk Group, Risk Status, Risk
          Multiple, Repair Status, and Repair Details attributes.
          <br />
          <br />
          Risk Group partitions vehicles into high, medium, and low based on
          their risk of experiencing the failure mode.
          <br />
          <br />
          Risk status indicates whether the vehicle is eligible to receive
          predictions on the failure mode. If a vehicle is in the affected
          population but does not have a prediction, it will say why (for
          example, because it has already been repaired). Empty risk status
          indicates the vehicle is not in the affected population.
          <br />
          <br />
          Risk Multiple shows how many times more likely a vehicle is to
          experience a failure than the average vehicle in the eligible
          population.
          <br />
          <br />
          Repair status
          <ul className="text-left list-disc px-5 py-2">
            <li className="mb-2">
              <span className="font-semibold">Natural Failure</span>: The
              vehicle has experienced a natural failure for the Failure Mode.
              The outcome may be that a claim was filed, or a part was replaced.
            </li>
            <li className="mb-2">
              <span className="font-semibold">Campaign Repair</span>: The
              vehicle has received a repair due to the campaign identified by
              the campaign code.
            </li>
          </ul>
          <br />
          <br />
          Repair details show details about the repaired or replaced vehicle
          part.
        </div>
      </div>
    </div>
  </div>
);

const Step3 = () => (
  <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-4 items-start">
    <div className="md:px-10">
      <div className="mb-6 pb-6 md:flex space-y-5 md:space-x-5 md:space-y-0 border-b">
        <div className="md:w-1/2 md:text-center">
          <img
            className="w-42 inline-block"
            src={S3Img1}
            alt="Sorting columns"
          />
        </div>
        <div className="md:w-1/2">
          Use the icons in column header to sort the table by the values in that
          column.
        </div>
      </div>
      <div className="mb-6 pb-6 md:flex space-y-5 md:space-x-5 md:space-y-0 border-b">
        <div className="md:w-1/2 md:text-center">
          <img
            className="inline-block"
            src={S3Img2}
            alt="Filter selection."
            width={300}
          />
        </div>
        <div className="md:w-1/2">
          Once you select and apply a filter, your selection will be visible
          when opening filter selector or when clicking the icon next to Vehicle
          Filters.
        </div>
      </div>
      <div className="md:flex space-y-5 md:space-x-5 md:space-y-0">
        <div className="md:w-1/2 md:text-center">
          <img
            className="inline-block"
            src={S3Img3}
            alt="Removing a filter."
            width={300}
          />
        </div>
        <div className="md:w-1/2">
          You can remove the filter by clicking the “x” inside the chip of the
          attribute you want to remove. Alternatively, you can select "is not
          filtered" in the dropdown. When the chip turns red (to indicate it's
          pending removal), you still have to confirm the removal by clicking
          Apply.
        </div>
      </div>
    </div>
  </div>
);

const Step4 = () => (
  <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-4 items-start">
    <div className="md:px-10">
      <div className="mb-6 pb-6 md:flex space-y-5 md:space-x-5 md:space-y-0 border-b">
        <div className="md:w-1/2 md:text-center">
          <img
            className="inline-block"
            src={S4Img1}
            alt="Create a Collection from filters"
            width={180}
          />
        </div>
        <div className="md:w-1/2">
          If there is a set of filters you use repeatedly, you can use the icon
          highlighted in red to save it as a collection.
        </div>
      </div>
      <div className="mb-6 pb-6 md:flex space-y-5 md:space-x-5 md:space-y-0 border-b">
        <div className="md:w-1/2 md:text-center">
          <img
            className="inline-block"
            src={S4Img2}
            alt="Creating new Collection"
            width={250}
          />
        </div>
        <div className="md:w-1/2">
          After clicking the icon, you will be prompted to name, review, and
          submit the collection.
        </div>
      </div>
      <div className="md:flex space-y-5 md:space-x-5 md:space-y-0">
        <div className="md:w-1/2 md:text-center">
          <img
            className="inline-block"
            src={S4Img3}
            alt="Collection filter now available"
            width={180}
          />
        </div>
        <div className="md:w-1/2">
          That collection will then become available as a filter option on the
          Vehicles page and elsewhere in the application, such as on the Failure
          Modes page.
        </div>
      </div>
    </div>
  </div>
);

const Step5 = () => (
  <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-4 items-start">
    <div className="md:px-10">
      <div className="md:flex space-y-5 md:space-x-5 md:space-y-0">
        <div className="md:w-1/2 md:text-center">
          <img
            className="inline-block"
            src={S5Img1}
            alt="Exporting"
            width={180}
          />
        </div>
        <div className="md:w-1/2">
          You can export the table as an Excel file (.xlsx) using the download
          icon on the top right.
        </div>
      </div>
    </div>
  </div>
);

const steps: Step[] = [
  {
    label: "Failure Mode Columns",
    active: true,
    content: Step1,
  },
  {
    label: "Failure Mode Attributes",
    content: Step2,
  },
  {
    label: "Filtering and Sorting",
    content: Step3,
  },
  {
    label: "Creating Collections",
    content: Step4,
  },
  {
    label: "Exporting",
    content: Step5,
  },
];

export default steps;
