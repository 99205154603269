import { RefObject, useState } from "react";
import { createPortal } from "react-dom";

import { DUCK_FLOATING_KEY } from "./constants";
import DraggableDuck from "./DraggableDuck";
import Duck from "./Duck";

interface DuckNestProps {
  draggableDuckRef: RefObject<HTMLDivElement | null>;
}

const DuckNest = ({ draggableDuckRef }: DuckNestProps) => {
  const initialDraggable = sessionStorage.getItem(DUCK_FLOATING_KEY) === "true";
  const [isDuckDraggable, internalSetIsDuckDraggable] =
    useState(initialDraggable);

  const setIsDuckDraggable = (isDraggable: boolean): void => {
    internalSetIsDuckDraggable(isDraggable);
    sessionStorage.setItem(DUCK_FLOATING_KEY, String(isDraggable));
  };

  return (
    <>
      {isDuckDraggable &&
        draggableDuckRef.current &&
        createPortal(
          <DraggableDuck setIsDraggable={() => setIsDuckDraggable(false)} />,
          draggableDuckRef.current
        )}

      {!isDuckDraggable && (
        // This empty div is necessary for Duck to correctly set its height.
        <div>
          <Duck setIsDraggable={setIsDuckDraggable} />
        </div>
      )}
    </>
  );
};

export default DuckNest;
