import {
  ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
  ATTRIBUTE_RETRIEVAL_K,
  ATTRIBUTE_SOURCE_KEYS,
} from "duck/graph/constants";
import {
  validateFilter,
  validateSort,
} from "duck/graph/nodes/VehiclesAgent/tools/utils";
import { PageHandler } from "duck/graph/PageHandler";
import getAttributeRetrievalTool from "duck/graph/tools/getAttributeRetrievalTool";
import getSortTableTool from "duck/graph/tools/getSortTableTool";
import getUpdateFilterTool from "duck/graph/tools/getUpdateFilterTool";
import { DuckGraphParams } from "duck/graph/types";
import { StructuredTool } from "@langchain/core/tools";

import { VEHICLES_PAGE_KEY } from "pages/Vehicles/constants";

/**
 * @summary Get the tools for the vin view agent.
 * The tools are specific to vin view, and are bound to the UI handlers
 * for vin view.
 * @param pageHandler Provides functions to adjust the url and query params.
 * @returns The full set of tools for the signal event analytics agent.
 */
const getTools = (
  params: DuckGraphParams,
  pageHandler: PageHandler
): StructuredTool[] => [
  getAttributeRetrievalTool(
    ATTRIBUTE_SOURCE_KEYS.Vehicle,
    ATTRIBUTE_RETRIEVAL_K,
    ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
    params.uiHandlers.setEphemeralMessage
  ),
  getUpdateFilterTool({
    pageHandler,
    pageKey: VEHICLES_PAGE_KEY,
    validateFunction: validateFilter,
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    source: "Vehicles",
  }),
  getSortTableTool({
    pageHandler,
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    validateFunction: validateSort,
    pageKey: VEHICLES_PAGE_KEY,
    source: "Vehicles",
  }),
];

export default getTools;
