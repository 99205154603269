import { useFlags } from "launchdarkly-react-client-sdk";

import VehiclesV0 from "pages/V0_Vehicles";
import Vehicles, { VehiclePageProps } from "pages/Vehicles/Vehicles";

/* 
This component helps direct users from /vehicles route to correct Vehicles component based on feature flags. Logic is as follows:
1. Users with only legacy FM (PACCAR) would like to have /vehicles route that shows them V0 vehicles (by V0 I mean v0 failure modes)
2. Users with only V1 Failure Modes would like to have /vehicles route that shows them V1 vehicles
3. Users with both legacy and V1 failure modes (Aisha for a short while) would like to see both items in the menu and have option to see both side by side. If they would arrive to /vehicles by url, they should go to V0 vehicles
*/
const VehiclesV0PageFlagsSwitch = (props: VehiclePageProps) => {
  const { legacyFailureModes } = useFlags();

  if (legacyFailureModes) {
    return <VehiclesV0 {...props} />;
  }

  return <Vehicles {...props} />;
};

export default VehiclesV0PageFlagsSwitch;
