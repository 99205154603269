import {
  getEphemeralMessageForNode,
  NodeOutputType,
} from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import { StringSetter, UIHandlers } from "duck/graph/types";
import { getLangSmithClient, NodeNames } from "duck/graph/utils";
import { AIMessage } from "@langchain/core/messages";
import { RunnableConfig } from "@langchain/core/runnables";

const getNode =
  (
    setEphemeralMessage: StringSetter,
    setAgentResponseHandler: UIHandlers["setAgentResponse"]
  ) =>
  async (
    state: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    const { messages, previousRunId } = state;

    if (!previousRunId) {
      console.log(
        "[SubmitFeedbackNode]: No previousRunId was found in the state. This should only happen if the user tries to submit feedback without a previous run to comment on."
      );
      setAgentResponseHandler(
        "Sorry, I couldn't submit your feedback because you haven't yet asked me anything. Please ask me something, then provide feedback about my response."
      );
      return {};
    }

    setEphemeralMessage(getEphemeralMessageForNode(NodeNames.SUBMIT_FEEDBACK));

    // we get routed here if the last message is an AI message with a tool_call named "SUBMIT_FEEDBACK"
    const lastMessage = messages[messages.length - 1] as AIMessage;
    const toolCall = lastMessage.tool_calls
      ? lastMessage.tool_calls[0]
      : undefined;

    // this should never happen but just in case
    if (!toolCall || toolCall.name !== NodeNames.SUBMIT_FEEDBACK) {
      throw new Error(
        `Invalid state: we arrived in the submitFeedback Node without calling the "${NodeNames.SUBMIT_FEEDBACK}" tool. The last message was ${JSON.stringify(lastMessage)}`
      );
    }

    console.debug("[SubmitFeedbackNode]: Submitting feedback", toolCall.args);

    const smithClient = getLangSmithClient();
    await smithClient.createFeedback(previousRunId, "user-feedback", {
      ...toolCall.args,
    });

    setAgentResponseHandler("Noted! Thank you for your feedback.");

    return {};
  };

export default getNode;
