import { APIFilter } from "shared/api/utils";
import { MileageUnit } from "shared/types";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { FilterState } from "features/ui/Filters/types";

import { RoutesValues } from "services/routes";

import * as conard from "./conard";
import * as daf from "./daf";
import * as demo from "./demo";
import * as dev from "./dev";
import * as ford from "./ford";
import * as gm from "./gm";
import * as hermesnvc from "./hermesnvc";
import * as knightswift from "./knightswift";
import * as loadtest from "./loadtest";
import * as local from "./local";
import * as nelbro from "./nelbro";
import * as nikola from "./nikola";
import * as nissan from "./nissan";
import * as paccar from "./paccar";
import * as sridemo from "./sridemo";

export const SUPPORT_EMAIL = "support@viaduct.ai";

interface MetabaseDashboardFilter {
  name: string;
  id: string;
  key: string;
  search?: boolean;
  hide?: boolean;
}

export type MetabaseDashboardFilterConfig =
  | {
      [group: string]: MetabaseDashboardFilter[];
    }
  | MetabaseDashboardFilter[];
interface BrandingConfig {
  feedbackStyles?: Object;
}

interface HeapAnalyticsConfig {
  id: string;
}

interface AnalyticsConfig {
  heap?: HeapAnalyticsConfig;
}

export type AuthScopes = "openid" | "email" | "offline_access";

interface AuthConfig {
  name: string;
  idp?: string;
  issuer: string;
  clientId: string;
  scopes: AuthScopes[];
  postLogoutRedirectUri?: string;
}

interface PageConfig {
  enabled: boolean;
}

interface GlobalConfig {
  mileageUnit: MileageUnit;
}

interface VehiclesConfig extends PageConfig {
  // pageWithFMCols shows failure mode columns & show/hide functionality + risk distribution section & tutorial
  // once no tenant is using the old page, we should remove this config + all the above mentioned components which are
  // very specific to the old page
  pageWithFMCols?: boolean;
}
interface VINViewConfig extends PageConfig {
  eventTimeline?: boolean;
  // Default from date in event timeline
  // Priority 1: eventTimelineFromDateVehicleAttribute
  // Priority 2: eventTimelineFromDate
  eventTimelineFromDateVehicleAttribute?: string;
  eventTimelineFromDate?: string;
  eventTimelineToDate?: string;
  defaultSignalEventFilters?: FilterState;
}
interface FleetsConfig extends PageConfig {}

interface EventsConfig extends PageConfig {
  hideMenuItem?: boolean;
  staticFilters?: APIFilter[];
  serviceRecordText?: string;
}
interface FailureModesConfig extends PageConfig {}
interface FailureModesV1Config extends PageConfig {}
interface SensorsConfig extends PageConfig {}
interface SimilarVehiclesConfig extends PageConfig {}
interface MaintenanceSchedulesConfig extends PageConfig {}
interface CollectionsConfig extends PageConfig {}
interface ServicePlansConfig extends PageConfig {}
interface AlertDefinitionsConfig extends PageConfig {}
interface DataExplorerConfig extends PageConfig {
  url: string;
}
// this is more Authentication related config, but we are already passing
// pagesConfig to the e2e docker image so leaving it as part of this for now
interface SingleIDPConfig extends PageConfig {
  unauthenticatedRedirect?: string;
  optionalAutoLogin?: boolean;
}

interface IssuesConfig extends PageConfig {
  topXChartsOptions?: string[];
  defaultBreakdownAttribute?: string;
  defaultFilters?: FilterGroupState;
}

interface SuggestedIssuesConfig extends PageConfig {}

interface ClaimAnalyticsConfig extends PageConfig {
  defaultClaimFilters?: FilterState;
  defaultVehicleFilters?: FilterState;
}

interface GroupByAttribute {
  id: string;
  defaultLabel: string;
}

interface SignalEventsAnalytics extends PageConfig {
  defaultSignalEventFilters?: FilterState;
  defaultVehicleFilters?: FilterState;
  defaultClaimGroupByAttribute?: GroupByAttribute;
}

interface CustomSignalEventsConfig extends PageConfig {
  eventPreviewFromDate?: string;
  eventPreviewToDate?: string;
}

interface ChatBotConfig extends PageConfig {
  url?: string;
}

interface RootPageConfig {
  route: RoutesValues;
}

interface NotFoundConfig {
  email: string;
}

interface SurvivalCurvesConfig extends PageConfig {}

export interface PagesConfig {
  global?: GlobalConfig;
  vinView?: VINViewConfig;
  vehicles?: VehiclesConfig;
  fleets?: FleetsConfig;
  events?: EventsConfig;
  failureModes?: FailureModesConfig;
  failureModesV1?: FailureModesV1Config;
  survivalCurves?: SurvivalCurvesConfig;
  similarVehicles?: SimilarVehiclesConfig;
  maintenanceSchedules?: MaintenanceSchedulesConfig;
  collections?: CollectionsConfig;
  notFound?: NotFoundConfig;
  rootPage?: RootPageConfig;
  dataExplorer?: DataExplorerConfig;
  singleIDPLogin?: SingleIDPConfig;
  servicePlans?: ServicePlansConfig;
  alertDefinitions?: AlertDefinitionsConfig;
  claimAnalytics?: ClaimAnalyticsConfig;
  repairAnalytics?: PageConfig;
  inspectionAnalytics?: PageConfig;
  signalEventsAnalytics?: SignalEventsAnalytics;
  sensors?: SensorsConfig;
  issues?: IssuesConfig;
  suggestedIssues?: SuggestedIssuesConfig;
  chatBot?: ChatBotConfig;
  dashboards?: DashboardsConfig;
  customSignalEvents?: CustomSignalEventsConfig;
  calculatedAttributes?: PageConfig;
}

interface DashboardConfig {
  name: string;
  id: string;
}

export interface DashboardsConfig extends PageConfig {
  dashboards: DashboardConfig[];
}

interface APIConfig {
  url: string;
}

interface FeatureFlagsConfig {
  launchDarklyClientId: string;
  anonymizeEmail?: string;
}

export interface AppConfig {
  environment: string;
  pages: PagesConfig;
  branding?: BrandingConfig;
  analytics: AnalyticsConfig;
  featureFlags: FeatureFlagsConfig;
  auth: AuthConfig;
  api: APIConfig;
  isIframed?: boolean;
  tenant?: string;
}

const configs: { [key: string]: AppConfig } = {
  [conard.host]: conard.prodAppConfig,
  [daf.host]: daf.prodAppConfig,
  [demo.host]: demo.prodAppConfig,
  [dev.host]: dev.appConfig,
  [ford.host]: ford.prodAppConfig,
  [gm.host]: gm.prodAppConfig,
  [hermesnvc.host]: hermesnvc.prodAppConfig,
  [knightswift.host]: knightswift.prodAppConfig,
  [loadtest.host]: loadtest.appConfig,
  [local.host]: local.appConfig,
  [nelbro.host]: nelbro.prodAppConfig,
  [nikola.host]: nikola.prodAppConfig,
  [nissan.host]: nissan.prodAppConfig,
  [paccar.host]: paccar.prodAppConfig,
  [sridemo.host]: sridemo.prodAppConfig,
};

export const get = (): AppConfig => {
  // Allow local override
  const hostname = process.env.REACT_APP_HOST || window.location.hostname;

  const config = configs[hostname];

  if (!config) {
    if (process.env.NODE_ENV === "development") {
      return getDefaultConfig();
    }
    throw new Error(`unknown config for ${hostname}`);
  }

  return config;
};

const getDefaultConfig = (): AppConfig => local.appConfig;
