import React from "react";
import classNames from "classnames";
import { GoGear as Icon } from "react-icons/go";
import { IconButton } from "@mui/material";

import DropdownSelect from "features/ui/DropdownSelect";

import { TITLE } from "./ChartActions";

type Props = {
  id?: string;
  chartTitle?: string;
  subTitle?: string;
  children?: React.ReactNode;
  showActions?: boolean;
  contentClassName?: string;
};

const OptionsElement = () => (
  <IconButton title={TITLE}>
    <Icon size={22} />
  </IconButton>
);

export const ChartActionsWrap = ({
  id = "",
  chartTitle,
  subTitle,
  children,
  showActions = true,
  contentClassName,
}: Props) => (
  <>
    <div
      className={classNames(
        "flex items-center space-x-2 z-[2] h-10 shrink-0 ",
        {
          "justify-between": chartTitle,
          "flex-nowrap items-end justify-end ml-3": !chartTitle,
        }
      )}
      onClick={(e) => e.stopPropagation()}
    >
      {chartTitle && (
        <h3 className="flex space-x-3 items-center font-semibold mb-1">
          {chartTitle}
        </h3>
      )}
      {showActions && (
        <DropdownSelect
          variant="text"
          buttonComponent={<OptionsElement />}
          content={
            <div className={classNames("p-4", contentClassName)}>
              {children}
            </div>
          }
          testId={`chart-actions-cta-${id}`}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      )}
    </div>
    {subTitle && <div className="text-viaduct-black text-sm">{subTitle}</div>}
  </>
);
