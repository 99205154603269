import React, { useMemo, useState } from "react";
import qs from "qs";
import { useNavigate } from "react-router-dom";

import {
  RepairAssociatedSignalEvent,
  RepairAssociatedSignalEventsRequest,
} from "shared/api/repairs/api";
import { useListRepairsAssociatedSignalEvents } from "shared/api/repairs/hooks";
import { getSortFilter } from "shared/api/utils";
import { SIGNAL_EVENTS_GENERIC_FILTER } from "shared/filterDefinitions";
import { SortBy } from "shared/types";
import { pluralize } from "shared/utils";

import { RepairAnalyticsTabsProps } from "pages/RepairAnalytics/RepairAnalyticsTabs";
import { SIGNAL_EVENTS_PAGE_KEY } from "pages/SignalEventsAnalytics/constants";
import { VEHICLES_PAGE_KEY } from "pages/Vehicles/constants";

import APIError from "features/ui/APIError";
import { getCheckboxCheckedProps } from "features/ui/Checkbox/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import AssociatedSignalEventsRelatesFilter from "features/ui/Filters/FilterTypes/RelatesFilter/AssociatedSignalEventsRelatesFilter";
import { useAssociatedSignalEventsRelatesFilter } from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/hooks";
import RelatesFilterActions from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesFilterActions";
import { WINDOW_DIRECTION_OPTION_BEFORE } from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesTimeWindowForm";
import SelectedRowsActions from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/SelectedRowsActions";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterOperator } from "features/ui/Filters/types";
import { getFiltersKey } from "features/ui/Filters/utils";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { Selectable } from "features/ui/Table/Table";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCellWithCheckbox from "features/ui/Table/TableCellWithCheckbox";

import { routes } from "services/routes";

// table filters key
const PAGE_KEY = "repairAnalytics-associated-se";
const DEFAULT_SORT: SortBy = { associationStrength: "desc" };
const ROWS_PER_PAGE = 20;

// SE filter above table key
const ASSOCIATED_SE_PAGE_KEY = "repairAnalytics-associated-se-filters";
const PENDING_FILTERS_ASSOCIATED_SE_LS_KEY = getPendingFiltersKey(
  ASSOCIATED_SE_PAGE_KEY
);

const RELATES_FILTER_KEY = "repairs-associated-se-relates-filter-v2";

export const getAssociatedSignalEventsSchema = (
  selectedPeriod: string,
  selectableOptions?: Selectable
): SchemaEntry[] => [
  {
    label: "Associated Signal Event",
    accessor: "signalEventID",
    dataType: DataType.JSX,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      label: "Associated Signal Event",
      fieldName: "signalEventID",
      search: true,
      filterType: "string",
      fieldNameForAPI: "ID",
      disableFiltering: true,
    }),
    selectable: selectableOptions,
  },
  {
    label: "Description",
    accessor: "signalEventDescription",
    dataType: DataType.STRING,
    limitedWidthClass: "max-w-xs",
  },
  {
    label: "Repair rate",
    accessor: "IPTV",
    dataType: DataType.NUMBER,
    sortable: true,
    description: `Repair rate per 1000 vehicles experiencing this signal event within ${selectedPeriod} of its occurrence`,
  },
  {
    label: "Association strength",
    accessor: "associationStrength",
    dataType: DataType.NUMBER,
    sortable: true,
    description: (
      <div className="text-left">
        A measure of the association between the signal events and the set of
        repairs. In particular, the association strength shows how many times
        more likely a vehicle which experiences the signal event is to go on to
        eventually have a repair than the average vehicle in the population (for
        example, association strength higher than 1 indicates that vehicles are
        more likely to experience the failure if they’ve experienced the signal
        event). A higher association strength indicates a higher likelihood of a
        non-random relationship between the signal event and the set of repairs.
        Empty cell indicates a failure without sufficient evidence of
        correlation.
      </div>
    ),
  },
  {
    label: "Repairs w/ preceding SE",
    accessor: "percentRepairsWithPreceding30DaysSignalEvent",
    dataType: DataType.PERCENTAGE,
    description: `Percent of repairs with preceding signal event within ${selectedPeriod}`,
    sortable: true,
  },

  {
    label: "Total signal event occurrences",
    accessor: "totalEventOccurrences",
    dataType: DataType.NUMBER,
    description: `Total number of signal event occurrences within ${selectedPeriod} prior to the defined repair set`,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      label: "Total signal event occurrences",
      fieldName: "totalEventOccurrences",
      filterType: "number",
      disableSelectFilters: true,
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Associated Repairs",
    accessor: "numAssociatedRepairs",
    dataType: DataType.NUMBER,
    description: `The total number of repairs that occur up to ${selectedPeriod} after a signal event`,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      label: "Associated Repairs",
      fieldName: "numAssociatedRepairs",
      filterType: "number",
      disableSelectFilters: true,
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Associated Vehicles",
    accessor: "numAssociatedVehicles",
    dataType: DataType.NUMBER,
    description: `The total number of unique vehicles with at least one repair occurring up to ${selectedPeriod} after a signal event.`,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      label: "Associated Vehicles",
      fieldName: "numAssociatedVehicles",
      filterType: "number",
      disableSelectFilters: true,
      onlyAllowPositiveIntegers: true,
    }),
  },
];

const formatRow = (
  row: RepairAssociatedSignalEvent,
  selectedSignalEvents: Set<string>,
  setSelectedSignalEvents: (events: Set<string>) => void
) => ({
  ...row,
  signalEventID: (
    <TableCellWithCheckbox
      value={row.signalEventID}
      selectedValues={selectedSignalEvents}
      setSelectedValues={setSelectedSignalEvents}
      testId="checkbox-associated-signal-event"
    />
  ),
});

const AssociatedSignalEvents = ({
  repairsFiltersFilterSortState,
  vehiclesFilters,
  onBadRequest,
}: RepairAnalyticsTabsProps) => {
  // navigation logic
  const navigate = useNavigate();

  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);
  const signalEventsFilterKey = getFiltersKey(SIGNAL_EVENTS_PAGE_KEY);

  const navigateToSignalEventAnalytics = () => {
    const signalEventIDFilter: FilterGroupState = filterStateToFilterGroupState(
      {
        signalEventID: {
          operator: FilterOperator.IN,
          values: Array.from(selectedSignalEvents),
        },
      }
    );

    navigate({
      pathname: routes.signalEventAnalytics,
      search: qs.stringify({
        [vehicleFilterKey]: getFiltersQuery(vehiclesFilters),
        [signalEventsFilterKey]: getFiltersQuery(signalEventIDFilter),
      }),
    });
  };

  // filter logic
  const {
    relatesFilter,
    setRelatesFilter,
    appliedRelatesFilter,
    setAppliedRelatesFilter,
    associatedSignalEventsFilterSortState,
    onApply,
    onCancel,
  } = useAssociatedSignalEventsRelatesFilter(
    ASSOCIATED_SE_PAGE_KEY,
    PENDING_FILTERS_ASSOCIATED_SE_LS_KEY,
    RELATES_FILTER_KEY
  );

  // table logic
  const [selectedSignalEvents, setSelectedSignalEvents] = useState(
    new Set<string>()
  );

  const repairsFilters = repairsFiltersFilterSortState?.filters;

  // table filters
  const {
    manageFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const windowSize = parseInt(
    appliedRelatesFilter.options.windowSize.toString()
  );

  const requestParams: RepairAssociatedSignalEventsRequest = {
    sort: getSortFilter(sort),
    filter: getFiltersQuery(filters),
    repairsFilter: getFiltersQuery(repairsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    signalEventOccurrencesFilter: getFiltersQuery(appliedRelatesFilter.filters),
    limit: ROWS_PER_PAGE,
    signalEventsTimeWindow: windowSize,
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListRepairsAssociatedSignalEvents(requestParams);

  const allSelectableValues = data?.map((x) => x.signalEventID) || [];
  const { allChecked, indeterminateChecked } = getCheckboxCheckedProps(
    selectedSignalEvents,
    allSelectableValues
  );

  const toggleSelectedSignalEvents = () => {
    if (allChecked) {
      setSelectedSignalEvents(new Set<string>());
      return;
    }
    setSelectedSignalEvents(new Set<string>(allSelectableValues));
  };

  const selectedPeriod = `${windowSize} ${pluralize("day", windowSize)}`;

  const schema = getAssociatedSignalEventsSchema(selectedPeriod, {
    onClick: toggleSelectedSignalEvents,
    checked: allChecked,
    indeterminate: indeterminateChecked,
  });

  const formattedData = useMemo(
    () =>
      data?.map((row) =>
        formatRow(row, selectedSignalEvents, setSelectedSignalEvents)
      ),
    [data, selectedSignalEvents]
  );

  return (
    <>
      <div className="mt-4 space-y-2 flex flex-wrap leading-10 space-x-2 items-end mb-6">
        <AssociatedSignalEventsRelatesFilter
          relatesFilter={relatesFilter}
          setRelatesFilter={setRelatesFilter}
          appliedRelatesFilter={appliedRelatesFilter}
          setAppliedRelatesFilter={setAppliedRelatesFilter}
          filterSortState={associatedSignalEventsFilterSortState}
          pendingFiltersKey={PENDING_FILTERS_ASSOCIATED_SE_LS_KEY}
          baseEntityText="Repair's Repair Date"
          windowDirectionOptions={[WINDOW_DIRECTION_OPTION_BEFORE]}
        />
        <RelatesFilterActions
          relatesState={relatesFilter}
          appliedRelatesFilter={appliedRelatesFilter}
          onApply={onApply}
          onCancel={onCancel}
        />
      </div>
      <SelectedRowsActions
        filterSortState={repairsFiltersFilterSortState}
        relatesFilter={appliedRelatesFilter}
        selectedSignalEvents={selectedSignalEvents}
        onExploreInSEAnalyticsActionClick={navigateToSignalEventAnalytics}
      />
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
          testId="associated-signal-events-table"
        />
      )}
      {error && (
        <APIError
          error={error}
          onBadRequest={() => {
            resetFilterSortState();
            onBadRequest();
          }}
        />
      )}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default AssociatedSignalEvents;
