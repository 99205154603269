import React, { useEffect, useState } from "react";

import { getNumberOfMembers } from "pages/Groups/utils";

import Button from "features/ui/Button";
import { isValidEmail } from "features/ui/PermissionsDialog/utils";

import { GroupMemberEntry } from "./utils";

interface AddMemberButtonProps {
  email: string;
  currentMembers: GroupMemberEntry[];
  onAddMember: () => void;
}

const AddMemberButton = ({
  email,
  currentMembers,
  onAddMember,
}: AddMemberButtonProps) => {
  const [buttonEnabled, setButtonEnabled] = useState(false);

  useEffect(() => {
    if (!isValidEmail(email)) {
      setButtonEnabled(false);
      return;
    }

    // is duplicated user
    const isDuplicatedUser = currentMembers.find(
      (m) => m.ID && m.email.toLowerCase() === email.toLowerCase()
    );
    setButtonEnabled(!Boolean(isDuplicatedUser));
  }, [currentMembers, email]);

  return (
    <Button
      color="primary"
      label="Add"
      size="small"
      className="w-20"
      onClick={onAddMember}
      disabled={!buttonEnabled}
      testId="add-group-member-cta"
    />
  );
};

interface RemoveMemberButtonProps {
  id: string;
  email: string;
  onRemoveMember: (id: string) => void;
}

const RemoveMemberButton = ({
  id,
  email,
  onRemoveMember,
}: RemoveMemberButtonProps) => (
  <Button
    variant="text"
    label="Remove"
    size="small"
    onClick={() => onRemoveMember(id)}
    testId={"remove-group-member-cta-" + email}
  />
);

interface Props {
  ID: string;
  email: string;
  isAdminGroup: boolean;
  currentMembers: GroupMemberEntry[];
  onAddMember: () => void;
  onRemoveMember: (id: string) => void;
}

const UserActions = ({
  ID,
  email,
  isAdminGroup,
  currentMembers,
  onAddMember,
  onRemoveMember,
}: Props) => {
  // Non-empty 'ID' means that member is already 'added' on the UI
  if (ID) {
    if (isAdminGroup && getNumberOfMembers(currentMembers) === 1) {
      return null;
    }
    return (
      <RemoveMemberButton
        id={ID}
        email={email}
        onRemoveMember={onRemoveMember}
      />
    );
  }
  return (
    <AddMemberButton
      email={email}
      currentMembers={currentMembers}
      onAddMember={onAddMember}
    />
  );
};

export default UserActions;
