import classNames from "classnames";

import { SimilarVehicle } from "shared/api/failureModes/api";
import { formatPercent } from "shared/utils";

interface Props {
  data: SimilarVehicle[];
  selected: string;
  onSelect: (vehicle: SimilarVehicle) => void;
}

const SimilarityModalVINList = ({ data, selected, onSelect }: Props) => {
  const list = data.map((currentSimilarVehicle) => {
    const { similarVIN, similarity } = currentSimilarVehicle;

    return (
      <div
        key={similarVIN}
        className={classNames(
          "p-2 flex flex-row flex-nowrap items-center cursor-pointer hover:bg-gray-200 rounded-lg",
          {
            "bg-gray-200": similarVIN === selected,
          }
        )}
        onClick={() => onSelect(currentSimilarVehicle)}
      >
        <div className="flex-1">{similarVIN}</div>
        <div className="text-gray-500">{formatPercent(similarity)}</div>
      </div>
    );
  });

  return (
    <div className="px-4 py-6 flex flex-col flex-nowrap justify-start bg-gray-100">
      <h4
        className="px-2 pb-4 font-bold leading-6 text-gray-900"
        data-testid="similarity-modal-title"
      >
        Similar Failed Vehicles
      </h4>
      <div className="w-60 text-sm">
        <div className="p-2 flex flex-row flex-nowrap items-center">
          <div className="flex-1">VIN</div>
          <div>Match</div>
        </div>
        <div data-testid="similar-vins-list">{list}</div>
      </div>
    </div>
  );
};

export default SimilarityModalVINList;
