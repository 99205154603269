import { getSortFilter } from "shared/api/utils";
import { useFailureModes } from "shared/api/v0_failureModes/hooks";

import { DEFAULT_NON_ARCHIVED_V0_FAILURE_MODES_FILTER } from "pages/V0_FailureModes/constants";

import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { SchemaEntry } from "features/ui/Table";

import { formatFailureModeToTableSchema } from "./utils";

/**
 * Since we do not have /attributes API for failure modes, we
 * are using the failure modes API to get the failure modes and
 * then build schemas manually.
 */

const useVehiclesFailureModesSchema = (
  skipRequest?: boolean
): SchemaEntry[] => {
  const { data: failureModesData } = useFailureModes({
    sort: getSortFilter({ status: "desc" }),
    filter: getFiltersQuery(
      filterStateToFilterGroupState(
        DEFAULT_NON_ARCHIVED_V0_FAILURE_MODES_FILTER
      )
    ),
    skipRequest,
  });

  if (skipRequest || !failureModesData || failureModesData.length === 0) {
    return [];
  }

  return failureModesData.reduce(
    (acc, failureMode) => [
      ...acc,
      ...formatFailureModeToTableSchema(failureMode, true),
    ],
    [] as SchemaEntry[]
  );
};

export default useVehiclesFailureModesSchema;
