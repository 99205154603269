import { AppConfig, AuthScopes, PagesConfig } from "config/config";

import pagesConfig from "./pagesConfig.json";

export const tenant = "demo";
export const host = `${tenant}.cloud.viaduct.ai`;

const analyticsConfigProd = {
  heap: { id: "2946143066" },
};

const authConfig = {
  name: "Viaduct",
  idp: "0oa13z7dyOysdGFnU5d6",
  clientId: "0oa2ubsygogUM1ypf5d6",
  issuer: "https://viaduct-cloud.okta.com/oauth2/default",
  scopes: ["openid", "email", "offline_access"] as AuthScopes[],
};

const prodAPIConfig = {
  url: `https://api.${host}/v0`,
};

const featureFlagsProd = {
  launchDarklyClientId: "61dea5c38b9572137fce8226",
};

export const prodAppConfig: AppConfig = {
  tenant,
  environment: "prod",
  pages: pagesConfig as PagesConfig,
  analytics: analyticsConfigProd,
  featureFlags: featureFlagsProd,
  auth: authConfig,
  api: prodAPIConfig,
};
