import { useMemo } from "react";
import { generatePath, Link } from "react-router-dom";

import { getSortFilter } from "shared/api/utils";
import { FailureMode } from "shared/api/v0_failureModes/api";
import { useFailureModes } from "shared/api/v0_failureModes/hooks";
import { SortBy } from "shared/types";

import APIError from "features/ui/APIError";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell";
import Title from "features/ui/Title";

import { routes } from "services/routes";

import StatusBadge from "./StatusBadge";

const formatRow = (failureMode: FailureMode) => {
  const { ID, name, status } = failureMode;
  return {
    ...failureMode,
    name: (
      <Link
        to={{
          pathname: generatePath(routes.failureMode, {
            id: encodeURIComponent(ID),
          }),
        }}
        className="text-metabase-blue hover:underline"
      >
        {name}
      </Link>
    ),
    status: <StatusBadge status={status} />,
  };
};

const schema: SchemaEntry[] = [
  {
    label: "Name",
    accessor: "name",
    dataType: DataType.JSX,
  },
  {
    label: "Description",
    accessor: "description",
    dataType: DataType.STRING,
  },
  {
    label: "Status",
    accessor: "status",
    dataType: DataType.JSX,
    description: (
      <div className="text-left p-3">
        Status can be: <br />
        <ul className="list-disc pl-3">
          <li>
            <b>Under Review</b> for newly added failure modes.
          </li>
          <li>
            <b>Active</b> for live failure modes.
          </li>
          <li>
            <b>Archived</b> for retired failure modes
          </li>
        </ul>
        <br />
        Only <b>Active</b> failure modes regularly produce new predictions.
      </div>
    ),
  },
];

const ROWS_PER_PAGE = 10;
const DEFAULT_SORT: SortBy = {
  status: "desc",
  name: "asc",
};

const FailureModes = () => {
  const { data, isLoading, headers, error, ...paginationData } =
    useFailureModes({
      limit: ROWS_PER_PAGE,
      sort: getSortFilter(DEFAULT_SORT),
    });

  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data]);

  return (
    <>
      <PageHeaderWrapper>
        <Title text="Failure Modes" />
      </PageHeaderWrapper>

      <div className="mb-6" />
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={DEFAULT_SORT}
        />
      )}
      {error && <APIError error={error} />}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">
          Currently there's no Failure Modes.
        </div>
      )}
    </>
  );
};

export default FailureModes;
