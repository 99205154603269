import { useListClaimsCount } from "shared/api/claims/hooks";
import { EventTypeEnum } from "shared/types";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import TableCount from "features/ui/Table/TableCount";

interface Props {
  filters: FilterGroupState;
  vehicleFilters: FilterGroupState;
  label: string;
}

const ClaimCount = ({ filters, vehicleFilters, label }: Props) => {
  const { isLoading, data, error } = useListClaimsCount({
    filter: getFiltersQuery(filters),
    vehiclesFilter: getFiltersQuery(vehicleFilters),
    analytics: true,
  });

  return (
    <TableCount
      prefix={`${label}: `}
      isLoading={isLoading}
      count={data?.count}
      entityName={EventTypeEnum.CLAIM}
      error={Boolean(error)}
    />
  );
};
export default ClaimCount;
