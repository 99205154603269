import { UIHandlers } from "duck/graph/types";
import { z } from "zod";
import { tool } from "@langchain/core/tools";

import { vectorStoreSearch } from "shared/api/vectorstore/api";

import { validateIssueId } from "./utils";

const SearchIssuesSchema = z.object({
  query: z.string().describe("The search query for retrieving issues."),
});

type SearchIssues = z.infer<typeof SearchIssuesSchema>;

const getSearchIssuesTool = (
  setEphemeralMessage: UIHandlers["setEphemeralMessage"]
) => {
  const searchIssues = async ({ query }: SearchIssues) => {
    setEphemeralMessage("Searching for issues");
    console.debug("Searching issues with query:", query);

    const params = {
      query,
      k: 3,
      distanceThreshold: 2,
      source: "v1/issues",
    };

    let data = [];
    try {
      const response = await vectorStoreSearch(params);
      data = response.data;
    } catch (_vectorStoreError) {
      console.error(
        "Failed to retrieve issues from vector store:",
        _vectorStoreError
      );
      return "No issues found for the given query.";
    }

    if (!data || data.length === 0) {
      return "No issues found for the given query.";
    }

    const issues = await Promise.all(
      data.map(async (result) => {
        const { document, title } = result;
        console.debug("Issue retrieved:", {
          title,
          document,
        });

        try {
          const parsedDocument = JSON.parse(document);
          await validateIssueId(parsedDocument.ID);
          return parsedDocument;
        } catch (error) {
          console.error("Failed to parse issue document:", document, error);
          return null;
        }
      })
    );

    const validIssues = issues.filter(Boolean);

    if (validIssues.length === 0) {
      return "No issues found for the given query.";
    }

    return `Retrieved ${validIssues.length} issues:\n\n${JSON.stringify(validIssues, null, 2)}`;
  };

  return tool(searchIssues, {
    name: "searchIssues",
    description:
      "Use this tool to search for issues based on their name and descriptions.",
    schema: SearchIssuesSchema,
  });
};

export default getSearchIssuesTool;
