import { addDays } from "date-fns";
import { toDate } from "date-fns-tz";

import { VALUES_SEPARATOR } from "pages/Issues/constants";
import { getChartValues } from "pages/Issues/utils";

import CustomShape from "features/ui/charts/ScatterChart/CustomShape";

export const prepareDataForScatteredChart = (
  data: Record<string, any>[],
  lineDefinitions: any[],
  stackOffset: number = 10
) => {
  const chartPoints: Record<string, Record<string, Record<string, any>[]>> = {};

  const addToChartPoints = (
    x: number,
    y: string,
    point: Record<string, any>
  ) => {
    if (chartPoints?.[x] === undefined) {
      chartPoints[x] = {};
    }
    if (chartPoints?.[x]?.[y] === undefined) {
      chartPoints[x][y] = [];
    }
    chartPoints[x][y].push(point);
  };

  const perCodeTypeColors: Record<string, string> = {};
  lineDefinitions.forEach(({ key, color }) => {
    perCodeTypeColors[key] = color;
  });

  // convert received data into { x: { y: [point] } } structure
  const vins = getChartValues("VIN", data, [], false);
  vins.forEach((vin) => {
    const vinData = data.filter((x) => x["VIN"] === vin);

    const externalIdsPerVin = getChartValues("externalID", vinData, [], false);
    externalIdsPerVin.forEach((externalId, externalIdIdx) => {
      const claimData = vinData.filter((x) => x["externalID"] === externalId);
      const mainClaimData = claimData.filter((x) => x["codeType"]);
      const otherClaimData = claimData.filter((x) => !x["codeType"]);

      const claimDate =
        mainClaimData && mainClaimData.length > 0
          ? mainClaimData[0]["claimDate"]
          : "";
      const VINLabel =
        externalIdsPerVin.length > 1 ? `${vin}/${externalIdIdx + 1}` : vin;
      const yAxisKey = [VINLabel, vin, claimDate].join(VALUES_SEPARATOR);

      // add 'claim' to chart for VINs that we got some signal event data for
      if (mainClaimData && mainClaimData.length > 0) {
        addToChartPoints(0, yAxisKey, {
          ...mainClaimData[0],
          codeType: "",
          claimDate: toDate(claimDate),
          yVIN: yAxisKey,
          daysSinceClaim: 0,
          shape: "triangle",
          color: perCodeTypeColors[""],
          count: 1,
        });
      }

      // process signal events of main claim
      mainClaimData.forEach((claimEntry) => {
        addToChartPoints(claimEntry["daysSinceClaim"], yAxisKey, {
          ...claimEntry,
          yVIN: yAxisKey,
          shape: "circle",
          color: perCodeTypeColors[claimEntry["codeType"]],
        });
      });

      // process 'other' claims
      otherClaimData.forEach((otherClaim) => {
        addToChartPoints(otherClaim["daysSinceClaim"], yAxisKey, {
          ...otherClaim,
          claimDate: addDays(
            toDate(otherClaim["claimDate"]),
            otherClaim["daysSinceClaim"]
          ),
          yVIN: yAxisKey,
          laborCode: otherClaim["otherClaimLaborCode"],
          shape: "triangle",
          color: perCodeTypeColors[""],
          count: 1,
        });
      });
    });
  });

  const daysSinceClaim = Object.keys(chartPoints).map((x) => parseInt(x));
  const groupedPoints: Record<string, any>[] = [];

  // offsets to points and merge distinctSignalEvents
  daysSinceClaim.forEach((daysSinceClaim) => {
    Object.keys(chartPoints[daysSinceClaim]).forEach((vinAndExtIdIdx) => {
      let yOffset = 0;
      const points = chartPoints[daysSinceClaim][vinAndExtIdIdx];

      const claims = points.filter((x) => !x["codeType"]);
      const signalEvents = points.filter((x) => x["codeType"]);

      // apply yOffsets
      claims.forEach((claim) => {
        claim["yOffset"] = yOffset;
        groupedPoints.push(claim);
        yOffset += stackOffset;
      });

      // draw signal events (group them) after claims and group them if needed
      const seCodeTypes = getChartValues("codeType", signalEvents, [], false);
      seCodeTypes.forEach((seCodeType) => {
        const perCodeTypeSignalEvents = signalEvents.filter(
          (x) => x["codeType"] === seCodeType
        );

        // Merge distinctSignalEvents
        const distinctSignalEvents = [
          ...new Set(
            perCodeTypeSignalEvents
              .map((x) => x["distinctSignalEvents"])
              .flat(1)
          ),
        ];
        distinctSignalEvents.sort();

        groupedPoints.push({
          ...perCodeTypeSignalEvents[0],
          yOffset,
          count: perCodeTypeSignalEvents.length,
          distinctSignalEvents,
        });

        yOffset += stackOffset;
      });
    });
  });

  // Create <Scatter> entry for each code type
  const codeTypes = Object.keys(perCodeTypeColors).sort();
  const chartEntries = codeTypes.map((codeType) => ({
    data: groupedPoints.filter((x) => x["codeType"] === codeType),
    color: perCodeTypeColors[codeType],
    opacity: 1,
    shape: CustomShape,
    key: `scatter-${codeType}`,
  }));

  return {
    chartEntries,
    minDaysSinceClaim: Math.min(...daysSinceClaim),
    maxDaysSinceClaim: Math.max(...daysSinceClaim),
  };
};
