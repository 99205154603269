import qs from "qs";
import { Link } from "react-router-dom";

import {
  CLAIM_ID_QUERY_KEY,
  CLAIM_LABEL_QUERY_KEY,
  CLAIMS_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";

import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";

interface Props {
  ID: string;
  externalID: string | null;
}

const LABEL = "Analyze Similar Claims";
const CLAIM_ANALYTICS_FILTER_KEY = getFiltersKey(CLAIMS_PAGE_KEY);

const AnalyzeSimilarClaimsAction = ({ ID, externalID }: Props) => (
  <Link
    to={{
      pathname: routes.claimAnalytics,
      search: qs.stringify({
        [CLAIM_ANALYTICS_FILTER_KEY]: getFiltersQuery(
          filterStateToFilterGroupState({
            similarClaimID: {
              values: [ID],
              operator: FilterOperator.IN,
            },
          })
        ),
        [CLAIM_ID_QUERY_KEY]: ID,
        [CLAIM_LABEL_QUERY_KEY]: externalID,
      }),
    }}
    className="text-blue-400 hover:text-blue-500"
  >
    {LABEL}
  </Link>
);

export default AnalyzeSimilarClaimsAction;
