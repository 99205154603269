import { NodeOutputType, NodeType } from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import { AIMessage, RemoveMessage } from "@langchain/core/messages";
import { RunnableConfig } from "@langchain/core/runnables";

const getNode =
  (): NodeType =>
  async (
    { messages }: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    // we routed here because the last agent repeated the same tool call
    // delete the last AI message
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage instanceof AIMessage && lastMessage.id) {
        return {
          messages: [new RemoveMessage({ id: lastMessage.id })],
        };
      }
    }
    return {};
  };

export default getNode;
