import TextField, { BaseTextFieldProps } from "@mui/material/TextField";

import { TestProps } from "shared/types";

const DEFAULT_NUM_ROWS = 4;

export interface TextAreaProps extends TestProps, BaseTextFieldProps {
  value?: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

/**
 * Simple text area component wrapper around MUI's TextField
 */
const TextArea = ({
  label,
  value,
  onChange,
  testId,
  ...otherProps
}: TextAreaProps) => (
  <TextField
    id={label}
    label={label}
    value={value}
    onChange={onChange}
    variant="outlined"
    size="small"
    margin="none"
    fullWidth
    rows={DEFAULT_NUM_ROWS}
    multiline
    {...otherProps}
    slotProps={{
      htmlInput: {
        "data-testid": testId,
      },
    }}
  />
);

export default TextArea;
