import { PageHandler } from "duck/graph/PageHandler";
import { StringSetter } from "duck/graph/types";
import { StructuredTool, tool } from "@langchain/core/tools";

import { getIssue } from "shared/api/issues/api";

import {
  getTabAvailability,
  setRouteValue,
  WithIssueId,
  withIssueIdSchema,
} from "./utils";

const getGoToTabTool = (
  pageHandler: PageHandler,
  setEphemeralMessage: StringSetter,
  tabKey: string,
  tabTitle: string
): StructuredTool => {
  const goToTab = async ({ issueId }: WithIssueId) => {
    console.debug(`Navigating to the ${tabTitle} tab`, { issueId });

    try {
      const issueResponse = await getIssue({ id: issueId });
      const tabAvailable = getTabAvailability(issueResponse.data, tabKey);

      await setRouteValue(issueId, pageHandler);

      if (!tabAvailable) {
        setEphemeralMessage(`navigating to the Issue Details page`);
        pageHandler.navigateToPage();
        return `The ${tabTitle} tab is not available for this issue, navigating to the Issue Details page`;
      }

      setEphemeralMessage(`Navigating to the ${tabTitle} tab`);
      pageHandler.navigateToTab(tabKey);

      return "queued navigation to the tab";
    } catch (error) {
      console.error(`Failed to navigate to the ${tabTitle} tab`, error);
      throw error;
    }
  };

  return tool(goToTab, {
    name: `goTo${tabKey}Tab`,
    description: `Use this tool when the user wants to view the ${tabTitle} tab of a specific issue.`,
    schema: withIssueIdSchema,
  });
};

export default getGoToTabTool;
