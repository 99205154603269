import { AppConfig, AuthScopes, PagesConfig } from "config/config";

import pagesConfig from "./pagesConfig.json";

const authConfig = {
  name: "Viaduct",
  idp: "0oa13z7dyOysdGFnU5d6",
  clientId: "0oacl10juwyRzJ1R55d7",
  issuer: "https://viaduct-cloud.okta.com/oauth2/auscl0yzf595Eypg05d7",
  scopes: ["openid", "email", "offline_access"] as AuthScopes[],
};

const localAuthConfig = {
  name: "Viaduct",
  idp: "0oa13z7dyOysdGFnU5d6",
  clientId: "0oa2ubsygogUM1ypf5d6",
  issuer: "https://viaduct-cloud.okta.com/oauth2/default",
  scopes: ["openid", "email", "offline_access"] as AuthScopes[],
};

const tenant = "localhost";
export const host = tenant;

const apiConfig = {
  // use REACT_APP_API_BASE_URL from .env
  // default to / (local proxy)
  url: process.env.REACT_APP_API_BASE_URL || `/`,
};

const featureFlags = {
  launchDarklyClientId: "65268dcc9a881a1252489998",
};

const isAPILocal = process.env.REACT_APP_API_BASE_URL?.includes("localhost");

export const appConfig: AppConfig = {
  tenant,
  environment: "dev",
  pages: pagesConfig as PagesConfig,
  analytics: {},
  featureFlags,
  auth: isAPILocal ? localAuthConfig : authConfig,
  api: apiConfig,
};
