import { AppConfig, AuthScopes, PagesConfig } from "config/config";

import pagesConfig from "./pagesConfig.json";

export const tenant = "daf";
export const host = `${tenant}.cloud.eu.viaduct.ai`;

const analyticsConfigProd = {
  heap: { id: "1683419583" },
};

const authConfig = {
  name: "Viaduct",
  idp: "0oacmzefma0wkuYWJ417",
  clientId: "0oacrof0bh7ITZYGD417",
  issuer: "https://viaduct-cloud-eu.okta.com/oauth2/auscrog490M5A7knT417",
  scopes: ["openid", "email", "offline_access"] as AuthScopes[],
};

const prodAPIConfig = {
  url: `https://api.${host}/v0`,
};

const featureFlagsProd = {
  launchDarklyClientId: "660d437a7722a80fe1c77b2d",
  anonymizeEmail: "user@anonymous.com",
};

export const prodAppConfig: AppConfig = {
  tenant,
  environment: "prod",
  pages: pagesConfig as PagesConfig,
  analytics: analyticsConfigProd,
  featureFlags: featureFlagsProd,
  auth: authConfig,
  api: prodAPIConfig,
};
