import { format } from "date-fns";
import { GPT4O_MODEL_SPEC } from "duck/graph/constants";
import { DuckGraphParams } from "duck/graph/types";
import { DUCK_PREVIOUS_RUN_ID_KEY } from "duck/ui/constants";
import { hasAllEnvVarsForViaDuck } from "duck/ui/utils";
import { v4 as uuidv4 } from "uuid";
import {
  AIMessage,
  HumanMessage,
  SystemMessage,
} from "@langchain/core/messages";

import * as config from "config/config";

import getGraph from "./getGraph";
import { getTracer } from "./utils";

let previousRunId: string | undefined;
let currentRunId: string | undefined;

// Main function to call agent
const callAgent = async (params: DuckGraphParams): Promise<void> => {
  if (!hasAllEnvVarsForViaDuck()) {
    console.warn(
      "Missing environment variables. Please set REACT_APP_LANGCHAIN_ENDPOINT, REACT_APP_LANGCHAIN_API_KEY, REACT_APP_LANGCHAIN_PROJECT, and REACT_APP_OPENAI_API_KEY."
    );
    throw new Error("Missing environment variables.");
  }

  const agentExecutor = await getGraph(params, false);

  const messages = params.messageHistory.map((message) =>
    message.author === "human"
      ? new HumanMessage(message.message, { name: "USER" })
      : new AIMessage(message.message, { name: "DUCK" })
  );

  const formattedDate = format(new Date(), "EEEE MMMM d, yyyy");
  messages.push(
    new SystemMessage(`The current date is ${formattedDate}`, {
      name: "SYSTEM",
    })
  );

  messages.push(new HumanMessage(params.text, { name: "USER" }));

  const { environment } = config.get();

  const duckGraphVersion = require("./version.json").version;

  previousRunId =
    currentRunId ??
    sessionStorage.getItem(DUCK_PREVIOUS_RUN_ID_KEY) ??
    undefined;
  if (currentRunId) {
    sessionStorage.setItem(DUCK_PREVIOUS_RUN_ID_KEY, currentRunId);
  }
  currentRunId = uuidv4();

  const graphRunnableConfig = {
    configurable: { thread_id: params.threadId },
    callbacks: [getTracer()],
    runId: currentRunId,
    runName: "DUCK Graph",
    tags: [duckGraphVersion],
    metadata: {
      environment,
      tenant: params.tenant,
      user: params.user,
      duckGraphVersion,
      nodeEnv: process.env.NODE_ENV,
      lsModelName: GPT4O_MODEL_SPEC.modelName,
      lsProvider: "openai",
      lsTemperature: GPT4O_MODEL_SPEC.temperature,
    },
  };

  const response = await agentExecutor.invoke(
    {
      userInput: params.text,
      messages,
      pageState: params.currentState,
      previousRunId,
    },
    graphRunnableConfig
  );

  console.log({ response });
};

export default callAgent;
