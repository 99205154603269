import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { getFiltersKey } from "features/ui/Filters/utils";

import { UpdateFilterSortToolParams } from "./types";

const getUpdateFilterTool = ({
  pageHandler,
  pageKey,
  validateFunction,
  setEphemeralMessage,
  source,
}: UpdateFilterSortToolParams): DynamicStructuredTool => {
  const updateFilterSchema = z.object({
    filterString: z
      .string()
      .optional()
      .describe(
        `Filter string for ${source}. Use empty string to clear/reset filter. Call retrieve${source}Attributes to get the list of available attributes.`
      ),
  });

  type UpdateFilter = z.infer<typeof updateFilterSchema>;
  const updateFilter = async ({
    filterString,
  }: UpdateFilter): Promise<string> => {
    setEphemeralMessage(`update ${source} filter`);
    console.debug(`Updating ${source} filters`, { filterString });
    const filterQueryString = filterString || "";

    if (validateFunction) {
      await validateFunction(filterQueryString);
    }

    pageHandler.updateFilter(filterQueryString, getFiltersKey(pageKey));
    return `Queued update ${source} filter`;
  };

  return tool(updateFilter, {
    name: `update${source}Filter`,
    description: `Update the filter for ${source}. Only call this for ${source} attributes.
Use this tool if the user wants to:
- Modify the ${source} filter based on ${source} attributes
- Clear the ${source} filter`,
    schema: updateFilterSchema,
  });
};

export default getUpdateFilterTool;
