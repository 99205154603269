import { useMemo, useState } from "react";
import { toast } from "react-toastify";

import {
  AssociatedSignalEvent,
  ListSignalEventAssociatedSignalEventsRequest,
} from "shared/api/signalEvents/api";
import { useListSignalEventAssociatedSignalEvents } from "shared/api/signalEvents/hooks";
import { getSortFilter } from "shared/api/utils";
import {
  GENERIC_FILTER_WITHOUT_SELECT,
  SIGNAL_EVENTS_GENERIC_FILTER,
} from "shared/filterDefinitions";
import { SortBy } from "shared/types";

import { SignalEventsAnalyticsTabsProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import APIError from "features/ui/APIError";
import { getCheckboxCheckedProps } from "features/ui/Checkbox/utils";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import SelectedRowsActions from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/SelectedRowsActions";
import { encodeRelatesFilter } from "features/ui/Filters/FilterTypes/RelatesFilter/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { RelatesFilterState } from "features/ui/Filters/types";
import Table, { OnSortParams, SchemaEntry } from "features/ui/Table";
import { Selectable } from "features/ui/Table/Table";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCellWithCheckbox from "features/ui/Table/TableCellWithCheckbox";

const PAGE_KEY = "signalEventsAnalytics-associated-signal-events";
const DEFAULT_SORT: SortBy = { associationStrength: "desc" };
const LIMIT = 500;
const TABLE_HEIGHT_PX = 500;

const getAssociatedSignalEventsSchema = (
  selectableOptions?: Selectable
): SchemaEntry[] => [
  {
    label: "Associated Signal Event",
    accessor: "signalEventID",
    dataType: DataType.JSX,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      label: "Signal Event ID",
      fieldName: "signalEventID",
      search: true,
      filterType: "string",
      fieldNameForAPI: "ID",
      disableFiltering: true,
    }),
    selectable: selectableOptions,
  },
  {
    label: "Description",
    accessor: "description",
    dataType: DataType.STRING,
    limitedWidthClass: "max-w-xs",
  },
  {
    label: "Assoc. Strength",
    accessor: "associationStrength",
    dataType: DataType.NUMBER,
    description:
      "Measure of the correlation between the associated signal event and the base signal event.",
    sortable: true,
    filter: GENERIC_FILTER_WITHOUT_SELECT({
      label: "Assoc. Strength",
      fieldName: "associationStrength",
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Vehicles w/ Co-occurrence",
    accessor: "vehiclesWithCooccurrence",
    dataType: DataType.NUMBER,
    description:
      "How many vehicles had an instance of both associated and base signal events occurring within the time window.",
    sortable: true,
    filter: GENERIC_FILTER_WITHOUT_SELECT({
      label: "Vehicles w/ Co-occurrence",
      fieldName: "vehiclesWithCooccurrence",
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Assoc. Vehicles",
    accessor: "associatedVehicles",
    dataType: DataType.NUMBER,
    description:
      "Count of vehicles that experienced an associated signal event.",
    sortable: true,
    filter: GENERIC_FILTER_WITHOUT_SELECT({
      label: "Assoc. Vehicles",
      fieldName: "associatedVehicles",
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Base Vehicles",
    accessor: "baseVehicles",
    dataType: DataType.NUMBER,
    description: "Count of vehicles that experienced a base signal event.",
    sortable: true,
    filter: GENERIC_FILTER_WITHOUT_SELECT({
      label: "Base Vehicles",
      fieldName: "baseVehicles",
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Total Vehicles",
    accessor: "totalVehicles",
    dataType: DataType.NUMBER,
    description: "Number of eligible vehicles.",
    sortable: true,
    filter: GENERIC_FILTER_WITHOUT_SELECT({
      label: "Total Vehicles",
      fieldName: "totalVehicles",
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
];

const formatRow = (
  row: AssociatedSignalEvent,
  selectedSignalEvents: Set<string>,
  setSelectedSignalEvents: (events: Set<string>) => void
) => ({
  ...row,
  signalEventID: (
    <TableCellWithCheckbox
      value={row.signalEventID}
      selectedValues={selectedSignalEvents}
      setSelectedValues={setSelectedSignalEvents}
      testId="checkbox-associated-signal-event"
    />
  ),
});

interface Props extends SignalEventsAnalyticsTabsProps {
  relatesFilter: RelatesFilterState;
}

const AssociatedSignalEventsTable = ({
  signalEventsFiltersFilterSortState,
  vehiclesFilters,
  relatesFilter,
}: Props) => {
  const [selectedSignalEvents, setSelectedSignalEvents] = useState(
    new Set<string>()
  );

  const signalEventsFilters = signalEventsFiltersFilterSortState?.filters;

  const {
    manageFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const requestParams: ListSignalEventAssociatedSignalEventsRequest = {
    sort: getSortFilter(sort),
    filter: getFiltersQuery(filters),
    signalEventOccurrencesFilter: getFiltersQuery(signalEventsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    associatedSignalEventsFilter: encodeRelatesFilter(relatesFilter, false),
    limit: LIMIT,
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListSignalEventAssociatedSignalEvents(requestParams);

  const allSelectableValues = data?.map((x) => x.signalEventID) || [];
  const { allChecked, indeterminateChecked } = getCheckboxCheckedProps(
    selectedSignalEvents,
    allSelectableValues
  );

  const toggleSelectedSignalEvents = () => {
    if (allChecked) {
      setSelectedSignalEvents(new Set<string>());
      return;
    }
    setSelectedSignalEvents(new Set<string>(allSelectableValues));
  };

  const schema = getAssociatedSignalEventsSchema({
    onClick: toggleSelectedSignalEvents,
    checked: allChecked,
    indeterminate: indeterminateChecked,
  });

  const formattedData = useMemo(
    () =>
      data?.map((row) =>
        formatRow(row, selectedSignalEvents, setSelectedSignalEvents)
      ),
    [data, selectedSignalEvents]
  );

  const addAsRelatedSignalEvent = () => {
    setSelectedSignalEvents(new Set<string>());
    toast.success("Signal Event Filters updated");
  };

  return (
    <>
      <SelectedRowsActions
        filterSortState={signalEventsFiltersFilterSortState}
        relatesFilter={relatesFilter}
        selectedSignalEvents={selectedSignalEvents}
        onAddToFilter={addAsRelatedSignalEvent}
      />
      {!error && (
        <Table
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={LIMIT}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
          scrollHeight={TABLE_HEIGHT_PX}
          testId="associated-signal-events-table"
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default AssociatedSignalEventsTable;
