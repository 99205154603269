import { GPT4OMINI_MODEL_SPEC } from "duck/graph/constants";
import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import {
  createStrictToolCallingAgent,
  invokeAgentNode,
  NodeType,
} from "duck/graph/nodes/utils";
import getRespondToUserTool from "duck/graph/tools/getRespondToUserTool";
import { DuckGraphParams } from "duck/graph/types";
import { getLLM, NodeNames } from "duck/graph/utils";

const getNode = async (params: DuckGraphParams): Promise<NodeType> => {
  const prompt = await loadPrompt(promptNames.PAGE_AGENT_RESPONDER_AGENT);
  const llm = getLLM(GPT4OMINI_MODEL_SPEC);
  const respondToUserTool = getRespondToUserTool(
    params.uiHandlers.setAgentResponse,
    params.uiHandlers.setEphemeralMessage
  );
  const agent = createStrictToolCallingAgent(llm, [respondToUserTool], prompt);

  return invokeAgentNode({
    agent,
    name: NodeNames.PAGE_AGENT_RESPONDER,
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    duckAccess: params.uiHandlers.duckAccess,
    useScreenshot: false,
  });
};

export default getNode;
