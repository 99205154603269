import { useMemo } from "react";
import { generatePath } from "react-router-dom";

import { getIssueClaimsExport, IssueClaim } from "shared/api/issues/api";
import {
  useListIssueClaims,
  useListIssueClaimsCount,
} from "shared/api/issues/hooks";
import { applyAdditionalSorting, getSortFilter } from "shared/api/utils";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import {
  ISSUE_CLAIMS_GENERIC_FILTER,
  ISSUE_POPULATIONS_FILTER,
  SIGNAL_EVENTS_GENERIC_FILTER,
} from "shared/filterDefinitions";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { EventTypeEnum, IssueTypes, SortBy } from "shared/types";
import { formatNumber, getTenantMileageUnit } from "shared/utils";

import GoToClaimCellData from "pages/ClaimAnalytics/GoToClaimCellData";
import { SignalEventsColumn } from "pages/Issues/SignalEvents/SignalEventsColumn";
import {
  getBaseAPIRoute,
  getIssueCombinedID,
  isSuggestedIssue,
} from "pages/Issues/utils";
import { VINEventTimelineDateLink } from "pages/VINView/ServiceRecords/VINEventTimelineDateLink";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import StringList from "features/ui/StringList";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const formatRow = (issueClaim: IssueClaim) => {
  const {
    ID,
    VIN,
    date,
    populations,
    mileage,
    mileageUnit,
    costTotal,
    currencyCode,
    signalEventsTriggered,
    mentionedPhrases,
    mentionedSignalEvents,
  } = issueClaim;

  const mentionedSignalEventIDs =
    mentionedSignalEvents && mentionedSignalEvents.map(({ ID }) => ID);

  return {
    ...issueClaim,
    VIN: <VINEventTimelineDateLink VIN={VIN} date={date} />,
    ID: (
      <GoToClaimCellData
        path={generatePath(routes.claimAnalyticsDetails, {
          id: ID,
        })}
      />
    ),
    populations: populations?.join(", "),
    mileage:
      mileage !== undefined && mileage !== null
        ? `${formatNumber(mileage, 2)} ${mileageUnit}`
        : "",
    costTotal:
      costTotal !== undefined && costTotal !== null
        ? `${formatNumber(costTotal, 2)} ${currencyCode}`
        : "",
    signalEventsTriggered: signalEventsTriggered && (
      <SignalEventsColumn
        signalEventIDs={signalEventsTriggered}
        title="Signal Events"
      />
    ),
    mentionedPhrases: mentionedPhrases && (
      <StringList title="Mentioned Key Phrases" values={mentionedPhrases} />
    ),
    mentionedSignalEvents: mentionedSignalEventIDs && (
      <SignalEventsColumn
        signalEventIDs={mentionedSignalEventIDs}
        title="Mentioned Signal Events"
      />
    ),
  };
};

const ROWS_PER_PAGE = 10;
const DEFAULT_SORT: SortBy = { date: "desc" };

const IssueClaimsTable = ({ issue, withComparisonPopulation }: Props) => {
  const { ID, comparisonPopulationFilter, updatedAt } = issue;
  const pageKey = `issue_claims_${ID}`;

  const { getSchemaForAccessor, getSchemaEntry } = useClaimsSchema();

  const schemaItems: (SchemaEntry | boolean | undefined)[] = [
    getSchemaForAccessor("goTo"),
    // We can use getSchemaForAccessor when we do not need to change anything from the config of Claim Schema.
    // Usually when we do not need to disable filtering.
    getSchemaEntry("externalID", undefined, { disableFiltering: true }),
    getSchemaEntry(
      "VIN",
      { dataType: DataType.JSX },
      { disableFiltering: true }
    ),
    {
      label: "Population(s)",
      accessor: "populations",
      dataType: DataType.STRING,
      description: "Issue population(s).",
      filter: ISSUE_POPULATIONS_FILTER,
    },
    getSchemaForAccessor("date"),
    {
      label: "Previous Claims",
      accessor: "previousClaims",
      dataType: DataType.NUMBER,
      sortable: true,
      filter: ISSUE_CLAIMS_GENERIC_FILTER({
        label: "Previous Claims",
        fieldName: "previousClaims",
        filterType: "number",
        disableSelectFilters: true,
        onlyAllowPositiveIntegers: true,
      }),
    },
    getSchemaForAccessor("monthsInService"),
    getSchemaForAccessor("mileage"),
    getSchemaForAccessor("costTotal"),
    getSchemaEntry("failedPartNumber", undefined, { disableFiltering: true }),
    getSchemaForAccessor("failedPartInformation.description"),
    getSchemaEntry("laborCode", undefined, { disableFiltering: true }),
    getSchemaForAccessor("laborCodeInformation.description"),
    getSchemaForAccessor("notesTechnician"),
    getSchemaForAccessor("notesCustomer"),
    getSchemaForAccessor("notesCorrection"),
    getSchemaEntry("group", undefined, { disableFiltering: true }),
    getSchemaEntry("subgroup", undefined, { disableFiltering: true }),
    getSchemaEntry(
      "mentionedPhrases",
      {
        dataType: DataType.JSX,
        limitedWidthClass: "max-w-xs",
      },
      { disableFiltering: true }
    ),
    getSchemaForAccessor("mentionedSignalEvents"),
    {
      label: "Signal Event Occurrences",
      accessor: "signalEventsOccurrences",
      sortable: true,
      dataType: DataType.NUMBER,
      description: "Signal event occurrence count in previous 30 days.",
      filter: ISSUE_CLAIMS_GENERIC_FILTER({
        label: "Signal Event Occurrences",
        fieldName: "signalEventsOccurrences",
        filterType: "number",
        disableSelectFilters: true,
        onlyAllowPositiveIntegers: true,
      }),
    },
    {
      label: "Signal Events",
      accessor: "signalEventsTriggered",
      sortable: true,
      dataType: DataType.JSX,
      description: "Signal events triggered in previous 30 days.",
      filter: SIGNAL_EVENTS_GENERIC_FILTER({
        label: "Signal Events",
        fieldName: "signalEventsTriggered",
        fieldNameForAPI: "ID",
        search: true,
        filterType: "string",
        disableFiltering: true,
      }),
    },
  ];

  const schema = schemaItems.filter(Boolean) as SchemaEntry[];

  const {
    manageFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const includeComparisonPopulation =
    Boolean(comparisonPopulationFilter) && withComparisonPopulation;

  const requestParams = {
    IDs: getIssueCombinedID(issue),
    includeComparisonPopulation,
    baseRoute: getBaseAPIRoute(issue),
    sort: getSortFilter(applyAdditionalSorting(sort)),
    filter: getFiltersQuery(filters),
    limit: ROWS_PER_PAGE,
    mileageUnit: getTenantMileageUnit(),
    updatedAt,
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListIssueClaims(requestParams);

  const countRequestParams = {
    IDs: getIssueCombinedID(issue),
    includeComparisonPopulation,
    baseRoute: getBaseAPIRoute(issue),
    filter: getFiltersQuery(filters),
    updatedAt,
  };

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useListIssueClaimsCount(countRequestParams);

  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data]);

  const downloadDisabled = !formattedData || formattedData.length === 0;

  const fileName = isSuggestedIssue(issue)
    ? "suggested-issue-claims"
    : "issue-claims";

  return (
    <>
      <div className="flex items-center mt-3 mb-1">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto"
          count={countData?.count as number}
          entityName={EventTypeEnum.CLAIM}
          isLoading={countIsLoading}
          error={!!countError}
        />
        <DownloadAction
          disabled={downloadDisabled}
          downloadFunc={getIssueClaimsExport(issue)}
          fileName={fileName}
          requestParams={{
            IDs: requestParams.IDs,
            filter: requestParams.filter,
            limit: MAX_ROWS_DOWNLOAD_LIMIT,
            includeComparisonPopulation:
              requestParams.includeComparisonPopulation,
          }}
          count={countData?.count as number}
          entityName="issue claim"
          filters={filters}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default IssueClaimsTable;
