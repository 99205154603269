import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { getTablePageKey } from "pages/utils";

import { getPageKeyWithVersion, getQueryKeys } from "features/ui/Filters/utils";

import { UpdateFilterSortToolParams } from "./types";

const getSortTableTool = ({
  pageHandler,
  pageKey,
  validateFunction,
  setEphemeralMessage,
  source,
}: UpdateFilterSortToolParams): DynamicStructuredTool => {
  const response = `Queued sort table action for ${source}`;

  const sortSchema = z.object({
    sort: z
      .string()
      .optional()
      .describe(
        `Sort string to apply to the ${source} table.
  Use empty string to reset to the default sort.
  Call retrieve${source}Attributes to get the list of sortable attributes for ${source}.`
      ),
  });

  type Sort = z.infer<typeof sortSchema>;

  const sortTable = async ({ sort }: Sort): Promise<string> => {
    setEphemeralMessage(`sort ${source} table action`);
    console.debug(`sorting ${source} table`, { sort });

    const { sortKey: sortKeyRoot } = getQueryKeys(
      getPageKeyWithVersion(getTablePageKey(pageKey))
    );

    if (!sort) {
      pageHandler.updateSort(sortKeyRoot, "", "");
      return response;
    }

    const [sortKeySuffix, sortOrder] = sort.split("=");
    await validateFunction(sort);

    pageHandler.updateSort(sortKeyRoot, sortKeySuffix, sortOrder);
    return response;
  };

  return tool(sortTable, {
    name: `update${source}TableSort`,
    description: `Update the sort order of the ${source} table. Only call this for ${source} attributes.
Use this tool when the user wants to:
- Sort the ${source} table by a ${source} attribute
- Reset to the default sort order`,
    schema: sortSchema,
  });
};

export default getSortTableTool;
