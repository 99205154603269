import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { DialogContent } from "@mui/material";

import { SimilarVehicle } from "shared/api/failureModes/api";
import { useListSimilarVehicles } from "shared/api/failureModes/hooks";
import { getSortFilter } from "shared/api/utils";
import { SortBy } from "shared/types";

import SimilarityModalDetails from "./SimilarityModalDetails";
import SimilarityModalVINList from "./SimilarityModalVINList";

const SIMILAR_VINS_LIMIT = 10;

interface Props {
  currentVIN: string;
  failureModeID: string;
  failureModeName: string;
}

const DEFAULT_SORT: SortBy = { similarity: "desc" };

const SimilarityModalContent = ({
  currentVIN,
  failureModeID,
  failureModeName,
}: Props) => {
  const { data = [], isLoading } = useListSimilarVehicles({
    currentVIN,
    failureModeID,
    limit: SIMILAR_VINS_LIMIT,
    sort: getSortFilter(DEFAULT_SORT),
  });

  const [selected, setSelected] = useState<SimilarVehicle>(data[0]);

  useEffect(() => {
    if (!selected) setSelected(data[0]);
  }, [isLoading, selected, data]);

  if (isLoading || !(selected && data && data.length)) {
    return (
      <DialogContent>
        <Skeleton height={300} className="mb-3" count={2} />
      </DialogContent>
    );
  }

  const { similarity, similarVIN } = selected;

  return (
    <DialogContent className="flex space-x-4">
      <SimilarityModalVINList
        data={data}
        selected={similarVIN}
        onSelect={setSelected}
      />
      <SimilarityModalDetails
        failureModeID={failureModeID}
        similarity={similarity}
        similarVIN={similarVIN}
        currentVIN={currentVIN}
        failureModeName={failureModeName}
      />
    </DialogContent>
  );
};
export default SimilarityModalContent;
