import Button from "features/ui/Button";

interface Props {
  comparisonPopulationFilter?: string;
  onClick: () => void;
  value: boolean;
}

const ANALYZE_LABEL = "Analyze With Comparison Population";
const REMOVE_LABEL = "Remove Comparison";
const NO_COMPARISON_POPULATION = "No comparison population defined";

const AnalyzeWithComparisonPopButton = ({
  comparisonPopulationFilter,
  onClick,
  value,
}: Props) => (
  <div className="flex justify-end mb-3">
    <Button
      color="primary"
      label={value ? REMOVE_LABEL : ANALYZE_LABEL}
      onClick={onClick}
      disabled={!comparisonPopulationFilter}
      title={comparisonPopulationFilter ? "" : NO_COMPARISON_POPULATION}
      testId="with-comparison-population-cta"
    />
  </div>
);

export default AnalyzeWithComparisonPopButton;
