import React, { useState } from "react";
import classNames from "classnames";
import { GrEdit as EditIcon } from "react-icons/gr";
import { MdExpandMore as ExpandMoreIcon } from "react-icons/md";
import { IconButton, Popover } from "@mui/material";

import { AlertDefinition } from "shared/api/alertDefinitions/api";
import { pluralize } from "shared/utils";

import Button from "features/ui/Button";
import Card from "features/ui/Card";
import Tooltip from "features/ui/Tooltip";

import { SET_AS_SEEN_TEXT } from "./constants";
import WatchlistEditAlertForm from "./WatchlistEditAlertForm";

const POPOVER_ELEMENT_ID = "notifications-popover";
const CLEAR_ALERTS_TEXT = "Clear alerts";
const EDIT_TOOLTIP_TEXT = "Edit";

interface Props {
  count: number;
  alert: AlertDefinition;
  onClear: (alert: AlertDefinition) => void;
  onClose: () => void;
  onMarkAsSeen: (notificationId: string) => void;
}

const WatchlistNotificationsPopover = ({
  count,
  alert,
  onClose,
  onClear,
  onMarkAsSeen,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onEdit = () => {
    setIsEdit(true);
  };

  return (
    <>
      <button
        onClick={handleOpen}
        className={classNames(
          "w-full text-blue-400 flex flex-row items-center justify-between pl-1 pr-0.5",
          {
            "font-bold": open,
          }
        )}
      >
        <div className="flex flex-row items-center gap-1">
          {count}
          {alert.notifications.some((notification) => !notification.seenAt) && (
            <div className="rounded-full w-2 h-2 bg-red-500"></div>
          )}
        </div>

        <div
          className={classNames({
            "text-blue-400": open,
            "text-gray-400": !open,
          })}
        >
          <ExpandMoreIcon size={18} />
        </div>
      </button>
      <Popover
        id={POPOVER_ELEMENT_ID}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Card classNames="flex flex-col">
          {!isEdit && (
            <>
              {alert.notifications.map((notification, index) => {
                if (notification.seenAt) {
                  return (
                    <div className="text-sm flex flex-row text-blue-400 gap-2 w-full justify-center">
                      <span>{count}</span> {pluralize("Alert", count)}
                    </div>
                  );
                } else {
                  return (
                    <Tooltip
                      content={
                        <span className="text-xs">{SET_AS_SEEN_TEXT}</span>
                      }
                      placement="bottom"
                    >
                      <button
                        onClick={() => onMarkAsSeen(notification.ID)}
                        className="font-bold text-sm flex flex-row text-blue-400 gap-2 w-full justify-center"
                      >
                        <span>{count}</span> {pluralize("New alert", count)}
                      </button>
                    </Tooltip>
                  );
                }
              })}

              <div className="flex flex-row gap-4 mt-2">
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => onClear(alert)}
                  disabled={alert.notifications.length === 0}
                >
                  {CLEAR_ALERTS_TEXT}
                </Button>
                <IconButton
                  size="small"
                  onClick={onEdit}
                  data-testid="show-filter-summary"
                  className={classNames(
                    "pl-0 !text-gray-400 hover:!text-gray-500"
                  )}
                >
                  <Tooltip
                    content={
                      <span className="text-xs">{EDIT_TOOLTIP_TEXT}</span>
                    }
                    placement="left"
                  >
                    <EditIcon />
                  </Tooltip>
                </IconButton>
              </div>
            </>
          )}
          {isEdit && (
            <WatchlistEditAlertForm
              alert={alert}
              onClose={() => setIsEdit(false)}
            />
          )}
        </Card>
      </Popover>
    </>
  );
};

export default WatchlistNotificationsPopover;
