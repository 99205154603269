import { useContext } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { APIFilter } from "shared/api/utils";
import { VehicleECUsAttributeContext } from "shared/contexts/VehicleECUsAttributesContextWrapper";
import { VehicleOptionsAttributeContext } from "shared/contexts/VehicleOptionsAttributesContextWrapper";

import ServiceRecommendations from "pages/ServiceRecommendations";
import HealthTab from "pages/VINView/HealthTab";
import HealthTabV0 from "pages/VINView/V0_HealthTab";

import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import * as config from "config/config";

import {
  VIN_VIEW_ECUS_TAB_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
  VIN_VIEW_HEALTH_TAB_KEY,
  VIN_VIEW_OPTIONS_TAB_KEY,
  VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY,
  VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY,
  VIN_VIEW_SERVICE_RECORDS_TAB_KEY,
  VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY,
  VIN_VIEW_TABS,
} from "./constants";
import ECUs from "./ECUs";
import EventsTimeline from "./Events/EventsTimeline";
import Options from "./Options";
import ServiceRecords from "./ServiceRecords";
import ServiceSchedule from "./ServiceSchedule/";

const VEHICLE_SERVICE_RECORDS_COLUMNS = ["date", "type", "externalID"];

interface Props {
  vin: string;
}

const SERVICE_RECORDS_VIN_PAGE_KEY = (vin: string) => `service_records_${vin}`;
const SERVICE_RECOMMENDATIONS_PAGE_KEY = (vin: string) =>
  `service_recommendations_${vin}`;

const VINViewTabs = ({ vin }: Props) => {
  const { ECUs: ECUList } = useContext(VehicleECUsAttributeContext);
  const { options: optionsList } = useContext(VehicleOptionsAttributeContext);
  const {
    servicePlans: servicePlansFlag,
    eventTimeline: eventTimelineFlag,
    failureModesV1: failureModesV1Flag,
    legacyFailureModes: legacyFailureModesFlag,
  } = useFlags();

  const {
    pages: {
      failureModes,
      failureModesV1,
      events,
      maintenanceSchedules,
      servicePlans,
      vinView,
    },
  } = config.get();

  const staticFilters: APIFilter[] = [{ name: "VIN", op: "eq", value: vin }];

  const tabsItems: (Tab | boolean | undefined)[] = [
    failureModesV1?.enabled &&
      failureModesV1Flag && {
        key: VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY,
        title: VIN_VIEW_TABS[VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY],
        content: <HealthTab vin={vin} />,
      },
    failureModes?.enabled &&
      legacyFailureModesFlag && {
        key: VIN_VIEW_HEALTH_TAB_KEY,
        title: VIN_VIEW_TABS[VIN_VIEW_HEALTH_TAB_KEY],
        content: <HealthTabV0 vin={vin} />,
      },
    events?.enabled && {
      key: VIN_VIEW_SERVICE_RECORDS_TAB_KEY,
      title: VIN_VIEW_TABS[VIN_VIEW_SERVICE_RECORDS_TAB_KEY],
      content: (
        <ServiceRecords
          staticFilters={staticFilters}
          columns={VEHICLE_SERVICE_RECORDS_COLUMNS}
          pageKey={SERVICE_RECORDS_VIN_PAGE_KEY(vin)}
        />
      ),
    },
    maintenanceSchedules?.enabled && {
      key: VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY,
      title: VIN_VIEW_TABS[VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY],
      content: <ServiceSchedule vin={vin} />,
    },
    servicePlans?.enabled &&
      servicePlansFlag && {
        key: VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY,
        title: VIN_VIEW_TABS[VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY],
        content: (
          <ServiceRecommendations
            isInsideTab
            staticFilters={staticFilters}
            pageKey={SERVICE_RECOMMENDATIONS_PAGE_KEY(vin)}
          />
        ),
      },
    vinView?.eventTimeline &&
      eventTimelineFlag && {
        key: VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
        title: VIN_VIEW_TABS[VIN_VIEW_EVENTS_TIMELINE_TAB_KEY],
        content: <EventsTimeline vin={vin} staticFilters={staticFilters} />,
      },
    ECUList &&
      ECUList.length > 0 && {
        key: VIN_VIEW_ECUS_TAB_KEY,
        title: VIN_VIEW_TABS[VIN_VIEW_ECUS_TAB_KEY],
        content: <ECUs vin={vin} staticFilters={staticFilters} />,
      },
    optionsList &&
      optionsList.length > 0 && {
        key: VIN_VIEW_OPTIONS_TAB_KEY,
        title: VIN_VIEW_TABS[VIN_VIEW_OPTIONS_TAB_KEY],
        content: <Options vin={vin} staticFilters={staticFilters} />,
      },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return <StatefulTabs tabs={tabs} />;
};

export default VINViewTabs;
