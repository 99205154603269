import { useEffect, useState } from "react";
import { FiGift } from "react-icons/fi";

import "./Docs.css";

import MarkdownSection from "./MarkdownSection";
import content from "./notes/content";

const RELEASES = [
  { title: "Release 24.4.1 - October, 2024", version: "24.4.1" },
  { title: "Release 24.3.3 - September, 2024", version: "24.3.3" },
  { title: "Release 24.3.2 - August, 2024", version: "24.3.2" },
  { title: "Release 24.3.1 - July, 2024", version: "24.3.1" },
  { title: "Release 24.2.3 - June, 2024", version: "24.2.3" },
  { title: "Release 24.2.2 - May, 2024", version: "24.2.2" },
  { title: "Release 24.2.1 - April, 2024", version: "24.2.1" },
  { title: "Release 24.1.3 - March, 2024", version: "24.1.3" },
  { title: "Release 24.1.2 - February, 2024", version: "24.1.2" },
  { title: "Release 24.1.1 - January, 2024", version: "24.1.1" },
  { title: "Release 23.4.3 - December, 2023", version: "23.4.3" },
  { title: "Release 23.4.2 - November, 2023", version: "23.4.2" },
  { title: "Release 23.4.1 - October, 2023", version: "23.4.1" },
  { title: "Release 23.3.4 - September, 2023", version: "23.3.4" },
  { title: "Release 23.3.3 - August, 2023", version: "23.3.3" },
  { title: "Release 23.3.2 - July, 2023", version: "23.3.2" },
  { title: "Release 23.3.1 - July, 2023", version: "23.3.1" },
  { title: "Release 23.2.6 - June, 2023", version: "23.2.6" },
  { title: "Release 23.2.5 - May, 2023", version: "23.2.5" },
  { title: "Release 23.2.4 - May, 2023", version: "23.2.4" },
  { title: "Release 23.2.3 - April, 2023", version: "23.2.3" },
  { title: "Release 23.2.2 - April, 2023", version: "23.2.2" },
  { title: "Release 23.2.1 - April, 2023", version: "23.2.1" },
  { title: "Release 23.1.3 - March, 2023", version: "23.1.3" },
  { title: "Release 23.1.2 - February, 2023", version: "23.1.2" },
  { title: "Release 23.1.1 - January, 2023", version: "23.1.1" },
  { title: "Release 22.4.3 - December, 2022", version: "22.4.3" },
  { title: "Release 22.4.2 - November, 2022", version: "22.4.2" },
  { title: "Release 22.4.1 - October, 2022", version: "22.4.1" },
  { title: "Release 22.3.5 - September, 2022", version: "22.3.5" },
  { title: "Release 22.3.4 - August, 2022", version: "22.3.4" },
  { title: "Release 22.3.3 - August, 2022", version: "22.3.3" },
  { title: "Release 22.3.2 - August, 2022", version: "22.3.2" },
  { title: "Release 22.3.1 - July, 2022", version: "22.3.1" },
  { title: "Release 22.2.4 - June, 2022", version: "22.2.4" },
  { title: "Release 22.2.3 - May, 2022", version: "22.2.3" },
  { title: "Release 22.2.2 - May, 2022", version: "22.2.2" },
  { title: "Release 22.2.1 - April, 2022", version: "22.2.1" },
  { title: "Release 22.1.2 - April, 2022", version: "22.1.2" },
];

const LAST_VIEWED_LS_KEY = "last_viewed_release_notes";

export const hasSeenLatestReleaseNotes = () =>
  localStorage.getItem(LAST_VIEWED_LS_KEY) === RELEASES[0].version;

const ReleaseNotes = () => {
  const [seenLastNotes] = useState(hasSeenLatestReleaseNotes());

  useEffect(() => {
    localStorage.setItem(LAST_VIEWED_LS_KEY, RELEASES[0].version);
  }, []);

  return (
    <>
      {Object.values(RELEASES).map(({ version, title }, index) => (
        <div
          className="pr-8 pl-4"
          key={version}
          data-testid="release-notes-section"
        >
          <div
            className="flex items-center text-xl mt-4 mb-2 font-bold"
            data-testid="release-notes-section-title"
          >
            {index === 0 && !seenLastNotes && <FiGift className="mr-2" />}
            {title}
          </div>
          <div
            className="shadow-lg p-4 mb-8"
            data-testid="release-notes-section-content"
          >
            <MarkdownSection content={content[version]} />
          </div>
        </div>
      ))}
    </>
  );
};

export default ReleaseNotes;
