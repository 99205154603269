import { PermissionEntry } from "shared/api/api";
import { PermissionID } from "shared/types";

import Select from "features/ui/Select";

import { GENERAL_PERMISSION_TYPES } from "./utils";

const DESCRIPTION = "Control permissions for general users.";

interface Props {
  permission: PermissionEntry;
  onUpdatePermission: (access: PermissionID) => void;
}

const GeneralPermission = ({ permission, onUpdatePermission }: Props) => (
  <div className="flex flex-col">
    <Select
      label="General Access"
      options={GENERAL_PERMISSION_TYPES}
      selected={
        GENERAL_PERMISSION_TYPES.find(({ id }) => id === permission.access) ||
        GENERAL_PERMISSION_TYPES[0]
      }
      onSelect={(selectedOption) =>
        onUpdatePermission(selectedOption.id as PermissionID)
      }
      testId="general-permission-dropdown"
    />
    <p className="text-xs text-gray-500 ml-1">{DESCRIPTION}</p>
  </div>
);

export default GeneralPermission;
