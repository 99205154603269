import { PageHandler } from "duck/graph/PageHandler";
import { StringSetter } from "duck/graph/types";
import { DynamicTool, tool } from "@langchain/core/tools";

const getGoToTabTool = (
  pageHandler: PageHandler,
  setEphemeralMessage: StringSetter,
  tabKey: string,
  tabTitle: string
): DynamicTool => {
  const goToTab = () => {
    setEphemeralMessage(`to ${tabTitle} tab`);
    console.debug(`Navigating to ${tabTitle} tab`);
    pageHandler.navigateToTab(tabKey);
    return `Queued navigation to the ${tabTitle} tab`;
  };

  return tool(goToTab, {
    name: `goTo${tabKey}Tab`,
    description: `Queue an action to navigate to the ${tabTitle} tab.`,
  });
};

export default getGoToTabTool;
