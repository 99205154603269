import { FilterRowState } from "features/ui/Filters/FilterBuilder/types";
import { SchemaEntry } from "features/ui/Table";

import { OnRowDataChangeHandle } from "./FilterRow";
import OperatorSelect from "./OperatorSelect";
import ValuesSelect from "./ValuesSelect";

interface Props extends FilterRowState {
  onRowDataChange: OnRowDataChangeHandle;
  attributeSchema?: SchemaEntry;
  disabled?: boolean;
}

const DefaultFilterFields = ({
  id,
  attribute,
  operator,
  values,
  onRowDataChange,
  attributeSchema,
  disabled,
}: Props) => (
  <>
    {/* Filter operators */}
    {attribute && (
      <OperatorSelect
        selected={operator}
        onChange={(selected) => onRowDataChange(id, "operator", selected)}
        attributeSchema={attributeSchema}
        disabled={disabled}
      />
    )}
    {/* Filter values */}
    <ValuesSelect
      attribute={attribute}
      operator={operator}
      values={values}
      schema={attributeSchema}
      onChange={(values) => onRowDataChange(id, "values", values)}
      disabled={disabled}
    />
  </>
);

export default DefaultFilterFields;
