import React from "react";

import BarChart from "features/ui/charts/BarChart";
import { DataElement } from "features/ui/charts/types";

import { LabelDescriptionRotatedTick } from "./LabelDescriptionRotatedTick";

interface Props {
  data: DataElement[];
  xAxisKey: string;
  yAxisKey: string;
  yAxisLabel: string;
  tooltipProps?: Record<string, any>;
}

const RateBarChart = ({
  data,
  xAxisKey,
  yAxisKey,
  yAxisLabel,
  tooltipProps,
}: Props) => (
  <BarChart
    data={data}
    xAxisKey={xAxisKey}
    yAxisKey={yAxisKey}
    yAxisLabel={yAxisLabel}
    xAxisProps={{
      textAnchor: "end",
      interval: 0,
      height: 100,
    }}
    customTick={<LabelDescriptionRotatedTick />}
    tooltipProps={tooltipProps}
  />
);

export default RateBarChart;
