import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { FaRegEyeSlash as EyeIcon } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";

import {
  AlertDefinition,
  deleteAlertDefinition,
} from "shared/api/alertDefinitions/api";
import { useListAlertDefinitions } from "shared/api/alertDefinitions/hooks";
import {
  clearNotifications as clearNotificationsRequest,
  setNotificationsAsSeen,
} from "shared/api/notifications/api";
import { useEmailFromJWT } from "shared/hooks";
import { pluralize } from "shared/utils";

import { WATCHLIST_TITLE } from "pages/LandingPage/constants";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import { getCheckboxCheckedProps } from "features/ui/Checkbox/utils";
import ConfirmationModal from "features/ui/ConfirmationModal";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { OnSortParams, SchemaEntry, Selectable } from "features/ui/Table/Table";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCellWithCheckbox from "features/ui/Table/TableCellWithCheckbox";
import Tooltip from "features/ui/Tooltip";

import {
  CLEAR_ERROR_TEXT,
  CLEAR_ITEMS_TEXT,
  CLEAR_MODAL_CANCEL_TEXT,
  CLEAR_MODAL_CONFIRM_TEXT,
  CLEAR_SUCCESS_TEXT,
  DEFAULT_WATCHLIST_SORT,
  EVENT_TYPE_LABELS,
  NO_ITEMS_TEXT,
  REMOVE_ITEMS_TEXT,
  SELECTED_ROW_ACTIONS_TEXT,
  SUCCESS_UNWATCH_TEXT,
  UNWATCH_MODAL_CANCEL_TEXT,
  UNWATCH_MODAL_CONFIRM_TEXT,
  WATCHLIST_KEY,
} from "./constants";
import WatchlistNotificationsPopover from "./WatchlistNotificationsPopover";

const getRecentItemsSchema = (selectableOptions: Selectable): SchemaEntry[] => [
  {
    label: "Watched Item",
    accessor: "objectID",
    dataType: DataType.STRING,
    selectable: selectableOptions,
    limitedWidthClass: "w-80",
  },
  {
    label: "Type",
    accessor: "eventType",
    dataType: DataType.STRING,
  },
  {
    label: "Alerts",
    accessor: "frequency", // todo: update with the correct child accessor for notifications
    dataType: DataType.STRING,
  },
  {
    label: "Last alert",
    accessor: "updatedAt", // todo: update with the correct child accessor for latest notification
    dataType: DataType.DATE_WITH_TIME,
  },
];

interface TableRowProps {
  row: AlertDefinition;
  selectedIds: Set<string>;
  setSelectedIds: (events: Set<string>) => void;
  onUnwatch?: (row: AlertDefinition) => void;
}

const WatchlistTitleColumn = ({
  row,
  selectedIds,
  setSelectedIds,
  onUnwatch,
}: TableRowProps) => {
  const email = useEmailFromJWT();
  const [showControls, setShowControls] = useState(false);
  const onMouseEnter = () => {
    // allow to delete alerts only if the alert was created by the user, to
    // prevent deleting on the behalf of other users if user has edit permissions
    if (row.createdBy === email) {
      setShowControls(true);
    }
  };
  const onMouseLeave = () => {
    setShowControls(false);
  };

  return (
    <div
      className="flex items-center"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <TableCellWithCheckbox
        value={row.ID}
        selectedValues={selectedIds}
        setSelectedValues={setSelectedIds}
        testId="checkbox-watchlist-table"
        hideText
      />
      <Link
        // todo: update the link to the correct page with filters page, currently only routing to alert definition
        to={`/alert-definitions/${row.ID}`}
        className="text-metabase-blue hover:underline overflow-hidden text-ellipsis ml-2"
        title={row.name}
      >
        {row.name}
      </Link>
      {showControls && (
        <div className="ml-auto">
          <Tooltip
            content={<span className="text-xs">{REMOVE_ITEMS_TEXT}</span>}
            placement="bottom-end"
          >
            <IconButton
              onClick={() => (onUnwatch ? onUnwatch(row) : null)}
              data-testid="create-bookmark-action"
              size="small"
              className={classNames("!p-0 hover:text-red-500", { hover: true })}
            >
              <EyeIcon size={16} />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const formatRow = (
  { row, selectedIds, setSelectedIds }: TableRowProps,
  onClose: () => void,
  onClear: (alert: AlertDefinition) => void,
  onUnwatch: (alert: AlertDefinition) => void,
  onMarkAsSeen: (notificationId: string) => void
) => ({
  ...row,
  frequency: (
    <WatchlistNotificationsPopover
      count={row.notifications.reduce((acc, x) => acc + x.count, 0)}
      alert={row}
      onClose={onClose}
      onClear={onClear}
      onMarkAsSeen={onMarkAsSeen}
    />
  ),
  eventType: EVENT_TYPE_LABELS[row.eventType],
  updatedAt: row.notifications[0]?.updatedAt ?? row.updatedAt,
  objectID: (
    <WatchlistTitleColumn
      row={row}
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      onUnwatch={onUnwatch}
    />
  ),
});

interface WatchlistProps {
  alertsCount?: number;
}

const Watchlist = ({ alertsCount }: WatchlistProps) => {
  const [sortString, setSortString] = useState<string>("");
  const { sort, manageOnSortChange } = useFilterSortState({
    pageKey: WATCHLIST_KEY,
    defaultSort: DEFAULT_WATCHLIST_SORT,
  });
  const email = useEmailFromJWT();
  const [unwatchModalVisible, setUnwatchModalVisible] = useState(false);
  const [clearModalVisible, setClearModalVisible] = useState(false);

  const [refreshKey, setRefreshKey] = useState<boolean>(false);

  const { data, error, isLoading, ...paginationData } = useListAlertDefinitions(
    {
      limit: 10,
      sort: sortString,
      refreshKey,
    }
  );

  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set());
  // confirmedIds acts as a persistent store to hold the action data while
  // the user confirms an action in a modal (unwatch or clear)
  const [confirmedIds, setConfirmedIds] = useState<string[]>([]);

  const allSelectableValues = data?.map((x) => x.ID) || [];
  const { allChecked, indeterminateChecked } = getCheckboxCheckedProps(
    selectedIds,
    allSelectableValues
  );

  const toggleSelectedItems = () => {
    if (allChecked) {
      setSelectedIds(new Set<string>());
      return;
    }
    setSelectedIds(new Set<string>(allSelectableValues));
  };

  const schema = getRecentItemsSchema({
    onClick: toggleSelectedItems,
    checked: allChecked,
    indeterminate: indeterminateChecked,
  });

  const onSort = ({ accessor, sort }: OnSortParams) => {
    setSortString(`${accessor}:${sort}`);
    manageOnSortChange({ [accessor]: sort });
  };

  // stopWatching is the final handle for the unwatch action
  const stopWatching = (alertIds: string[]) => {
    const promises = alertIds.map((alertId) =>
      deleteAlertDefinition({ id: alertId })
    );
    Promise.all(promises)
      .then(() => {
        toast.success(SUCCESS_UNWATCH_TEXT);
        setRefreshKey(!refreshKey);
        setConfirmedIds([]);
        setSelectedIds(new Set<string>());
        setUnwatchModalVisible(false);
      })
      .catch(() => {
        setRefreshKey(!refreshKey);
      });
  };

  const onStopWatching = (alertIds: string[]) => {
    setUnwatchModalVisible(true);
    setConfirmedIds(alertIds);
  };

  const onStopWatchingSelected = () => {
    if (!data) return;
    const userCreatedIds = Array.from(selectedIds).filter((id) => {
      const alert = data.find((x) => x.ID === id);
      return alert?.createdBy === email;
    });
    onStopWatching(userCreatedIds);
  };

  const handleStopWatching = (confirmed: boolean) => {
    if (!confirmed) {
      setUnwatchModalVisible(false);
      setConfirmedIds([]);
      return;
    }
    stopWatching(confirmedIds);
  };

  const clearNotifications = () => {
    clearNotificationsRequest({ IDs: confirmedIds })
      .then(() => {
        toast.success(CLEAR_SUCCESS_TEXT);
        setConfirmedIds([]);
        setClearModalVisible(false);
        setRefreshKey(!refreshKey);
        setSelectedIds(new Set<string>());
      })
      .catch(() => {
        toast.error(CLEAR_ERROR_TEXT);
        setConfirmedIds([]);
        setClearModalVisible(false);
        setRefreshKey(!refreshKey);
        setSelectedIds(new Set<string>());
      });
  };

  const onClearNotificationsForSelected = () => {
    if (!data) return;
    const selectedAlertsIds = Array.from(selectedIds);
    const notificationIds: string[] = [];
    // get the ids of all notifications for the selected alerts
    selectedAlertsIds.forEach((alertId) => {
      const alert = data.find((x) => x.ID === alertId);
      if (alert) {
        notificationIds.push(...alert.notifications.map((x) => x.ID));
      }
    });
    onClearNotifications(notificationIds);
    setSelectedIds(new Set<string>());
  };

  const onClearNotificationsForAlert = (alert: AlertDefinition) => {
    const notificationIds = alert.notifications.map((x) => x.ID);
    onClearNotifications(notificationIds);
  };

  const onClearNotifications = (notificationIds: string[]) => {
    setClearModalVisible(true);
    setConfirmedIds(notificationIds);
  };

  const handleClearNotifications = (confirmed: boolean) => {
    if (!confirmed) {
      setClearModalVisible(false);
      setConfirmedIds([]);
      return;
    }
    clearNotifications();
  };

  const onMarkAsSeen = (notificationId: string) => {
    setNotificationsAsSeen({ IDs: [notificationId] }).then(() => {
      setRefreshKey(!refreshKey);
    });
  };

  const deleteTextClear = () => (
    <div>
      Are you sure you want to <strong>clear</strong> notifications for{" "}
      <i>{confirmedIds.length}</i> {pluralize("alert", confirmedIds.length)}?
    </div>
  );

  const deleteTextUnwatch = () => (
    <div>
      Are you sure you want to <strong>permanently</strong> stop watching{" "}
      <i>{confirmedIds.length}</i> {pluralize("item", confirmedIds.length)}?
    </div>
  );

  const formattedData = useMemo(
    () =>
      data?.map((row) =>
        formatRow(
          { row, selectedIds, setSelectedIds },
          () => setRefreshKey(!refreshKey),
          (alert) => onClearNotificationsForAlert(alert),
          (alert) => onStopWatching([alert.ID]),
          (notificationId) => onMarkAsSeen(notificationId)
        )
      ),
    // eslint-disable-next-line
    [data, refreshKey, selectedIds]
  );

  if (isLoading) return <Skeleton height={200} />;

  if (error) return <APIError error={error} />;

  return (
    data && (
      <Card>
        <div className="flex flex-col justify-between w-full max-h-30">
          <h3 className="flex space-x-3 items-center font-semibold mb-3 capitalize">
            {WATCHLIST_TITLE}
            {alertsCount != null && alertsCount !== 0 && (
              <span className="text-red-500 ml-2">({alertsCount})</span>
            )}
          </h3>

          {data.length === 0 && (
            <span className="text-sm">{NO_ITEMS_TEXT}</span>
          )}
          {data.length > 0 && (
            <>
              <div className="bg-gray-50 p-1.5 mb-1 ml-0 text-xs w-fit rounded-md">
                <label className="mr-4 text-gray-400">
                  {SELECTED_ROW_ACTIONS_TEXT}
                </label>
                <button
                  className="text-blue-400 disabled:text-gray-300"
                  disabled={selectedIds.size === 0}
                  onClick={onStopWatchingSelected}
                >
                  {REMOVE_ITEMS_TEXT}
                </button>
                <button
                  className="text-blue-400 disabled:text-gray-300 ml-2"
                  disabled={selectedIds.size === 0}
                  onClick={onClearNotificationsForSelected}
                >
                  {CLEAR_ITEMS_TEXT}
                </button>
              </div>
              <PaginatedTable
                {...paginationData}
                data={formattedData}
                schema={schema}
                dense={true}
                onSort={onSort}
                sortBy={sort}
              />
            </>
          )}
        </div>
        <ConfirmationModal
          isOpen={clearModalVisible}
          onClose={handleClearNotifications}
          loading={false}
          confirmText={CLEAR_MODAL_CONFIRM_TEXT}
          closeText={CLEAR_MODAL_CANCEL_TEXT}
          title=""
          text={deleteTextClear()}
          confirmColor="error"
        />
        <ConfirmationModal
          isOpen={unwatchModalVisible}
          onClose={handleStopWatching}
          loading={false}
          confirmText={UNWATCH_MODAL_CONFIRM_TEXT}
          closeText={UNWATCH_MODAL_CANCEL_TEXT}
          title=""
          text={deleteTextUnwatch()}
          confirmColor="error"
        />
      </Card>
    )
  );
};

export default Watchlist;
