import { useFlags } from "launchdarkly-react-client-sdk";

import { ContextProviderName } from "shared/contexts/ContextWrappers";
import { useAttributesContext } from "shared/contexts/GenericAttributesContextWrapper";
import {
  CLAIMS_GENERIC_FILTER,
  SIGNAL_EVENTS_GENERIC_FILTER,
} from "shared/filterDefinitions";

import { FilterOperator } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

import useDealersSchema from "./dealerSchema";
import useGeneralDescriptionSchema from "./generalDescriptionSchema";
import { SchemaEntryOverride, SchemaRelations, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

export const useClaimsSchema = (accessorsToHide: string[] = []): UseSchema => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();
  const attributes = useAttributesContext(ContextProviderName.Claim);

  const dealerSchema = useDealersSchema();
  const generalDescriptionSchema = useGeneralDescriptionSchema();

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(CLAIMS_GENERIC_FILTER, attributeMap);

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const overrides: SchemaEntryOverride = {
    ID: {
      schemaOverride: {
        label: "",
        filter: undefined,
      },
    },
    similarClaimID: {
      schemaOverride: {
        filter: CLAIMS_GENERIC_FILTER({
          fieldName: "similarClaimID",
          fieldNameForAPI: "ID",
          label: "Similar Claim ID",
          whitelistedFilterOperators: [FilterOperator.IN],
        }),
      },
    },
    costTotal: {
      ignore: !warrantyClaimsCostFF,
    },
    costLabor: {
      ignore: !warrantyClaimsCostFF,
    },
    costParts: {
      ignore: !warrantyClaimsCostFF,
    },
    costOther: {
      ignore: !warrantyClaimsCostFF,
    },
    totalApprovedNetCost: {
      ignore: !warrantyClaimsCostFF,
    },
    relatedSignalEventOccurrences: {
      schemaOverride: {
        filter: SIGNAL_EVENTS_GENERIC_FILTER({
          fieldName: "relatedSignalEventOccurrences",
          fieldNameForAPI: "ID",
          label: getDisplayLabel(
            "relatedSignalEventOccurrences",
            "Related Signal Events"
          ),
          search: true,
          disableFiltering: true,
          disableContainsFilters: true,
          filterType: "relates",
          baseEntityText: "Claims",
        }),
      },
    },
    mentionedSignalEvents: {
      schemaOverride: {
        filter: SIGNAL_EVENTS_GENERIC_FILTER({
          fieldName: "mentionedSignalEvents",
          fieldNameForAPI: "ID",
          label: getDisplayLabel(
            "mentionedSignalEvents",
            "Mentioned Signal Events"
          ),
          search: true,
          disableFiltering: true,
          disableContainsFilters: true,
        }),
      },
    },
  };

  const relations: SchemaRelations = {
    "/v1/dealers": dealerSchema,
    "/v1/parts": generalDescriptionSchema,
    "/v1/laborCodes": generalDescriptionSchema,
  };

  const schema: SchemaEntry[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    overrides,
    relations,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    attributes,
    getDisplayLabel,
    attributeMap,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaEntry,
    getSchemaForAccessor,
  };
};
