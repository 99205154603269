import { validateApiRequest } from "duck/graph/nodes/utils";
import { PageHandler } from "duck/graph/PageHandler";
import { z } from "zod";

import { getIssueRequestURI } from "shared/api/issues/api";
import { IssueTypes } from "shared/types";

import {
  ISSUE_ACTIVITY_TAB_KEY,
  ISSUE_CLAIMS_TAB_KEY,
  ISSUE_RELATIONSHIP_TAB_KEY,
  ISSUE_REPAIR_EFFICACY_TAB_KEY,
  ISSUE_SIGNAL_EVENTS_TAB_KEY,
} from "pages/Issues/constants";
import { getIssueTabsAvailability } from "pages/Issues/utils";

export const getTabAvailability = (
  issue: IssueTypes,
  tabKey: string
): boolean => {
  const {
    claimsTabDisabled,
    signalEventsTabDisabled,
    relationshipsTabDisabled,
    repairEfficacyTabDisabled,
  } = getIssueTabsAvailability(issue);

  switch (tabKey) {
    case ISSUE_CLAIMS_TAB_KEY:
      return !claimsTabDisabled;
    case ISSUE_SIGNAL_EVENTS_TAB_KEY:
      return !signalEventsTabDisabled;
    case ISSUE_RELATIONSHIP_TAB_KEY:
      return !relationshipsTabDisabled;
    case ISSUE_REPAIR_EFFICACY_TAB_KEY:
      return !repairEfficacyTabDisabled;
    case ISSUE_ACTIVITY_TAB_KEY:
      return true;
    default:
      throw new Error(`Unknown tab key: ${tabKey}`);
  }
};

export const validateIssueId = async (issueId: string): Promise<void> => {
  if (!issueId) {
    throw new Error("The Issue ID is empty");
  }

  await validateApiRequest({ id: issueId }, getIssueRequestURI, "Issue ID");
};

export const setRouteValue = async (
  issueId: string,
  pageHandler: PageHandler
): Promise<void> => {
  await validateIssueId(issueId);
  pageHandler.setRouteValue(`/issues/${issueId}`);
};

export const withIssueIdSchema = z.object({
  issueId: z
    .string()
    .describe("The ID of the Issue to display. There is no default value."),
});

export type WithIssueId = z.infer<typeof withIssueIdSchema>;
