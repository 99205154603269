import {
  DOCUMENT_RETRIEVAL_DISTANCE_THRESHOLD,
  DOCUMENT_RETRIEVAL_K,
  DOCUMENTS_SOURCE,
} from "duck/graph/constants";
import getAnalyzeScreenshotAgentNode from "duck/graph/nodes/AnalyzeScreenshotAgent/getNode";
import { GraphStateType } from "duck/graph/state";
import getRespondToUserTool from "duck/graph/tools/getRespondToUserTool";
import { DuckGraphParams } from "duck/graph/types";
import { ToolNode } from "@langchain/langgraph/prebuilt";

import getClaimAnalyticsAgentNodes from "./ClaimAnalyticsAgent/getNodes";
import getRejectClarifyNode from "./GreetingRejectClarifyAgent/getNode";
import getHandleRepeatedToolCallNode from "./HandleRepeatedToolCallNode/getNode";
import getIssueDetailsAgentNode from "./IssueDetailsAgent/getNodes";
import getIssueAgentNodes from "./IssuesAgent/getNodes";
import getPageAgentResponderAgentNode from "./PageAgentResponderAgent/getNode";
import getRagAgentNode from "./RagAgent/getNode";
import getRouterAgentNode from "./RouterAgent/getNode";
import getSignalEventAnalyticsAgentNodes from "./SignalEventAnalyticsAgent/getNodes";
import getSubmitFeedbackNode from "./SubmitFeedbackAgent/getNode";
import {
  createCaptureScreenshotNode,
  createDocumentRetrievalNode,
  NodeType,
} from "./utils";
import getVehiclesAgentNodes from "./VehiclesAgent/getNodes";
import getVinViewAgentNodes from "./VinViewAgent/getNodes";

const getDocumentRetrievalNode = (params: DuckGraphParams): NodeType =>
  createDocumentRetrievalNode(
    DOCUMENTS_SOURCE,
    DOCUMENT_RETRIEVAL_K,
    DOCUMENT_RETRIEVAL_DISTANCE_THRESHOLD,
    params.uiHandlers.setEphemeralMessage
  );

const getRespondToUserToolNode = (
  params: DuckGraphParams
): ToolNode<GraphStateType> =>
  new ToolNode<GraphStateType>([
    getRespondToUserTool(
      params.uiHandlers.setAgentResponse,
      params.uiHandlers.setEphemeralMessage
    ),
  ]);

const getCaptureScreenshotToolNode = (params: DuckGraphParams): NodeType =>
  createCaptureScreenshotNode(params.uiHandlers.setEphemeralMessage);

export {
  getClaimAnalyticsAgentNodes,
  getRagAgentNode,
  getRouterAgentNode,
  getDocumentRetrievalNode,
  getRejectClarifyNode,
  getRespondToUserToolNode,
  getAnalyzeScreenshotAgentNode,
  getCaptureScreenshotToolNode,
  getSignalEventAnalyticsAgentNodes,
  getVinViewAgentNodes,
  getVehiclesAgentNodes,
  getIssueAgentNodes,
  getIssueDetailsAgentNode,
  getSubmitFeedbackNode,
  getPageAgentResponderAgentNode,
  getHandleRepeatedToolCallNode,
};
