import { AlertEventType } from "shared/api/alertDefinitions/api";

import { AlertDefinitionState } from "pages/AlertDefinitions/types";

import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import {
  DEFAULT_FILTER_BUILDER_STATE,
  DEFAULT_FILTER_BUILDER_STATE_ANY,
} from "features/ui/Filters/FilterBuilder/constants";
import { SelectOption } from "features/ui/Select";

export const ALERT_PLAN_TITLE = "Alert Definition";
export const ICON_SIZE = 15;
export const ON_SUCCESS_CREATE_TEXT =
  "Successfully created a new alert definition.";
export const ON_UPDATE_CREATE_TEXT =
  "Your alert definition has been updated successfully.";
export const ALERT_DEFINITION_DESCRIPTION_LABEL = "Description";
export const ALERT_DEFINITION_NAME_LABEL = "Name";
export const ALERT_DEFINITIONS_TITLE = "Alert Definitions";
export const ALERT_DEFINITIONS_PER_PAGE = 15;
export const CTA_TEXT = "New Alert Definition";
export const OTHER_FREQUENCY_STATUS_CONSTANT = "Other";
export const EMPTY_ALERT_DEFINITION_STATE: AlertDefinitionState = {
  ID: "",
  createdAt: "",
  updatedAt: "",
  createdBy: "",
  updatedBy: "",
  name: "",
  description: null,
  vehiclesFilter: DEFAULT_FILTER_BUILDER_STATE,
  eventType: AlertEventType.SIGNAL_EVENT_OCCURRENCE,
  eventFilter: DEFAULT_FILTER_BUILDER_STATE_ANY,
  frequency: "asap",
  emails: [],
  groups: [],
  initialGroups: [],
  access: [],
  canEdit: false,
  inAppAlerts: true,
  emailAlerts: false,
};

export enum FrequencyOptions {
  ASAP = "asap",
  HOURLY = "hourly",
  DAILY = "daily",
  WEEKLY = "weekly",
}

export const FREQUENCY_OPTIONS_SELECT_TIME_SCHEDULED_OPTIONS: DropdownSelectOption<string>[] =
  [
    {
      id: FrequencyOptions.HOURLY.toString(),
      value: "Hour",
    },
    {
      id: FrequencyOptions.DAILY.toString(),
      value: "Day",
    },
    {
      id: FrequencyOptions.WEEKLY.toString(),
      value: "Week",
    },
  ];

export const ALERT_EVENT_TYPE_OPTIONS: SelectOption[] = [
  { id: AlertEventType.SIGNAL_EVENT_OCCURRENCE, value: "Signal Events" },
  { id: AlertEventType.CLAIM, value: "Claims" },
  {
    id: AlertEventType.SERVICE_RECOMMENDATION,
    value: "Service Recommendations",
  },
];
