import { Claim } from "shared/api/claims/api";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { formatNumber } from "shared/utils";

import CardHeader from "pages/Issues/Details/CardHeader";

import Card from "features/ui/Card";
import LabelValuePairs from "features/ui/LabelValuePairs";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  data: Claim;
}

const CARD_TITLE = "Repair Costs";

const DISPLAY_FIELDS: string[] = [
  "costTotal",
  "costParts",
  "costLabor",
  "costOther",
];

const CardRepairCosts = ({ data }: Props) => {
  const { schema } = useClaimsSchema();
  const formatRow = (row: Claim) => {
    const {
      costTotal,
      costParts,
      costLabor,
      costOther,
      totalApprovedNetCost,
      currencyCode,
    } = row;
    return {
      ...row,
      costTotal: costTotal && `${formatNumber(costTotal)} ${currencyCode}`,
      costParts: costParts && `${formatNumber(costParts)} ${currencyCode}`,
      costLabor: costLabor && `${formatNumber(costLabor)} ${currencyCode}`,
      costOther: costOther && `${formatNumber(costOther)} ${currencyCode}`,
      totalApprovedNetCost:
        totalApprovedNetCost &&
        `${formatNumber(totalApprovedNetCost)} ${currencyCode}`,
    };
  };

  const formattedData = formatRow(data);

  return (
    <Card>
      <CardHeader title={CARD_TITLE} />
      <LabelValuePairs
        schema={schema as SchemaEntry<keyof Claim>[]}
        data={formattedData}
        fields={DISPLAY_FIELDS}
      />
    </Card>
  );
};

export default CardRepairCosts;
