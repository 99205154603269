import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { useGetCustomRecord } from "shared/api/serviceRecords/hooks";
import { EventTypeEnum } from "shared/types";
import { getEntityWithExternalIDTitle } from "shared/utils";

import CustomRecordDetail from "pages/VINView/ServiceRecords/EventDetail/CustomRecordDetail";

import APIError from "features/ui/APIError/APIError";
import Board from "features/ui/Board/Board";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

type Params = {
  id: string;
};

const SingleCustomRecord = () => {
  const { id } = useParams<Params>();

  const { data, isLoading, error } = useGetCustomRecord({
    ID: id as string,
  });

  if (isLoading) {
    return <Skeleton count={2} height={300} className="mb-3" />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data) {
    return <div>No data</div>;
  }

  const boardElements: JSX.Element[][] = [
    [<CustomRecordDetail customRecordID={id} />],
  ];

  const { externalID } = data;

  const title = getEntityWithExternalIDTitle(
    externalID,
    EventTypeEnum.CUSTOM_RECORD
  );

  return (
    <>
      <PageHeaderWrapper>
        <Title text={title} />
      </PageHeaderWrapper>
      <Board elements={boardElements} />
    </>
  );
};

export default SingleCustomRecord;
