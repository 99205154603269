export const replaceURLWithHTMLLinks = (text: string, className?: string) => {
  const exp =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  return text.replace(
    exp,
    className ? `<a class=${className} href='$1'>$1</a>` : `<a href='$1'>$1</a>`
  );
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};
