import { useCallback } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useFailureModes } from "shared/api/failureModes/hooks";
import { getSortFilter } from "shared/api/utils";
import { FAILURE_MODE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER } from "shared/filterDefinitions";
import useFailureModesRiskModelPredictionsSchema, {
  RISK_MODEL_PREDICTION_DISPLAYED_FIELDS,
} from "shared/schemas/failureModesRiskModelPredictionsSchema";
import { useRiskModelPredictionsSchema } from "shared/schemas/riskModelPredictionsSchema";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";

import { DEFAULT_NON_ARCHIVED_FAILURE_MODES_FILTER } from "pages/FailureModes/constants";

import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import {
  FilterSchemaItem,
  UseFilterSortState,
} from "features/ui/Filters/types";

import * as config from "config/config";

import { INDEX_WHERE_FM_COLUMNS_START } from "./constants";
import ExtraFailureModesHeader from "./ExtraFailureModesHeader";
import TableColumnSettings from "./TableColumnSettings";
import { getAccessorsForVisibleFMColumns } from "./utils";

interface Props {
  pageKey: string;
  vehiclesFiltersFilterSortState: UseFilterSortState;
  initialVisibleFailureModeColumns: string[];
}

const ACCESSOR_TO_INSERT_FM_COLS_AFTER: string = "mileage";

export const useVehiclesFailureModeColumns = ({
  pageKey,
  vehiclesFiltersFilterSortState,
  initialVisibleFailureModeColumns,
}: Props) => {
  const { attributes } = useRiskModelPredictionsSchema(
    (item: FilterSchemaItem) =>
      FAILURE_MODE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER("", item)
  );
  const possibleAttributes =
    attributes?.filter(({ ID }) =>
      RISK_MODEL_PREDICTION_DISPLAYED_FIELDS.includes(ID)
    ) || [];

  const {
    pages: { failureModesV1, vehicles },
  } = config.get();
  const { legacyFailureModes, failureModesV1: failureModesV1FeatureFlag } =
    useFlags();

  const { data: failureModesData } = useFailureModes({
    sort: getSortFilter({ status: "desc" }),
    filter: getFiltersQuery(
      filterStateToFilterGroupState(DEFAULT_NON_ARCHIVED_FAILURE_MODES_FILTER)
    ),
    skipRequest:
      !failureModesV1?.enabled ||
      !legacyFailureModes ||
      !failureModesV1FeatureFlag,
  });

  const columnSettingsEnabled =
    vehicles?.pageWithFMCols &&
    failureModesV1?.enabled &&
    legacyFailureModes &&
    failureModesV1FeatureFlag &&
    failureModesData &&
    failureModesData.length > 0;

  const failureModeProps = possibleAttributes.map((prop) => prop.ID);

  const allFailureModesIds =
    (failureModesData && failureModesData.map(({ ID }) => ID)) || [];

  const additionalOptions = possibleAttributes.map(({ displayName, ID }) => ({
    label: displayName,
    value: ID,
  }));

  const visibleFailureModesColumns = getAccessorsForVisibleFMColumns(
    allFailureModesIds,
    columnSettingsEnabled
      ? vehiclesFiltersFilterSortState.failureModeColumns
      : initialVisibleFailureModeColumns, // ignore failureModeColumns from useFilterSortState() if showColumnsSettings is false
    failureModeProps
  );

  const TableColumnSettingsComponent = useCallback(() => {
    if (!columnSettingsEnabled) return null;

    const showHideColumnsOptionsFailureModes = failureModesData
      ? failureModesData?.map(({ ID, name }) => ({
          label: name,
          value: ID,
        }))
      : [];

    return (
      <TableColumnSettings
        pageKey={pageKey}
        onChange={vehiclesFiltersFilterSortState.manageOnVisibleFMColumnChange}
        visibleColumns={vehiclesFiltersFilterSortState.failureModeColumns}
        options={showHideColumnsOptionsFailureModes}
        additionalOptions={additionalOptions}
        additionalOptionsLabel="Attributes"
      />
    );
  }, [
    columnSettingsEnabled,
    pageKey,
    failureModesData,
    vehiclesFiltersFilterSortState,
    additionalOptions,
  ]);

  const ExtraTableHeaderForFailureModes = useCallback(() => {
    if (!columnSettingsEnabled || !failureModesData) return null;
    return (
      <ExtraFailureModesHeader
        failureModes={failureModesData}
        visibleFailureModeColumns={
          vehiclesFiltersFilterSortState.failureModeColumns
        }
        failureModeProps={failureModeProps}
      />
    );
  }, [
    columnSettingsEnabled,
    failureModesData,
    vehiclesFiltersFilterSortState.failureModeColumns,
    failureModeProps,
  ]);

  return {
    columnSettingsEnabled,
    visibleFailureModesColumns,
    TableColumnSettingsComponent,
    ExtraTableHeaderForFailureModes,
  };
};

export const useVehiclesSchemaWithFailureModes = () => {
  const {
    pages: { vehicles, failureModes },
  } = config.get();
  const { legacyFailureModes } = useFlags();
  const addFMColumns =
    vehicles?.pageWithFMCols && failureModes?.enabled && legacyFailureModes;
  const { schema: vehicleAttributesSchema, ...otherVehiclesSchemaProps } =
    useVehiclesSchema();

  const vehicleFMAttributesSchema =
    useFailureModesRiskModelPredictionsSchema(!addFMColumns);

  // Insert failure modes schemas into the schema after ACCESSOR_TO_INSERT_FM_COLS_AFTER.
  // - note that will will only be added if pageWithFMCols is enabled in the config
  // otherwise vehicleFMAttributesSchema returns empty array.
  const mileageIndex =
    vehicleAttributesSchema.findIndex(
      ({ accessor }) => accessor === ACCESSOR_TO_INSERT_FM_COLS_AFTER
    ) || INDEX_WHERE_FM_COLUMNS_START;

  const modifiedVehiclesSchema =
    vehicleAttributesSchema && vehicleFMAttributesSchema
      ? [
          ...vehicleAttributesSchema.slice(0, mileageIndex + 1),
          ...vehicleFMAttributesSchema,
          ...vehicleAttributesSchema.slice(mileageIndex + 1),
        ]
      : vehicleAttributesSchema;

  return {
    schema: modifiedVehiclesSchema,
    ...otherVehiclesSchemaProps,
  };
};
