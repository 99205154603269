import { getTenantServiceRecordName } from "shared/utils";

export const VIN_VIEW_EVENTS_TIMELINE_TAB_KEY = "events-timeline";
export const VIN_VIEW_EVENTS_TIMELINE_TAB_TITLE = "Timeline";
export const VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY =
  "service-recommendations";
export const VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_TITLE =
  "Service Recommendations";
export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_PREFIX =
  "vin_event_timeline_data_";
export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY = (vin: string) =>
  `${VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_PREFIX}${vin}_v2`;
export const MAX_LIMIT_EVENTS = 2000;

export const VIN_VIEW_HEALTH_TAB_KEY = "health";
export const VIN_VIEW_HEALTH_TAB_TITLE = "Health";
export const VIN_VIEW_SERVICE_RECORDS_TAB_KEY = "service-records";
export const VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY = "service-schedules";
export const VIN_VIEW_SERVICE_SCHEDULE_TAB_TITLE = "Service Schedule";
export const VIN_VIEW_ECUS_TAB_KEY = "ecus";
export const VIN_VIEW_ECUS_TAB_TITLE = "ECUs";
export const VIN_VIEW_OPTIONS_TAB_KEY = "options";
export const VIN_VIEW_OPTIONS_TAB_TITLE = "Options";
export const VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY = "risk-model-results";
export const VIN_VIEW_RISK_MODEL_RESULTS_TAB_TITLE = "Health";

export const VIN_VIEW_TABS = {
  [VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY]: VIN_VIEW_RISK_MODEL_RESULTS_TAB_TITLE,
  [VIN_VIEW_HEALTH_TAB_KEY]: VIN_VIEW_HEALTH_TAB_TITLE,
  [VIN_VIEW_SERVICE_RECORDS_TAB_KEY]: getTenantServiceRecordName(),
  [VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY]: VIN_VIEW_SERVICE_SCHEDULE_TAB_TITLE,
  [VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY]:
    VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_TITLE,
  [VIN_VIEW_EVENTS_TIMELINE_TAB_KEY]: VIN_VIEW_EVENTS_TIMELINE_TAB_TITLE,
  [VIN_VIEW_ECUS_TAB_KEY]: VIN_VIEW_ECUS_TAB_TITLE,
  [VIN_VIEW_OPTIONS_TAB_KEY]: VIN_VIEW_OPTIONS_TAB_TITLE,
};
