import { useCallback } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { getSortFilter } from "shared/api/utils";
import { useFailureModes } from "shared/api/v0_failureModes/hooks";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";

import { DEFAULT_NON_ARCHIVED_FAILURE_MODES_FILTER } from "pages/FailureModes/constants";
import TableColumnSettings from "pages/Vehicles/TableColumnSettings";

import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { UseFilterSortState } from "features/ui/Filters/types";

import * as config from "config/config";

import { INDEX_WHERE_FM_COLUMNS_START } from "./constants";
import ExtraFailureModesHeader from "./ExtraFailureModesHeader";
import { getAccessorsForVisibleFMColumns } from "./utils";
import useVehiclesFailureModesSchema from "./vehiclesFailureModesSchema";

interface Props {
  pageKey: string;
  vehiclesFiltersFilterSortState: UseFilterSortState;
  initialVisibleFailureModeColumns: string[];
}

const ACCESSOR_TO_INSERT_FM_COLS_AFTER: string = "mileage";

const SHOW_HIDE_COLUMNS_OPTIONS_FAILURE_MODE_PROPS = [
  { label: "Risk Group", value: "riskGroup" },
  { label: "Risk Status", value: "riskStatus" },
  { label: "Risk Multiple", value: "riskMultiple" },
  { label: "Repair Status", value: "repairStatus" },
  { label: "Repair Details", value: "repairDetails" },
];

export const useVehiclesFailureModeColumns = ({
  pageKey,
  vehiclesFiltersFilterSortState,
  initialVisibleFailureModeColumns,
}: Props) => {
  const {
    pages: { failureModes, vehicles },
  } = config.get();
  const { legacyFailureModes } = useFlags();

  const { data: failureModesData } = useFailureModes({
    sort: getSortFilter({ status: "desc" }),
    filter: getFiltersQuery(
      filterStateToFilterGroupState(DEFAULT_NON_ARCHIVED_FAILURE_MODES_FILTER)
    ),
    skipRequest: !failureModes?.enabled && !legacyFailureModes,
  });

  const columnSettingsEnabled =
    vehicles?.pageWithFMCols &&
    failureModes?.enabled &&
    legacyFailureModes &&
    failureModesData &&
    failureModesData.length > 0;

  const failureModeProps = SHOW_HIDE_COLUMNS_OPTIONS_FAILURE_MODE_PROPS.map(
    (prop) => prop.value
  );

  const allFailureModesIds =
    (failureModesData && failureModesData.map(({ ID }) => ID)) || [];

  const additionalColumnsOptionsValues =
    SHOW_HIDE_COLUMNS_OPTIONS_FAILURE_MODE_PROPS.map(({ value }) => value);

  const visibleFailureModesColumns = getAccessorsForVisibleFMColumns(
    allFailureModesIds,
    columnSettingsEnabled
      ? vehiclesFiltersFilterSortState.failureModeColumns
      : initialVisibleFailureModeColumns, // ignore failureModeColumns from useFilterSortState() if showColumnsSettings is false
    additionalColumnsOptionsValues
  );

  const TableColumnSettingsComponent = useCallback(() => {
    if (!columnSettingsEnabled) return null;

    const showHideColumnsOptionsFailureModes = failureModesData
      ? failureModesData?.map(({ ID, name }) => ({
          label: name,
          value: ID,
        }))
      : [];

    return (
      <TableColumnSettings
        pageKey={pageKey}
        onChange={vehiclesFiltersFilterSortState.manageOnVisibleFMColumnChange}
        visibleColumns={vehiclesFiltersFilterSortState.failureModeColumns}
        options={showHideColumnsOptionsFailureModes}
        additionalOptions={SHOW_HIDE_COLUMNS_OPTIONS_FAILURE_MODE_PROPS}
        additionalOptionsLabel="Attributes"
      />
    );
  }, [
    columnSettingsEnabled,
    pageKey,
    failureModesData,
    vehiclesFiltersFilterSortState,
  ]);

  const ExtraTableHeaderForFailureModes = useCallback(() => {
    if (!columnSettingsEnabled || !failureModesData) return null;
    return (
      <ExtraFailureModesHeader
        failureModes={failureModesData}
        visibleFailureModeColumns={
          vehiclesFiltersFilterSortState.failureModeColumns
        }
        failureModeProps={failureModeProps}
      />
    );
  }, [
    columnSettingsEnabled,
    failureModesData,
    vehiclesFiltersFilterSortState.failureModeColumns,
    failureModeProps,
  ]);

  return {
    columnSettingsEnabled,
    visibleFailureModesColumns,
    TableColumnSettingsComponent,
    ExtraTableHeaderForFailureModes,
  };
};

export const useVehiclesSchemaWithFailureModes = (
  accessorsToHide: string[] = []
) => {
  const {
    pages: { vehicles, failureModes },
  } = config.get();
  const { legacyFailureModes } = useFlags();
  const addFMColumns =
    vehicles?.pageWithFMCols && failureModes?.enabled && legacyFailureModes;
  const { schema: vehicleAttributesSchema, ...otherVehiclesSchemaProps } =
    useVehiclesSchema(accessorsToHide);

  const vehicleFMAttributesSchema =
    useVehiclesFailureModesSchema(!addFMColumns);

  // Insert failure modes schemas into the schema after ACCESSOR_TO_INSERT_FM_COLS_AFTER.
  // - note that will will only be added if pageWithFMCols is enabled in the config
  // otherwise vehicleFMAttributesSchema returns empty array.
  const mileageIndex =
    vehicleAttributesSchema.findIndex(
      ({ accessor }) => accessor === ACCESSOR_TO_INSERT_FM_COLS_AFTER
    ) || INDEX_WHERE_FM_COLUMNS_START;

  const modifiedVehiclesSchema =
    vehicleAttributesSchema && vehicleFMAttributesSchema
      ? [
          ...vehicleAttributesSchema.slice(0, mileageIndex + 1),
          ...vehicleFMAttributesSchema,
          ...vehicleAttributesSchema.slice(mileageIndex + 1),
        ]
      : vehicleAttributesSchema;

  return {
    schema: modifiedVehiclesSchema,
    ...otherVehiclesSchemaProps,
  };
};
