import { useState } from "react";
import classNames from "classnames";
import {
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  ScatterChart as SC,
  Scatter,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
  ZAxis,
} from "recharts";
import { LegendType, Margin } from "recharts/types/util/types";

import {
  AXIS_TOOLTIP_FONT_SIZE,
  DEFAULT_Y_AXIS_ID,
  LABEL_COLOR,
  REFERENCE_LINE_COLOR,
  REFERENCE_LINE_OPACITY,
  REFERENCE_LINE_WIDTH,
} from "features/ui/charts/constants";
import CustomLegend from "features/ui/charts/CustomLegend";
import { LegendConfig, ZoomProps } from "features/ui/charts/types";

import { useZoom } from "./hooks";
import TruncatedYAxisTick from "./TruncatedYAxisTick";

export interface ScatterChartProps {
  onDotHover?: (event: any) => void;
  onDotLeave?: (event: any) => void;
  serviceEventsReferenceLines?: any[];
  vehicleReferenceLines?: any[];
  onReferenceLineClick?: (event: any) => void;
  xAxisKey: string;
  xAxisLabel: string;
  xAxisProps?: XAxisProps;
  yAxisProps?: YAxisProps;
  yAxisKey: string;
  yAxisLabel: string;
  zAxisKey: string;
  zAxisLabel: string;
  rows: DataRow[];
  tooltipProps: TooltipProps<any, any>;
  margin?: Margin;
  height?: number;
  maxHeight?: number;
  showYAxis?: boolean;
  legendConfig?: LegendConfig;
  syncId?: string;
}

export interface DataRow {
  data: any[];
  color: string;
  opacity: number;
  key: string;
  shape?: LegendType | any;
}

export interface VehicleReferenceLineType {
  key: string;
  x: number;
  color: string;
}

export interface ReferenceLineType {
  key: string;
  x: string;
  data: any;
}

const ScatterChart = (props: ScatterChartProps & ZoomProps) => {
  const [cursorInsideChart, setCursorInsideChart] = useState(false);
  const {
    onDotHover,
    onDotLeave,
    serviceEventsReferenceLines,
    vehicleReferenceLines,
    onReferenceLineClick,
    xAxisKey,
    xAxisLabel,
    xAxisProps,
    yAxisProps,
    yAxisKey,
    yAxisLabel,
    zAxisKey,
    zAxisLabel,
    rows,
    tooltipProps,
    margin = { top: 20, right: 20, bottom: 10, left: 100 },
    height = 400,
    maxHeight = 400,
    showYAxis = true,
    legendConfig,
    enableZoom = false,
    syncId,
    onZoom,
    onMouseMove,
    cursorX,
    zoomOverride,
    onZoomOut,
    zoomReferenceAreaOverride,
    onZoomReferenceAreaChange,
    onSyncDateWithZoom,
  } = props;

  const {
    isZoomedIn,
    handleZoom,
    handleMouseDown,
    handleMouseMove,
    zoomReferenceArea,
    zoomXAxisDomain,
    resetZoomButton,
  } = useZoom({
    enableZoom,
    onZoom,
    onZoomOut,
    zoomOverride,
    onZoomReferenceAreaChange,
    zoomReferenceAreaOverride,
    zoomOutControlMarginSide: margin.right,
    onSyncDateWithZoom,
  });

  const onHandleMouseMove = (e?: any) => {
    onMouseMove && onMouseMove(e);
    handleMouseMove && handleMouseMove(e);
  };

  const xAxisDomain =
    enableZoom && isZoomedIn
      ? zoomXAxisDomain
      : xAxisProps?.domain
        ? xAxisProps.domain
        : ["auto", "auto"];

  return (
    <div className="flex-col relative">
      {resetZoomButton}
      {legendConfig && <CustomLegend legendConfig={legendConfig} />}
      <ResponsiveContainer
        width="100%"
        height={height}
        maxHeight={maxHeight}
        className="overflow-x-hidden overflow-y-auto"
      >
        <SC
          margin={margin}
          style={{ height }}
          className={classNames({
            "select-none": enableZoom,
          })}
          onMouseDown={handleMouseDown}
          onMouseMove={onHandleMouseMove}
          onMouseUp={handleZoom}
          syncId={syncId}
          onMouseEnter={() => setCursorInsideChart(true)}
          onMouseLeave={() => setCursorInsideChart(false)}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={xAxisKey}
            name={xAxisLabel}
            label={
              xAxisLabel
                ? {
                    value: xAxisLabel,
                    position: "middle",
                    dy: 15,
                    fill: LABEL_COLOR,
                  }
                : undefined
            }
            type="number"
            tick={{ fontSize: AXIS_TOOLTIP_FONT_SIZE }}
            {...xAxisProps}
            domain={xAxisDomain}
            allowDataOverflow={isZoomedIn}
          />
          <YAxis
            type="category"
            dataKey={yAxisKey}
            name={yAxisLabel}
            allowDuplicatedCategory={false}
            interval={0}
            tick={<TruncatedYAxisTick />}
            hide={!showYAxis}
            yAxisId={DEFAULT_Y_AXIS_ID}
            {...yAxisProps}
          />
          <ZAxis
            dataKey={zAxisKey}
            name={zAxisLabel}
            range={[30, 200]}
            scale="linear"
          />
          <Tooltip
            cursor={false}
            {...tooltipProps}
            isAnimationActive={false}
            separator=": "
            // when user goes outside the chart, we hide the tooltip, otherwise we let recharts handle it
            active={cursorInsideChart ? undefined : false}
          />
          {zoomReferenceAreaOverride || zoomReferenceArea}
          {vehicleReferenceLines &&
            vehicleReferenceLines.map(
              ({ key, x, color }: VehicleReferenceLineType) => (
                <ReferenceLine
                  yAxisId={DEFAULT_Y_AXIS_ID}
                  key={key}
                  x={x}
                  stroke={color}
                  strokeWidth={REFERENCE_LINE_WIDTH}
                  opacity={REFERENCE_LINE_OPACITY}
                />
              )
            )}
          {cursorX && (
            <line
              x1={cursorX}
              x2={cursorX}
              y1={0}
              y2={height}
              stroke="#ccc"
              strokeDasharray="5 5"
            />
          )}
          {serviceEventsReferenceLines &&
            serviceEventsReferenceLines.map(
              ({ key, x, data }: ReferenceLineType) => (
                <ReferenceLine
                  yAxisId={DEFAULT_Y_AXIS_ID}
                  key={key}
                  x={x}
                  stroke={REFERENCE_LINE_COLOR}
                  strokeWidth={REFERENCE_LINE_WIDTH}
                  opacity={REFERENCE_LINE_OPACITY}
                  onClick={() =>
                    onReferenceLineClick && onReferenceLineClick(data)
                  }
                  className="cursor-pointer"
                />
              )
            )}
          {rows.map(({ key, data, color, shape, opacity }) => (
            <Scatter
              key={key}
              data={data}
              fill={color}
              shape={shape || "circle"}
              onMouseEnter={onDotHover}
              onMouseLeave={onDotLeave}
              opacity={opacity}
              yAxisId={DEFAULT_Y_AXIS_ID}
            />
          ))}
        </SC>
      </ResponsiveContainer>
    </div>
  );
};
export default ScatterChart;
