import {
  getAnalyzeScreenshotAgentNode,
  getCaptureScreenshotToolNode,
  getClaimAnalyticsAgentNodes,
  getDocumentRetrievalNode,
  getHandleRepeatedToolCallNode,
  getIssueAgentNodes,
  getIssueDetailsAgentNode,
  getPageAgentResponderAgentNode,
  getRagAgentNode,
  getRejectClarifyNode,
  getRespondToUserToolNode,
  getRouterAgentNode,
  getSignalEventAnalyticsAgentNodes,
  getSubmitFeedbackNode,
  getVehiclesAgentNodes,
  getVinViewAgentNodes,
} from "duck/graph/nodes";
import { graphState } from "duck/graph/state";
import { DuckGraphParams } from "duck/graph/types";
import { Runnable } from "@langchain/core/runnables";
import { END, MemorySaver, START, StateGraph } from "@langchain/langgraph/web";

import { GenericToolNodeName, getNextNode, NodeNames } from "./utils";

// Typescript has been unusually finicky about the type of the routerEdgeTargets.
// It appears to want the type to align with the nodes in the initial base graph.
// It complains if extra node names are present. For example, it doesn't like this:
// type ExtendedNodeNames = NodeNamesType | "__end__" | "__start__";
// We should only add to this list if additional nodes are added to the initial graph
// that does not include nodes or edges for page agents.
type RouterEdgeTargets =
  | "rag"
  | "greetingRejectClarify"
  | "router"
  | "respondToUser"
  | "documentRetrieval"
  | "submitFeedback"
  | "__end__"
  | "__start__";

/**
 * Create the elements of the graph that are always present, regardless of the
 * configuration of the tenant and the settings in LaunchDarkly.
 */
const createGraph = async (params: DuckGraphParams) => {
  const duckAccess = params.uiHandlers.duckAccess;

  // We type this as Record<string, string> so that we can add anything we want
  // to it. We intentionally do not use the SupervisorEdgeTargets type here
  // because it would not allow us to add anything to the supervisorEdgeTargets,
  // which is the whole point of the exercise.
  const routerEdgeTargets: Record<string, string> = {
    [NodeNames.RAG]: NodeNames.RAG,
    [NodeNames.GREETING_REJECT_CLARIFY]: NodeNames.GREETING_REJECT_CLARIFY,
    [NodeNames.RESPOND_TO_USER]: NodeNames.RESPOND_TO_USER,
    [NodeNames.ANALYZE_SCREENSHOT]: NodeNames.ANALYZE_SCREENSHOT,
    [NodeNames.SUBMIT_FEEDBACK]: NodeNames.SUBMIT_FEEDBACK,
    [END]: END,
  };

  const stateGraph = new StateGraph(graphState)
    .addNode(NodeNames.DOCUMENT_RETRIEVAL, getDocumentRetrievalNode(params))
    .addNode(NodeNames.CAPTURE_SCREENSHOT, getCaptureScreenshotToolNode(params))
    .addNode(NodeNames.ROUTER, await getRouterAgentNode(params))
    .addNode(
      NodeNames.GREETING_REJECT_CLARIFY,
      await getRejectClarifyNode(params)
    )
    .addNode(NodeNames.RAG, await getRagAgentNode(params))
    .addNode(NodeNames.RESPOND_TO_USER, getRespondToUserToolNode(params))
    .addNode(
      NodeNames.ANALYZE_SCREENSHOT,
      await getAnalyzeScreenshotAgentNode(params)
    )
    .addNode(
      NodeNames.PAGE_AGENT_RESPONDER,
      await getPageAgentResponderAgentNode(params)
    )
    .addNode(
      NodeNames.HANDLE_REPEATED_TOOL_CALL,
      getHandleRepeatedToolCallNode()
    )
    .addNode(
      NodeNames.SUBMIT_FEEDBACK,
      getSubmitFeedbackNode(
        params.uiHandlers.setEphemeralMessage,
        params.uiHandlers.setAgentResponse
      )
    )
    .addEdge(START, NodeNames.DOCUMENT_RETRIEVAL)
    .addEdge(NodeNames.DOCUMENT_RETRIEVAL, NodeNames.CAPTURE_SCREENSHOT)
    .addEdge(NodeNames.CAPTURE_SCREENSHOT, NodeNames.ROUTER)
    .addEdge(NodeNames.RAG, NodeNames.RESPOND_TO_USER)
    .addEdge(NodeNames.GREETING_REJECT_CLARIFY, NodeNames.RESPOND_TO_USER)
    .addEdge(
      NodeNames.HANDLE_REPEATED_TOOL_CALL,
      NodeNames.PAGE_AGENT_RESPONDER
    )
    .addEdge(NodeNames.PAGE_AGENT_RESPONDER, NodeNames.RESPOND_TO_USER)
    .addEdge(NodeNames.ANALYZE_SCREENSHOT, NodeNames.RESPOND_TO_USER)
    .addEdge(NodeNames.RESPOND_TO_USER, END)
    .addEdge(NodeNames.SUBMIT_FEEDBACK, END);

  // I would have preferred to break this apart into separate functions but
  // Typescript made it difficult to type the parameters.
  // The stateGraph and the conditional edges both had very particular and
  // unintuitive types that were difficult to reuse or define efficiently.
  // In the end I decided it was better to have a single mega-function.
  // It doesn't seem like Langgraph intends their graphs to be dynamically
  // defined like we are doing here.
  if (duckAccess.claimAnalytics.enabled) {
    const {
      node: claimAnalyticsAgentNode,
      toolNode: claimAnalyticsAgentToolNode,
    } = await getClaimAnalyticsAgentNodes(params);

    stateGraph
      .addNode(NodeNames.CLAIM_ANALYTICS, claimAnalyticsAgentNode)
      .addNode(NodeNames.CLAIM_ANALYTICS_TOOLS, claimAnalyticsAgentToolNode)
      .addConditionalEdges(NodeNames.CLAIM_ANALYTICS, getNextNode, {
        [GenericToolNodeName]: NodeNames.CLAIM_ANALYTICS_TOOLS,
        [NodeNames.HANDLE_REPEATED_TOOL_CALL]:
          NodeNames.HANDLE_REPEATED_TOOL_CALL,
        [NodeNames.PAGE_AGENT_RESPONDER]: NodeNames.PAGE_AGENT_RESPONDER,
      })
      .addEdge(NodeNames.CLAIM_ANALYTICS_TOOLS, NodeNames.CLAIM_ANALYTICS);

    routerEdgeTargets[NodeNames.CLAIM_ANALYTICS] = NodeNames.CLAIM_ANALYTICS;
  }

  if (duckAccess.signalEventAnalytics.enabled) {
    const {
      node: signalEventAnalyticsAgentNode,
      toolNode: signalEventAnalyticsAgentToolNode,
    } = await getSignalEventAnalyticsAgentNodes(params);

    stateGraph
      .addNode(NodeNames.SIGNAL_EVENT_ANALYTICS, signalEventAnalyticsAgentNode)
      .addNode(
        NodeNames.SIGNAL_EVENT_ANALYTICS_TOOLS,
        signalEventAnalyticsAgentToolNode
      )
      .addConditionalEdges(NodeNames.SIGNAL_EVENT_ANALYTICS, getNextNode, {
        [GenericToolNodeName]: NodeNames.SIGNAL_EVENT_ANALYTICS_TOOLS,
        [NodeNames.HANDLE_REPEATED_TOOL_CALL]:
          NodeNames.HANDLE_REPEATED_TOOL_CALL,
        [NodeNames.PAGE_AGENT_RESPONDER]: NodeNames.PAGE_AGENT_RESPONDER,
      })
      .addEdge(
        NodeNames.SIGNAL_EVENT_ANALYTICS_TOOLS,
        NodeNames.SIGNAL_EVENT_ANALYTICS
      );

    routerEdgeTargets[NodeNames.SIGNAL_EVENT_ANALYTICS] =
      NodeNames.SIGNAL_EVENT_ANALYTICS;
  }

  if (duckAccess.vinView.enabled) {
    const { node: vinViewAgentNode, toolNode: vinViewAgentToolNode } =
      await getVinViewAgentNodes(params);

    stateGraph
      .addNode(NodeNames.VIN_VIEW, vinViewAgentNode)
      .addNode(NodeNames.VIN_VIEW_TOOLS, vinViewAgentToolNode)
      .addConditionalEdges(NodeNames.VIN_VIEW, getNextNode, {
        [GenericToolNodeName]: NodeNames.VIN_VIEW_TOOLS,
        [NodeNames.HANDLE_REPEATED_TOOL_CALL]:
          NodeNames.HANDLE_REPEATED_TOOL_CALL,
        [NodeNames.PAGE_AGENT_RESPONDER]: NodeNames.PAGE_AGENT_RESPONDER,
      })
      .addEdge(NodeNames.VIN_VIEW_TOOLS, NodeNames.VIN_VIEW);

    routerEdgeTargets[NodeNames.VIN_VIEW] = NodeNames.VIN_VIEW;
  }

  if (duckAccess.vehicles.enabled) {
    const { node: vehiclesAgentNode, toolNode: vehiclesAgentToolNode } =
      await getVehiclesAgentNodes(params);

    stateGraph
      .addNode(NodeNames.VEHICLES, vehiclesAgentNode)
      .addNode(NodeNames.VEHICLES_TOOLS, vehiclesAgentToolNode)
      .addConditionalEdges(NodeNames.VEHICLES, getNextNode, {
        [GenericToolNodeName]: NodeNames.VEHICLES_TOOLS,
        [NodeNames.HANDLE_REPEATED_TOOL_CALL]:
          NodeNames.HANDLE_REPEATED_TOOL_CALL,
        [NodeNames.PAGE_AGENT_RESPONDER]: NodeNames.PAGE_AGENT_RESPONDER,
      })
      .addEdge(NodeNames.VEHICLES_TOOLS, NodeNames.VEHICLES);

    routerEdgeTargets[NodeNames.VEHICLES] = NodeNames.VEHICLES;
  }

  if (duckAccess.issues.enabled) {
    const { node: issuesAgentNode, toolNode: issuesAgentToolNode } =
      await getIssueAgentNodes(params);

    stateGraph
      .addNode(NodeNames.ISSUES, issuesAgentNode)
      .addNode(NodeNames.ISSUES_TOOLS, issuesAgentToolNode)
      .addConditionalEdges(NodeNames.ISSUES, getNextNode, {
        [GenericToolNodeName]: NodeNames.ISSUES_TOOLS,
        [NodeNames.HANDLE_REPEATED_TOOL_CALL]:
          NodeNames.HANDLE_REPEATED_TOOL_CALL,
        [NodeNames.PAGE_AGENT_RESPONDER]: NodeNames.PAGE_AGENT_RESPONDER,
      })
      .addEdge(NodeNames.ISSUES_TOOLS, NodeNames.ISSUES);

    routerEdgeTargets[NodeNames.ISSUES] = NodeNames.ISSUES;
  }

  if (duckAccess.issueDetails.enabled) {
    const { node: issueDetailsAgentNode, toolNode: issueDetailsAgentToolNode } =
      await getIssueDetailsAgentNode(params);

    stateGraph
      .addNode(NodeNames.ISSUE_DETAILS, issueDetailsAgentNode)
      .addNode(NodeNames.ISSUE_DETAILS_TOOLS, issueDetailsAgentToolNode)
      .addConditionalEdges(NodeNames.ISSUE_DETAILS, getNextNode, {
        [GenericToolNodeName]: NodeNames.ISSUE_DETAILS_TOOLS,
        [NodeNames.HANDLE_REPEATED_TOOL_CALL]:
          NodeNames.HANDLE_REPEATED_TOOL_CALL,
        [NodeNames.PAGE_AGENT_RESPONDER]: NodeNames.PAGE_AGENT_RESPONDER,
      })
      .addEdge(NodeNames.ISSUE_DETAILS_TOOLS, NodeNames.ISSUE_DETAILS);

    routerEdgeTargets[NodeNames.ISSUE_DETAILS] = NodeNames.ISSUE_DETAILS;
  }

  stateGraph.addConditionalEdges(
    NodeNames.ROUTER,
    getNextNode,
    // We "as" the routerEdgeTargets to the RouterEdgeTargets type,
    // even though we have added extra node names to the routerEdgeTargets
    // variable that do not comply with the indicated typing.
    // We need to do this for Typescript to accept the variable.
    // Yes, this is counterintuitive but it does allow us to dynamically add
    // nodes to the graph.
    routerEdgeTargets as Record<string, RouterEdgeTargets>
  );

  return stateGraph;
};

/**
 * @summary Get DUCK's compiled state graph.
 * @param params The parameters for the agent from the UI
 * @param withMemory True to use the memory checkpointer, false to not have a checkpointer
 * @returns The compiled state graph
 */
const getGraph = async (
  params: DuckGraphParams,
  withMemory: boolean = false
): Promise<Runnable> => {
  const stateGraph = await createGraph(params);

  // The MemorySaver checkpointer is not intended for production usage
  const checkpointer = withMemory ? new MemorySaver() : undefined;

  return stateGraph.compile({ checkpointer });
};

export default getGraph;
