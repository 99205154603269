import { AppConfig, AuthScopes, PagesConfig } from "config/config";

import pagesConfig from "./pagesConfig.json";

export const tenant = "loadtest";
export const host = `${tenant}.cloud.viaduct.ai`;

const analyticsConfig = {
  heap: { id: "" },
};

const authConfig = {
  name: "Viaduct",
  idp: "0oa13z7dyOysdGFnU5d6",
  clientId: "0oaeajdny0TYhLkhF5d7",
  issuer: "https://viaduct-cloud.okta.com/oauth2/auseaje4wng1p2Tvw5d7",
  scopes: ["openid", "email", "offline_access"] as AuthScopes[],
};

// this tenant only has 1 environment, no loadtest vs prod
const APIConfig = {
  url: `https://api.${host}/v0`,
};

const featureFlags = {
  launchDarklyClientId: "6597f1e51877ca0f45220f54",
};

export const appConfig: AppConfig = {
  tenant,
  environment: "prod",
  pages: pagesConfig as PagesConfig,
  analytics: analyticsConfig,
  featureFlags,
  auth: authConfig,
  api: APIConfig,
};
