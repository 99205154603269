import { useState } from "react";

import { useSignalEventOccurrencesFiltersSchema } from "shared/hooks";
import { cloneObject } from "shared/utils";

import { SIGNAL_EVENTS_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";

import DropdownSelect from "features/ui/DropdownSelect";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { DEFAULT_RELATES_FILTER } from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import RelatesTimeWindowForm from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesTimeWindowForm";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { RelatesFilterState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";

import { OnRowDataChangeHandle } from "./FilterRow";

interface Props {
  id: string;
  relates?: RelatesFilterState | null;
  onRowDataChange: OnRowDataChangeHandle;
  attributeSchema?: SchemaEntry;
  baseEntityText?: string;
  disabled?: boolean;
}

const DEFAULT_BASE_ENTITY_TEXT = "Claims";

const RelatesFilterFields = ({
  id,
  relates,
  onRowDataChange,
  attributeSchema,
  disabled,
}: Props) => {
  const baseEntityText =
    attributeSchema?.filter?.baseEntityText ?? DEFAULT_BASE_ENTITY_TEXT;

  const [relatesFilter, setRelatesFilter] = useState<RelatesFilterState>(
    relates ? cloneObject(relates) : DEFAULT_RELATES_FILTER
  );

  // we need to have a different key for each relates filter across app
  const RELATED_FILTER_PAGE_KEY = `relatesFilterState-${id}`;
  const PENDING_FILTERS_RELATED_FILTER_LS_KEY = getPendingFiltersKey(
    RELATED_FILTER_PAGE_KEY
  );

  // this is a nested filter, so we need to fetch filters from the parent filter component
  const relatedSignalEventsFilterSortState = useFilterSortState({
    pageKey: RELATED_FILTER_PAGE_KEY,
    defaultFilterValues: relates?.filters ?? DEFAULT_RELATES_FILTER.filters,
    pendingFiltersLocalStorageKey: PENDING_FILTERS_RELATED_FILTER_LS_KEY,
    disableUsingQuery: true,
    disableUsingLocalStorage: true,
  });

  const onUpdate = (newRelatesState: RelatesFilterState) => {
    setRelatesFilter(newRelatesState);
    applyFilters(newRelatesState);
  };
  const applyFilters = (newRow: RelatesFilterState) => {
    onRowDataChange(id, "relates", newRow);
  };
  const applyRelatesFilter = (newFilterState: FilterGroupState) => {
    onUpdate({
      ...relatesFilter,
      filters: newFilterState,
    });
  };

  // we want to avoid nested related filters
  const signalEventsOccurrencesFiltersSchema =
    useSignalEventOccurrencesFiltersSchema(["relatedSignalEventOccurrences"]);

  const signalEventsFilterLabel = getFilterLabel(
    SIGNAL_EVENTS_FILTER_LABEL,
    relatedSignalEventsFilterSortState.filters
  );

  return (
    <div className="flex flex-col w-full space-y-2">
      <div className="flex items-center space-x-2">
        <span>defined by</span>
        <DropdownSelect
          testId="associated-signal-events-filters-dropdown"
          label={signalEventsFilterLabel}
          buttonClass="h-[38px]"
          disabled={disabled}
          content={
            <FilterSelector
              schema={signalEventsOccurrencesFiltersSchema}
              filterSortState={relatedSignalEventsFilterSortState}
              title={SIGNAL_EVENTS_FILTER_LABEL}
              testId="related-signal-event-filters"
              pendingFiltersKey={PENDING_FILTERS_RELATED_FILTER_LS_KEY}
              onApplyFilters={applyRelatesFilter}
              defaultFilters={DEFAULT_RELATES_FILTER.filters}
            />
          }
        />
      </div>
      <div className="w-full">
        <RelatesTimeWindowForm
          relatesState={relatesFilter}
          onUpdate={onUpdate}
          baseEntityText={baseEntityText}
          inFilterSelector={true}
          disabled={disabled}
          disableSelectingWindowDirection={
            attributeSchema?.filter?.disableSelectingWindowDirection
          }
        />
      </div>
    </div>
  );
};

export default RelatesFilterFields;
