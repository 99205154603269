import Skeleton from "react-loading-skeleton";
import { Link, useParams } from "react-router-dom";

import { useFailureMode } from "shared/api/failureModes/hooks";
import { DEFAULT_MILEAGE_UNIT } from "shared/constants";
import { capitalizeFirstLetter, formatNumber } from "shared/utils";

import {
  DEFINITION_TAB_KEY,
  EVENTS_TAB_KEY,
  FAILURE_MODE_TITLE,
  FAILURE_MODES_TABS,
  NOMINAL_RISK_MODEL_PERFORMANCE_KEY,
  RISK_MODEL_RESULTS_TAB_KEY,
} from "pages/FailureModes/constants";

import APIError from "features/ui/APIError";
import Button from "features/ui/Button";
import EditDetails from "features/ui/Form/EditDetails";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import StatefulTabs from "features/ui/StatefulTabs";
import Title from "features/ui/Title";

import { routes } from "services/routes";

import DefinitionTab from "./DefinitionTab";
import EventsTab from "./EventsTab/EventsTab";
import InfoTable from "./InfoTable";
import PerformanceTab from "./PerformanceTab";
import ResultsTab from "./ResultsTab/ResultsTab";

type FailureModeParams = {
  id: string;
};

const FailureMode = () => {
  const { id } = useParams<FailureModeParams>();

  const {
    data: failureMode,
    isLoading,
    error,
  } = useFailureMode({
    id: id as string,
    mileageUnit: DEFAULT_MILEAGE_UNIT,
  });

  if (error) {
    return <APIError error={error} />;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  if (!failureMode) {
    return (
      <div className="py-4 text-gray-400 text-sm">
        No data for Failure Mode {id}.
      </div>
    );
  }

  const tabs = [
    {
      key: DEFINITION_TAB_KEY,
      title: FAILURE_MODES_TABS[DEFINITION_TAB_KEY],
      content: <DefinitionTab failureMode={failureMode} />,
    },
    {
      key: EVENTS_TAB_KEY,
      title: FAILURE_MODES_TABS[EVENTS_TAB_KEY],
      content: <EventsTab failureModeID={failureMode.ID} />,
    },
    {
      key: RISK_MODEL_RESULTS_TAB_KEY,
      title: FAILURE_MODES_TABS[RISK_MODEL_RESULTS_TAB_KEY],
      content: <ResultsTab failureMode={failureMode} />,
    },
    {
      key: NOMINAL_RISK_MODEL_PERFORMANCE_KEY,
      title: FAILURE_MODES_TABS[NOMINAL_RISK_MODEL_PERFORMANCE_KEY],
      content: <PerformanceTab failureMode={failureMode} />,
    },
  ];

  const data = [
    {
      label: "Name",
      value: failureMode.name,
      extra: (
        <>
          <EditDetails props={failureMode} />
          <Button
            label="Close"
            variant="outlined"
            size="small"
            to={routes.failureModesV1}
            component={Link}
          />
        </>
      ),
    },
    {
      label: "Description",
      value: failureMode.description || "",
    },
    {
      label: "Type",
      value: capitalizeFirstLetter(failureMode.type),
    },
  ];
  if (failureMode.warrantyPolicy) {
    data.push({
      label: "Warranty Policy",
      value: `${failureMode.warrantyPolicy.ID} - ${failureMode.warrantyPolicy.description} (${failureMode.warrantyPolicy.defaultMonths} months, ${formatNumber(failureMode.warrantyPolicy.defaultMileage, 2)}${failureMode.warrantyPolicy.defaultMileageUnit}, ${formatNumber(failureMode.warrantyPolicy.defaultEngineHours)} engine hours)`,
    });
  }

  return (
    <>
      <PageHeaderWrapper>
        <Title text={FAILURE_MODE_TITLE} />
      </PageHeaderWrapper>
      <InfoTable
        data={data}
        labelClassName={failureMode.warrantyPolicy ? "w-32" : "w-24"}
        extraClassName="w-50"
      />

      <StatefulTabs tabs={tabs} />
    </>
  );
};

export default FailureMode;
