import classNames from "classnames";

interface BetaBadgeProps {
  newLine?: boolean;
  dark?: boolean;
}

const BetaBadge = ({ newLine = true, dark = false }: BetaBadgeProps) => (
  <span
    className={classNames("text-xs font-light", {
      "text-gray-500": dark,
      "text-gray-400": !dark,
    })}
  >
    {newLine && <br />}
    BETA
  </span>
);

export default BetaBadge;
