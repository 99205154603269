import React from "react";

import { useSignalEventOccurrencesFiltersSchema } from "shared/hooks";

import { SIGNAL_EVENTS_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";

import DropdownSelect from "features/ui/DropdownSelect";
import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import RelatesTimeWindowForm from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesTimeWindowForm";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import {
  RelatesFilterState,
  RelatesFilterWindowDirectionType,
  UseFilterSortState,
} from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

interface RelatesFilterFormProps {
  relatesFilter: RelatesFilterState;
  setRelatesFilter: (row: RelatesFilterState) => void;
  appliedRelatesFilter: RelatesFilterState;
  setAppliedRelatesFilter: (row: RelatesFilterState) => void;
  filterSortState: UseFilterSortState;
  pendingFiltersKey: string;
  baseEntityText: string;
  windowDirectionOptions?: DropdownSelectOption<RelatesFilterWindowDirectionType>[];
}

const AssociatedSignalEventsRelatesFilter = ({
  relatesFilter,
  setRelatesFilter,
  appliedRelatesFilter,
  setAppliedRelatesFilter,
  filterSortState,
  pendingFiltersKey,
  baseEntityText,
  windowDirectionOptions,
}: RelatesFilterFormProps) => {
  const filterTitle = "Associated Signal Event defined by";
  const onApplyFilters = (newFilters: FilterGroupState) => {
    setRelatesFilter({
      ...relatesFilter,
      filters: newFilters,
    });
    setAppliedRelatesFilter({
      ...appliedRelatesFilter,
      filters: newFilters,
    });
  };

  const onUpdateRelatesFilter = (row: RelatesFilterState) => {
    setRelatesFilter(row);
  };

  const signalEventsFilterLabel = getFilterLabel(
    SIGNAL_EVENTS_FILTER_LABEL,
    filterSortState.filters
  );

  // we want to avoid nested related filters
  const signalEventsOccurrencesFiltersSchema =
    useSignalEventOccurrencesFiltersSchema(["relatedSignalEventOccurrences"]);

  return (
    <>
      <span>{filterTitle}</span>
      <DropdownSelect
        testId="associated-signal-events-filters-dropdown"
        label={signalEventsFilterLabel}
        buttonClass="mt-6 h-[38px] mr-4"
        content={
          <FilterSelector
            schema={signalEventsOccurrencesFiltersSchema}
            filterSortState={filterSortState}
            title={SIGNAL_EVENTS_FILTER_LABEL}
            testId="associated-signal-event-filters"
            pendingFiltersKey={pendingFiltersKey}
            initialIsAdvancedFilter={false}
            onApplyFilters={onApplyFilters}
          />
        }
      />
      <RelatesTimeWindowForm
        relatesState={relatesFilter}
        onUpdate={onUpdateRelatesFilter}
        baseEntityText={baseEntityText}
        inFilterSelector={false}
        windowDirectionOptions={windowDirectionOptions}
      />
    </>
  );
};

export default AssociatedSignalEventsRelatesFilter;
