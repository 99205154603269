import { useGetIssueRepairEfficacyReoccurrenceRateByPopulation } from "shared/api/issues/hooks";
import { useCustomLocalStorageState } from "shared/hooks";
import { IssueTypes, ReoccurrenceProps } from "shared/types";

import ChartWithComparisonPopulation from "pages/Issues/Charts/ChartWithComparisonPopulation";
import PopulationChart from "pages/Issues/Charts/PopulationChart";
import { RepairEfficacyCharts } from "pages/Issues/constants";
import {
  getAxisKeyLabelFromActions,
  getChartOptionsKey,
} from "pages/Issues/utils";

import Card from "features/ui/Card";
import {
  numberOfClaimsWithReoccurringSignalEvent,
  rateOfClaimsWithReoccurringSignalEvent,
  yAxis,
} from "features/ui/charts/actions";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";

interface Props extends ReoccurrenceProps {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const TITLE = "Signal Reoccurrence: By Population";

const CHART_NAME = RepairEfficacyCharts.REOCCURRENCE_BY_POPULATION;

const actions: ChartAction[] = [
  {
    id: "y",
    title: yAxis,
    type: "dropdownSelect",
    options: [
      rateOfClaimsWithReoccurringSignalEvent,
      numberOfClaimsWithReoccurringSignalEvent,
    ],
  },
];

const ReoccurrenceByPopulationChart = ({
  issue,
  withComparisonPopulation,
  seOccurrenceWithin,
  seReoccurrenceStartingFrom,
  seReoccurrenceUpTo,
}: Props) => {
  const chartOptionsKey = getChartOptionsKey(CHART_NAME, issue.ID);
  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(actions) });

  const {
    axisKey: yAxisKey,
    axisValue: yAxisValue,
    valueFormatter,
    tooltipValueFormatter,
  } = getAxisKeyLabelFromActions(selectedOptions, actions, "y");

  const PopulationsChart = withComparisonPopulation
    ? ChartWithComparisonPopulation
    : PopulationChart;

  const dataLoadHookParams = {
    signalEventsTimeWindow: seOccurrenceWithin,
    signalEventsReoccurrenceTimeWindowFrom: seReoccurrenceStartingFrom,
    signalEventsReoccurrenceTimeWindowTo: seReoccurrenceUpTo,
  };

  return (
    <Card>
      <ChartActionsWrap id={CHART_NAME} chartTitle={TITLE}>
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      <PopulationsChart
        issue={issue}
        yAxisKey={yAxisKey}
        yAxisLabel={yAxisValue}
        chartType="DaysSinceClaim"
        chart={CHART_NAME}
        dataLoadHook={useGetIssueRepairEfficacyReoccurrenceRateByPopulation}
        dataLoadHookParams={{ ...dataLoadHookParams }}
        valueFormatter={valueFormatter}
        tooltipValueFormatter={tooltipValueFormatter}
      />
    </Card>
  );
};

export default ReoccurrenceByPopulationChart;
