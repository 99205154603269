import { useCallback, useEffect, useState } from "react";
import qs from "qs";
import { useNavigate } from "react-router";

import { TAB_QUERY_PARAM } from "shared/constants";
import { SortBy } from "shared/types";

import { QS_PARSE_ARRAY_LIMIT, useQuery } from "services/hooks";

import { DEFAULT_FILTER_BUILDER_STATE } from "./FilterBuilder/constants";
import { FilterGroupState } from "./FilterBuilder/types";
import {
  getTopLevelRowFromFilterGroupState,
  isAdvancedFilterState,
  removeAttributesFromFilterGroupState,
  updateOrAddRowFilterGroupState,
} from "./FilterBuilder/utils";
import {
  ChartSettingsChangeHandler,
  FilterChangeProps,
  FilterSortState,
  InternalFilterState,
  InternalFilterStateParams,
  RelatesFilterState,
  UseFilterSortState,
  UseFilterSortStateProps,
} from "./types";
import {
  getNewFilterSortState,
  getPageKeyWithVersion,
  getUpdatedQueryParams,
  persistToLocalStorage,
  sanitizeChartSettings,
  useInitialStateValuesAndKeys,
} from "./utils";

export const useFilterSortState = ({
  pageKey,
  defaultSort,
  defaultFailureModeColumns = [],
  disableUsingQuery = false,
  disableUsingLocalStorage = false,
  defaultFilterValues,
  pendingFiltersLocalStorageKey,
  defaultTab,
}: UseFilterSortStateProps): UseFilterSortState => {
  const query = useQuery();
  const navigate = useNavigate();

  const pageKeyWithVersion = getPageKeyWithVersion(pageKey);
  const pendingFiltersLocalStorageKeyWithVersion =
    pendingFiltersLocalStorageKey &&
    getPageKeyWithVersion(pendingFiltersLocalStorageKey);

  const {
    queryKeys,
    initialized,
    setInitialized,
    isAdvancedFilterEditor,
    setIsAdvancedFilterEditor,
    filters,
    setFilters,
    sort,
    setSort,
    columns,
    setColumns,
    chartSettings,
    setChartSettings,
    relatedSignalEventsFilter,
    setRelatedSignalEventsFilter,
  } = useInternalFilterState({
    pageKeyWithVersion,
    defaultSort,
    defaultFailureModeColumns,
    disableUsingQuery,
    disableUsingLocalStorage,
    defaultFilterValues,
  });

  const { filtersKey, sortKey, columnsKey } = queryKeys;

  const currentTab = query[TAB_QUERY_PARAM]
    ? String(query[TAB_QUERY_PARAM])
    : defaultTab;

  const onFilterSortStateChange = useCallback(
    (next: FilterSortState) => {
      const currentQuery = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
        arrayLimit: QS_PARSE_ARRAY_LIMIT,
        // We need higher depth because of the relates filter (default is 5)
        depth: 10,
      });

      const data = getNewFilterSortState({
        disableUsingQuery,
        next,
        currentQuery,
        queryKeys,
      });

      if (!disableUsingLocalStorage) {
        persistToLocalStorage(data, pageKeyWithVersion);
      }

      if (data.sort) setSort(data.sort);

      if (data.filters) {
        setFilters(data.filters);
        if (isAdvancedFilterState(data.filters)) {
          setIsAdvancedFilterEditor(true);
        }
      }

      if (data.columns) setColumns(data.columns);

      if (data.chartSettings) setChartSettings(data.chartSettings);

      if (data.relatedSignalEventsFilter)
        setRelatedSignalEventsFilter(data.relatedSignalEventsFilter);

      if (!disableUsingQuery) {
        navigate(
          { search: getUpdatedQueryParams(data, queryKeys, currentQuery) },
          { replace: true }
        );
      }
    },
    // we dont want to run this when filters, sort, columns change because we use them inside this callback
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      navigate,
      persistToLocalStorage,
      disableUsingQuery,
      filtersKey,
      sortKey,
      columnsKey,
      disableUsingLocalStorage,
    ]
  );

  // Turn filters on / off based on previous values.
  // Only used with basic filters (not FilterBuilder)
  const manageFilterChange = ({
    key,
    op_id,
    values = [],
    ...otherProps
  }: FilterChangeProps) => {
    if (!filters) return;

    const childRow = getTopLevelRowFromFilterGroupState(key, filters);
    const currentValues = childRow?.values || [];
    const currentOperator = childRow?.operator;

    // Do not do anything when values or operator haven't changed.
    // Note: we just do naive equality check.
    // Note 2: the values can be the same but operator changed and we still have to trigger filtering!
    if (
      (currentValues === values ||
        JSON.stringify(currentValues) === JSON.stringify(values)) &&
      currentOperator === op_id
    ) {
      return;
    }

    // If values are empty, remove this key from filter.
    if (!values.length) {
      const updated = removeAttributesFromFilterGroupState(filters, [key]);
      return updateFilters(updated);
    }

    // FilterSelector will not be able to get correct pendingFilters state from localStorage
    // unless we reset this here in cases where UseFilterSortState is shared between table & FilterSelector LS
    // Random example where this is needed:
    // - Top contributors bar chart: "include selected values as page filter" will update filters,
    // but pending filters will stay the same - this resets them before setting filters and they will thus be the same as filters
    // due to defaultValue: filters (see FilterSelector.tsx)
    if (pendingFiltersLocalStorageKeyWithVersion) {
      localStorage.removeItem(pendingFiltersLocalStorageKeyWithVersion);
    }

    const newFilters = updateOrAddRowFilterGroupState(filters, {
      type: "row",
      id: `row-${new Date()}`,
      attribute: key,
      values,
      operator: op_id,
      ...otherProps,
    });

    return updateFilters(newFilters);
  };

  const manageOnSortChange = (sort: SortBy) =>
    onFilterSortStateChange({
      sort,
    });

  const manageOnVisibleFMColumnChange = (columns: string[]) =>
    onFilterSortStateChange({
      columns,
    });

  /**
   * Manage changes to the settings of one chart.
   * The settings for that one chart are put into a PageChartSettingsState object
   * and sent to the onFilterSortStateChange() function to be merged with the rest
   * of the chart settings in the data set.
   * @param chartSettings
   * @param chartKey
   */
  const manageChartSettingsChange: ChartSettingsChangeHandler = (
    chartSettings,
    chartKey
  ) => {
    if (!currentTab) return;

    // The chartSettings block sent to onFilterSortStateChange() should be of type
    // PageChartSettingsState so we build up the right structure.
    onFilterSortStateChange({
      // On rare occasions, the chartSettings object can contain undefined optionIds.
      // The lookbackWindow of the Suggested Issues chart is one of them.
      // sanitizeChartSettings() removes those individual chart settings.
      chartSettings: sanitizeChartSettings({
        [currentTab]: {
          [chartKey]: chartSettings,
        },
      }),
    });
  };

  const manageRelatedSignalEventsFilterChange = (
    relatedSignalEventsFilter: RelatesFilterState
  ) =>
    onFilterSortStateChange({
      relatedSignalEventsFilter,
    });

  const updateFilters = (updatedFilters: FilterGroupState) =>
    onFilterSortStateChange({
      filters: updatedFilters,
    });

  const resetFilters = (fieldNames?: string[]) => {
    const updatedFilters =
      (fieldNames &&
        removeAttributesFromFilterGroupState(filters, fieldNames)) ||
      DEFAULT_FILTER_BUILDER_STATE;

    setInitialized(false);
    updateFilters(updatedFilters);
    // Filters don't like to be told what to set them to after being mounted. This is a little hacky solution to reset the component
    // that should be good enough
    setTimeout(() => setInitialized(true), 10);
  };

  const resetSort = () => {
    onFilterSortStateChange({
      sort: defaultSort,
    });
  };

  /* Reset ALL table state */
  const resetFilterSortState = () => {
    const defaultState = {
      sort: defaultSort,
      filters: defaultFilterValues,
      columns: [],
    };
    // We want to check if the filters are already reset, to avoid infinite loop in case we get 400 on default state request
    if (
      JSON.stringify({ sort, filters, columns, chartSettings }) !==
      JSON.stringify(defaultState)
    ) {
      setInitialized(false);
      onFilterSortStateChange({
        sort: defaultSort,
        filters: defaultFilterValues,
        columns: [],
      });
      // Filter don't like to be told what to set it to after being mounted. This is a little hacky solution to reset the component
      // that should be good enough
      setTimeout(() => setInitialized(true), 0);
    }
  };

  /* 
      When the user comes to the page initially we check if there's query params in the URL and store them to localStorage. 
      If there are none we check the localStorage and set filters / sort / columns based on that. 
    */
  useEffect(() => {
    // We also have to update pending filters in localStorage if we are using it.
    if (pendingFiltersLocalStorageKeyWithVersion) {
      localStorage.setItem(
        pendingFiltersLocalStorageKeyWithVersion,
        JSON.stringify(filters)
      );
    }

    // Upon load make sure our advanced filter editor is set correctly based on the filters.
    setIsAdvancedFilterEditor(isAdvancedFilterState(filters));

    onFilterSortStateChange({
      sort,
      columns,
      filters,
      chartSettings,
      relatedSignalEventsFilter,
    });
    setTimeout(() => setInitialized(true), 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    initialized,
    sort,
    manageOnSortChange,
    filters,
    manageFilterChange,
    resetFilters,
    resetSort,
    resetFilterSortState,
    updateFilters,
    failureModeColumns: columns,
    manageOnVisibleFMColumnChange,
    chartSettings,
    manageChartSettingsChange,
    relatedSignalEventsFilter,
    manageRelatedSignalEventsFilterChange,
    isAdvancedFilterEditor,
    setIsAdvancedFilterEditor,
  };
};

/**
 * Internal hook to manage all state variables related to page filtering, sorting, and other UI configurations.
 *
 * This hook is separated from the main useFilterSortState hook to:
 * 1. Manage all individual state variables (filters, sort, columns, chart settings, etc.) and their setters.
 * 2. Provide a clear separation between state management and the logic for persisting/retrieving these states.
 * 3. Allow for easier testing and potential reuse of this state management logic.
 *
 * It initializes all state variables with their default or persisted values and returns both the current states
 * and their setter functions. This centralized state management approach allows the main usePageStateManager
 * hook to focus on the logic of persisting these states and handling user interactions.
 */
const useInternalFilterState = ({
  pageKeyWithVersion,
  defaultSort,
  defaultFailureModeColumns = [],
  disableUsingQuery = false,
  disableUsingLocalStorage = false,
  defaultFilterValues,
}: InternalFilterStateParams): InternalFilterState => {
  // currently initialized is the same for ALL filters on the page - if one is not initialized,
  // all are considered not. We should either remove this state or reconsider its implementation
  const [initialized, setInitialized] = useState(false);

  const { initialValues, queryKeys } = useInitialStateValuesAndKeys(
    pageKeyWithVersion,
    disableUsingQuery,
    disableUsingLocalStorage,
    defaultFilterValues
  );

  const defaultFilters: FilterGroupState =
    initialValues.filters || DEFAULT_FILTER_BUILDER_STATE;

  const isAdvancedFilterEditorDefault = isAdvancedFilterState(defaultFilters);

  const [isAdvancedFilterEditor, setIsAdvancedFilterEditor] = useState(
    isAdvancedFilterEditorDefault
  );

  const [filters, setFilters] = useState<FilterGroupState>(defaultFilters);

  const [sort, setSort] = useState<SortBy | undefined>(
    initialValues.sort !== undefined ? initialValues.sort : defaultSort
  );

  const [columns, setColumns] = useState<string[]>(
    initialValues.columns !== undefined
      ? initialValues.columns || []
      : defaultFailureModeColumns
  );

  const [chartSettings, setChartSettings] = useState<
    UseFilterSortState["chartSettings"]
  >(initialValues.chartSettings);

  const [relatedSignalEventsFilter, setRelatedSignalEventsFilter] = useState<
    RelatesFilterState | undefined
  >(initialValues.relatedSignalEventsFilter);

  return {
    queryKeys,
    initialized,
    setInitialized,
    isAdvancedFilterEditor,
    setIsAdvancedFilterEditor,
    filters,
    setFilters,
    sort,
    setSort,
    columns,
    setColumns,
    chartSettings,
    setChartSettings,
    relatedSignalEventsFilter,
    setRelatedSignalEventsFilter,
  };
};
