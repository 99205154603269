import { createContext } from "react";
import { useParams } from "react-router";

import { RecentItemType } from "shared/api/recentItems/api";
import { useCreateRecentItem } from "shared/api/recentItems/hooks";

import { RouteConfig } from "services/routesConfig";

const DEFAULT_ID_URL_PARAMETER = "id";
export const RouteContext = createContext<RouteConfig | null>(null);

const useGetObjectIdFromRoute = (config: RouteConfig): string | undefined => {
  const params = useParams();
  const idParam = config.idUrlParameter ?? DEFAULT_ID_URL_PARAMETER;
  const objectID = params[idParam];
  if (!objectID) {
    return;
  }
  return objectID;
};

const useRecentItemsMiddleware = (
  config: RouteConfig & { recentItemType: RecentItemType }
): void => {
  const objectID = useGetObjectIdFromRoute(config);
  useCreateRecentItem({ objectType: config.recentItemType, objectID });
};

const RouteMiddleware = ({
  config,
  children,
}: {
  config: RouteConfig;
  children: any;
}): JSX.Element => {
  // handle recent item
  useRecentItemsMiddleware({
    ...config,
    recentItemType: config.recentItemType!,
  });

  return (
    <RouteContext.Provider value={config}>{children}</RouteContext.Provider>
  );
};

export default RouteMiddleware;
