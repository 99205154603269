import { EntityAttribute, ListAttributesRequest } from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI } from "shared/api/hooks";

import * as api from "./api";

export const useListWorkOrdersAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    api.listWorkOrdersAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );
