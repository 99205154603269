import { promptNames } from "duck/graph/nodes/types";
import { AgentNodes, getAgentNodes } from "duck/graph/nodes/utils";
import { DuckGraphParams } from "duck/graph/types";
import { NodeNames } from "duck/graph/utils";

import { routes } from "services/routes";

import getTools from "./tools/getTools";

const getNodes = (params: DuckGraphParams): Promise<AgentNodes> =>
  getAgentNodes({
    params,
    getTools,
    promptName: promptNames.ISSUES_AGENT,
    name: NodeNames.ISSUES,
    route: routes.issues,
  });

export default getNodes;
