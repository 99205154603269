import { TestProps } from "shared/types";

import ToggleShownCheckbox from "features/ui/ToggleShownCheckbox";

interface Props extends TestProps {
  value: string;
  selectedValues: Set<string>;
  setSelectedValues: (events: Set<string>) => void;
  hideText?: boolean;
}

const TableCellWithCheckbox = ({
  value,
  selectedValues,
  setSelectedValues,
  testId,
  hideText = false,
}: Props) => (
  // pl-[3px] is used to align the checkbox with header checkbox (smelly)
  // todo: find a better way to align through element composition
  <div className="flex items-center space-x-1 flex-nowrap pl-[3px]">
    <ToggleShownCheckbox
      rowKey={value}
      setFunction={setSelectedValues}
      values={selectedValues}
      testId={testId}
    />

    {!hideText && <span>{value}</span>}
  </div>
);

export default TableCellWithCheckbox;
