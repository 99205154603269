import { useCallback, useMemo } from "react";
import { generatePath, Link } from "react-router-dom";

import {
  AlertDefinition,
  deleteAlertDefinition,
} from "shared/api/alertDefinitions/api";
import {
  useListAlertDefinitions,
  useListAlertDefinitionsCount,
} from "shared/api/alertDefinitions/hooks";
import { getSortFilter } from "shared/api/utils";
import NewCTA from "shared/components/NewCTA";
import useAlertDefinitionSchema from "shared/schemas/alertDefinitionsSchema";
import { SortBy } from "shared/types";

import APIError from "features/ui/APIError";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import DeleteAndPermissionRowAction from "features/ui/Table/PermissionRowAction/DeleteAndPermissionRowAction";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";
import Title from "features/ui/Title";

import { routes } from "services/routes";

import {
  ALERT_DEFINITIONS_PER_PAGE,
  ALERT_DEFINITIONS_TITLE,
  CTA_TEXT,
} from "./constants";

const PAGE_KEY = "alertDefinitions";
const DEFAULT_SORT: SortBy = { name: "asc" };

const AlertDefinitions = () => {
  const { schema } = useAlertDefinitionSchema();

  const modifiedSchema: SchemaEntry[] = [
    ...schema,
    {
      label: "Actions",
      accessor: "actions",
      dataType: DataType.JSX,
      sortable: false,
    },
  ];

  const {
    filters,
    sort,
    manageFilterChange,
    manageOnSortChange,
    resetFilterSortState,
    resetFilters,
    initialized: filtersInitialized,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const requestParams = {
    filter: getFiltersQuery(filters),
    sort: getSortFilter(sort),
    refreshKey: true,
  };

  const { data, error, isLoading, requestKey, ...paginationData } =
    useListAlertDefinitions(requestParams);

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
    requestKey: countRequestKey,
  } = useListAlertDefinitionsCount(requestParams);

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const formatRow = useCallback(
    (alertDefinition: AlertDefinition) => {
      const pathname = generatePath(routes.alertDefinition, {
        id: alertDefinition.ID,
      });

      return {
        ...alertDefinition,
        name: (
          <Link to={pathname} className="text-metabase-blue hover:underline">
            {alertDefinition.name}
          </Link>
        ),
        vehiclesFilter: (
          <FilterQueryPresentation filter={alertDefinition.vehiclesFilter} />
        ),
        eventFilter: (
          <FilterQueryPresentation filter={alertDefinition.eventFilter} />
        ),
        actions: (
          <DeleteAndPermissionRowAction
            entity={alertDefinition}
            canEdit={alertDefinition.canEdit}
            deleteCallback={deleteAlertDefinition}
            entityType="alertDefinition"
            permissions={alertDefinition.access}
            requestKeys={[requestKey, countRequestKey]}
          />
        ),
      };
    },
    [requestKey, countRequestKey]
  );

  const formattedData = useMemo(() => data?.map(formatRow), [data, formatRow]);
  return (
    <>
      <PageHeaderWrapper>
        <Title text={ALERT_DEFINITIONS_TITLE} />
        <PageHeaderActionsWrapper>
          <NewCTA
            to={routes.newAlertDefinition}
            label={CTA_TEXT}
            testId="new-alert-definition-cta"
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <div className="flex my-2 items-center">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto self-end"
          count={countData?.count as number}
          entityName="alert definition"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={modifiedSchema}
          isLoading={isLoading}
          loadingRows={ALERT_DEFINITIONS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          pageKey={PAGE_KEY}
          dense
        />
      )}
    </>
  );
};
export default AlertDefinitions;
