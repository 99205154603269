import { FormControl, InputLabel, MenuItem } from "@mui/material";
import { default as MUISelect, SelectChangeEvent } from "@mui/material/Select";

import { TestProps } from "shared/types";
import { toCamelCase } from "shared/utils";

export type Option = string | number;

export interface SelectOption<T = Option> extends TestProps {
  value: Option;
  id: T;
  label?: Option;
  className?: string;
  group?: string;
  children?: SelectOption<T>[];
}

export interface SelectProps<T extends SelectOption> extends TestProps {
  fullWidth?: boolean;
  options: T[];
  selected?: T;
  allowNoneSelected?: boolean;
  onSelect: (value: T) => void;
  label?: string;
  disabled?: boolean;
}

export const NONE_SELECTED_OPTION: SelectOption = {
  id: "null",
  value: "None",
};

const Select = <T extends SelectOption>({
  fullWidth = true,
  options,
  selected,
  allowNoneSelected,
  onSelect,
  label,
  disabled,
  testId,
}: SelectProps<T>) => {
  const modifiedOptions = [
    allowNoneSelected ? (NONE_SELECTED_OPTION as T) : undefined,
    ...options,
  ].filter(Boolean) as T[];

  const selectedModified =
    allowNoneSelected && !selected ? (NONE_SELECTED_OPTION as T) : selected;

  const handleOnChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    const option = modifiedOptions.find(({ id }) => id === value);
    if (option) {
      onSelect(option);
    }
  };

  const labelId = toCamelCase(label || "select-label");

  return (
    <FormControl fullWidth={fullWidth} margin="none" size="small">
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <MUISelect
        labelId={labelId}
        id={label}
        value={(selectedModified?.id as string) || ""}
        label={label}
        onChange={handleOnChange}
        fullWidth={fullWidth}
        disabled={disabled}
        data-testid={testId}
        size="small"
        margin="none"
        className="relative"
        MenuProps={{
          disablePortal: true,
        }}
      >
        {modifiedOptions.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            data-testid={option.testId}
          >
            {option.value.toString()}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
};

export default Select;
