import { useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { CgSpinnerTwo as LoadingIcon } from "react-icons/cg";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";

import { DeleteRequest, PermissionEntry } from "shared/api/api";
import { servicePlansLoadDataFunc } from "shared/api/filter.utils";
import {
  deleteServicePlan,
  getServicePlan,
  newServicePlan,
  ServicePlan as ServicePlanModel,
  ServicePlanRequest,
  updateServicePlan,
} from "shared/api/servicePlans/api";
import { useEmailFromJWT, useVehiclesFiltersSchema } from "shared/hooks";
import useServicePlansSchema from "shared/schemas/servicePlansSchema";

import {
  CANCEL_CTA_TEXT,
  SUBMIT_CTA_EDIT,
  SUBMIT_CTA_NEW,
} from "pages/constants";
import {
  DEFAULT_RULE_STATE,
  EVERYONE_DEFAULT_ACCESS,
  ICON_SIZE,
  ON_SUCCESS_CREATE_TEXT,
  PLAN_DESCRIPTION_LABEL,
  PLAN_FAMILY_LABEL,
  PLAN_NAME_LABEL,
  SERVICE_PLAN_TITLE,
  SP_FORM_UPDATED_SUCCESSFULLY_MESSAGE,
} from "pages/ServicePlans/constants";
import { ServicePlanRuleState } from "pages/ServicePlans/types";
import { validateForm } from "pages/ServicePlans/utils";
import EligibleVehicleCount from "pages/shared/EligibleVehicleCount";

import Button from "features/ui/Button";
import DeleteAction from "features/ui/DeleteAction";
import FilterBuilder from "features/ui/Filters/FilterBuilder";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import SelectFilter from "features/ui/Filters/FilterTypes/SelectFilter";
import EditDetails from "features/ui/Form/EditDetails";
import FormSection from "features/ui/FormSection";
import Input from "features/ui/Input";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import PermissionsSettingsAction from "features/ui/PermissionsDialog/PermissionsSettingsAction";
import { calculatePermissionToSubmit } from "features/ui/PermissionsDialog/utils";
import Title from "features/ui/Title";

import { routes } from "services/routes";

import EditAction from "./EditAction";
import FormLoader from "./FormLoader";
import Rules from "./Rules";

type ServicePlanParams = {
  id: string;
};

const ServicePlan = () => {
  const navigate = useNavigate();
  const { rbac } = useFlags();

  const { id: servicePlanID } = useParams<ServicePlanParams>();

  const vehiclesFiltersSchema = useVehiclesFiltersSchema({
    disableIsNotFilteredFilters: true,
  });

  const isCreatePage = !servicePlanID;

  const [editMode, setEditMode] = useState(isCreatePage);

  const [initialServicePlan, setInitialServicePlan] =
    useState<ServicePlanModel>();

  const [name, setName] = useState("");
  const [description, setDescription] = useState<string>("");
  const [family, setFamily] = useState<string>("");
  const [vehicleFilterState, setVehicleFilterState] =
    useState<FilterGroupState>(DEFAULT_FILTER_BUILDER_STATE);
  const [rules, setRules] = useState<ServicePlanRuleState[]>([
    DEFAULT_RULE_STATE,
  ]);
  const [canEdit, setCanEdit] = useState(false);

  const [createdUpdated, setCreatedUpdated] = useState({
    createdBy: initialServicePlan?.createdBy || "",
    createdAt: initialServicePlan?.createdAt || "",
    updatedBy: initialServicePlan?.updatedBy || "",
    updatedAt: initialServicePlan?.updatedAt || "",
  });

  const myEmail = useEmailFromJWT();
  const [access, setAccess] = useState<PermissionEntry[]>([
    EVERYONE_DEFAULT_ACCESS,
    { ID: myEmail, email: myEmail, access: "edit" },
  ]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [showStaticValidationErrors, setShowStaticValidationErrors] =
    useState(!isCreatePage);

  const { getDisplayLabel } = useServicePlansSchema();

  const setServicePlanData = (newServicePlan: ServicePlanModel) => {
    const {
      name,
      description,
      family,
      rules,
      access,
      vehicleFilter,
      canEdit,
      createdAt,
      createdBy,
      updatedAt,
      updatedBy,
    } = newServicePlan;

    setCreatedUpdated({ createdAt, createdBy, updatedAt, updatedBy });

    setName(name);
    if (description) {
      setDescription(description);
    }
    if (family) {
      setFamily(family);
    }
    setRules(
      rules.map((r) => ({
        filter: filterBuilderQueryToFilterBuilderState(r.condition),
        recommendation: r.recommendation,
      }))
    );
    if (
      vehicleFilter &&
      filterBuilderQueryToFilterBuilderState(vehicleFilter) !== undefined
    ) {
      setVehicleFilterState(
        filterBuilderQueryToFilterBuilderState(vehicleFilter)!
      );
    }
    if (access) {
      setAccess(access);
    }

    setCanEdit(canEdit);
  };

  const setAndRememberInitialServicePlan = (servicePlan: ServicePlanModel) => {
    setInitialServicePlan(servicePlan);
    setServicePlanData(servicePlan);
  };

  const resetToInitialServicePlan = () => {
    if (!initialServicePlan) return;
    setServicePlanData(initialServicePlan);
  };

  // If we are on the Edit page, we retrieve the current plan data
  // and set the form values to these ..
  useEffect(() => {
    if (isCreatePage) return;

    setIsLoading(true);

    getServicePlan({ id: servicePlanID as string })
      .then(({ data }) => setAndRememberInitialServicePlan(data))
      .catch((err) => {
        navigate(routes.servicePlans);
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatePage, servicePlanID]);

  const { valid, message: staticErrorMessage } = validateForm(
    name,
    vehicleFilterState,
    rules
  );

  const handleServicePlanCreate = (data: ServicePlanRequest) => {
    newServicePlan({ ...data, acl: calculatePermissionToSubmit(access, []) })
      .then(({ data: { ID } }) => {
        toast.success(ON_SUCCESS_CREATE_TEXT);
        setEditMode(false);
        navigate(generatePath(routes.servicePlan, { id: ID }));
      })
      .catch((error) => {
        setValidationErrors(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleServicePlanUpdate = (data: ServicePlanRequest) => {
    updateServicePlan({ ID: servicePlanID!, ...data })
      .then(() => {
        setEditMode(false);
        toast.success(SP_FORM_UPDATED_SUCCESSFULLY_MESSAGE);
      })
      .catch((error) => {
        setValidationErrors(error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const submitCallback = isCreatePage
    ? handleServicePlanCreate
    : handleServicePlanUpdate;

  const handleOnCancel = () => {
    if (isCreatePage) {
      navigate(routes.servicePlans);
    } else {
      resetToInitialServicePlan();
      setEditMode(false);
    }
  };

  const handleOnSubmit = () => {
    if (!valid) {
      setShowStaticValidationErrors(true);
      return;
    }
    setValidationErrors("");
    setIsSubmitting(true);
    submitCallback({
      name,
      description: description || null,
      vehicleFilter: getFiltersQuery(vehicleFilterState),
      family: family || null,
      rules: rules.map(({ filter, recommendation }) => ({
        condition: getFiltersQuery(filter),
        recommendation,
      })),
    });
  };

  const handleOnDelete = (args: DeleteRequest) =>
    deleteServicePlan(args).then((response) => {
      navigate(routes.servicePlans);
      return response;
    });

  const ctaIcon =
    (isSubmitting && <LoadingIcon className="animate-spin" />) || undefined;

  const SUBMIT_CTA_TEXT = isCreatePage ? SUBMIT_CTA_NEW : SUBMIT_CTA_EDIT;

  const showError =
    validationErrors || (showStaticValidationErrors && staticErrorMessage);

  const isFormDisabled = isSubmitting || !editMode;

  const canEditServicePlan = !rbac || (rbac && canEdit);

  return (
    <div>
      {isLoading && <FormLoader />}
      {!isLoading && (
        <div className="max-w-5xl">
          {showError && (
            <Alert
              severity="error"
              data-testid="sps-form-validation-errors"
              className="mb-5"
            >
              <div className="font-bold mb-1">Validation errors</div>
              {validationErrors || staticErrorMessage}
            </Alert>
          )}
          <PageHeaderWrapper>
            <Title text={SERVICE_PLAN_TITLE} />
          </PageHeaderWrapper>
          <div className="mb-2 flex justify-between">
            <div className="flex w-full">
              <div className="w-24 mr-4 leading-9 text-right">
                {getDisplayLabel("name", PLAN_NAME_LABEL)}
              </div>
              <Input
                value={name}
                onChange={({ target: { value } }) => setName(value)}
                testId="sps-name"
                disabled={isFormDisabled}
                fullWidth={false}
                className="w-80"
                tabIndex={0}
              />
              <div className="flex items-center ml-auto">
                {!isCreatePage && <EditDetails props={createdUpdated} />}
                {editMode && (
                  <>
                    <Button
                      color="secondary"
                      variant="outlined"
                      label={CANCEL_CTA_TEXT}
                      onClick={handleOnCancel}
                      isLoading={isSubmitting}
                      endIcon={ctaIcon}
                      disabled={isSubmitting}
                      testId="sps-form-cancel-cta"
                      size="medium"
                      className="!mr-4"
                      tabIndex={-1}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      label={SUBMIT_CTA_TEXT}
                      onClick={handleOnSubmit}
                      isLoading={isSubmitting}
                      endIcon={ctaIcon}
                      disabled={isSubmitting}
                      testId="sps-form-submit-cta"
                      size="medium"
                      tabIndex={-1}
                    />
                  </>
                )}
                {!editMode && !isCreatePage && canEditServicePlan && (
                  <>
                    <PermissionsSettingsAction
                      entity="servicePlan"
                      entityId={servicePlanID!}
                      entityName={name}
                      permissions={access as PermissionEntry[]}
                      canEdit={canEdit}
                      iconSize={ICON_SIZE}
                      entityRequestKeys={[]}
                    />
                    <DeleteAction
                      data={{
                        ID: servicePlanID!,
                        name: initialServicePlan?.name || name,
                      }}
                      entityName="service plan"
                      deleteCallback={handleOnDelete}
                      entityRequestKeys={[]}
                      iconOnly={true}
                    />
                    <EditAction setEditMode={setEditMode} />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="mb-2 flex">
            <div className="w-24 mr-4  leading-9 text-right shrink-0">
              {getDisplayLabel("description", PLAN_DESCRIPTION_LABEL)}
            </div>
            <Input
              value={description}
              onChange={({ target: { value } }) => setDescription(value)}
              testId="sps-description"
              disabled={isFormDisabled}
              tabIndex={1}
            />
          </div>
          <div className="mb-8 flex">
            <div className="w-24 mr-4  leading-9 text-right shrink-0">
              {getDisplayLabel("family", PLAN_FAMILY_LABEL)}
            </div>
            <SelectFilter
              label=""
              fieldName="family"
              loadOptionsFunc={servicePlansLoadDataFunc}
              multiple={false}
              disableArbitraryText={false}
              onInputChange={setFamily}
              initialSelected={[{ id: family, value: family }]}
              search
              testId="sps-family"
              disabled={isFormDisabled}
              appendArbitraryOption={false}
              placeholder=""
              loadDataOnOpen
            />
          </div>
          <FormSection
            title={getDisplayLabel("vehicleFilter", "Eligible Vehicles")}
            extraTitleComponent={
              !editMode && (
                <EligibleVehicleCount vehicleFilter={vehicleFilterState} />
              )
            }
            text={
              isFormDisabled
                ? undefined
                : "Specify filters to define the set of vehicles eligible for this plan."
            }
          >
            {isFormDisabled ? (
              <FilterQueryPresentation
                filterState={vehicleFilterState}
                tableSchema={vehiclesFiltersSchema}
                dense={false}
              />
            ) : (
              <FilterBuilder
                filterBuilderState={vehicleFilterState}
                filtersSchema={vehiclesFiltersSchema}
                onChange={setVehicleFilterState}
                inline
                disabled={isFormDisabled}
                testId="eligible-vehicles-filter-builder"
              />
            )}
          </FormSection>
          <Rules
            rules={rules}
            setRules={setRules}
            disabledInput={isFormDisabled}
            servicePlanId={servicePlanID}
          />
        </div>
      )}
    </div>
  );
};

export default ServicePlan;
