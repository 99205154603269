import { IssueTypes } from "shared/types";

import CardHeader from "pages/Issues/Details/CardHeader";
import { getPopulationString } from "pages/Issues/utils";

import Card from "features/ui/Card";

import IssueSignalEventsPopulationStatistics from "./IssueSignalEventsPopulationStatistics";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const AT_RISK_TITLE = `Signal Events: ${getPopulationString("atRisk")}`;
const PER_POPULATION_TITLE = "Signal Event Metrics per Population";

const IssueSignalEventsStatistics = (props: Props) => (
  <Card classNames="mb-3">
    <CardHeader
      title={
        props.withComparisonPopulation ? PER_POPULATION_TITLE : AT_RISK_TITLE
      }
      showActions={false}
    />
    <div className="space-y-3">
      <IssueSignalEventsPopulationStatistics population="atRisk" {...props} />
      {props.withComparisonPopulation && (
        <IssueSignalEventsPopulationStatistics
          population="comparison"
          {...props}
        />
      )}
    </div>
  </Card>
);

export default IssueSignalEventsStatistics;
