import Input from "features/ui/Input";

interface Props {
  collectionName: string;
  onCollectionNameChange: (str: string) => void;
  nameValid: boolean;
}

const Step2 = ({
  collectionName,
  onCollectionNameChange,
  nameValid,
}: Props) => (
  <div className="max-w-xs">
    <Input
      value={collectionName}
      label="Name your new collection"
      placeholder="Collection name"
      onChange={(e) => onCollectionNameChange(e.target.value)}
    />
    {collectionName.length > 0 && !nameValid && (
      <div className="text-red-400 text-xs">
        Collection name should be between 5 and 120 characters.
      </div>
    )}
  </div>
);

export default Step2;
