type Props = {
  elements: JSX.Element[][];
};

const Board = ({ elements }: Props) => (
  <div className="lg:flex lg:space-x-3">
    {elements.map((element, index) => (
      <div
        className="flex flex-col space-y-3 w-full my-3 lg:my-0"
        key={`board-${index}`}
      >
        {element.map((entry, entryIdx) => (
          <span key={`board-${index}-${entryIdx}`}>{entry}</span>
        ))}
      </div>
    ))}
  </div>
);

export default Board;
