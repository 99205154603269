import { useFlags } from "launchdarkly-react-client-sdk";
import { Navigate, Route, Routes } from "react-router-dom";

import SecureComponent from "features/auth/Login/SecureComponent";

import { redirects, routes } from "services/routes";
import {
  RouteConfig,
  RouteDataProps,
  routesConfig,
} from "services/routesConfig";
import * as config from "config/config";

import RouteMiddleware from "./services/routes/RouteMiddleware";

const DEFAULT_ROUTE_VISIBILITY = true;

const RouteComponent = ({
  config,
  data,
}: {
  config: RouteConfig;
  data: RouteDataProps;
}) => {
  // RouteConfig has exactly one of component or componentFunction defined
  if (config.insecure) {
    return config.componentFunction
      ? config.componentFunction(data)
      : config.component;
  }
  return (
    <SecureComponent>
      <RouteMiddleware config={config}>
        {config.componentFunction
          ? config.componentFunction(data)
          : config.component}
      </RouteMiddleware>
    </SecureComponent>
  );
};

const AppRoutes = () => {
  const flags = useFlags();
  const { pages } = config.get();
  const dashboards =
    (pages.dashboards?.enabled && pages.dashboards?.dashboards) || [];

  const rootPage = pages.rootPage?.route
    ? pages.rootPage.route
    : routes.vehicles;

  const routeProps: RouteDataProps = {
    flags,
    pages,
    props: { dashboards, rootPage },
  };
  const displayedRoutes = Object.entries(routesConfig).filter(([_, config]) =>
    config.shouldDisplay
      ? config.shouldDisplay(routeProps)
      : DEFAULT_ROUTE_VISIBILITY
  );

  /**
   * Ideally we start "lifting" routes up to the data router:
   * - https://reactrouter.com/en/main/upgrading/v6-data#start-lifting-routes-and-leveraging-the-data-apis
   */
  return (
    <Routes>
      {Object.entries(redirects).map(([from, to]) => (
        <Route path={from} key={to} element={<Navigate to={to} />} />
      ))}
      {displayedRoutes.map(([key, config]) => (
        <Route
          key={key}
          path={config.path}
          element={<RouteComponent config={config} data={routeProps} />}
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
