import { provideQueryStringNavigation } from "duck/graph/PageHandler/utils";
import { Stack } from "@mui/material";

import DuckMessages from "./DuckMessages";
import DuckResetMemory from "./DuckResetMemory";
import DuckTextInput from "./DuckTextInput";
import {
  usePendingAction,
  useQueryStringNavigation,
  useThreadId,
} from "./hooks";

interface DuckOpenContentProps {
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

const DuckOpenContent = ({ loading, setLoading }: DuckOpenContentProps) => {
  const { pendingAction, setPendingAction } = usePendingAction();

  const { threadId, resetThreadId } = useThreadId();
  // We make the queryStringNavigation functions available to the agent
  // and the tools and handlers used by the agent.
  const queryStringNavigation = useQueryStringNavigation();
  provideQueryStringNavigation(queryStringNavigation);

  return (
    <Stack height="100%" paddingX={0.5} display="flex" flexGrow={1}>
      <Stack
        display="flex"
        direction="column"
        flexGrow={1}
        overflow="hidden"
        height="100%"
      >
        <DuckMessages
          loading={loading}
          pendingAction={pendingAction}
          setPendingAction={setPendingAction}
          updateLocation={queryStringNavigation.updateLocation}
        />
      </Stack>
      <Stack className="py-1" direction="row" spacing={0.5} flexGrow={0}>
        <DuckResetMemory
          enabled={!loading}
          resetThreadId={resetThreadId}
          setPendingAction={setPendingAction}
        />
        <DuckTextInput
          threadId={threadId}
          loading={loading}
          setLoading={setLoading}
          setPendingAction={setPendingAction}
          acquireLocationInformation={queryStringNavigation.deliverLocationInfo}
          clearLocationInfo={queryStringNavigation.clearLocationInfo}
        />
      </Stack>
    </Stack>
  );
};

export default DuckOpenContent;
