import { useEffect, useState } from "react";
import DAFCF from "imgs/daf/DAF-CF.png";
import DAFXD from "imgs/daf/DAF-XD.png";
import DAFXF from "imgs/daf/DAF-XF.png";
import DAFXG from "imgs/daf/DAF-XG.png";
import DAFXGPlus from "imgs/daf/DAF-XG+.png";
import FordF250KingRanch from "imgs/ford/f250-kingranch.png";
import FordF250Lariat from "imgs/ford/f250-lariat.png";
import FordF250Limited from "imgs/ford/f250-limited.png";
import FordF250Platinum from "imgs/ford/f250-platinum.png";
import FordF250XL from "imgs/ford/f250-xl.png";
import FordF250XLT from "imgs/ford/f250-xlt.png";
import FordF350KingRanch from "imgs/ford/f350-kingranch.png";
import FordF350Lariat from "imgs/ford/f350-lariat.png";
import FordF350Limited from "imgs/ford/f350-limited.png";
import FordF350Platinum from "imgs/ford/f350-platinum.png";
import FordF350XL from "imgs/ford/f350-xl.png";
import FordF350XLT from "imgs/ford/f350-xlt.png";
import FordF450KingRanch from "imgs/ford/f450-kingranch.png";
import FordF450Lariat from "imgs/ford/f450-lariat.png";
import FordF450Limited from "imgs/ford/f450-limited.png";
import FordF450Platinum from "imgs/ford/f450-platinum.png";
import FordF450XL from "imgs/ford/f450-xl.png";
import FordF450XLT from "imgs/ford/f450-xlt.png";
import FordGeneric from "imgs/ford/generic.png";
import GenericCar from "imgs/generic-car.png";
import BuickEnclaveImage from "imgs/gm/buick_enclave.png";
import ChevroletBlazerImage from "imgs/gm/chevrolet_blazer.png";
import ChevroletColoradoImage from "imgs/gm/chevrolet_colorado.png";
import ChevroletTraverseImage from "imgs/gm/chevrolet_traverse.png";
import GMCArcadiaImage from "imgs/gm/gmc_arcadia.png";
import GMCCanyonImage from "imgs/gm/gmc_canyon.png";
import KenworthImage from "imgs/Kenworth.jpg";
import KnightswiftImage from "imgs/Knightswift.png";
import NikolaImage from "imgs/Nikola.png";
import NissanARIYA from "imgs/NissanARIYA.png";
import NissanPATHFINDER from "imgs/NissanPATHFINDER.png";
import NissanQX60 from "imgs/NissanQX60.png";
import NissanROGUE from "imgs/NissanROGUE.png";
import PeterbiltImage from "imgs/Peterbilt.png";

import { getTransportCategory } from "shared/api/api";
import { Vehicle } from "shared/api/vehicles/api";
import { useVehicle } from "shared/api/vehicles/hooks";
import { getTenantMileageUnit } from "shared/utils";

import * as config from "config/config";

// useVehicleImage is a fake hook for
// getting the image associated with a vehicle
export const useVehicleImage = (data: Partial<Vehicle> = {}): string => {
  const { tenant } = config.get();

  if (tenant === "knightswift") {
    return KnightswiftImage;
  }

  let { vehicleMake, vehicleModel, trimLevel } = data;

  // handle missing values
  if (!vehicleMake) {
    vehicleMake = "";
  }
  if (!vehicleModel) {
    vehicleModel = "";
  }

  // TODO: let's add all these to tenant config at one point
  // and use a CDN or API photo

  // PACCAR custom images
  if (vehicleMake.toLowerCase() === "peterbilt") {
    return PeterbiltImage;
  }
  if (vehicleMake.toLowerCase() === "kenworth") {
    return KenworthImage;
  }

  // GM custom images
  if (vehicleMake === "Chevrolet" && vehicleModel === "Colorado") {
    return ChevroletColoradoImage;
  }
  if (vehicleMake === "Chevrolet" && vehicleModel === "Blazer") {
    return ChevroletBlazerImage;
  }
  if (vehicleMake === "Chevrolet" && vehicleModel === "Traverse C1") {
    return ChevroletTraverseImage;
  }
  if (vehicleMake === "Buick" && vehicleModel === "Enclave C1") {
    return BuickEnclaveImage;
  }
  if (vehicleMake === "GMC" && vehicleModel === "Acadia C1") {
    return GMCArcadiaImage;
  }
  if (vehicleMake === "GMC" && vehicleModel === "Canyon") {
    return GMCCanyonImage;
  }

  // Nissan custom images
  if (vehicleMake.toLowerCase() === "nissan" && vehicleModel === "ROGUE") {
    return NissanROGUE;
  }
  if (vehicleMake.toLowerCase() === "nissan" && vehicleModel === "ARIYA") {
    return NissanARIYA;
  }
  if (vehicleMake.toLowerCase() === "nissan" && vehicleModel === "PATHFINDER") {
    return NissanPATHFINDER;
  }
  if (vehicleMake.toLowerCase() === "infiniti" && vehicleModel === "QX60") {
    return NissanQX60;
  }

  // DAF custom images
  if (vehicleModel === "CF") {
    return DAFCF;
  }
  if (vehicleModel === "XD") {
    return DAFXD;
  }
  if (vehicleModel === "XF") {
    return DAFXF;
  }
  if (vehicleModel === "XG") {
    return DAFXG;
  }
  if (vehicleModel === "XG+") {
    return DAFXGPlus;
  }

  // Ford images
  if (vehicleMake === "F" && vehicleModel === "F-250") {
    if (trimLevel === "XL") {
      return FordF250XL;
    }
    if (trimLevel === "XLT") {
      return FordF250XLT;
    }
    if (trimLevel === "LARIAT") {
      return FordF250Lariat;
    }
    if (trimLevel === "KING RANCH") {
      return FordF250KingRanch;
    }
    if (trimLevel === "PLATINUM") {
      return FordF250Platinum;
    }
    if (trimLevel === "LIMITED") {
      return FordF250Limited;
    }
  }

  if (vehicleMake === "F" && vehicleModel === "F-350") {
    if (trimLevel === "XL") {
      return FordF350XL;
    }
    if (trimLevel === "XLT") {
      return FordF350XLT;
    }
    if (trimLevel === "LARIAT") {
      return FordF350Lariat;
    }
    if (trimLevel === "KING RANCH") {
      return FordF350KingRanch;
    }
    if (trimLevel === "PLATINUM") {
      return FordF350Platinum;
    }
    if (trimLevel === "LIMITED") {
      return FordF350Limited;
    }
  }

  if (vehicleMake === "F" && vehicleModel === "F-450") {
    if (trimLevel === "XL") {
      return FordF450XL;
    }
    if (trimLevel === "XLT") {
      return FordF450XLT;
    }
    if (trimLevel === "LARIAT") {
      return FordF450Lariat;
    }
    if (trimLevel === "KING RANCH") {
      return FordF450KingRanch;
    }
    if (trimLevel === "PLATINUM") {
      return FordF450Platinum;
    }
    if (trimLevel === "LIMITED") {
      return FordF450Limited;
    }
  }

  if (vehicleMake === "F") {
    return FordGeneric;
  }

  // Nikola custom images
  if (vehicleMake === "NikolaTruck") {
    return NikolaImage;
  }

  return GenericCar;
};

export const useVehicleTransportCategoryDisplayName = (
  vin: string
): string | undefined => {
  const [transportCategory, setTransportCategory] = useState<string>();
  const { data } = useVehicle({ vin, mileageUnit: getTenantMileageUnit() });

  // when vin changed and thus inferred category, reset inferred category first
  useEffect(() => {
    setTransportCategory("");
  }, [vin]);

  useEffect(() => {
    if (!data) return;

    const { transportCategoryID } = data;

    if (!transportCategoryID) return;

    getTransportCategory({ id: transportCategoryID })
      .then(({ data: { name } }) => setTransportCategory(name))
      .catch((err) => {
        console.error(err);
      });
  }, [data]);

  return transportCategory;
};
