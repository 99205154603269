import { SyntheticEvent } from "react";
import * as React from "react";
import {
  BsChevronDown as ChevronDownIcon,
  BsChevronUp as ChevronUpIcon,
} from "react-icons/bs";
import {
  Accordion as Acc,
  AccordionDetails,
  AccordionSummaryProps,
} from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/system";

import { AccordionArrowPosition } from "shared/types";

import Label from "features/ui/Label/Label";

export interface AccordionProps {
  id: string;
  title: string | JSX.Element;
  rightContent?: string | JSX.Element;
  children?: string | JSX.Element;
  expanded?: boolean;
  onChange?: (event: SyntheticEvent, isExpanded: boolean) => void;
  arrowPosition?: AccordionArrowPosition;
}

interface ExtendedAccordionSummaryProps {
  // arrowposition intentionally has this name, otherwise the following error is thrown in the console:
  // React does not recognize the `arrowPosition` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `arrowposition` instead.
  // eslint-disable-next-line
  arrowposition: AccordionArrowPosition;
  expanded?: boolean;
  props?: AccordionSummaryProps;
  children?: React.ReactNode;
}

const CustomizedAccordion = styled(Acc)(() => ({
  "&&.Mui-expanded:before": {
    opacity: 1,
  },
}));

const CustomizedAccordionSummary = styled(
  ({ expanded, ...props }: ExtendedAccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
      {...props}
    />
  )
)(({ arrowposition }) => ({
  flexDirection: arrowposition === "right" ? "row" : "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
}));

const Accordion = ({
  id,
  title,
  children,
  expanded,
  onChange,
  arrowPosition = "right",
  rightContent,
}: AccordionProps) => (
  <CustomizedAccordion
    key={id}
    data-testid={`accordion-${id}`}
    expanded={expanded}
    onChange={onChange && onChange}
    square
    disableGutters
    slotProps={{
      transition: {
        unmountOnExit: true,
      },
    }}
  >
    <CustomizedAccordionSummary arrowposition={arrowPosition}>
      <div className="w-full flex justify-between items-center">
        <Label text={title} className="ml-2 text-gray-500" />
        {rightContent}
      </div>
    </CustomizedAccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </CustomizedAccordion>
);

export default Accordion;
