import { AppConfig, AuthScopes, PagesConfig } from "config/config";

import pagesConfig from "./pagesConfig.json";

export const tenant = "gm";
export const host = `${tenant}.cloud.viaduct.ai`;

const prodAuthConfig = {
  name: "gm",
  idp: "0oa13z7dyOysdGFnU5d6",
  clientId: "0oa95bknrmyjJIAyV5d7",
  issuer: "https://viaduct-cloud.okta.com/oauth2/aus95bmmteCNKg77E5d7",
  scopes: ["openid", "email", "offline_access"] as AuthScopes[],
};

const prodAPIConfig = {
  url: `https://api.${host}/v0`,
};

const featureFlagsProd = {
  launchDarklyClientId: "643d31ea46a60e13d54c594e",
};

export const prodAppConfig: AppConfig = {
  tenant,
  environment: "prod",
  // we need to cast pagesConfig to PagesConfig because it's imported from a json file and typescript doesn't know the type of rootPage.route string
  pages: pagesConfig as PagesConfig,
  analytics: {
    heap: { id: "2946143066" },
  },
  featureFlags: featureFlagsProd,
  auth: prodAuthConfig,
  api: prodAPIConfig,
};
