import {
  GPT4O_MODEL_SPEC,
  LC_API_KEY,
  LC_ENDPOINT,
  LC_PROJECT_NAME,
  OPENAI_API_KEY,
} from "duck/graph/constants";
import { GraphStateType } from "duck/graph/state";
import { ModelSpec } from "duck/graph/types";
import { Client } from "langsmith";
import { Document } from "@langchain/core/documents";
import { AIMessage, BaseMessage, ToolMessage } from "@langchain/core/messages";
import { LangChainTracer } from "@langchain/core/tracers/tracer_langchain";
import { END } from "@langchain/langgraph/web";
import { ChatOpenAI, ChatOpenAICallOptions } from "@langchain/openai";

import {
  vectorStoreSearch,
  VectorStoreSearchParameters,
  VectorStoreSearchResult,
} from "shared/api/vectorstore/api";

// Nodes that can be routed through a tool call with the same name
export const ToolCallRoutableNodeNames = {
  RAG: "rag",
  ANALYZE_SCREENSHOT: "analyzeScreenshot",
  CAPTURE_SCREENSHOT: "captureScreenshot",
  GREETING_REJECT_CLARIFY: "greetingRejectClarify",
  CLAIM_ANALYTICS: "claimAnalytics",
  SIGNAL_EVENT_ANALYTICS: "signalEventAnalytics",
  VIN_VIEW: "vinView",
  VEHICLES: "vehicles",
  ROUTER: "router",
  RESPOND_TO_USER: "respondToUser",
  ISSUES: "issues",
  ISSUE_DETAILS: "issueDetails",
  SUBMIT_FEEDBACK: "submitFeedback",
  PAGE_AGENT_RESPONDER: "pageAgentResponder",
} as const;
export type ToolCallRoutableNodeNamesType =
  (typeof ToolCallRoutableNodeNames)[keyof typeof ToolCallRoutableNodeNames];

// Combine the values for all NodeNames
export const NodeNames = {
  ...ToolCallRoutableNodeNames,
  DOCUMENT_RETRIEVAL: "documentRetrieval",
  CLAIM_ANALYTICS_TOOLS: "claimAnalyticsTools",
  SIGNAL_EVENT_ANALYTICS_TOOLS: "signalEventAnalyticsTools",
  VIN_VIEW_TOOLS: "vinViewTools",
  VEHICLES_TOOLS: "vehiclesTools",
  ISSUES_TOOLS: "issuesTools",
  ISSUE_DETAILS_TOOLS: "issueDetailsTools",
  HANDLE_REPEATED_TOOL_CALL: "handleRepeatedToolCall",
} as const;
export type NodeNamesType = (typeof NodeNames)[keyof typeof NodeNames];

export const GenericToolNodeName = "tools";

type NextNodeType =
  | ToolCallRoutableNodeNamesType
  | typeof GenericToolNodeName
  | typeof END
  | typeof NodeNames.HANDLE_REPEATED_TOOL_CALL;

/**
 * Respond based on the last message's tool call
 * @summary Conditional routing function for the agent
 * @param state
 * @returns An indicator of which node to route to
 */
export const getNextNode = (state: GraphStateType): NextNodeType => {
  const { messages } = state;

  // If the last message is a repeated tool call, route to HANDLE_REPEATED_TOOL_CALL node
  if (isRepeatedToolCall(messages)) {
    console.error(
      "getNextNode: Repeated tool call detected, routing to HANDLE_REPEATED_TOOL_CALL"
    );
    return NodeNames.HANDLE_REPEATED_TOOL_CALL;
  }
  const lastMessage = messages[messages.length - 1];
  if (
    "tool_calls" in lastMessage &&
    Array.isArray(lastMessage.tool_calls) &&
    lastMessage.tool_calls?.length
  ) {
    const toolCallName = lastMessage.tool_calls[0].name;
    console.debug("getNextNode: Tool call name:", toolCallName);
    if (Object.values(ToolCallRoutableNodeNames).includes(toolCallName)) {
      console.debug("getNextNode: Routing to", toolCallName);
      return toolCallName;
    }
    console.debug("getNextNode: Routing to tools");
    return GenericToolNodeName;
  }

  // this should never happen but just in case
  console.error(
    "getNextNode: there is no tool call in the last message, ending the conversation"
  );
  return END;
};

/**
 * Determines if the last three messages in the provided array represent a repeated tool call.
 *
 * The function checks the following conditions:
 * 1. The array must contain at least three messages.
 * 2. The last message must be an instance of `AIMessage` with a tool call.
 * 3. The second last message must be an instance of `ToolMessage` with the same tool call name and a successful status.
 * 4. The third last message must be an instance of `AIMessage` with the same name and tool call as the last message.
 * 5. The parameters of the tool calls in the last and third last messages must be deeply equal.
 *
 * @param messages - An array of `BaseMessage` objects to be checked.
 * @returns `true` if the last three messages represent a repeated tool call, otherwise `false`.
 */
const isRepeatedToolCall = (messages: BaseMessage[]): boolean => {
  if (messages.length < 3) {
    return false;
  }

  const lastMessage = messages[messages.length - 1];
  const secondLastMessage = messages[messages.length - 2];
  const thirdLastMessage = messages[messages.length - 3];

  // Check if last message is an AIMessage with a tool call
  if (!(lastMessage instanceof AIMessage)) {
    return false;
  }
  const lastToolCall = lastMessage.tool_calls?.[0];
  if (!lastToolCall) {
    return false;
  }

  // Check if second last message is a successful ToolMessage with the same tool call name
  if (!(secondLastMessage instanceof ToolMessage)) {
    return false;
  }
  if (
    secondLastMessage.name !== lastToolCall.name ||
    secondLastMessage.status === "error"
  ) {
    return false;
  }

  // Check if third last message is an AIMessage with the same name and tool call as the last message
  if (!(thirdLastMessage instanceof AIMessage)) {
    return false;
  }
  if (thirdLastMessage.name !== lastMessage.name) {
    return false;
  }
  const thirdToolCall = thirdLastMessage.tool_calls?.[0];
  if (!thirdToolCall || thirdToolCall.name !== lastToolCall.name) {
    return false;
  }

  // Compare params for deep equality
  const paramsEqual =
    JSON.stringify(lastToolCall.args) === JSON.stringify(thirdToolCall.args);

  if (!paramsEqual) {
    return false;
  }

  return true;
};

export const getLLM = (
  modelSpec: ModelSpec = GPT4O_MODEL_SPEC
): ChatOpenAI<ChatOpenAICallOptions> =>
  new ChatOpenAI({
    openAIApiKey: OPENAI_API_KEY,
    model: modelSpec.modelName,
    temperature: modelSpec.temperature,
    modelKwargs: modelSpec.modelKwargs,
  });

export const retrieveVectorStoreResults = async (
  params: VectorStoreSearchParameters
): Promise<VectorStoreSearchResult[]> => {
  let data: VectorStoreSearchResult[] = [];

  try {
    const response = await vectorStoreSearch(params);
    data = response.data;
  } catch (vectorStoreError) {
    console.error("Failed to retrieve relevant documents", vectorStoreError);
    return [];
  }

  return data;
};

export const retrieveRelevantDocuments = async (
  query: string,
  source?: string,
  k?: number,
  distanceThreshold?: number
): Promise<Document[]> => {
  console.debug("Retrieving relevant documents", {
    query,
    source,
    k,
    distanceThreshold,
  });

  const params: VectorStoreSearchParameters = {
    query,
    k,
    distanceThreshold,
    source,
  };

  const data = await retrieveVectorStoreResults(params);

  if (!data) {
    console.error("No documents found for the given question.");
    return [];
  }

  const documents = data.map((result) => {
    const { documentID, document, title, url, metadata } = result;
    console.log("Document retrieved:", {
      documentID,
      document,
      title,
      url,
      source,
      metadata,
    });

    let parsedMetadata: Record<string, any>;
    try {
      parsedMetadata = JSON.parse(metadata);
    } catch (error) {
      console.error("Failed to parse metadata:", error);
      parsedMetadata = { rawMetadata: metadata };
    }

    return new Document({
      pageContent: document,
      metadata: { title, url, source, ...parsedMetadata },
      id: documentID,
    });
  });

  return documents;
};

/**
 * @summary Format the documents for the LLM
 * @param docs The documents to format
 * @returns The formatted documents
 */

export const formatDocs = (docs: Document[]) =>
  JSON.stringify(
    docs.map((doc) => ({
      pageContent: doc.pageContent,
      url: doc.metadata.url,
      title: doc.metadata.title,
    }))
  );

let langSmithClient: Client | undefined;
export const getLangSmithClient = () => {
  if (!langSmithClient) {
    langSmithClient = new Client({ apiKey: LC_API_KEY, apiUrl: LC_ENDPOINT });
  }
  return langSmithClient;
};

export const getTracer = () =>
  new LangChainTracer({
    client: getLangSmithClient(),
    projectName: LC_PROJECT_NAME,
  });
