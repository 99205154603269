export const GROUP_ISSUES_TITLE = "My Group's Issues";
export const MY_ISSUES_TITLE = "My Issues";
export const ALL_ISSUES_TITLE = "All Issues";
export const STALE_ISSUES_TEXT =
  "Restrict to stale issues (not updated in the last 7 days)";
export const OPEN_ISSUES_TITLE = "Current Open Issues";
export const WARRANTY_COST_TITLE = "Warranty Cost over the last 7 days";
export const AFFECTED_BY_CLAIMS_TITLE =
  "Vehicles Affected by Claims over the last 7 days";
export const AFFECTED_BY_SIGNAL_EVENTS_TITLE =
  "Vehicles Affected by Signal Events over the last 7 days";
export const AFFECTED_BY_HIGH_PRIORITY_SIGNAL_EVENTS_TITLE =
  "Vehicles Affected by High Priority Signal Events over the last 7 days";
export const TOTAL_TOOLTIP_TEXT = "Total over the last 7 days";
export const RELATIVE_TOOLTIP_TEXT =
  "Relative change from the previous 7 day period";
export type IssuesViewType = "myIssues" | "groupIssues" | "allIssues";
