import { PageHandler } from "duck/graph/PageHandler";
import { DuckGraphParams } from "duck/graph/types";
import { StructuredTool } from "@langchain/core/tools";

import {
  ISSUE_ACTIVITY_TAB_KEY,
  ISSUE_CLAIMS_TAB_KEY,
  ISSUE_DETAILS_TAB_CHARTS,
  ISSUE_DETAILS_TAB_TITLES,
  ISSUE_RELATIONSHIP_TAB_KEY,
  ISSUE_REPAIR_EFFICACY_TAB_KEY,
  ISSUE_SIGNAL_EVENTS_TAB_KEY,
} from "pages/Issues/constants";

import getGoToIssueDetailsPageTool from "./getGoToIssueDetailsPageTool";
import getGoToTabTool from "./getGoToTabTool";
import getSearchIssuesTool from "./getSearchIssuesTool";
import getUpdateTabChartSettingsTool from "./getUpdateTabChartSettingsTool";

/**
 * @summary Get the tools for the vin view agent.
 * The tools are specific to vin view, and are bound to the UI handlers
 * for vin view.
 * @param params The data from the UI layer that is necessary for the tools to function.
 * @returns The full set of tools for the signal event analytics agent.
 */
const getTools = (
  params: DuckGraphParams,
  pageHandler: PageHandler
): StructuredTool[] => {
  const tabKeys: (keyof typeof ISSUE_DETAILS_TAB_TITLES)[] = [
    ISSUE_CLAIMS_TAB_KEY,
    ISSUE_SIGNAL_EVENTS_TAB_KEY,
    ISSUE_RELATIONSHIP_TAB_KEY,
    ISSUE_REPAIR_EFFICACY_TAB_KEY,
    ISSUE_ACTIVITY_TAB_KEY,
  ];

  const tools: StructuredTool[] = [
    getGoToIssueDetailsPageTool(
      pageHandler,
      params.uiHandlers.setEphemeralMessage
    ),
    getSearchIssuesTool(params.uiHandlers.setEphemeralMessage),
  ];

  // Add tools for each tab and chart
  tabKeys.forEach((tabKey) => {
    const tabTitle = ISSUE_DETAILS_TAB_TITLES[tabKey].replace(/\s/g, "");
    const tabCharts = ISSUE_DETAILS_TAB_CHARTS[tabKey];
    tools.push(
      getGoToTabTool(
        pageHandler,
        params.uiHandlers.setEphemeralMessage,
        tabKey,
        tabTitle
      )
    );
    Object.values(tabCharts).forEach((chartName) => {
      tools.push(
        getUpdateTabChartSettingsTool(
          pageHandler,
          params.uiHandlers.setEphemeralMessage,
          tabKey,
          tabTitle,
          chartName
        )
      );
    });
  });

  return tools;
};

export default getTools;
