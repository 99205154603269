import classNames from "classnames";
import { DuckMessage as DuckMessageType } from "duck/context/DuckMessagesContextWrapper";
import ReactMarkdown from "react-markdown";

interface DuckMessageProps {
  message: DuckMessageType;
}

/**
 * The Markdown component by itself recognizes lists but does not properly render them.
 * It omits the discs and numbers from the lists. The `components` parameter sent to
 * the Markdown component allows us to override the default rendering of lists to make
 * them look correct. It is likely that the CSS of the project prevents the standard
 * rendering of lists from working as expected in markdown.
 */
const DuckMessage = ({ message: { author, message } }: DuckMessageProps) => {
  const isHuman = author === "human";

  return (
    <div
      className={classNames("w-full flex", {
        "justify-end": isHuman,
        "justify-start": !isHuman,
      })}
    >
      <div
        className={classNames("p-1", {
          "font-bold": isHuman,
          italic: isHuman,
          "text-right": isHuman,
          "text-left": !isHuman,
        })}
      >
        <ReactMarkdown
          components={{
            ul: ({ node, ...props }) => (
              <ul
                style={{ listStyleType: "disc", paddingLeft: "20px" }}
                {...props}
              />
            ),
            ol: ({ node, ...props }) => (
              <ol
                style={{ listStyleType: "decimal", paddingLeft: "20px" }}
                {...props}
              />
            ),
            a: ({ node, ...props }) => (
              // The ReactMarkdown library injects content into the anchor tag.
              // It is not a problem that there is no content at this point in the process.
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                // It strikes me as odd that we are using a color named "metabase-blue" here
                // but it is widely used throughout the codebase. It appears to be our defacto
                // standard blue color for links.
                className="text-metabase-blue hover:underline"
                {...props}
                target="_blank"
              />
            ),
          }}
        >
          {message}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default DuckMessage;
