import React from "react";

import { MAX_CHART_LABEL_LENGTH } from "pages/VINView/Events/EventsTimeline";

import {
  AXIS_TOOLTIP_FONT_SIZE,
  LABEL_COLOR,
} from "features/ui/charts/constants";

interface TruncatedYAxisTickProps {
  x?: number;
  y?: number;
  payload?: {
    value: string;
  };
}

const TruncatedYAxisTick = ({ x, y, payload }: TruncatedYAxisTickProps) => {
  if (!payload || !payload.value) return null;

  const truncatedValue =
    payload.value.length > MAX_CHART_LABEL_LENGTH + 1
      ? `${payload.value.slice(0, MAX_CHART_LABEL_LENGTH)}...`
      : payload.value;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={4}
        textAnchor="end"
        fill={LABEL_COLOR}
        fontSize={AXIS_TOOLTIP_FONT_SIZE}
      >
        {truncatedValue}
      </text>
    </g>
  );
};

export default TruncatedYAxisTick;
