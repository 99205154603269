import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import { BY_VEHICLES_AGE_TAB_KEY } from "pages/constants";

import { GoToByVehiclesAgeTabToolParams } from "./types";

const getGoToByVehiclesAgeTabTool = ({
  pageHandler,
  chartOptions,
  pageKey,
  chartOptionsKey,
  setEphemeralMessage,
}: GoToByVehiclesAgeTabToolParams): DynamicStructuredTool => {
  const goToByVehiclesAgeTabSchema = z.object({
    y: z
      .enum(chartOptions.y)
      .optional()
      .describe(
        `The metric to be displayed on the y-axis of the chart. Default value: '${chartOptions.y[0]}'.`
      ),
    x: z
      .enum(chartOptions.x)
      .optional()
      .describe(
        `The vehicle age attribute to be displayed on the x-axis of the chart. Default value: '${chartOptions.x[0]}'.`
      ),
    granularity: z
      .enum(chartOptions.granularity)
      .optional()
      .describe(
        `The granularity of the chart. Default value: '${chartOptions.granularity[0]}'.`
      ),
    exposure: z
      .enum(chartOptions.exposure)
      .optional()
      .describe(
        `The exposure of the chart. Default value: '${chartOptions.exposure[0]}'.`
      ),
  });

  type GoToByVehiclesAgeTab = z.infer<typeof goToByVehiclesAgeTabSchema>;

  const goToByVehiclesAgeTab = ({
    y,
    x,
    granularity,
    exposure,
  }: GoToByVehiclesAgeTab) => {
    setEphemeralMessage("to by vehicles age tab");
    console.debug("Navigating to By Vehicles Age tab with:", {
      y,
      x,
      granularity,
      exposure,
    });

    const nonNullY = y ?? chartOptions.y[0];
    const nonNullX = x ?? chartOptions.x[0];
    const nonNullGranularity = granularity ?? chartOptions.granularity[0];
    const nonNullExposure = exposure ?? chartOptions.exposure[0];

    pageHandler.updateTabChartSettings(pageKey, BY_VEHICLES_AGE_TAB_KEY, {
      [chartOptionsKey]: [
        { id: "y", optionId: nonNullY },
        { id: "x", optionId: nonNullX },
        { id: "granularity", optionId: nonNullGranularity },
        { id: "exposure", optionId: nonNullExposure },
      ],
    });

    return "queued navigation to By Vehicles Age tab";
  };

  return tool(goToByVehiclesAgeTab, {
    name: "queueGoToByVehiclesAgeTab",
    description: `Navigate to the By Vehicles Age tab to view a chart and a table with data organized by vehicle age.
Use this tool when the user wants to:
- Explore data by vehicle age
- View a "MOP/MIS" chart to detect emerging production issues
- Select a different metric to view on the y-axis of the chart
- Select a different vehicle age attribute to view on the x-axis of the chart
- Select a different granularity for the chart
- Select a different exposure for the chart`,
    schema: goToByVehiclesAgeTabSchema,
  });
};

export default getGoToByVehiclesAgeTabTool;
