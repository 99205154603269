import { validateApiRequest } from "duck/graph/nodes/utils";

import {
  ClaimFiltersRequest,
  getClaimsMetricsRequestURI,
} from "shared/api/claims/api";

/**
 * Enum representing the possible sources for the filter.
 */
export enum FilterSource {
  CLAIM = "Claims",
  VEHICLE = "Vehicles",
  SIGNAL_EVENT_OCCURRENCE = "SignalEventOccurrences",
}

const filterSourceMap: Record<FilterSource, keyof ClaimFiltersRequest> = {
  [FilterSource.CLAIM]: "claimsFilter",
  [FilterSource.VEHICLE]: "vehiclesFilter",
  [FilterSource.SIGNAL_EVENT_OCCURRENCE]: "signalEventOccurrencesFilter",
};

/**
 * Returns a function that validates the filter string based on the provided source.
 *
 * @param source The source of the filter, which can be one of 'claim', 'vehicle', or 'signalEventOccurrence'.
 * @returns A function that takes a filterString parameter and validates it.
 * @throws If the source or the filterString for the source is invalid, a descriptive error will be thrown.
 */
export const getValidateFilter =
  (source: FilterSource) =>
  async (filterString: string): Promise<void> => {
    const filterKey = filterSourceMap[source];

    const params: ClaimFiltersRequest = {
      [filterKey]: filterString,
    };

    await validateApiRequest(params, getClaimsMetricsRequestURI);
  };
