import { Stack } from "@mui/material";

import BetaBadge from "features/ui/BetaBadge";

import DuckLogo from "./duck-logo.svg";

const DuckSummary = () => (
  <Stack alignItems="center" direction="row" spacing={1}>
    <img className="h-[17px] w-[17px]" src={DuckLogo} alt="Duck Logo" />
    <Stack>
      <div className="text-gray-600">ViaDuck Assistant</div>
      <BetaBadge newLine={false} dark={true} />
    </Stack>
  </Stack>
);

export default DuckSummary;
