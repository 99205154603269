import { validateApiRequest } from "duck/graph/nodes/utils";
import { PageHandler } from "duck/graph/PageHandler";
import { z } from "zod";

import { getVehicleRequestURI } from "shared/api/vehicles/api";

const validateVin = async (vin: string): Promise<void> => {
  if (!vin) {
    throw new Error("The VIN is empty");
  }

  await validateApiRequest(
    { vin, mileageUnit: "mi" },
    getVehicleRequestURI,
    "VIN"
  );
};

export const setRouteValue = async (
  vin: string,
  pageHandler: PageHandler
): Promise<void> => {
  await validateVin(vin);
  pageHandler.setRouteValue(`/vehicles/${vin}`);
};

export const withVinSchema = z.object({
  vin: z.string().describe("The VIN to display. There is no default value."),
});

export type WithVin = z.infer<typeof withVinSchema>;
