import Skeleton from "react-loading-skeleton";

import {
  SurvivalCurve as SCT,
  SurvivalCurveResponse,
} from "shared/api/v0_failureModes/api";
import {
  useFailureMode,
  useSurvivalCurves,
} from "shared/api/v0_failureModes/hooks";
import { formatNumber, getTenantMileageUnit } from "shared/utils";

import LineChart from "features/ui/charts/LineChart";
import { ReferenceElement } from "features/ui/charts/types";

interface Props {
  failureModeID: string;
}

const HEIGHT_PX = 400;
const LOADERS_HEIGHT = 186;
const LOADERS_COUNT = 2;
const MAX_TICKS_X_AXIS = 21;
const X_AXIS_TICK_INTERVAL = 100000;

const DEFAULT_X_AXIS_TICKS = Array.from(Array(MAX_TICKS_X_AXIS).keys()).map(
  (i) => i * X_AXIS_TICK_INTERVAL
);

const formatCurveData = (curveData: SCT[]) =>
  curveData.map(({ probability, ...otherValues }) => ({
    ...otherValues,
    probability: probability * 100,
  }));

const calculateDistanceMaxTick = (
  curveData: SCT[],
  failureMileage99Percentile: number
) => {
  const maxDistance = Math.max(
    curveData[curveData.length - 1].distance,
    failureMileage99Percentile
  );

  return Math.ceil(maxDistance / X_AXIS_TICK_INTERVAL) * X_AXIS_TICK_INTERVAL;
};

const generateXAxisTicks = (
  curveData: SCT[],
  failureMileage99Percentile: number
) => {
  // We want to show X axis ticks every 100000 miles, but only until the last value (rounded up to nearest 100.000).
  if (!curveData.length) {
    return DEFAULT_X_AXIS_TICKS;
  }

  const maxDistanceTick = calculateDistanceMaxTick(
    curveData,
    failureMileage99Percentile
  );

  return DEFAULT_X_AXIS_TICKS.filter((tick) => tick <= maxDistanceTick);
};

const getRelativeFailurePercentage = (
  mileage?: number | null,
  survivalCurveData?: SCT[]
) => {
  if (!mileage || !survivalCurveData) return;

  const r = survivalCurveData.find(({ distance }) => distance >= mileage);

  if (!r?.probability) return;

  return Math.round(r.probability * 100);
};

const getReferenceElements = ({
  failureMileageAverage,
  failureMileage99Percentile,
}: SurvivalCurveResponse): ReferenceElement[] | undefined => {
  let references = [];

  if (failureMileageAverage) {
    references.push({
      label: "Average Failure Mileage",
      xAxisValue: failureMileageAverage,
      color: "green",
    });
  }
  if (failureMileage99Percentile) {
    references.push({
      label: "99th Percentile Failure Mileage",
      xAxisValue: failureMileage99Percentile,
      color: "red",
    });
  }
  return references;
};

const V0_SurvivalCurve = ({ failureModeID }: Props) => {
  const {
    data: survivalCurve,
    isLoading,
    error,
  } = useSurvivalCurves({
    distanceUnit: getTenantMileageUnit(),
    failureModeID,
  });

  const { data } = useFailureMode({ id: failureModeID });

  const failureModeName = data?.name ?? "";

  const curveData: SCT[] | undefined =
    survivalCurve?.data && formatCurveData(survivalCurve.data);

  const references = survivalCurve && getReferenceElements(survivalCurve);

  const relativeFailurePercentage = getRelativeFailurePercentage(
    survivalCurve?.failureMileage99Percentile,
    survivalCurve?.data
  );

  return (
    <div>
      <h3 className="text-xl">
        <span className="mr-1">
          Population Survival Curve - {failureModeName}
        </span>
      </h3>
      <h6 className="text-gray-400 mb-4 text-sm max-w-3xl">
        An estimate of the probability that an in-scope vehicle experiences a
        failure due to the failure mode by the time it reaches a given mileage,
        based on historical data.&nbsp;
        {relativeFailurePercentage &&
          survivalCurve?.failureMileage99Percentile && (
            <span>
              For example, at approximately{" "}
              {formatNumber(survivalCurve.failureMileage99Percentile, 0)}{" "}
              {getTenantMileageUnit()}, around {relativeFailurePercentage}% of
              vehicles will have already failed.
            </span>
          )}
      </h6>
      {!isLoading && curveData && (
        <LineChart
          width="100%"
          height={HEIGHT_PX}
          data={curveData}
          references={references}
          margin={{ right: 30 }}
          xAxisKey="distance"
          xAxisLabel="Mileage"
          xAxisProps={{
            ticks: generateXAxisTicks(
              curveData,
              survivalCurve?.failureMileage99Percentile || 0
            ),
            tickFormatter: (value: number | string) =>
              `${formatNumber(Number(value))}`,
            interval: 0,
          }}
          yAxisLines={[{ key: "probability", label: "Probability of failure" }]}
          yAxisProps={{
            tickFormatter: (value: number | string) => `${value}%`,
          }}
          tooltipProps={{
            labelFormatter: (label: string) =>
              `${formatNumber(Number(label), 0)} ${getTenantMileageUnit()}`,
            formatter: (value: number) => `${formatNumber(value)}%`,
          }}
        />
      )}
      {isLoading && !error && (
        <Skeleton
          count={LOADERS_COUNT}
          height={LOADERS_HEIGHT}
          className="mb-3"
        />
      )}
      {!isLoading && error && (
        <div className="py-4">
          The population survival curve for the {failureModeName} failure mode
          is unsupported.
        </div>
      )}
    </div>
  );
};

export default V0_SurvivalCurve;
