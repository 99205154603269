import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FiChevronRight as ChevronRight } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import useBreadcrumbs, { BreadcrumbsRoute } from "use-react-router-breadcrumbs";

interface BreadcrumbsProps {
  routeConfig: BreadcrumbsRoute[];
  defaultActions?: JSX.Element[];
}

const Breadcrumbs = ({ routeConfig, defaultActions }: BreadcrumbsProps) => {
  const { landingPage } = useFlags();
  const breadcrumbs = useBreadcrumbs(routeConfig);

  // If there is only one or two levels, we do not show breadcrumbs at all
  if (breadcrumbs.length < 3) {
    return null;
  }

  const showDefaultActions =
    landingPage && defaultActions && defaultActions.length > 0;

  return (
    <div className="flex items-center pb-4" data-testid="breadcrumbs">
      {breadcrumbs.map(({ match, breadcrumb, location }, index) =>
        match.pathname !== location.pathname ? (
          <React.Fragment key={match.pathname}>
            <NavLink
              to={match.pathname}
              className="hover:text-blue-400 text-blue-500 text-xs"
              data-testid="breadcrumbs-link"
            >
              {breadcrumb}
            </NavLink>
            <Divider />
          </React.Fragment>
        ) : (
          <span
            data-testid="breadcrumbs-label"
            key={match.pathname}
            className="text-xs text-gray-400"
          >
            {breadcrumb}
          </span>
        )
      )}
      <div className="ml-auto flex space-x-1">
        {showDefaultActions &&
          defaultActions.map((action, i) => <div key={i}>{action}</div>)}
      </div>
    </div>
  );
};

const Divider = () => (
  <span className="px-2 text-gray-400" data-testid="breadcrumbs-divider">
    <ChevronRight size="12" />
  </span>
);

export default Breadcrumbs;
