import { useState } from "react";
import { MdClose as CloseIcon } from "react-icons/md";
import { ClickAwayListener } from "@mui/material";

import { areArraysEqual } from "shared/utils";

import Button from "features/ui/Button";
import CheckboxGroup from "features/ui/CheckboxGroup";

export interface Option {
  label: string;
  value: string;
}

interface Props {
  visibleColumns: string[];
  options: Option[];
  additionalOptions?: Option[];
  additionalOptionsLabel?: string;
  onChange: (checked: string[]) => void;
  onClose: () => void;
}

const TOOLTIP_TITLE = "Choose Failure Mode Columns";
const FAILURE_MODES_TITLE = "Failure Modes";
const INFO_TEXT =
  "At least one failure mode and one attribute must be selected.";

const ColumnsOptions = ({
  visibleColumns,
  options,
  additionalOptions,
  additionalOptionsLabel,
  onChange,
  onClose,
}: Props) => {
  const [checkedColumns, setCheckedColumns] =
    useState<string[]>(visibleColumns);

  const atLeastOneFailureModeSelected = options.find(({ value }) =>
    checkedColumns.includes(value)
  );

  const atLeastOneFailureModeAttributeSelected =
    additionalOptions &&
    Boolean(
      additionalOptions.find(({ value }) => checkedColumns.includes(value))
    );

  const hasAtLeastOneFmAndOneAttrSelected =
    atLeastOneFailureModeSelected && atLeastOneFailureModeAttributeSelected;

  const ctaDisabled =
    areArraysEqual(checkedColumns, visibleColumns) ||
    !hasAtLeastOneFmAndOneAttrSelected;

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div
        className="w-96 min-w-full md:min-w-0 px-4 py-3 absolute top-0 left-0 overflow-x-auto shadow-sm border rounded-sm bg-gray-50 z-20"
        data-testid="column-options-wrap"
      >
        <div className="flex justify-between items-baseline mb-5">
          <h3 className="text-base font-semibold">{TOOLTIP_TITLE}</h3>
          <CloseIcon className="cursor-pointer" size="20" onClick={onClose} />
        </div>
        {!options && <div>Loading columns ...</div>}
        <div className="mb-4">
          <div className="flex justify-between">
            {options && (
              <div data-testid="fm-checkboxes-wrap">
                <h3 className="mb-3 text-sm font-semibold">
                  {FAILURE_MODES_TITLE}
                </h3>
                <CheckboxGroup
                  checkboxes={options}
                  checked={checkedColumns}
                  onChange={setCheckedColumns}
                />
              </div>
            )}
            <AdditionalOptions
              title={additionalOptionsLabel}
              options={additionalOptions}
              checked={checkedColumns}
              onChange={setCheckedColumns}
            />
          </div>
          <div className="mt-3 text-gray-400 text-xs">{INFO_TEXT}</div>
        </div>
        <div className="mt-6 flex justify-between">
          <Button
            label="Apply"
            size="small"
            testId="apply-column-settings"
            onClick={() => onChange(checkedColumns)}
            disabled={ctaDisabled}
            variant="contained"
          />
          <Button
            label="Hide all"
            size="small"
            variant="text"
            onClick={() => onChange([])}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};

interface AdditionalOptionsProps {
  title?: string;
  onChange: (checked: string[]) => void;
  options?: Option[];
  checked: string[];
}

const AdditionalOptions = ({
  title,
  onChange,
  options,
  checked,
}: AdditionalOptionsProps) => {
  if (!options) return null;

  return (
    <div data-testid="fm-attributes-checkboxes-wrap">
      {title && <div className="font-semibold text-sm mb-2">{title}</div>}
      <CheckboxGroup
        checkboxes={options}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
};

export default ColumnsOptions;
