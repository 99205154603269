import { toDate } from "date-fns-tz";

import { DataElement } from "features/ui/charts/types";

interface ObjWithDate {
  date: string;
}

export const prepareMetricsHistoryDataByTimestamp = (
  data: ObjWithDate[]
): DataElement[] =>
  data.map((cmh: ObjWithDate) => ({ ...cmh, ts: toDate(cmh.date).getTime() }));
