import { Dispatch, SetStateAction, useEffect, useMemo } from "react";

import {
  getSignalEventsTopContributorsExport,
  SignalEventsTopContributorsRequest,
} from "shared/api/signalEvents/api";
import { useListSignalEventsTopContributors } from "shared/api/signalEvents/hooks";
import { getSortFilter } from "shared/api/utils";
import { MAX_TOP_CONTRIBUTORS_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import { TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR } from "shared/schemas/constants";
import { useSchemaEntryForAttribute } from "shared/schemas/hooks";
import { EventTypeEnum, SortBy } from "shared/types";

import { canShowDescription } from "pages/ClaimAnalytics/tabPages/TopContributors/utils";
import { SignalEventsFiltersProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import APIError from "features/ui/APIError";
import DescriptionColumn from "features/ui/DescriptionColumn";
import DownloadAction from "features/ui/DownloadAction";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterChangeProps, FilterOperator } from "features/ui/Filters/types";
import PercentageTrend from "features/ui/PercentageTrend";
import { SelectOption } from "features/ui/Select";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell";

import { TABLE_SCHEMA } from "./utils";

const PAGE_KEY = "signalEvents-top-contributors";
const DEFAULT_SORT: SortBy = { DPTV: "desc" };
const ROWS_PER_PAGE = 20;

interface Props extends SignalEventsFiltersProps {
  selectedGroupByAttribute: SelectOption;
  onFiltersUpdated: Dispatch<SetStateAction<FilterGroupState | undefined>>;
  onBadRequest: () => void;
  selectedByVehicleAgeExposure?: string;
  selectedByVehicleAgeExposureBucket?: number;
}

const TopContributorsTable = ({
  selectedGroupByAttribute,
  vehiclesFilters,
  signalEventsFilters,
  onFiltersUpdated,
  onBadRequest,
  selectedByVehicleAgeExposure,
  selectedByVehicleAgeExposureBucket,
}: Props) => {
  const overrides: Partial<SchemaEntry> = { sortable: true };
  const firstColumn = useSchemaEntryForAttribute(
    selectedGroupByAttribute.id as string,
    EventTypeEnum.SIGNAL_EVENT,
    overrides
  );

  const descriptionColumns = canShowDescription(
    selectedGroupByAttribute.id.toString()
  )
    ? [
        {
          label: "Description",
          accessor: "description",
          dataType: "jsx" as DataType,
        },
      ]
    : [];

  const schema: SchemaEntry[] = firstColumn
    ? [firstColumn, ...descriptionColumns, ...TABLE_SCHEMA]
    : TABLE_SCHEMA;

  const {
    manageFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
    disableUsingQuery: true,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const handleFiltering = ({
    key,
    op_id,
    values,
    extra,
    dataType,
  }: FilterChangeProps) => {
    manageFilterChange({ key, op_id, values, extra, dataType });
    onFiltersUpdated({
      ...filters,
      [key]: {
        values: values || [],
        operator: op_id || FilterOperator.IN,
      },
    });
  };

  const handleResetFilters = (fieldNames?: string[] | undefined) => {
    resetFilters(fieldNames);
    onFiltersUpdated(undefined);
  };

  useEffect(() => {
    resetFilters([TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupByAttribute?.id]);

  // whenever filters change, update the parent component's filters state
  useEffect(() => {
    onFiltersUpdated(filters);
  }, [filters, onFiltersUpdated]);

  const groupBy = selectedGroupByAttribute?.id as string;

  const requestParams: SignalEventsTopContributorsRequest = {
    sort: getSortFilter(sort),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    signalEventOccurrencesFilter: getFiltersQuery(signalEventsFilters),
    limit: ROWS_PER_PAGE,
    groupBy,
    filter: getFiltersQuery(filters),
    byVehicleAgeExposure: selectedByVehicleAgeExposure,
    byVehicleAgeExposureBucket: selectedByVehicleAgeExposureBucket,
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListSignalEventsTopContributors(requestParams);

  const formattedData = useMemo(
    () =>
      data &&
      data?.map((row) => {
        const {
          IPTV30DayChangeRatio,
          DPTV30DayChangeRatio,
          groupByAttributeValue,
        } = row;
        return {
          ...row,
          IPTV30DayChangeRatio:
            (IPTV30DayChangeRatio !== null &&
              IPTV30DayChangeRatio !== undefined && (
                <PercentageTrend trend={IPTV30DayChangeRatio} />
              )) ||
            null,
          DPTV30DayChangeRatio:
            (DPTV30DayChangeRatio !== null &&
              DPTV30DayChangeRatio !== undefined && (
                <PercentageTrend trend={DPTV30DayChangeRatio} />
              )) ||
            null,
          description: groupByAttributeValue && (
            <DescriptionColumn
              fieldName={selectedGroupByAttribute.id.toString()}
              fieldValue={groupByAttributeValue}
            />
          ),
        };
      }),
    [data, selectedGroupByAttribute.id]
  );

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <>
      <div className="flex items-center mt-5">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={handleResetFilters}
        />
        {!error && (
          <div className="ml-auto">
            <DownloadAction
              disabled={downloadDisabled}
              downloadFunc={(args) =>
                getSignalEventsTopContributorsExport({
                  ...args,
                  IDs: [groupBy],
                })
              }
              fileName="signal-events-top-contributors"
              requestParams={{
                ...requestParams,
                limit: MAX_TOP_CONTRIBUTORS_ROWS_DOWNLOAD_LIMIT,
              }}
              entityName="signal event"
              filters={{
                ...filters,
                ...signalEventsFilters,
                ...vehiclesFilters,
              }}
            />
          </div>
        )}
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={handleResetFilters}
          onFilterChange={handleFiltering}
          filters={filters}
          stickyFirstColumn={true}
          dense
        />
      )}
      {error && (
        <APIError
          error={error}
          onBadRequest={() => {
            resetFilterSortState();
            onBadRequest();
          }}
        />
      )}
      {!error && !isLoading && !data?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};
export default TopContributorsTable;
