import {
  AreaChart as AC,
  Area,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from "recharts";

import {
  AXIS_TEXT_COLOR,
  AXIS_TOOLTIP_FONT_SIZE,
  LABEL_COLOR,
  LINE_COLOR,
} from "features/ui/charts/constants";
import { DataElement } from "features/ui/charts/types";

interface AreaElement {
  key: string;
  label: string;
  color?: string;
}

export interface AreaChartProps {
  data: DataElement[];
  areas: AreaElement[];
  width?: number | string;
  height?: number | string;
  xAxisKey: string;
  xAxisLabel?: string;
  showLegend?: boolean;
  yAxisProps?: YAxisProps;
  xAxisProps?: XAxisProps;
  tooltipProps?: TooltipProps<any, any>;
}

const legendFormatter = (value: string, { payload: { label } }: any) => (
  <span className="text-gray-400 text-xs">{label}</span>
);

const AreaChart = ({
  data,
  areas,
  height = 300,
  width = "100%",
  xAxisKey,
  xAxisLabel,
  showLegend = false,
  yAxisProps,
  xAxisProps,
  tooltipProps,
}: AreaChartProps) => {
  const xAxisTicks = Array.from(data.keys()).map((i) => data[i][xAxisKey]);

  return (
    <ResponsiveContainer width={width} height={height}>
      <AC data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} />
        <YAxis
          scale="sequential"
          width={40}
          tick={{ fill: AXIS_TEXT_COLOR, fontSize: AXIS_TOOLTIP_FONT_SIZE }}
          type="number"
          {...yAxisProps}
        />
        <XAxis
          label={{
            value: xAxisLabel,
            position: "middle",
            dy: 15,
            fill: LABEL_COLOR,
          }}
          height={45}
          tick={{ fill: AXIS_TEXT_COLOR, fontSize: AXIS_TOOLTIP_FONT_SIZE }}
          dataKey={xAxisKey}
          ticks={xAxisTicks}
          minTickGap={10}
          allowDuplicatedCategory={false}
          {...xAxisProps}
        />
        {showLegend && (
          <Legend
            formatter={legendFormatter}
            align="left"
            verticalAlign="top"
            wrapperStyle={{ top: 0, paddingBottom: 5 }}
          />
        )}
        <Tooltip
          contentStyle={{ fontSize: AXIS_TOOLTIP_FONT_SIZE }}
          labelStyle={{ fontSize: AXIS_TOOLTIP_FONT_SIZE }}
          {...tooltipProps}
        />
        <Tooltip
          contentStyle={{ fontSize: AXIS_TOOLTIP_FONT_SIZE }}
          labelStyle={{ fontSize: AXIS_TOOLTIP_FONT_SIZE }}
          {...tooltipProps}
        />
        {areas.map(({ key, label, color }) => (
          <Area
            key={key}
            type="monotone"
            dataKey={key}
            stroke={color || LINE_COLOR}
            fillOpacity={0.5}
            fill={color || LINE_COLOR}
            label={label}
          />
        ))}
      </AC>
    </ResponsiveContainer>
  );
};

export default AreaChart;
