export const FILTER_PROMPT_CLAUSE = `## Filter String Parameter Logic
Viaduct's API allows filtering using the \`filter\` query parameter, formatted as:  
\`$field=$operation:$value\`.

### **Supported Operations:**
- **Comparisons:** \`gt\`, \`gte\`, \`lt\`, \`lte\`  
- **Set Membership:** \`in\`, \`nin\` (values separated by \`|\`)  
- **Null Checks:** \`is\`, \`isn\`  
- **Substring Match:** \`like\`, \`nlike\`
- **Prefix Match:** \`startswith\`, \`nstartswith\` (values separated by \`|\`)  
- **Recent Date Range:** \`inLast\` (e.g., \`inLast:3d\`)

> **Important:** Use only **one operation** per \`$field\` when constructing the filter string.

### **Combining Filters:**
- **AND:** Use a comma \`,\`  
- **OR:** Use \`||\`  
- **Grouping:** Use parentheses for nested expressions  

### **Special Considerations:**
- For values containing \`,\` or \`|\`, enclose the value in double quotes:  
  Example: \`$field=$operation:"$value"\`
- If a filter attribute supports \`distinctValues\` and the user requests a value that does not exist, inform the user that there are no matches for the given value.

### **Examples:**
- **Field is not null:** \`vehicleStartedDrivingAt=isn:null\`  
- **Exact match:** \`VIN=in:ABC\`  
- **Contains:** \`vehicleMake=like:Toyota\`
- **Starts with multiple values:** \`signalEventID=startswith:"u"|"p"\`  
- **AND combination:** \`VIN=in:ABC,mileage=gt:100\`  
- **OR combination:** \`VIN=in:ABC||mileage=gt:100\`  
- **Nested expressions:** \`VIN=in:ABC||(mileage=gt:100,monthsInService=gt:50)\``;

export const SORT_PROMPT_CLAUSE = `## Sort String Parameter Logic
Viaduct's API supports sorting via the \`sort\` query parameter, formatted as:  
\`[$field]=$order\`

### **Parameters:**
- **\`$field\`:** The attribute to sort by (e.g., mileage, vehicleModelYear).  
- **\`$order\`:** The sort order, which can be:
  - \`asc\`: Ascending
  - \`desc\`: Descending

### **Guidelines:**
- The sort parameter is **optional** and should only be specified if there is a valid reason to apply sorting.
- The attributes indicate whether they support sorting.

### **Examples:**
- **Sort by mileage in descending order:**  
  \`[mileage]=desc\`
- **Sort by vehicle model year in ascending order:**  
  \`[vehicleModelYear]=asc\``;

export const PAGE_AGENT_WORKFLOW_CLAUSE = `## Workflow Details:
1. **Identify the User's Request**:
   - Determine the necessary steps to answer the query.
   - Retrieve relevant attributes so you can apply valid filter and sort parameters.
2. **Queue Actions**:
   - Queue the required actions using the available tools.
3. **Handle Errors**:
   - If an error occurs, make the necessary adjustments (if possible) and retry the failed tool.
   - If the user requests filtering or sorting on an invalid attribute:
     - Suggest alternate valid attributes that closely match the invalid one.
   - If the request cannot be fulfilled, reject it with a detailed explanation of the reason.
4. **Report to Supervisor**:
   - Upon completion, provide a response to the \`supervisor\` with one of the following:
     - A list of the actions that were taken.
     - A detailed explanation of missing or ambiguous information if clarification is required.
     - A detailed reason if the request could not be fulfilled.`;
