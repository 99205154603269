import { useState } from "react";

import { useCustomLocalStorageState } from "shared/hooks";

import { filterStateToFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import { DEFAULT_EMPTY_RELATES_FILTER } from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { RelatesFilterState } from "features/ui/Filters/types";

import * as config from "config/config";

export const useAssociatedSignalEventsRelatesFilter = (
  pageKey: string,
  pendingFiltersKey: string,
  relatesFilterKey: string
) => {
  const {
    pages: { signalEventsAnalytics },
  } = config.get();

  const defaultSignalEventFilters = filterStateToFilterGroupState(
    signalEventsAnalytics?.defaultSignalEventFilters
  );

  const associatedSignalEventsFilterSortState = useFilterSortState({
    pageKey,
    defaultFilterValues:
      defaultSignalEventFilters ?? DEFAULT_EMPTY_RELATES_FILTER.filters,
    pendingFiltersLocalStorageKey: pendingFiltersKey,
  });

  const initialRelatesFilter: RelatesFilterState = {
    operator: DEFAULT_EMPTY_RELATES_FILTER.operator,
    options:
      associatedSignalEventsFilterSortState.relatedSignalEventsFilter
        ?.options ?? DEFAULT_EMPTY_RELATES_FILTER.options,
    filters: associatedSignalEventsFilterSortState.filters,
  };

  const [appliedRelatesFilter, setAppliedRelatesFilter] =
    useCustomLocalStorageState<RelatesFilterState>(relatesFilterKey, {
      defaultValue: initialRelatesFilter,
    });
  const [relatesFilter, setRelatesFilter] =
    useState<RelatesFilterState>(appliedRelatesFilter);

  const onApply = () => {
    setAppliedRelatesFilter(relatesFilter);
  };

  const onCancel = () => {
    const defaultRelatesFilter = {
      operator: DEFAULT_EMPTY_RELATES_FILTER.operator,
      options: DEFAULT_EMPTY_RELATES_FILTER.options,
      filters: associatedSignalEventsFilterSortState.filters,
    };
    setRelatesFilter(defaultRelatesFilter);
    setAppliedRelatesFilter(defaultRelatesFilter);
  };

  return {
    relatesFilter,
    setRelatesFilter,
    appliedRelatesFilter,
    setAppliedRelatesFilter,
    associatedSignalEventsFilterSortState,
    onApply,
    onCancel,
  };
};
