import {
  ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
  ATTRIBUTE_RETRIEVAL_K,
  ATTRIBUTE_SOURCE_KEYS,
} from "duck/graph/constants";
import {
  FilterSource,
  getValidateFilter,
} from "duck/graph/nodes/SignalEventAnalyticsAgent/utils";
import { PageHandler } from "duck/graph/PageHandler";
import getAttributeRetrievalTool from "duck/graph/tools/getAttributeRetrievalTool";
import getGoToAssociatedSignalEventsTabTool from "duck/graph/tools/getGoToAssociatedSignalEventsTabTool";
import getGoToAssociatedVehiclesTabTool from "duck/graph/tools/getGoToAssociatedVehiclesTabTool";
import getGoToByVehiclesAgeTabTool from "duck/graph/tools/getGoToByVehiclesAgeTabTool";
import getGoToTopContributorsTabTool from "duck/graph/tools/getGoToTopContributorsTabTool";
import getRetrieveTopContributorsGroupByOptionsTool from "duck/graph/tools/getRetrieveTopContributorsGroupByOptionsTool";
import getUpdateFilterTool from "duck/graph/tools/getUpdateFilterTool";
import { DuckGraphParams } from "duck/graph/types";
import { StructuredTool } from "@langchain/core/tools";

import {
  SIGNAL_EVENTS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/SignalEventsAnalytics/constants";
import { SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY } from "pages/SignalEventsAnalytics/tabPages/ByVehicleAge/ByVehicleAge";
import {
  DEFAULT_GROUP_BY_ATTRIBUTE,
  SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
  SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
} from "pages/SignalEventsAnalytics/tabPages/TopContributors/TopContributors";

import { RelatesFilterWindowDirection } from "features/ui/Filters/types";

import getGoToAssociatedClaimsTabTool from "./getGoToAssociatedClaimsTabTool";
import getGoToSignalEventsTabTool from "./getGoToSignalEventsTabTool";

/**
 * @summary Get the tools for the signal event analytics agent.
 * The tools are specific to signal event analytics, and are bound to the UI handlers
 * for signal event analytics.
 * @param params The data from the UI layer that is necessary for the tools to function.
 * @returns The full set of tools for the signal event analytics agent.
 */
const getTools = (
  params: DuckGraphParams,
  pageHandler: PageHandler
): StructuredTool[] => {
  const tools = [
    getAttributeRetrievalTool(
      ATTRIBUTE_SOURCE_KEYS.SignalEventOccurrences,
      ATTRIBUTE_RETRIEVAL_K,
      ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
      params.uiHandlers.setEphemeralMessage
    ),
    getAttributeRetrievalTool(
      ATTRIBUTE_SOURCE_KEYS.Vehicle,
      ATTRIBUTE_RETRIEVAL_K,
      ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
      params.uiHandlers.setEphemeralMessage
    ),
    getRetrieveTopContributorsGroupByOptionsTool(
      params.availableData.signalEventAnalytics.topContributorsGroupByOptions,
      params.uiHandlers.setEphemeralMessage
    ),
    getUpdateFilterTool({
      pageHandler,
      pageKey: SIGNAL_EVENTS_PAGE_KEY,
      validateFunction: getValidateFilter(FilterSource.SIGNAL_EVENT_OCCURRENCE),
      setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
      source: FilterSource.SIGNAL_EVENT_OCCURRENCE,
    }),
    getUpdateFilterTool({
      pageHandler,
      pageKey: VEHICLES_PAGE_KEY,
      validateFunction: getValidateFilter(FilterSource.VEHICLE),
      setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
      source: FilterSource.VEHICLE,
    }),
    getGoToSignalEventsTabTool(
      pageHandler,
      params.availableData.signalEventAnalytics.signalEventsChartOptions,
      params.uiHandlers.setEphemeralMessage
    ),
    getGoToByVehiclesAgeTabTool({
      pageHandler,
      chartOptions:
        params.availableData.signalEventAnalytics.byVehicleAgeChartOptions,
      pageKey: SIGNAL_EVENTS_PAGE_KEY,
      chartOptionsKey: SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY,
      setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    }),
    getGoToTopContributorsTabTool({
      pageHandler,
      chartOptions:
        params.availableData.signalEventAnalytics.topContributorsChartOptions,
      groupByOptions:
        params.availableData.signalEventAnalytics.topContributorsGroupByOptions,
      selectedChartOptions:
        params.currentState.signalEventAnalytics
          .selectedTopContributorsChartOptions,
      selectedGroupByAttribute:
        params.currentState.signalEventAnalytics
          .selectedTopContributorsGroupByAttribute,
      defaultGroupByAttribute: DEFAULT_GROUP_BY_ATTRIBUTE,
      pageKey: SIGNAL_EVENTS_PAGE_KEY,
      chartOptionsKey: SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
      groupByOptionsKey: SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
      setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    }),
    getGoToAssociatedVehiclesTabTool(
      pageHandler,
      params.uiHandlers.setEphemeralMessage
    ),
    getGoToAssociatedClaimsTabTool(
      pageHandler,
      params.availableData.signalEventAnalytics.associatedClaimsGroupByOptions,
      params.currentState.signalEventAnalytics.selectedAssociatedClaimsOptions,
      params.uiHandlers.setEphemeralMessage
    ),
  ];

  const duckAccess = params.uiHandlers.duckAccess;
  if (duckAccess.signalEventAnalytics.associatedSignalEventsTab) {
    tools.push(
      getGoToAssociatedSignalEventsTabTool(
        pageHandler,
        SIGNAL_EVENTS_PAGE_KEY,
        Object.values(RelatesFilterWindowDirection),
        params.uiHandlers.setEphemeralMessage
      )
    );
  }

  return tools;
};

export default getTools;
