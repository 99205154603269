import { Dispatch, SetStateAction } from "react";

import {
  CustomSignalEventDefinition,
  CustomSignalEventEventDefinitionRequestBody,
  CustomSignalEventsRequestBody,
  CustomSignalEventState,
} from "shared/api/customSignalEvents/api";
import { EventTypeEnum, EventTypeEnumCapitalizedType } from "shared/types";
import { getTenantMileageUnit } from "shared/utils";

import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  filterStateToFilterGroupState,
  getFiltersQuery,
  isFilterBuilderStateValid,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { Option, SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import {
  CUSTOM_ATTRIBUTE_FUNCTION_PARAMETERS,
  CustomAttributeFunction,
  DEFAULT_CUSTOM_ATTRIBUTES,
  DEFAULT_DESCRIPTION,
  DEFAULT_DOWNSAMPLING_OCCURRENCES,
  DEFAULT_DOWNSAMPLING_OPTION,
  DEFAULT_DOWNSAMPLING_SECONDS_AFTER,
  DEFAULT_DOWNSAMPLING_SECONDS_BEFORE,
  DEFAULT_ID,
  DEFAULT_NAME,
  DownsamplingOptions,
  EDITABLE_STATUSES,
  PUBLISHABLE_STATUSES,
  STOP_WORDS,
} from "./constants";
import { CustomAttribute } from "./Definition/CustomAttributes/CustomAttributeSelect";
import { FunctionInputConfig } from "./Definition/CustomAttributes/FunctionInput/FunctionInput";
import { Downsampling } from "./Definition/DownsamplingSection";
import { CustomSignalEventStatus } from "./types";

export interface SignalEventStudioState {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  description: string;
  setDescription: Dispatch<SetStateAction<string>>;
  id: string;
  setId: Dispatch<SetStateAction<string>>;
  isIdSet: boolean;
  setIsIdSet: Dispatch<SetStateAction<boolean>>;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  eventTypeOptions: SelectOption[];
  selectedEventTypeOption?: SelectOption<Option>;
  setSelectedEventTypeOption: Dispatch<
    SetStateAction<SelectOption<Option> | undefined>
  >;
  schema?: SchemaEntry<string>[];
  filterBuilderState?: FilterGroupState<FilterRowState>;
  setFilterBuilderState: Dispatch<
    SetStateAction<FilterGroupState<FilterRowState> | undefined>
  >;
  customAttributeFilterBuilderState?: FilterGroupState<FilterRowState>;
  setCustomAttributeFilterBuilderState: Dispatch<
    SetStateAction<FilterGroupState<FilterRowState> | undefined>
  >;
  onCustomAttributeRemoved: (id: string) => void;
  selectedDownsamplingOption: Downsampling;
  setSelectedDownsamplingOption: Dispatch<SetStateAction<Downsampling>>;
  customAttributes: CustomAttribute[];
  setCustomAttributes: Dispatch<SetStateAction<CustomAttribute[]>>;
  completeCustomAttributes: CustomAttribute[];
  customAttributesSchema: SchemaEntry<string>[];
  formValidationErrors: string[];
  isValidForm: boolean;
  showPreview?: string | boolean;
  selectedVIN?: string;
  setSelectedVIN: Dispatch<SetStateAction<string | undefined>>;
  dateParam: string;
  dateType: DataType;
}

export const isCustomAttributeComplete = (
  customAttribute: CustomAttribute
): boolean => {
  if (!customAttribute.type) {
    return false;
  }

  const params: FunctionInputConfig[] =
    CUSTOM_ATTRIBUTE_FUNCTION_PARAMETERS[
      customAttribute.type as CustomAttributeFunction
    ];
  return params.every((param) => {
    const parameter = param.parameter as keyof CustomAttribute;
    if (param.parameter === "eventFilter") {
      return (
        customAttribute[parameter] &&
        !isFilterInvalid(customAttribute[parameter] as FilterGroupState, true)
      );
    }

    return (
      customAttribute[parameter] !== undefined &&
      customAttribute[parameter] !== ""
    );
  });
};

export const isFilterInvalid = (
  filterBuilderState: FilterGroupState | undefined,
  isCustomAttribute: boolean = false
): boolean =>
  !!filterBuilderState &&
  !isFilterBuilderStateValid(filterBuilderState, !isCustomAttribute) &&
  filterBuilderState !== DEFAULT_FILTER_BUILDER_STATE;

export const validateCustomEventFormState = (
  selectedEventTypeOption: SelectOption | undefined,
  filterBuilderState: FilterGroupState | undefined,
  customAttributeFilterBuilderState: FilterGroupState | undefined,
  customAttributes: CustomAttribute[],
  selectedDownsamplingOption: Downsampling
): string[] => {
  const errors = [];

  if (!selectedEventTypeOption) {
    errors.push("Base Event Type is required");
  }

  if (isFilterInvalid(filterBuilderState)) {
    errors.push("Standard Attribute filter is invalid");
  }

  if (isFilterInvalid(customAttributeFilterBuilderState, true)) {
    errors.push("Custom Attribute filter is invalid");
  }

  customAttributes.forEach((customAttribute) => {
    if (!isCustomAttributeComplete(customAttribute)) {
      errors.push(`Custom Attribute "${customAttribute.label}" is incomplete`);
    }
  });

  const customAttributeNames = customAttributes.map(
    (customAttribute) => customAttribute.label
  );
  if (new Set(customAttributeNames).size !== customAttributeNames.length) {
    errors.push("Custom Attribute names must be unique");
  }

  if (
    selectedDownsamplingOption.option === DownsamplingOptions.OCCURS_AT_LEAST &&
    (selectedDownsamplingOption.occurrences < 0 ||
      selectedDownsamplingOption.secondsAfter < 0 ||
      selectedDownsamplingOption.secondsBefore < 0)
  ) {
    errors.push("Downsampling is incomplete");
  }

  if (
    selectedDownsamplingOption.option === DownsamplingOptions.REST_PERIOD &&
    (selectedDownsamplingOption.secondsAfter < 0 ||
      selectedDownsamplingOption.secondsBefore < 0)
  ) {
    errors.push("Downsampling is incomplete");
  }
  return errors;
};

export const getCustomSignalEventRequestParams = (
  VINs: string[],
  selectedEventTypeOption: SelectOption | undefined,
  filterBuilderState: FilterGroupState | undefined,
  customAttributeFilterBuilderState: FilterGroupState | undefined,
  customAttributes: CustomAttribute[],
  selectedDownsamplingOption: Downsampling
): CustomSignalEventsRequestBody => ({
  inputEventType: selectedEventTypeOption!.id as EventTypeEnum,
  inputEventFilter: getFiltersQuery(filterBuilderState),
  customAttributeFilter: getFiltersQuery(customAttributeFilterBuilderState),
  vehiclesFilter: getFiltersQuery(
    filterStateToFilterGroupState({
      VIN: { values: VINs, operator: FilterOperator.IN },
    })
  ),
  customAttributes: customAttributes.map((customAttribute) => ({
    ID: customAttribute.id,
    label: customAttribute.label,
    type: customAttribute.type as CustomAttributeFunction,
    parameters: {
      eventType: customAttribute.eventType,
      eventFilter: getFiltersQuery(customAttribute.eventFilter) || undefined,
      field: customAttribute.field,
      aggregation: customAttribute.aggregation,
      direction: customAttribute.direction,
      unit: customAttribute.unit,
      start: customAttribute.start,
      end: customAttribute.end,
      fieldA: customAttribute.fieldA,
      fieldB: customAttribute.fieldB,
      before: customAttribute.before,
      after: customAttribute.after,
    },
  })),
  downsampling:
    selectedDownsamplingOption.option !== "none"
      ? {
          type: selectedDownsamplingOption.option as
            | "occursAtLeast"
            | "restPeriod",
          parameters: {
            after: selectedDownsamplingOption.secondsAfter,
            before: selectedDownsamplingOption.secondsBefore,
            occurrences: selectedDownsamplingOption.occurrences,
          },
        }
      : undefined,
  mileageUnit: getTenantMileageUnit(),
});

export const getCreateCustomSignalEventRequestBody = (
  id: string,
  name: string,
  description: string | null,
  VIN: string,
  selectedEventTypeOption: SelectOption | undefined,
  filterBuilderState: FilterGroupState | undefined,
  customAttributeFilterBuilderState: FilterGroupState | undefined,
  customAttributes: CustomAttribute[],
  selectedDownsamplingOption: Downsampling
): CustomSignalEventEventDefinitionRequestBody => ({
  ID: id,
  name,
  description,
  type: selectedEventTypeOption!.id as EventTypeEnum,
  eventDefinition: {
    inputEventType: selectedEventTypeOption!.id as EventTypeEnum,
    inputEventFilter: getFiltersQuery(filterBuilderState),
    customAttributeFilter: getFiltersQuery(customAttributeFilterBuilderState),
    vehiclesFilter: getFiltersQuery(
      filterStateToFilterGroupState({
        VIN: { values: [VIN], operator: FilterOperator.IN },
      })
    ),
    customAttributes: customAttributes.map((customAttribute) => ({
      ID: customAttribute.id,
      label: customAttribute.label,
      type: customAttribute.type as CustomAttributeFunction,
      parameters: {
        eventType: customAttribute.eventType,
        eventFilter: getFiltersQuery(customAttribute.eventFilter) || undefined,
        field: customAttribute.field,
        aggregation: customAttribute.aggregation,
        direction: customAttribute.direction,
        unit: customAttribute.unit,
        start: customAttribute.start,
        end: customAttribute.end,
        fieldA: customAttribute.fieldA,
        fieldB: customAttribute.fieldB,
        before: customAttribute.before,
        after: customAttribute.after,
      },
    })),
    downsampling:
      selectedDownsamplingOption.option !== "none"
        ? {
            type: selectedDownsamplingOption.option as
              | "occursAtLeast"
              | "restPeriod",
            parameters: {
              after: selectedDownsamplingOption.secondsAfter,
              before: selectedDownsamplingOption.secondsBefore,
              occurrences: selectedDownsamplingOption.occurrences,
            },
          }
        : undefined,
    mileageUnit: getTenantMileageUnit(),
  },
});

export const getDateTypeForEventType = (
  dateParam: string,
  eventSchema?: SchemaEntry[]
): DataType => {
  const schemaEntry = eventSchema?.find(
    (entry) => entry.accessor === dateParam
  );
  return schemaEntry?.dataType || DataType.DATE_WITH_TIME_NO_TZ;
};

export const addFiltersToInputEvent = (
  filters: FilterGroupState,
  filterQuery?: string
) => {
  const existingFilters = filterBuilderQueryToFilterBuilderState(filterQuery);
  const mergedFilter = mergeFilterGroupStates(existingFilters, filters);
  return getFiltersQuery(mergedFilter);
};

export const getInputConfiguration = (
  selectedOptionID: string
): FunctionInputConfig[] => {
  if (!selectedOptionID) {
    return [];
  }
  return CUSTOM_ATTRIBUTE_FUNCTION_PARAMETERS[
    selectedOptionID as CustomAttributeFunction
  ];
};

export const combineCustomEventsTableSchema = (
  baseEventTypeSchema: SchemaEntry[] | undefined,
  customAttributesSchema: SchemaEntry[],
  skippedAccessors: string[] = []
): SchemaEntry[] => [
  {
    label: "date",
    accessor: `date`,
    dataType: "dateWithTime" as DataType,
  },
  ...(customAttributesSchema || []),
  ...(baseEventTypeSchema
    ?.filter(({ accessor }) => !skippedAccessors.includes(accessor))
    .map((schemaEntry) => ({
      ...schemaEntry,
      accessor: `inputEventData.${schemaEntry.accessor}`,
      filter: undefined,
      sortable: false,
    })) || []),
];

// Function creates dropdown options (id, value pairs) from multiple schemas for a certain type of value (number, date, etc.)
// See unit tests for more context
export const combineSchemasToSelectOptions = (
  primarySchema: SchemaEntry[] | undefined,
  primaryEventType: string,
  secondarySchema: SchemaEntry[] | undefined,
  secondaryEventType: string | undefined,
  combineSchemas: boolean,
  dataTypes: string[]
): DropdownSelectOption[] => {
  const filteredPrimarySchema =
    primarySchema?.filter((a) => dataTypes.includes(a.dataType)) || [];
  const filteredSecondarySchema =
    secondarySchema?.filter((a) => dataTypes.includes(a.dataType)) || [];

  if (!combineSchemas || primaryEventType === secondaryEventType) {
    if (primarySchema) {
      return filteredPrimarySchema.map((a) => ({
        id: a.accessor,
        value: a.label,
      }));
    }
    if (secondarySchema) {
      return filteredSecondarySchema.map((a) => ({
        id: a.accessor,
        value: a.label,
      }));
    }
  }

  const primaryAccessors = filteredPrimarySchema.map(
    ({ accessor }) => accessor
  );
  const secondaryAccessors = filteredSecondarySchema.map(
    ({ accessor }) => accessor
  );

  const commonAccessors = primaryAccessors
    .filter((x) => secondaryAccessors.includes(x))
    .sort();
  const onlyPrimaryAccessors = primaryAccessors
    .filter((x) => !secondaryAccessors.includes(x))
    .sort();
  const onlySecondaryAccessors = secondaryAccessors
    .filter((x) => !primaryAccessors.includes(x))
    .sort();

  return [
    ...commonAccessors.map((x) => ({
      id: x,
      value: [
        filteredPrimarySchema.find((y) => y.accessor === x)?.label || "",
        `(${primaryEventType}, ${secondaryEventType})`,
      ].join(" "),
    })),
    ...onlyPrimaryAccessors.map((x) => ({
      id: x,
      value: [
        filteredPrimarySchema.find((y) => y.accessor === x)?.label || "",
        `(${primaryEventType})`,
      ].join(" "),
    })),
    ...onlySecondaryAccessors.map((x) => ({
      id: x,
      value: [
        filteredSecondarySchema.find((y) => y.accessor === x)?.label || "",
        `(${secondaryEventType})`,
      ].join(" "),
    })),
  ];
};

const shortenWord = (word: string): string => {
  word = word.replace(/[^a-zA-Z]/g, "");

  if (STOP_WORDS.has(word)) {
    return "";
  }
  if (word.length > 3) {
    const firstLetter = word[0];
    const isFirstLetterVowel = /[aeiou]/i.test(firstLetter);
    const shortened = word.replace(/[aeiou]/gi, "");
    return isFirstLetterVowel
      ? firstLetter + shortened.slice(0, 2)
      : shortened.slice(0, 3);
  }
  return word;
};

export const getIdFromName = (name: string): string => {
  const snakeCaseName = name
    .toLowerCase()
    .split(" ")
    .map(shortenWord)
    .filter(Boolean)
    .join("_");

  const res = `cse_${snakeCaseName}`;
  return res.length > 30 ? res.slice(0, 30) : res;
};

export interface CustomSEDefinitionState {
  defaultName: string;
  defaultDescription: string;
  defaultID: string;
  defaultSelectedEventTypeOption: SelectOption | undefined;
  defaultFilterBuilderState: FilterGroupState | undefined;
  defaultCustomAttributeFilterBuilderState: FilterGroupState | undefined;
  defaultDownsampling: Downsampling;
  defaultCustomAttributes: CustomAttribute[];
}

export const getInitialCustomSEDefinitionState = (
  eventTypeOptions: SelectOption<Option>[],
  customSignalEventDefinition?: CustomSignalEventDefinition
): CustomSEDefinitionState => {
  const defaultEventTypeOption =
    eventTypeOptions?.length > 0 ? eventTypeOptions[0] : undefined;

  const selectedDownsampling =
    customSignalEventDefinition?.eventDefinition?.downsampling;

  return {
    defaultName: customSignalEventDefinition?.name || DEFAULT_NAME,
    defaultDescription:
      customSignalEventDefinition?.description || DEFAULT_DESCRIPTION,
    defaultID: customSignalEventDefinition?.ID || DEFAULT_ID,
    defaultSelectedEventTypeOption:
      eventTypeOptions.find(
        ({ id }) =>
          id === customSignalEventDefinition?.eventDefinition?.inputEventType
      ) || defaultEventTypeOption,
    defaultFilterBuilderState: filterBuilderQueryToFilterBuilderState(
      customSignalEventDefinition?.eventDefinition?.inputEventFilter
    ),
    defaultCustomAttributeFilterBuilderState:
      filterBuilderQueryToFilterBuilderState(
        customSignalEventDefinition?.eventDefinition?.customAttributeFilter
      ),
    defaultDownsampling: {
      option: selectedDownsampling?.type || DEFAULT_DOWNSAMPLING_OPTION,
      secondsAfter:
        selectedDownsampling?.parameters?.after ||
        DEFAULT_DOWNSAMPLING_SECONDS_AFTER,
      secondsBefore:
        selectedDownsampling?.parameters?.before ||
        DEFAULT_DOWNSAMPLING_SECONDS_BEFORE,
      occurrences:
        selectedDownsampling?.parameters?.occurrences ||
        DEFAULT_DOWNSAMPLING_OCCURRENCES,
    },
    defaultCustomAttributes:
      customSignalEventDefinition?.eventDefinition?.customAttributes.map(
        ({ ID, parameters, ...otherCAData }, index) => {
          const { eventFilter, ...otherParameters } = parameters;
          return {
            index,
            id: ID,
            ...otherCAData,
            ...otherParameters,
            eventFilter: filterBuilderQueryToFilterBuilderState(
              parameters?.eventFilter
            ),
          };
        }
      ) || DEFAULT_CUSTOM_ATTRIBUTES,
  };
};

export const isInitialCustomSEDefinitionState = (
  signalEventStudioState: SignalEventStudioState,
  customSignalEventDefinition?: CustomSignalEventDefinition
) => {
  const {
    name,
    description,
    id,
    selectedEventTypeOption,
    filterBuilderState,
    customAttributeFilterBuilderState,
    selectedDownsamplingOption,
    customAttributes,
    eventTypeOptions,
  } = signalEventStudioState;

  const {
    defaultName,
    defaultDescription,
    defaultID,
    defaultSelectedEventTypeOption,
    defaultFilterBuilderState,
    defaultCustomAttributeFilterBuilderState,
    defaultDownsampling,
    defaultCustomAttributes,
  } = getInitialCustomSEDefinitionState(
    eventTypeOptions,
    customSignalEventDefinition
  );

  if (name !== defaultName) {
    return false;
  }

  if (description !== defaultDescription) {
    return false;
  }

  if (id !== defaultID) {
    return false;
  }

  if (selectedEventTypeOption?.id !== defaultSelectedEventTypeOption?.id) {
    return false;
  }

  if (
    getFiltersQuery(filterBuilderState) !==
    getFiltersQuery(defaultFilterBuilderState)
  ) {
    return false;
  }

  if (
    getFiltersQuery(customAttributeFilterBuilderState) !==
    getFiltersQuery(defaultCustomAttributeFilterBuilderState)
  ) {
    return false;
  }

  if (selectedDownsamplingOption.option !== defaultDownsampling.option) {
    return false;
  }
  if (
    selectedDownsamplingOption.secondsAfter !== defaultDownsampling.secondsAfter
  ) {
    return false;
  }
  if (
    selectedDownsamplingOption.secondsBefore !==
    defaultDownsampling.secondsBefore
  ) {
    return false;
  }
  if (
    selectedDownsamplingOption.occurrences !== defaultDownsampling.occurrences
  ) {
    return false;
  }

  if (customAttributes.length !== defaultCustomAttributes.length) {
    return false;
  }

  for (const ca of customAttributes) {
    const defaultCA = defaultCustomAttributes.find((c) => c.id === ca.id);
    if (!defaultCA) {
      return false;
    }

    for (const field in ca) {
      if (field === "eventFilter") {
        if (
          getFiltersQuery(ca.eventFilter) !==
          getFiltersQuery(defaultCA.eventFilter)
        ) {
          return false;
        }
      } else if (
        ca[field as keyof CustomAttribute] !==
        defaultCA[field as keyof CustomAttribute]
      ) {
        return false;
      }
    }
  }

  return true;
};

export const setInitialCustomSEDefinitionState = (
  signalEventStudioState: SignalEventStudioState,
  customSignalEventDefinition?: CustomSignalEventDefinition
) => {
  const {
    setName,
    setDescription,
    setId,
    setSelectedEventTypeOption,
    setFilterBuilderState,
    setCustomAttributeFilterBuilderState,
    setSelectedDownsamplingOption,
    setCustomAttributes,
    eventTypeOptions,
  } = signalEventStudioState;

  const {
    defaultName,
    defaultDescription,
    defaultID,
    defaultSelectedEventTypeOption,
    defaultFilterBuilderState,
    defaultCustomAttributeFilterBuilderState,
    defaultDownsampling,
    defaultCustomAttributes,
  } = getInitialCustomSEDefinitionState(
    eventTypeOptions,
    customSignalEventDefinition
  );

  setName(defaultName);
  setDescription(defaultDescription);
  setId(defaultID);
  setSelectedEventTypeOption(defaultSelectedEventTypeOption);
  setFilterBuilderState(defaultFilterBuilderState);
  setCustomAttributeFilterBuilderState(
    defaultCustomAttributeFilterBuilderState
  );
  setSelectedDownsamplingOption(defaultDownsampling);
  setCustomAttributes(defaultCustomAttributes);
};

export const getCustomSignalEventState = (
  status?: CustomSignalEventStatus,
  editMode: boolean = true
): CustomSignalEventState => {
  if (!status) {
    // missing status indicates unsaved custom signal event
    return {
      actionsState: {
        canCancel: true,
        canEditID: true,
        canEditDefinition: true,
        canPublish: false,
        canDelete: false,
        canNavigateToDetailsPage: false,
      },
    };
  }

  const canEdit = EDITABLE_STATUSES.includes(status);
  const canEditDefinition = editMode && canEdit;

  const canPublish = editMode && PUBLISHABLE_STATUSES.includes(status);

  const canDelete = editMode && !["ready_for_deletion"].includes(status);

  const canNavigateToDetailsPage = !["ready_for_deletion"].includes(status);

  const canCancel = canEditDefinition;

  return {
    actionsState: {
      // ID cannot be updated at any time once custom signal event definition exist in database
      canEditID: false,
      canCancel,
      canEditDefinition,
      canPublish,
      canDelete,
      canNavigateToDetailsPage,
    },
  };
};

interface MonthlyRange {
  previousDate: Date;
  futureDate: Date;
}

export const getMonthlyRange = (
  date: Date,
  numMonths: number = 1
): MonthlyRange => {
  const baseDate = new Date(date);
  const previousDate = new Date(baseDate);
  const futureDate = new Date(baseDate);

  previousDate.setMonth(baseDate.getMonth() - numMonths);
  futureDate.setMonth(baseDate.getMonth() + numMonths);

  return { previousDate, futureDate };
};

/**
 * Decapitalizes an EventTypeEnumCapitalized value to its EventTypeEnum equivalent.
 * @param str The EventTypeEnumCapitalized value to decapitalize
 * @returns The corresponding EventTypeEnum value
 */
export const decapitalizeEventType = (
  str: EventTypeEnumCapitalizedType | undefined
): EventTypeEnum | undefined => {
  if (!str) return;
  return (str.charAt(0).toLowerCase() + str.slice(1)) as EventTypeEnum;
};
