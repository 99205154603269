import { GPT4OMINI_MODEL_SPEC } from "duck/graph/constants";
import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import {
  createStrictToolCallingAgent,
  invokeAgentNode,
  NodeType,
} from "duck/graph/nodes/utils";
import {
  getRouteToIssuesToolDef,
  getRouteToSignalEventAnalyticsToolDef,
  getRouteToVinViewToolDef,
  routeToAnalyzeScreenshotToolDef,
  routeToClaimAnalyticsToolDef,
  routeToGreetingRejectClarifyToolDef,
  routeToIssueDetailsToolDef,
  routeToRagToolDef,
  routeToSubmitFeedbackToolDef,
  routeToVehiclesToolDef,
} from "duck/graph/tools/routingTools";
import { DuckGraphParams } from "duck/graph/types";
import { getLLM, NodeNames } from "duck/graph/utils";
import { BindToolsInput } from "@langchain/core/language_models/chat_models";

const getNode = async (params: DuckGraphParams): Promise<NodeType> => {
  const duckAccess = params.uiHandlers.duckAccess;

  const tools: BindToolsInput[] = [
    routeToRagToolDef,
    routeToAnalyzeScreenshotToolDef,
    routeToGreetingRejectClarifyToolDef,
    routeToSubmitFeedbackToolDef,
  ];

  if (duckAccess.claimAnalytics.enabled) {
    tools.push(routeToClaimAnalyticsToolDef);
  }
  if (duckAccess.signalEventAnalytics.enabled) {
    tools.push(getRouteToSignalEventAnalyticsToolDef(duckAccess));
  }
  if (duckAccess.vinView.enabled) {
    tools.push(getRouteToVinViewToolDef(duckAccess));
  }
  if (duckAccess.vehicles.enabled) {
    tools.push(routeToVehiclesToolDef);
  }
  if (duckAccess.issues.enabled) {
    tools.push(getRouteToIssuesToolDef(duckAccess));
  }
  if (duckAccess.issueDetails.enabled) {
    tools.push(routeToIssueDetailsToolDef);
  }

  const prompt = await loadPrompt(promptNames.ROUTER_AGENT);
  const llm = getLLM(GPT4OMINI_MODEL_SPEC);

  // disable parallel tool calls since we currently don't want to route to multiple agents
  const agent = createStrictToolCallingAgent(llm, tools, prompt, false);

  return invokeAgentNode({
    agent,
    name: NodeNames.ROUTER,
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    duckAccess: params.uiHandlers.duckAccess,
    useScreenshot: false,
  });
};

export default getNode;
