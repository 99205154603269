import { validateApiRequest } from "duck/graph/nodes/utils";

import {
  getSignalEventsOccurrencesMetricsRequestURI,
  SignalEventsFiltersRequest,
} from "shared/api/signalEvents/api";

/**
 * Enum representing the possible sources for the filter.
 */
export enum FilterSource {
  CLAIM = "claim",
  VEHICLE = "vehicle",
  SIGNAL_EVENT_OCCURRENCE = "signalEventOccurrence",
}

const filterSourceMap: Record<FilterSource, keyof SignalEventsFiltersRequest> =
  {
    [FilterSource.CLAIM]: "claimFilter",
    [FilterSource.VEHICLE]: "vehiclesFilter",
    [FilterSource.SIGNAL_EVENT_OCCURRENCE]: "signalEventOccurrencesFilter",
  };

/**
 * Returns a function that validates the filter string based on the provided source.
 *
 * @param source The source of the filter, which can be one of 'claim', 'vehicle', or 'signalEventOccurrence'.
 * @returns A function that takes a filterString parameter and validates it.
 * @throws If the source or the filterString for the source is invalid, a descriptive error will be thrown.
 */
export const getValidateFilter =
  (source: FilterSource) =>
  async (filterString: string): Promise<void> => {
    const filterKey = filterSourceMap[source];
    if (!filterKey) {
      throw new Error("Invalid filter source");
    }

    const params: SignalEventsFiltersRequest = {
      [filterKey]: filterString,
    };

    await validateApiRequest(
      params,
      getSignalEventsOccurrencesMetricsRequestURI
    );
  };
