import { useGroupBySelectOptions } from "shared/schemas/hooks";
import { EventTypeEnum, IssueTypes, ReoccurrenceProps } from "shared/types";

import {
  numberOfClaimsWithReoccurringSignalEvent,
  rateOfClaimsWithReoccurringSignalEvent,
  yAxis,
} from "features/ui/charts/actions";
import { ChartAction } from "features/ui/charts/Actions/types";

import * as config from "config/config";

import ReoccurrenceBarChart from "./ReoccurrenceBarChart";

interface Props extends ReoccurrenceProps {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const {
  pages: { issues },
} = config.get();

const ReoccurrenceBarChartWrapper = ({
  issue,
  withComparisonPopulation,
  seOccurrenceWithin,
  seReoccurrenceStartingFrom,
  seReoccurrenceUpTo,
}: Props) => {
  const groupBySelectOptions = useGroupBySelectOptions(
    EventTypeEnum.CLAIM,
    true
  ).map((x) => ({
    ...x,
    label: x.value,
  })) as ChartAction["options"];

  const actions: ChartAction[] = [
    {
      id: "legend",
      title: "Legend",
      type: "dropdownSelect",
      options: groupBySelectOptions,
      defaultOptionId: issues?.defaultBreakdownAttribute || "laborCode",
    },
    {
      id: "y",
      title: yAxis,
      type: "dropdownSelect",
      options: [
        rateOfClaimsWithReoccurringSignalEvent,
        numberOfClaimsWithReoccurringSignalEvent,
      ],
    },
  ];

  if (!groupBySelectOptions || groupBySelectOptions.length === 0) {
    return null;
  }

  return (
    <ReoccurrenceBarChart
      issue={issue}
      withComparisonPopulation={withComparisonPopulation}
      actions={actions}
      seOccurrenceWithin={seOccurrenceWithin}
      seReoccurrenceStartingFrom={seReoccurrenceStartingFrom}
      seReoccurrenceUpTo={seReoccurrenceUpTo}
    />
  );
};

export default ReoccurrenceBarChartWrapper;
