import classNames from "classnames";
import { format } from "date-fns";
import { TooltipProps } from "recharts";
import { NameType } from "recharts/types/component/DefaultTooltipContent";
import { ValueType } from "tailwindcss/types/config";

import { SHORT_DATE_FORMAT } from "shared/constants";
import { formatNumber } from "shared/utils";

import { SelectOption } from "features/ui/Select";

interface CustomTooltipProps {
  yAxisOptions: SelectOption[];
}

type CustomTooltipPropsAll = TooltipProps<ValueType, NameType> &
  CustomTooltipProps;

export const ChartTooltip = ({
  active,
  payload,
  label,
  yAxisOptions,
}: CustomTooltipPropsAll) => {
  if (!active || !yAxisOptions) return null;

  const data = payload?.[0]?.payload;
  const activeDataKey = payload?.[0]?.dataKey;

  if (!data) return null;

  return (
    <div className="flex flex-col bg-white shadow px-2 py-3 text-xs">
      <div className="border-b pb-1 mb-1">
        Occurrence Date: {format(label, SHORT_DATE_FORMAT)}
      </div>
      {data.vehicleCount && (
        <div>Cumulative Vehicle Count: {formatNumber(data.vehicleCount)}</div>
      )}
      {yAxisOptions.map((option) => (
        <div key={option.id}>
          {data[option.id] !== undefined && data[option.id] !== null && (
            <div
              className={classNames({
                "text-blue-500": activeDataKey === option.id,
              })}
            >
              {option.value}: {formatNumber(data[option.id])}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
