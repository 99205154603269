import { useState } from "react";

const CHARACTER_LIMIT_BEFORE_TRIM = 300;
const SHOW_FULL_TEXT_CTA = "Show full";
const HIDE_FULL_TEXT_CTA = "Collapse";

const Notes = ({ notes }: { notes?: string | null }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!notes) {
    return null;
  }

  if (notes.length > CHARACTER_LIMIT_BEFORE_TRIM) {
    const text = isExpanded
      ? notes
      : notes.substring(0, CHARACTER_LIMIT_BEFORE_TRIM - 3) + " ...";

    return (
      <span>
        {text}
        <ShowAllCta setIsExpanded={setIsExpanded} isExpanded={isExpanded} />
      </span>
    );
  }

  return <span>{notes}</span>;
};

interface ShowAllCTaProps {
  setIsExpanded: (b: boolean) => void;
  isExpanded: boolean;
}

const ShowAllCta = ({ setIsExpanded, isExpanded }: ShowAllCTaProps) => (
  <button
    className="block py-2 text-blue-400"
    onClick={() => setIsExpanded(!isExpanded)}
  >
    {isExpanded ? HIDE_FULL_TEXT_CTA : SHOW_FULL_TEXT_CTA}
  </button>
);

export default Notes;
