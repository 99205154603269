import { APIFilterOp } from "shared/api/utils";
import {
  keepNumberBetweenLimits,
  removeNonDigitCharacters,
} from "shared/utils";

import DropdownSelect, {
  DropdownSelectOption,
} from "features/ui/DropdownSelect/DropdownSelect";
import {
  MAX_WINDOW_SIZE,
  MIN_WINDOW_SIZE,
} from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import { getSelectOptionValue } from "features/ui/Filters/FilterTypes/RelatesFilter/utils";
import {
  OccursFilterOperator,
  RelatesFilterState,
  RelatesFilterWindowDirection,
  RelatesFilterWindowDirectionType,
} from "features/ui/Filters/types";
import { onlyAllowPositiveIntegersOnKeyDown } from "features/ui/Filters/utils";
import Input from "features/ui/Input";
import { SelectOption } from "features/ui/Select/Select";

interface Props {
  relatesState: RelatesFilterState;
  onUpdate: (row: RelatesFilterState) => void;
  baseEntityText: string;
  inFilterSelector: boolean;
  windowDirectionOptions?: DropdownSelectOption<RelatesFilterWindowDirectionType>[];
  disabled?: boolean;
  disableSelectingWindowDirection?: boolean;
}

const OCCURS_OPTIONS: SelectOption<APIFilterOp>[] = [
  { id: "occurs", value: "occurs" },
  { id: "noccurs", value: "does not occur" },
];

export const WINDOW_DIRECTION_OPTION_BEFORE: DropdownSelectOption<RelatesFilterWindowDirectionType> =
  {
    id: RelatesFilterWindowDirection.BEFORE,
    value: "before",
    label: "before",
    testId: "before",
  };

export const WINDOW_DIRECTION_OPTION_AFTER: DropdownSelectOption<RelatesFilterWindowDirectionType> =
  {
    id: RelatesFilterWindowDirection.AFTER,
    value: "after",
    label: "after",
    testId: "after",
  };

export const WINDOW_DIRECTION_OPTION_BOTH: DropdownSelectOption<RelatesFilterWindowDirectionType> =
  {
    id: RelatesFilterWindowDirection.BOTH,
    value: "before or after",
    label: "before or after",
    testId: "before-or-after",
  };

export const WINDOW_DIRECTION_OPTIONS: DropdownSelectOption<RelatesFilterWindowDirectionType>[] =
  [
    WINDOW_DIRECTION_OPTION_BEFORE,
    WINDOW_DIRECTION_OPTION_AFTER,
    WINDOW_DIRECTION_OPTION_BOTH,
  ];

const RelatesTimeWindowForm = ({
  relatesState,
  onUpdate,
  baseEntityText,
  inFilterSelector,
  windowDirectionOptions = WINDOW_DIRECTION_OPTIONS,
  disableSelectingWindowDirection = false,
  disabled,
}: Props) => (
  <div
    className="flex flex-wrap items-center gap-2"
    data-testid="relates-time-window-form"
  >
    {!inFilterSelector && <span>occurs</span>}
    {inFilterSelector && (
      <DropdownSelect
        options={OCCURS_OPTIONS}
        onSelect={(selectedOption) => {
          onUpdate({
            ...relatesState,
            operator: selectedOption.id as OccursFilterOperator,
          });
        }}
        label={getSelectOptionValue(relatesState.operator, OCCURS_OPTIONS)}
        size="small"
        disabled={disabled}
      />
    )}
    <div>within {disableSelectingWindowDirection ? "the last" : ""}</div>
    <Input
      className="!w-20 min-w-[5rem]"
      type="number"
      value={relatesState.options.windowSize}
      testId="related-events-window-size"
      max={MAX_WINDOW_SIZE}
      min={MIN_WINDOW_SIZE}
      onKeyDown={onlyAllowPositiveIntegersOnKeyDown}
      disabled={disabled}
      onChange={(e) => {
        onUpdate({
          ...relatesState,
          options: {
            ...relatesState.options,
            windowSize: keepNumberBetweenLimits(
              removeNonDigitCharacters(e.target.value),
              MIN_WINDOW_SIZE,
              MAX_WINDOW_SIZE
            ),
          },
        });
      }}
    />
    <div>days</div>
    {(windowDirectionOptions?.length === 1 && (
      <div>{windowDirectionOptions[0].value}</div>
    )) ||
      (!disableSelectingWindowDirection && (
        <DropdownSelect
          options={windowDirectionOptions}
          onSelect={(selectedOption) => {
            onUpdate({
              ...relatesState,
              options: {
                ...relatesState.options,
                windowDirection: selectedOption.id,
              },
            });
          }}
          label={getSelectOptionValue(
            relatesState.options.windowDirection,
            windowDirectionOptions
          )}
          testId="related-events-window-direction"
          size="small"
          disabled={disabled}
        />
      ))}
    <div className="whitespace-nowrap">{baseEntityText}</div>
  </div>
);

export default RelatesTimeWindowForm;
