import React, { useState } from "react";
import classNames from "classnames";
import { MdClose as CloseIcon } from "react-icons/md";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";

import {
  AlertDefinition,
  AlertDefinitionPutRequestData,
  updateAlertDefinition,
} from "shared/api/alertDefinitions/api";

import { FrequencyOptions } from "pages/AlertDefinitions/constants";

import Button from "features/ui/Button";
import Error from "features/ui/Error";

import {
  EVENT_TYPE_LABELS,
  UPDATE_ALERT_ERROR_TEXT,
  UPDATE_ALERT_SUCCESS_TEXT,
} from "./constants";
import WatchlistAlertRadioForm from "./WatchlistAlertRadioForm";

interface Props {
  alert: AlertDefinition;
  onClose: () => void;
}

const alertDefinitionToPutRequest = (
  alert: AlertDefinition
): AlertDefinitionPutRequestData => ({
  ID: alert.ID,
  name: alert.name,
  description: alert.description,
  vehiclesFilter: alert.vehiclesFilter,
  frequency: alert.frequency,
  emails: alert.emails,
  groupIds: [],
  eventType: alert.eventType,
  eventFilter: alert.eventFilter,
  inAppAlerts: alert.inAppAlerts,
  emailAlerts: alert.emailAlerts,
});

const WatchlistEditAlertForm = ({ alert, onClose }: Props) => {
  const [form, setForm] = useState<AlertDefinitionPutRequestData>(
    alertDefinitionToPutRequest(alert)
  );

  if (!form) {
    return <Error></Error>;
  }
  const handleFrequencyOrTypeChange = (
    frequency: FrequencyOptions,
    inAppAlerts: boolean,
    emailAlerts: boolean
  ) => {
    form.frequency = frequency;
    form.inAppAlerts = inAppAlerts;
    form.emailAlerts = emailAlerts;
    setForm({ ...form });
  };
  const onSave = () => {
    updateAlertDefinition(form)
      .then((_) => {
        toast.success(UPDATE_ALERT_SUCCESS_TEXT);
        onClose();
      })
      .catch((_) => {
        toast.error(UPDATE_ALERT_ERROR_TEXT);
        onClose();
      });
  };

  return (
    <div className="flex flex-col gap-2 min-w-80">
      <div className="flex flex-row justify-between items-center gap-4 mb-2">
        <div className="flex flex-col w-full">
          <div className="flex flex-row text-xs gap-2 items-center justify-between">
            <span
              className="text-gray-400 max-w-42 overflow-hidden text-ellipsis"
              title={EVENT_TYPE_LABELS[form.eventType]}
            >
              {EVENT_TYPE_LABELS[form.eventType]}
            </span>
            <IconButton
              size="small"
              onClick={onClose}
              data-testid="show-filter-summary"
              className={classNames("pl-0 !text-gray-400 hover:!text-gray-500")}
            >
              <CloseIcon size={16} />
            </IconButton>
          </div>

          <span
            className="text-sm overflow-hidden text-ellipsis whitespace-nowrap font-bold"
            title={form.name}
          >
            {form.name}
          </span>
        </div>
      </div>

      <div>
        <WatchlistAlertRadioForm
          frequency={form.frequency as FrequencyOptions}
          inAppAlerts={form.inAppAlerts}
          emailAlerts={form.emailAlerts}
          onChange={handleFrequencyOrTypeChange}
        ></WatchlistAlertRadioForm>
        <div className="flex flex-row gap-4 justify-center mt-4">
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={onSave}
          >
            Save
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WatchlistEditAlertForm;
