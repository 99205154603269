import { APIPaginatedRequest } from "shared/api/api";
import { usePaginatedAPI } from "shared/api/hooks";

import {
  createRecentItem,
  listRecentItemsRequestURI,
  RecentItem,
  RecentItemType,
} from "./api";

interface ListRecentItemProps extends APIPaginatedRequest {
  refreshKey?: boolean;
}

export const useListRecentItems = (args: ListRecentItemProps) =>
  usePaginatedAPI<typeof args, RecentItem[]>(listRecentItemsRequestURI, args);

interface CreateRecentItemProps {
  objectID?: string;
  objectType?: RecentItemType;
}

export const useCreateRecentItem = (args: CreateRecentItemProps) => {
  if (args.objectID && args.objectType) {
    createRecentItem({
      objectType: args.objectType,
      objectID: args.objectID,
    }).catch((err) => {
      console.error(err);
    });
  }
};
