import DropdownSelect from "features/ui/DropdownSelect/DropdownSelect";
import FormSection from "features/ui/FormSection";
import { SelectOption } from "features/ui/Select";

interface Props {
  options: SelectOption[];
  selectedOption?: SelectOption;
  onOptionSelect: (selectedOption: SelectOption) => void;
  disabled?: boolean;
  className?: string;
}

const SELECT_EVENT_TYPE_EMPTY_TEXT = "Select Event Type";

const BaseEventTypeSelectSection = ({
  options,
  selectedOption,
  onOptionSelect,
  disabled,
  className,
}: Props) => (
  <FormSection
    title="Base Event Type"
    text="Specify the Event Type upon which you will build your custom attribute"
    className={className}
  >
    <DropdownSelect
      label={(selectedOption?.value as string) || SELECT_EVENT_TYPE_EMPTY_TEXT}
      options={options}
      onSelect={onOptionSelect}
      testId="custom-base-event-type"
      disabled={disabled}
    />
  </FormSection>
);

export default BaseEventTypeSelectSection;
