import classNames from "classnames";

import { TestProps } from "shared/types";

interface Props extends TestProps {
  disabled?: boolean;
  onClick: () => void;
}

const TEXT = "Apply filter";

const FilterCTA = ({ disabled, onClick, testId }: Props) => (
  <div className="text-right text-white">
    <button
      data-testid={testId || "updateFilterBtn"}
      className={classNames("font-semibold rounded p-2 bg-blue-400", {
        "hover:bg-blue-500": !disabled,
        "cursor-not-allowed opacity-50": disabled,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      {TEXT}
    </button>
  </div>
);

export default FilterCTA;
