import classNames from "classnames";

import Button from "features/ui/Button";

import { WHEN_AT_BOTTOM_MARGIN_PX } from "./ResetZoomButton";

const LABEL = "Set date range to zoom";

interface Props {
  isZoomedIn: boolean;
  onClick: () => void;
  marginSide?: number;
}

const SetDateToZoom = ({ isZoomedIn, onClick, marginSide = 0 }: Props) => (
  <Button
    variant="outlined"
    label={LABEL}
    size="small"
    disabled={!isZoomedIn}
    className={classNames({
      invisible: !isZoomedIn,
    })}
    onClick={onClick}
    style={{
      position: "absolute",
      right: marginSide + 10,
      bottom: WHEN_AT_BOTTOM_MARGIN_PX,
      zIndex: 10,
    }}
  />
);

export default SetDateToZoom;
