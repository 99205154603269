/**
 * Since we do not have /attributes API for failure modes, we
 * are using the failure modes API to get the failure modes and
 * then build schemas manually.
 */

import { useFlags } from "launchdarkly-react-client-sdk";

import { useFailureModes } from "shared/api/failureModes/hooks";
import { getSortFilter } from "shared/api/utils";
import { FAILURE_MODE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER } from "shared/filterDefinitions";

import { DEFAULT_NON_ARCHIVED_FAILURE_MODES_FILTER } from "pages/FailureModes/constants";

import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterSchemaItem } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

import { useRiskModelPredictionsSchema } from "./riskModelPredictionsSchema";
import { getFilterPropertiesFromAttribute, getSchemaType } from "./utils";

export const RISK_MODEL_PREDICTION_DISPLAYED_FIELDS = [
  "riskStatus",
  "riskScore",
  "riskLabel",
  "repairStatus",
  "repairDetails",
];
const RISK_MODEL_PREDICTION_PREFIX = "riskModelPrediction";

const useFailureModesRiskModelPredictionsSchema = (
  skipRequest?: boolean
): SchemaEntry[] => {
  const { failureModesV1 } = useFlags();
  const { data: failureModesData } = useFailureModes({
    skipRequest: !failureModesV1 || skipRequest,
    sort: getSortFilter({ status: "desc" }),
    filter: getFiltersQuery(
      filterStateToFilterGroupState(DEFAULT_NON_ARCHIVED_FAILURE_MODES_FILTER)
    ),
  });

  const { attributes } = useRiskModelPredictionsSchema(
    (item: FilterSchemaItem) =>
      FAILURE_MODE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER("", item)
  );

  if (!failureModesData || failureModesData.length === 0) {
    return [];
  }

  return failureModesData.reduce(
    (acc, failureMode) => [
      ...acc,
      ...(attributes || [])
        .filter((attribute) =>
          RISK_MODEL_PREDICTION_DISPLAYED_FIELDS.includes(attribute.ID)
        )
        .map((attribute) => ({
          label: `${failureMode.name} ${attribute.displayName}`,
          accessor: `${RISK_MODEL_PREDICTION_PREFIX}.${failureMode.ID}.${attribute.ID}`,
          dataType: getSchemaType(attribute.type),
          description:
            DESCRIPTION_OVERRIDES[attribute.ID] ||
            attribute.description ||
            undefined,
          filter: FAILURE_MODE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER(
            failureMode.ID,
            {
              label: `${failureMode.name}: ${attribute.displayName}`,
              fieldName: `${RISK_MODEL_PREDICTION_PREFIX}.${failureMode.ID}.${attribute.ID}`,
              fieldNameForAPI: attribute.ID,
              ...getFilterPropertiesFromAttribute(attribute),
            }
          ),
          dropdownGroup: "Failure Modes",
        })),
    ],
    [] as SchemaEntry[]
  );
};

export const DESCRIPTION_OVERRIDES: Record<string, any> = {
  riskLabel: (
    <ul className="text-left list-disc px-5 py-2">
      <li className="mb-2">
        <span className="font-semibold">High risk</span>: Vehicle is one of the
        highest-risk vehicles for this Failure Mode. It is recommended that
        these vehicles be considered for proactive repair.
      </li>
      <li className="mb-2">
        <span className="font-semibold">Medium risk</span>: Vehicle has shown
        only moderate risk factors for this Failure Mode. It is recommended that
        these vehicles be monitored for failure because vehicle risk profiles
        can change over time.
      </li>
      <li>
        <span className="font-semibold">Low risk</span>: Vehicle is one of the
        lowest-risk vehicles for this Failure Mode. No action is recommended for
        these vehicles at this time.
      </li>
    </ul>
  ),
  riskStatus: (
    <ul className="text-left list-disc px-5 py-2">
      <li className="mb-2">
        <span className="font-semibold">Active</span>: The vehicle has
        up-to-date data and is receiving predictions.
      </li>
      <li className="mb-2">
        <span className="font-semibold">No Recent Data</span>: The vehicle has
        not had a data reading for the last 90 days. Depending on the Failure
        Mode it may still be able to receive predictions.
      </li>
      <li className="mb-2">
        <span className="font-semibold">Inactive</span>: The vehicle has already
        had a repair for the Failure Mode, therefore it is not receiving new
        predictions.
      </li>
    </ul>
  ),
  riskScore: (
    <div className="text-left">
      Shows how many times a vehicle is more likely to fail than the average
      vehicle that is eligible for the same failure.
    </div>
  ),
  repairStatus: (
    <ul className="text-left list-disc px-5 py-2">
      <li className="mb-2">
        <span className="font-semibold">Natural Failure</span>: The vehicle has
        experienced a natural failure for the Failure Mode. The outcome may be
        that a claim was filed, or a part was replaced.
      </li>
      <li className="mb-2">
        <span className="font-semibold">Campaign Repair</span>: The vehicle has
        received a repair due to the campaign identified by the campaign code.
      </li>
    </ul>
  ),
  warrantyActive: (
    <div className="text-left">
      Shows whether the specific vehicle part is still eligible for warranty.
      The value is calculated based on the vehicle's service duration and
      mileage.
      <br /> <br />
      An empty cell means that there was not enough data to calculate the
      warranty status.
    </div>
  ),
};

export default useFailureModesRiskModelPredictionsSchema;
