import { useParams } from "react-router-dom";

import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

import VehicleInfo from "./VehicleInfo";
import VINViewTabs from "./VINViewTabs";

type VINViewParams = {
  vin: string;
};

const VINView = () => {
  const { vin } = useParams<VINViewParams>();

  return (
    <>
      <PageHeaderWrapper />
      <VehicleInfo vin={vin as string} />
      <VINViewTabs vin={vin as string} />
    </>
  );
};

export default VINView;
