import { useEffect, useState } from "react";

import { VEHICLE_ATTRIBUTES_PREFIX } from "shared/api/utils";
import { TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR } from "shared/schemas/constants";

import { DataElement } from "features/ui/charts/types";
import {
  FilterChangeCallback,
  FilterOperator,
} from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";

interface Props {
  selectedGroupByAttribute: SelectOption;
  onVehiclesFiltersChange?: FilterChangeCallback;
  onClaimsFiltersChange?: FilterChangeCallback;
  onSignalEventsFiltersChange?: FilterChangeCallback;
}

export const useBarSelection = ({
  selectedGroupByAttribute,
  onVehiclesFiltersChange,
  onClaimsFiltersChange,
  onSignalEventsFiltersChange,
}: Props) => {
  const isVehicleAttribute = selectedGroupByAttribute.id
    .toString()
    .startsWith(VEHICLE_ATTRIBUTES_PREFIX);

  const attributeNameToUseForFilterChange = isVehicleAttribute
    ? selectedGroupByAttribute.id
        .toString()
        .replace(VEHICLE_ATTRIBUTES_PREFIX, "")
    : selectedGroupByAttribute.id.toString();

  // reset selected bars when group by attribute changes
  useEffect(() => {
    setSelectedBars([]);
  }, [selectedGroupByAttribute]);

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const [selectedBars, setSelectedBars] = useState<string[]>([]);

  // Toggle currently selected bars
  // make sure to stopPropagation so that the chart doesn't get the click event fired, which reset selected bars..
  const handleOnBarClick = (
    row: DataElement,
    event: React.MouseEvent<SVGPathElement, MouseEvent>
  ) => {
    event.stopPropagation();
    const dimensionValue = row[TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR];
    setSelectedBars((prevSelectedBars) => {
      if (!prevSelectedBars) return [dimensionValue];

      const isSelected = prevSelectedBars.includes(dimensionValue);
      if (isSelected) {
        return prevSelectedBars.filter((bar) => bar !== dimensionValue);
      } else {
        return [...prevSelectedBars, dimensionValue];
      }
    });
    setShowContextMenu(false);
  };

  const resetSelectedBars = () => {
    setSelectedBars([]);
  };

  const handleOnChartClick = () => {
    setSelectedBars([]);
    // we close the context menu on any mouse click anywhere on the chart..
    if (showContextMenu) setShowContextMenu(false);
  };

  const onSelectedBarsApplyAsFilter = () => {
    const claimOrSEFiltersChangeFunc =
      onClaimsFiltersChange || onSignalEventsFiltersChange;

    const onFiltersChangeFunc = isVehicleAttribute
      ? onVehiclesFiltersChange
      : claimOrSEFiltersChangeFunc;

    // We are handling the case where groupByAttributeValue="Absent" or "Present"
    // - it should yield a different filter when applied to the page
    // - this is currently only the case for ECUs, so we handle just that for now and we assume the format is always <X>.<Y>.<Z>
    // - Example: ECUs.AIRB.program becomes ECUs.ABS.present=eq:"false"
    // - relevant API code: https://github.com/viaduct-ai/vehicle-profiles-api/blob/363e413fbcd16f5c9ce84a342786faaf09536140/service/handlers/claims/v1/top_contributors.go#L183
    // - no, I don't think this is very nice either, but it's the best I could come up with for now.. Ideally we wouldn't introduce arbitrary "Absent" values in the first place from the API..
    let fieldName = attributeNameToUseForFilterChange;
    let filterOperator = FilterOperator.IN;
    let filterValue: string[] = selectedBars;

    const isAbsentValue =
      selectedBars.includes("Absent") ||
      selectedBars.some((val) => val.endsWith("Absent"));

    const isPresentValue =
      selectedBars.includes("Present") ||
      selectedBars.some((val) => val.endsWith("Present"));

    if (isPresentValue) {
      filterOperator = FilterOperator.IS_TRUE;
      filterValue = ["true"];
    } else if (isAbsentValue) {
      fieldName = `${attributeNameToUseForFilterChange.substring(
        0,
        attributeNameToUseForFilterChange.lastIndexOf(".")
      )}.present`;

      filterOperator = FilterOperator.IS_FALSE;
      filterValue = ["false"];
    }

    onFiltersChangeFunc &&
      onFiltersChangeFunc({
        key: fieldName,
        op_id: filterOperator,
        values: filterValue,
      });

    resetSelectedBars();
    setShowContextMenu(false);
  };

  const onBarRightClick = (
    _x: DataElement,
    _y: number,
    event: React.MouseEvent<SVGPathElement, MouseEvent>
  ) => {
    event.preventDefault();

    // If the user right-clicks on a bar and no bars are selected, automatically select the right-clicked bar
    if (selectedBars.length === 0) {
      handleOnBarClick(_x, event);
    }

    setContextMenuPosition({ x: event.clientX, y: event.clientY });
    setShowContextMenu(true);
  };

  const contextMenu = (
    <div
      className="bg-white shadow border px-4 py-3 absolute z-10"
      style={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}
    >
      <ul className="flex flex-col space-y-2">
        <li
          onClick={onSelectedBarsApplyAsFilter}
          className="text-sm hover:opacity-75 cursor-pointer"
        >
          Include selected values as page filter
        </li>
      </ul>
    </div>
  );

  return {
    selectedBars,
    showContextMenu,
    handleOnBarClick,
    onBarRightClick,
    contextMenu,
    handleOnChartClick,
  };
};
