import { useFlags } from "launchdarkly-react-client-sdk";

import { useCustomLocalStorageState } from "shared/hooks";
import { IssueTypes } from "shared/types";

import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import {
  ISSUE_ACTIVITY_TAB_KEY,
  ISSUE_CLAIMS_TAB_KEY,
  ISSUE_DETAILS_TAB_TITLES,
  ISSUE_RELATIONSHIP_TAB_KEY,
  ISSUE_REPAIR_EFFICACY_TAB_KEY,
  ISSUE_SIGNAL_EVENTS_TAB_KEY,
  WITH_COMPARISON_POPULATION_KEY,
} from "./constants";
import IssueActivityTab from "./IssueActivityTab";
import IssueClaimsTab from "./IssueClaimsTab";
import IssueRelationshipTab from "./IssueRelationshipTab";
import IssueRepairEfficacyTab from "./IssueRepairEfficacyTab";
import IssueSignalEventsTab from "./IssueSignalEventsTab";
import { getIssueTabsAvailability, isSuggestedIssue } from "./utils";

interface Props {
  issue: IssueTypes;
}

const IssueTabs = ({ issue }: Props) => {
  const { issueActivityLog } = useFlags();

  const [withComparisonPopulation, setWithComparisonPopulation] =
    useCustomLocalStorageState(WITH_COMPARISON_POPULATION_KEY, {
      defaultValue: false,
    });

  const { comparisonPopulationFilter } = issue;

  const {
    claimsTabDisabled,
    signalEventsTabDisabled,
    relationshipsTabDisabled,
    repairEfficacyTabDisabled,
  } = getIssueTabsAvailability(issue);

  const suggestedIssue = isSuggestedIssue(issue);

  const claimsTabNoCriteriaText = suggestedIssue
    ? "Claims criteria must be defined to enable claim analysis"
    : "Add claims criteria to enable claim analysis";

  const signalEventsTabNoCriteriaText = suggestedIssue
    ? "Signal events criteria must be defined to enable signal event analysis"
    : "Add signal event criteria to enable signal event analysis";

  let missingCriteria = "";
  if (claimsTabDisabled) {
    missingCriteria = "Claims criteria";
  }
  if (signalEventsTabDisabled) {
    missingCriteria = "Signal event criteria";
  }
  if (claimsTabDisabled && signalEventsTabDisabled) {
    missingCriteria = "Both claims criteria and signal events";
  }
  const relationshipTabNoCriteriaText = `${missingCriteria} must be defined to enable relationship analysis`;
  const repairEfficacyTabNoCriteriaText = `${missingCriteria} must be defined to enable repair efficacy analysis`;

  const onToggleWithComparisonPopulation = () =>
    setWithComparisonPopulation(!withComparisonPopulation);

  const showWithComparisonPopulation =
    withComparisonPopulation && Boolean(comparisonPopulationFilter);

  const commonProps = {
    issue,
    withComparisonPopulation: showWithComparisonPopulation,
    onToggleWithComparisonPopulation,
  };

  const tabsItems: (Tab | boolean | undefined)[] = [
    {
      key: ISSUE_CLAIMS_TAB_KEY,
      title: ISSUE_DETAILS_TAB_TITLES[ISSUE_CLAIMS_TAB_KEY],
      content: <IssueClaimsTab {...commonProps} />,
      testId: ISSUE_CLAIMS_TAB_KEY,
      disabled: claimsTabDisabled,
      disabledText: claimsTabNoCriteriaText,
    },
    {
      key: ISSUE_SIGNAL_EVENTS_TAB_KEY,
      title: ISSUE_DETAILS_TAB_TITLES[ISSUE_SIGNAL_EVENTS_TAB_KEY],
      content: <IssueSignalEventsTab {...commonProps} />,
      testId: ISSUE_SIGNAL_EVENTS_TAB_KEY,
      disabled: signalEventsTabDisabled,
      disabledText: signalEventsTabNoCriteriaText,
    },
    {
      key: ISSUE_RELATIONSHIP_TAB_KEY,
      title: ISSUE_DETAILS_TAB_TITLES[ISSUE_RELATIONSHIP_TAB_KEY],
      content: <IssueRelationshipTab {...commonProps} />,
      testId: ISSUE_RELATIONSHIP_TAB_KEY,
      disabled: relationshipsTabDisabled,
      disabledText: relationshipTabNoCriteriaText,
    },
    {
      key: ISSUE_REPAIR_EFFICACY_TAB_KEY,
      title: ISSUE_DETAILS_TAB_TITLES[ISSUE_REPAIR_EFFICACY_TAB_KEY],
      content: <IssueRepairEfficacyTab {...commonProps} />,
      testId: ISSUE_REPAIR_EFFICACY_TAB_KEY,
      disabled: repairEfficacyTabDisabled,
      disabledText: repairEfficacyTabNoCriteriaText,
    },
    issueActivityLog && {
      key: ISSUE_ACTIVITY_TAB_KEY,
      title: ISSUE_DETAILS_TAB_TITLES[ISSUE_ACTIVITY_TAB_KEY],
      content: <IssueActivityTab {...commonProps} />,
      testId: ISSUE_ACTIVITY_TAB_KEY,
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return <StatefulTabs tabs={tabs} tabPanelClassName="py-3" />;
};

export default IssueTabs;
