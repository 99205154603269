import { useState } from "react";

import { useCustomLocalStorageState } from "shared/hooks";
import { useGroupBySelectOptions } from "shared/schemas/hooks";
import { EventTypeEnum } from "shared/types";

import { useTopContributorsChartSettings } from "pages/hooks";
import { RepairAnalyticsProps } from "pages/RepairAnalytics/RepairAnalyticsTabs";
import TopContributorsGroupBySelector from "pages/shared/TopContributorsGroupBySelector";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { SelectOption } from "features/ui/Select";

import TopChart from "./TopChart";
import TopContributorsTable from "./TopContributorsTable";

const DEFAULT_GROUP_BY_ATTRIBUTE = "status";
const DEFAULT_GROUP_BY_ATTRIBUTE_LABEL = "Status";
const DEFAULT_GROUP_BY_SELECT_OPTION: SelectOption = {
  id: DEFAULT_GROUP_BY_ATTRIBUTE,
  value: DEFAULT_GROUP_BY_ATTRIBUTE_LABEL,
};
export const GROUP_BY_ATTRIBUTE_CHART_NULL_REPLACEMENT = "Empty";
const GROUP_BY_ATTRIBUTE_LOCAL_STORAGE_KEY = "repairAnTopContrGroupByAttribute";

const CHART_OPTIONS_KEY = "repairAnTopContrChartOptions";

const TopContributors = ({
  repairsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
}: RepairAnalyticsProps) => {
  const {
    actions,
    selectedOptions,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
    handleSelectedOptionsChange,
  } = useTopContributorsChartSettings(
    repairsFiltersFilterSortState,
    CHART_OPTIONS_KEY,
    EventTypeEnum.REPAIR
  );

  const repairsFilters = repairsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  // TODO: Also generalize & centralize groupBy into a separate hook on all analytics pages
  const groupBySelectOptions = useGroupBySelectOptions(EventTypeEnum.REPAIR);
  const [generalFilters, setGeneralFilters] = useState<
    FilterGroupState | undefined
  >();

  const [selectedGroupByAttribute, setSelectedGroupByAttribute] =
    useCustomLocalStorageState<SelectOption>(
      GROUP_BY_ATTRIBUTE_LOCAL_STORAGE_KEY,
      {
        defaultValue: DEFAULT_GROUP_BY_SELECT_OPTION,
      }
    );

  const resetState = () => {
    setSelectedGroupByAttribute(DEFAULT_GROUP_BY_SELECT_OPTION);
    repairsFiltersFilterSortState.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <TopContributorsGroupBySelector
        groupBySelectOptions={groupBySelectOptions}
        selectedGroupByAttribute={selectedGroupByAttribute}
        setSelectedGroupByAttribute={setSelectedGroupByAttribute}
      />
      <TopChart
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        repairsFilters={repairsFilters}
        filters={generalFilters}
        onRepairsFiltersChange={
          repairsFiltersFilterSortState?.manageFilterChange
        }
        onVehiclesFiltersChange={
          vehiclesFiltersFilterSortState?.manageFilterChange
        }
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleSelectedOptionsChange}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
        onBadRequest={resetState}
      />
      <TopContributorsTable
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        repairsFilters={repairsFilters}
        onFiltersUpdated={setGeneralFilters}
        onBadRequest={resetState}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
      />
    </>
  );
};

export default TopContributors;
