import { useEffect, useState } from "react";

import { useSignalEventOccurrencesFiltersSchema } from "shared/hooks";
import { cloneObject } from "shared/utils";

import { SIGNAL_EVENTS_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";

import DropdownSelect from "features/ui/DropdownSelect";
import {
  FilterGroupState,
  FilterRowStateNotNull,
} from "features/ui/Filters/FilterBuilder/types";
import RelatesTimeWindowForm from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesTimeWindowForm";
import { FilterSkeleton } from "features/ui/Filters/FilterTypes/SelectFilter/FilterSkeleton";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import ResetToDefaultButton from "features/ui/Filters/FilterWizard/ResetToDefaultButton";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import {
  FilterChangeCallback,
  FilterOperator,
  RelatesFilterState,
} from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import { DEFAULT_RELATES_FILTER } from "./constants";
import { encodeRelatesFilter } from "./utils";

interface Props {
  filters?: FilterGroupState;
  defaultFilters?: FilterGroupState;
  filter?: FilterRowStateNotNull;
  fieldName: string;
  onFiltersReset?: (accessors?: string[]) => void;
  onFilterChange: FilterChangeCallback;
  initialized?: boolean;
  baseEntityText?: string;
  disableSelectingWindowDirection?: boolean;
  pageKey: string;
}

const ExtendedRelatesFilter = ({
  filters,
  defaultFilters,
  filter,
  fieldName,
  onFiltersReset,
  onFilterChange,
  initialized,
  baseEntityText = "Claim",
  disableSelectingWindowDirection = false,
  pageKey,
}: Props) => {
  const [relatesFilter, setRelatesFilter] = useState<RelatesFilterState>(
    filter?.relates ? cloneObject(filter?.relates) : DEFAULT_RELATES_FILTER
  );

  // we need to have a different key for each relates filter across app
  const RELATED_FILTER_PAGE_KEY = `relatesFilterState-${pageKey}`;
  const PENDING_FILTERS_RELATED_FILTER_LS_KEY = getPendingFiltersKey(
    RELATED_FILTER_PAGE_KEY
  );

  // this is a nested filter, so we need to fetch filters from the parent filter component
  const relatedSignalEventsFilterSortState = useFilterSortState({
    pageKey: RELATED_FILTER_PAGE_KEY,
    defaultFilterValues:
      filter?.relates?.filters ?? DEFAULT_RELATES_FILTER.filters,
    pendingFiltersLocalStorageKey: PENDING_FILTERS_RELATED_FILTER_LS_KEY,
    disableUsingQuery: true,
    disableUsingLocalStorage: true,
  });

  useEffect(() => {
    // when filters are reset from above in useFilterSortState, we would like to reset the state in here as well.
    // As Select component is not able to react to changes in initial state, we temporarily unmount and then
    // immediately remount component again. We set the initial state to the default row.
    if (!initialized) {
      setRelatesFilter(DEFAULT_RELATES_FILTER);
    }
  }, [initialized]);

  const onUpdate = (newRelatesState: RelatesFilterState) => {
    setRelatesFilter(newRelatesState);
    applyFilters(newRelatesState);
  };
  const applyFilters = (newRow: RelatesFilterState) => {
    onFilterChange({
      key: fieldName,
      op_id: FilterOperator.RELATES,
      values: [encodeRelatesFilter(newRow)],
      extra: undefined,
      relates: cloneObject(newRow),
    });
  };
  const applyRelatesFilter = (newFilterState: FilterGroupState) => {
    onUpdate({
      ...relatesFilter,
      filters: newFilterState,
    });
  };

  // we want to avoid nested related filters
  const signalEventsOccurrencesFiltersSchema =
    useSignalEventOccurrencesFiltersSchema(["relatedSignalEventOccurrences"]);

  const signalEventsFilterLabel = getFilterLabel(
    SIGNAL_EVENTS_FILTER_LABEL,
    relatedSignalEventsFilterSortState.filters
  );

  if (!initialized) return <FilterSkeleton />;

  return (
    <>
      <div className="flex flex-wrap leading-10 space-x-2 space-y-2 items-end mb-6">
        <span>Related Signal Events defined by</span>
        <DropdownSelect
          testId="associated-signal-events-filters-dropdown"
          label={signalEventsFilterLabel}
          buttonClass="mt-6 h-[38px] mr-4"
          content={
            <FilterSelector
              schema={signalEventsOccurrencesFiltersSchema}
              filterSortState={relatedSignalEventsFilterSortState}
              title={SIGNAL_EVENTS_FILTER_LABEL}
              testId="related-signal-event-filters"
              pendingFiltersKey={PENDING_FILTERS_RELATED_FILTER_LS_KEY}
              onApplyFilters={applyRelatesFilter}
              defaultFilters={DEFAULT_RELATES_FILTER.filters}
            />
          }
        />
        <RelatesTimeWindowForm
          relatesState={relatesFilter}
          onUpdate={onUpdate}
          baseEntityText={baseEntityText}
          inFilterSelector={true}
          disableSelectingWindowDirection={disableSelectingWindowDirection}
        />
      </div>
      <div className="flex items-center justify-end mt-3">
        <ResetToDefaultButton
          filters={filters}
          defaultFilters={defaultFilters}
          fieldName={fieldName}
          testId="relates-reset-to-default"
          onResetToDefault={onFiltersReset}
        />
      </div>
    </>
  );
};

export default ExtendedRelatesFilter;
