import { PageHandler } from "duck/graph/PageHandler";
import { StringSetter } from "duck/graph/types";
import { StructuredTool, tool } from "@langchain/core/tools";

import { setRouteValue, WithVin, withVinSchema } from "./utils";

const getGoToTabTool = (
  pageHandler: PageHandler,
  setEphemeralMessage: StringSetter,
  tabKey: string,
  tabTitle: string,
  toolDescription: string
): StructuredTool => {
  const goToTab = async ({ vin }: WithVin) => {
    setEphemeralMessage(`navigating to ${tabTitle} tab`);
    console.debug(`[VIN]: Navigating to ${tabTitle} tab with vin ${vin}`);
    await setRouteValue(vin, pageHandler);
    pageHandler.navigateToTab(tabKey);
    return `queued navigation to ${tabTitle} tab`;
  };

  return tool(goToTab, {
    name: `goTo${tabKey}Tab`,
    description: toolDescription,
    schema: withVinSchema,
  });
};

export default getGoToTabTool;
