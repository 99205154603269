export enum Reload {
  HARD = "hard",
  SOFT = "soft",
  NONE = "none",
}

export type LocationInfo = {
  reloadRequired: Reload;
  path: string;
  url: string;
};

export interface QueryStringNavigation {
  deliverLocationInfo: () => LocationInfo;
  updateLocation: () => void;
  clearLocationInfo: () => void;
  setRouteValue: (pathname: string) => void;
  setMinimumReload: (reload: Reload) => void;
  updateQueryStringParameter: (
    paramName: string,
    paramValue: string,
    reload?: Reload
  ) => void;
  navigateToTab: (tabId: string) => void;
  updateFilter: (
    filterQueryString: string,
    queryStringParameterName: string
  ) => void;
  setOmitExistingQueryParam: (paramName: string | undefined) => void;
  setOmitExistingQueryParamsStartingWith: (prefix: string | undefined) => void;
}

export interface PendingAction {
  pendingAction: boolean;
  setPendingAction: (pendingAction: boolean) => void;
}

/**
 * This interface defines the access permitted to Duck in this tenant.
 * It is used to determine which pages and tabs Duck can make available to the user.
 * This access is defined by LaunchDarkly feature flags and by the config object for
 * the tenant.
 */
export interface DuckAccess {
  claimAnalytics: {
    enabled: boolean;
    claimsTab: boolean;
    byVehicleAgeTab: boolean;
    topContributorsTab: boolean;
    associatedSignalEventsTab: boolean;
    associatedVehiclesTab: boolean;
  };
  signalEventAnalytics: {
    enabled: boolean;
    signalEventsTab: boolean;
    byVehicleAgeTab: boolean;
    topContributorsTab: boolean;
    associatedClaimsTab: boolean;
    associatedSignalEventsTab: boolean;
    associatedVehiclesTab: boolean;
  };
  vehicles: {
    enabled: boolean;
  };
  vinView: {
    enabled: boolean;
    healthTabRiskModel: boolean;
    healthTabFailureModes: boolean;
    serviceRecordsTab: boolean;
    serviceScheduleTab: boolean;
    serviceRecommendationsTab: boolean;
    timelineTab: boolean;
    ecusTab: boolean;
    optionsTab: boolean;
  };
  issues: {
    enabled: boolean;
    issuesTab: boolean;
    suggestedIssuesTab: boolean;
  };
  issueDetails: {
    enabled: boolean;
  };
}
