import { useState } from "react";

import { Group } from "shared/api/rbac/api";

import MembersTable from "pages/Groups/Details/Members/MembersTable";
import { GroupMemberEntry } from "pages/Groups/Details/Members/utils";
import { initGroupMemberEntries } from "pages/Groups/utils";

import { StepProps } from "features/ui/Stepper";

const StepAddMembers = ({ data, onDataUpdated }: StepProps) => {
  const group = data as Group;
  group.canEdit = true;

  const [groupMembers, setGroupMembers] = useState(
    initGroupMemberEntries(group.users, true)
  );

  const onUpdate = (members: GroupMemberEntry[]) => {
    setGroupMembers(members);
    onDataUpdated &&
      onDataUpdated({
        users: members.filter(({ ID }) => ID).map(({ email }) => ({ email })),
      });
  };

  return (
    <div className="p-1">
      <MembersTable
        group={group}
        groupMembers={groupMembers}
        onUpdate={onUpdate}
      />
    </div>
  );
};

export default StepAddMembers;
