import { useContext } from "react";

import "./duck-loading.css";

import { DuckMessagesContext } from "duck/context/DuckMessagesContextWrapper";
import { Stack } from "@mui/material";

import DuckLogo from "./duck-logo.svg";
import ThoughtBubbleTail from "./ThoughtBubbleTail";

const DuckLoadingAnimation = () => {
  const { ephemeralMessage } = useContext(DuckMessagesContext);

  return (
    <Stack alignItems="center" direction="column" spacing={1}>
      <Stack alignItems="center" direction="column">
        <p className="bg-gray-100 border border-gray-200 text-gray-400 px-2 py-1 rounded-xl text-xs">
          {ephemeralMessage || "quack"}
        </p>
        <ThoughtBubbleTail />
      </Stack>
      <div className="container">
        <div className="animate-duck-loading">
          <img src={DuckLogo} alt="Duck Logo" />
        </div>
      </div>
    </Stack>
  );
};

export default DuckLoadingAnimation;
