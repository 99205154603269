import { RiskModelPrediction } from "shared/api/failureModes/api";
import { intersection } from "shared/utils";

import { SchemaEntry } from "features/ui/Table";

import RiskBadge from "./RiskBadge";

export const getFailureModePredictionAccessor = (
  ID: string,
  property: string
) => `riskModelPrediction.${ID}.${property}`;

export const getAccessorsForVisibleFMColumns = (
  allFailureModesIds: string[],
  visibleColumns: string[],
  additionalColumnsOptionsValues: string[]
): string[] => {
  const failureModeColumnsToShow = intersection(
    allFailureModesIds,
    visibleColumns
  );

  const failureModePropsToShow = intersection(
    additionalColumnsOptionsValues,
    visibleColumns
  );

  return failureModeColumnsToShow.reduce(
    (acc, fmId) =>
      failureModePropsToShow.reduce((accessors, prop) => {
        accessors.push(getFailureModePredictionAccessor(fmId, prop));
        return accessors;
      }, acc),
    []
  );
};

export const getPredictionsFormatted = (
  predictions: RiskModelPrediction[]
): Record<string, RiskModelPrediction> => {
  if (!predictions) return {};
  return Object.values(predictions).reduce(
    (prevVal, prediction) => ({
      ...prevVal,
      [prediction.failureModeID]: {
        ...prediction,
        riskLabel: <RiskBadge risk={prediction.riskLabel} />,
      },
    }),
    {}
  );
};

export const getColumnsToShow = (schema: SchemaEntry[], columns?: string[]) => {
  const columnsToShow = columns
    ? schema.filter((s) => columns?.includes(s.accessor))
    : schema;

  return columnsToShow.map(({ accessor }) => accessor);
};
