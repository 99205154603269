import { KeyboardEvent } from "react";

import { removeNonDigitCharacters } from "shared/utils";

import { DownsamplingOptions } from "pages/CustomSignalEvents/constants";

import { onlyAllowPositiveIntegersOnKeyDown } from "features/ui/Filters/utils";
import FormSection from "features/ui/FormSection";
import Input from "features/ui/Input";
import { InputProps } from "features/ui/Input/Input";
import RadioButtonGroup, {
  RadioSelectOption,
} from "features/ui/RadioButtonGroup";

export interface Downsampling {
  option: string;
  secondsBefore: number;
  secondsAfter: number;
  occurrences: number;
}

interface DownsamplingInputProps {
  currentValue: Downsampling;
  onChange: (value: Downsampling) => void;
  disabled?: boolean;
  className?: string;
}

const DownsamplingInput = ({
  currentValue,
  onChange,
  disabled,
  className,
}: DownsamplingInputProps) => {
  const onKeyDown = (event: KeyboardEvent) =>
    onlyAllowPositiveIntegersOnKeyDown(event);

  const onChangeFilter = (input: string) => removeNonDigitCharacters(input);

  const sharedProps: Partial<InputProps> = {
    fullWidth: false,
    className: "!mx-2 w-20",
    onKeyDown,
    min: 0,
  };

  const downSamplingOptions: RadioSelectOption[] = [
    {
      id: DownsamplingOptions.NONE,
      value: "Do not downsample",
    },
    {
      id: DownsamplingOptions.REST_PERIOD,
      value:
        "Downsample by keeping lonely events and discarding tightly packed events",
      additionalContent: (
        <div className="text-gray-600 text-sm leading-9">
          Keep events which do not have any neighboring occurrence within
          <Input
            testId="seconds-before-input"
            value={currentValue.secondsBefore}
            onChange={({ target: { value } }) =>
              onInputChange("secondsBefore", onChangeFilter(value))
            }
            disabled={currentValue.option !== DownsamplingOptions.REST_PERIOD}
            {...sharedProps}
          />
          seconds before and
          <Input
            testId="seconds-after-input"
            value={currentValue.secondsAfter}
            onChange={({ target: { value } }) =>
              onInputChange("secondsAfter", onChangeFilter(value))
            }
            disabled={currentValue.option !== DownsamplingOptions.REST_PERIOD}
            {...sharedProps}
          />
          seconds after
        </div>
      ),
    },
    {
      id: DownsamplingOptions.OCCURS_AT_LEAST,
      value:
        "Downsample by keeping tightly packed events and discarding lonely events",
      additionalContent: (
        <div className="text-gray-600 text-sm leading-9">
          Keep events which have at least
          <Input
            value={currentValue.occurrences}
            onChange={({ target: { value } }) =>
              onInputChange("occurrences", onChangeFilter(value))
            }
            disabled={
              currentValue.option !== DownsamplingOptions.OCCURS_AT_LEAST
            }
            {...sharedProps}
          />
          neighboring events within
          <Input
            value={currentValue.secondsBefore}
            onChange={({ target: { value } }) =>
              onInputChange("secondsBefore", onChangeFilter(value))
            }
            disabled={
              currentValue.option !== DownsamplingOptions.OCCURS_AT_LEAST
            }
            {...sharedProps}
          />
          seconds before and
          <Input
            value={currentValue.secondsAfter}
            onChange={({ target: { value } }) =>
              onInputChange("secondsAfter", onChangeFilter(value))
            }
            disabled={
              currentValue.option !== DownsamplingOptions.OCCURS_AT_LEAST
            }
            {...sharedProps}
          />
          seconds after
        </div>
      ),
    },
  ];

  const handleOptionChanged = (option: string) => {
    onChange({ ...currentValue, option });
  };

  const onInputChange = (key: string, value: string) => {
    onChange({ ...currentValue, [key]: Number(value) });
  };

  return (
    <FormSection title="Downsampling" className={className}>
      <RadioButtonGroup
        options={downSamplingOptions}
        onSelect={handleOptionChanged}
        selected={currentValue.option}
        disabled={disabled}
      />
    </FormSection>
  );
};

export default DownsamplingInput;
