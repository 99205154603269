export interface TitleProps {
  text: string;
}

const Title = ({ text }: TitleProps) => (
  <h1 data-testid="v-title">
    <span className="text-2xl font-semibold">{text}</span>
  </h1>
);

export default Title;
