import { useEffect, useState } from "react";
import { HiOutlineX } from "react-icons/hi";
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
} from "@mui/material";

import Stepper, { Step } from "features/ui/Stepper";
import { onDataUpdatedCallback } from "features/ui/Stepper/Stepper";

export type ActionType = "create" | "update";

export const getTextForActionType = (action: ActionType): string => {
  const textSaveActions: ActionType[] = ["update", "create"];

  if (textSaveActions.includes(action)) {
    return "Save";
  }

  throw new Error(`ActionType ${action} not implemented!`);
};

const DEFAULT_SIZE: DialogProps["maxWidth"] = "md";

export interface Props {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  steps: Step[];
  step?: number;
  size?: DialogProps["maxWidth"];
  nonLinear?: boolean;
  data?: Record<string, any>;
  onDataUpdated?: onDataUpdatedCallback;
  lastStepActionLabel?: string;
  lastStepCallback?: () => void; // if undefined default will be onClose
  lastStepCallbackIsLoading?: boolean;
  action?: ActionType;
  onlyAllowCurrentStep?: boolean; // if true, considers the given step as the last step (no navigation through steps allowed)
  disableUsingLocalStorage?: boolean;
  closeOnOutsideClick?: boolean;
}

const StepperModal = ({
  title,
  isOpen,
  onClose,
  steps,
  step = 0,
  size = DEFAULT_SIZE,
  nonLinear = true,
  data,
  onDataUpdated,
  lastStepActionLabel,
  lastStepCallback,
  lastStepCallbackIsLoading,
  action,
  onlyAllowCurrentStep,
  disableUsingLocalStorage,
  closeOnOutsideClick = true,
}: Props) => {
  const [activeStep, setActiveStep] = useState(step);

  useEffect(() => {
    setActiveStep(step);
  }, [step, isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={() => closeOnOutsideClick && onClose()}
      maxWidth={size}
      fullWidth
    >
      <DialogTitle className="flex items-center justify-between">
        <span data-testid="stepper-modal-title">{title}</span>
        <IconButton
          onClick={onClose}
          size="small"
          data-testid="stepper-modal-close"
        >
          <HiOutlineX />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stepper
          steps={steps}
          activeStep={activeStep}
          onStepChange={setActiveStep}
          onClose={onClose}
          nonLinear={nonLinear}
          onDataUpdated={onDataUpdated}
          data={data}
          disableUsingLocalStorage={disableUsingLocalStorage}
          lastStepActionLabel={lastStepActionLabel}
          lastStepCallback={lastStepCallback}
          lastStepCallbackIsLoading={lastStepCallbackIsLoading}
          action={action}
          onlyAllowCurrentStep={onlyAllowCurrentStep}
        />
      </DialogContent>
    </Dialog>
  );
};

export default StepperModal;
