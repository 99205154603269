import { useFlags } from "launchdarkly-react-client-sdk";

import { useGetIssueClaimBreakdownByVehicleAge } from "shared/api/issues/hooks";
import { DEFAULT_EXPOSURE } from "shared/constants";
import { useCustomLocalStorageState } from "shared/hooks";
import {
  IssueChart,
  IssueChartGroupBy,
  IssueChartType,
  IssueTypes,
} from "shared/types";
import { getTenantMileageUnit, pluralize } from "shared/utils";

import { useClaimByVehicleAgeExposureOptions } from "pages/hooks";
import GroupByChart from "pages/Issues/Charts/GroupByChart";
import { ClaimsCharts } from "pages/Issues/constants";
import {
  getAxisKeyLabelFromActions,
  getChartOptionsKey,
  getPopulationString,
} from "pages/Issues/utils";

import Card from "features/ui/Card";
import {
  cumulativeCPV,
  cumulativeIPTV,
  xAxis,
  yAxis,
} from "features/ui/charts/actions";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  ChartActionOption,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import {
  getAxisValue,
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";

import * as config from "config/config";

import { useOptionsForAccessors } from "./utils";

const CHART_NAME: IssueChart = ClaimsCharts.TOP_X_BY_VEHICLE_AGE;
const CHART_TYPE: IssueChartType = "VehicleAge";
const GROUP_BY_FIELD: IssueChartGroupBy = "groupByAttributeValue";

const claimBreakdownByVehicleAgeTitleGenerate = (
  yAxis: string,
  groupByAttribute: string,
  xAxisOptions: ChartActionOption[],
  xAxisLabel: string
) => {
  const selectedOption = xAxisOptions.find((o) => o.id === groupByAttribute);
  const selectedOptionTitle = pluralize(
    selectedOption?.value.toString() || "Value",
    5
  );

  if (cumulativeCPV.id === yAxis) {
    return `Top 5 ${selectedOptionTitle} by ${xAxisLabel}`;
  }
  // This is currently the same as above, but I would keep it here if we decide to add
  // different name in the future.
  return `Top 5 ${selectedOptionTitle} by ${xAxisLabel}`;
};

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const TopXByVehicleAgeChart = ({ issue, withComparisonPopulation }: Props) => {
  const { warrantyClaimsCost } = useFlags();

  const exposures = useClaimByVehicleAgeExposureOptions();
  const chartOptionsKey = getChartOptionsKey(CHART_NAME, issue.ID, "v4");

  const {
    pages: { issues },
  } = config.get();
  const legendAccessors = issues?.topXChartsOptions || [
    "laborCode",
    "failedPartNumber",
  ];
  const legendOptions = useOptionsForAccessors(legendAccessors);

  const actions: ChartAction[] = [
    {
      id: "legend",
      title: "Legend",
      type: "dropdownSelect",
      options: legendOptions,
      defaultOptionId: legendAccessors[0],
    },
    {
      id: "x",
      title: xAxis,
      type: "dropdownSelect",
      options: exposures,
      defaultOptionId: DEFAULT_EXPOSURE,
    },
    {
      id: "y",
      title: yAxis,
      type: "dropdownSelect",
      options: [cumulativeIPTV, warrantyClaimsCost && cumulativeCPV].filter(
        Boolean
      ),
    },
  ];

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(actions) });

  const groupByValue = getAxisValue(
    actions,
    "legend",
    getSelectedOptionId(selectedOptions, "legend")
  );

  const { axisKey: xAxisKey, axisValue: xAxisValue } =
    getAxisKeyLabelFromActions(selectedOptions, actions, "x");

  const commonChartProps = {
    issue,
    chartType: CHART_TYPE,
    chart: CHART_NAME,
    dataLoadHook: useGetIssueClaimBreakdownByVehicleAge,
    selectedOptions,
    groupByField: GROUP_BY_FIELD,
    actions,
    syncId: CHART_NAME,
    xAxisLabel: xAxisValue,
    exposure: xAxisKey,
    dataLoadHookParams: {
      byVehicleAgeExposure: xAxisKey,
      mileageUnit: getTenantMileageUnit(),
    },
  };

  const chartTitle = claimBreakdownByVehicleAgeTitleGenerate(
    getSelectedOptionId(selectedOptions, "y"),
    getSelectedOptionId(selectedOptions, "legend"),
    legendOptions,
    xAxisValue
  );

  const atRiskChartTitle = `${chartTitle} : ${getPopulationString("atRisk")}`;
  const comparisonChartTitle = `${chartTitle} : ${getPopulationString(
    "comparison"
  )}`;

  return (
    <Card>
      <div className="space-y-5">
        <div>
          <ChartActionsWrap
            id={CHART_NAME}
            chartTitle={atRiskChartTitle}
            subTitle={groupByValue}
          >
            <ChartActions
              actions={actions}
              selectedOptions={selectedOptions}
              onOptionChange={setSelectedOptions}
            />
          </ChartActionsWrap>
          <GroupByChart {...commonChartProps} population="atRisk" />
        </div>
        {withComparisonPopulation && (
          <div>
            <ChartActionsWrap
              id={CHART_NAME}
              chartTitle={comparisonChartTitle}
              subTitle={groupByValue}
              showActions={false}
              contentClassName="w-[350px]"
            />
            <GroupByChart {...commonChartProps} population="comparison" />
          </div>
        )}
      </div>
    </Card>
  );
};

export default TopXByVehicleAgeChart;
