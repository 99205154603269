import {
  parseSortKeySuffix,
  validateApiRequest,
  validateSortOrder,
} from "duck/graph/nodes/utils";

import {
  listVehiclesCountRequestURI,
  listVehiclesRequestURI,
} from "shared/api/vehicles/api";

/**
 * Validate the filter that is passed in. If the validation fails, an error will be thrown.
 *
 * @param params The parameters contains a vehiclesFilter property.
 * @throws If validation fails, an error will be thrown. The error is unusually wordy
 * because it is intended for consumption by the agent, which benefits from the detail.
 */
export const validateFilter = async (filterStirng: string): Promise<void> => {
  await validateApiRequest(
    { filter: filterStirng, mileageUnit: "mi" },
    listVehiclesCountRequestURI
  );
};

export const validateSort = async (sortString: string): Promise<void> => {
  const [sortKeySuffix, sortOrder] = sortString.split("=");
  validateSortOrder(sortOrder);

  const fieldName = parseSortKeySuffix(sortKeySuffix);

  await validateApiRequest(
    { sort: `${fieldName}:${sortOrder}`, mileageUnit: "mi", limit: 1 },
    listVehiclesRequestURI
  );
};
