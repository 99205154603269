import React from "react";
import classNames from "classnames";
import { Paper } from "@mui/material";

import { TestProps } from "shared/types";

export interface CardProps extends TestProps {
  children?: React.ReactNode;
  classNames?: string;
}

/**
 * Card is a common flexbox container component
 */
const Card = ({ children, classNames: cNames, testId }: CardProps) => (
  <Paper
    elevation={2}
    children={children}
    className={classNames("py-3 px-6 !text-gray-700", cNames)}
    data-testid={testId}
  />
);

export default Card;
