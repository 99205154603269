import { useEffect, useState } from "react";
import { BsChevronUp as ChevronUpIcon } from "react-icons/bs";
import {
  FiCopy as CopyIcon,
  FiTrash2 as DeleteRowIcon,
  FiEdit2 as EditRowIcon,
  FiPlus as NewRowIcon,
} from "react-icons/fi";
import { IconButton } from "@mui/material";

import { CUSTOM_ATTRIBUTE_FUNCTION_OPTIONS } from "pages/CustomSignalEvents/constants";
import {
  getInputConfiguration,
  isCustomAttributeComplete,
} from "pages/CustomSignalEvents/utils";

import DropdownSelect from "features/ui/DropdownSelect";
import Input from "features/ui/Input/Input";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import { CustomAttribute } from "./CustomAttributeSelect";
import FunctionInput from "./FunctionInput/FunctionInput";

interface Props {
  attribute: CustomAttribute;
  onChange: (attribute: CustomAttribute) => void;
  onAddAttribute: () => void;
  onCopyAttribute: () => void;
  onDeleteAttribute: () => void;
  onAttributeIncomplete: () => void;
  baseEventType: string;
  baseEventTypeSchema: SchemaEntry<string>[] | undefined;
  previousCustomAttributes: CustomAttribute[];
  disabled?: boolean;
}

const dropdownLabel = (selectedOptionID?: string): string =>
  (CUSTOM_ATTRIBUTE_FUNCTION_OPTIONS.find(
    (option) => option.id === selectedOptionID
  )?.value as string) || "Select function";

const CustomAttributeForm = ({
  attribute,
  onChange,
  onAddAttribute,
  onCopyAttribute,
  onDeleteAttribute,
  onAttributeIncomplete,
  baseEventType,
  baseEventTypeSchema,
  previousCustomAttributes,
  disabled,
}: Props) => {
  const [open, setOpen] = useState(true);

  const combinedSchema = [
    ...previousCustomAttributes.map((attribute: CustomAttribute) => ({
      label: attribute.label,
      accessor: attribute.id,
      dataType: "number" as DataType,
    })),
    ...(baseEventTypeSchema || []),
  ];

  const isComplete = isCustomAttributeComplete(attribute);

  useEffect(() => {
    if (!isComplete) {
      onAttributeIncomplete();
    }
  }, [isComplete, onAttributeIncomplete]);

  return (
    <div className="flex mb-4">
      <Input
        label="Label"
        value={attribute.label}
        onChange={({ target: { value } }) =>
          onChange({ ...attribute, label: value })
        }
        fullWidth={false}
        className="!mr-2 w-48 flex-shrink-0"
        disabled={disabled}
      />
      <div className="w-full">
        <div className="flex">
          <div className="flex space-x-2">
            <DropdownSelect
              options={CUSTOM_ATTRIBUTE_FUNCTION_OPTIONS}
              label={dropdownLabel(attribute.type)}
              onSelect={(selectedOption) => {
                onChange({ ...attribute, type: selectedOption.id as string });
              }}
              buttonClass="!py-[7px] text-left"
              disabled={disabled}
            />
            {attribute.type && (
              <IconButton
                onClick={() => setOpen(!open)}
                size="small"
                data-testid="custom-attribute-edit-row"
                className="w-10"
                disabled={disabled}
              >
                {open ? (
                  <ChevronUpIcon title="collapse row" />
                ) : (
                  <EditRowIcon title="edit row" />
                )}
              </IconButton>
            )}
          </div>
          <div
            className="ml-8 flex flex-nowrap self-center space-x-1"
            data-testid="filter-row-actions"
          >
            <IconButton
              onClick={onAddAttribute}
              size="small"
              data-testid="custom-attribute-add-row"
              disabled={disabled}
            >
              <NewRowIcon />
            </IconButton>
            <IconButton
              onClick={onCopyAttribute}
              size="small"
              data-testid="custom-attribute-copy-row"
              disabled={disabled}
            >
              <CopyIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                onAttributeIncomplete();
                onDeleteAttribute();
              }}
              size="small"
              data-testid="custom-attribute-delete-row"
              disabled={disabled}
            >
              <DeleteRowIcon />
            </IconButton>
          </div>
        </div>
        {open && attribute.type && (
          <div className="flex flex-col space-y-2 mt-4">
            {getInputConfiguration(attribute.type).map((config) => (
              <FunctionInput
                key={config.parameter}
                attribute={attribute}
                onChange={onChange}
                config={config}
                baseEventType={baseEventType}
                fieldSchema={combinedSchema}
                disabled={disabled}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomAttributeForm;
