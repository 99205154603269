import { APIFilter } from "shared/api/utils";
import { useFailureModeVehiclesCount } from "shared/api/v0_failureModes/hooks";
import { cloneObject, getTenantMileageUnit } from "shared/utils";

import { getFailureModePredictionAccessor } from "pages/V0_Vehicles/utils";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import {
  getFilterGroupStateTopLevelRowAttributes,
  getFiltersQuery,
  removeAttributesFromFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";

import { RiskDistributionProps } from "./RiskDistribution";

const riskGroupFilter = (name: string, value: string): APIFilter[] => [
  {
    name,
    op: FilterOperator.IN,
    value,
  },
];

export const useRisks = ({ filters, failureModeID }: RiskDistributionProps) => {
  const riskGroupAccessor = getFailureModePredictionAccessor(
    failureModeID,
    "riskGroup"
  );

  let filtersWithoutRiskGroup =
    cloneObject(filters) || DEFAULT_FILTER_BUILDER_STATE;
  if (
    filters &&
    getFilterGroupStateTopLevelRowAttributes(filters).includes(
      riskGroupAccessor
    )
  ) {
    filtersWithoutRiskGroup = removeAttributesFromFilterGroupState(filters, [
      riskGroupAccessor,
    ]);
  }

  const { data: dataLow, isLoading: isLoadingLow } =
    useFailureModeVehiclesCount({
      id: failureModeID,
      filter: getFiltersQuery(
        filtersWithoutRiskGroup,
        riskGroupFilter(riskGroupAccessor, "low")
      ),
      mileageUnit: getTenantMileageUnit(),
    });
  const { data: dataMedium, isLoading: isLoadingMedium } =
    useFailureModeVehiclesCount({
      id: failureModeID,
      filter: getFiltersQuery(
        filtersWithoutRiskGroup,
        riskGroupFilter(riskGroupAccessor, "medium")
      ),
      mileageUnit: getTenantMileageUnit(),
    });
  const { data: dataHigh, isLoading: isLoadingHigh } =
    useFailureModeVehiclesCount({
      id: failureModeID,
      filter: getFiltersQuery(
        filtersWithoutRiskGroup,
        riskGroupFilter(riskGroupAccessor, "high")
      ),
      mileageUnit: getTenantMileageUnit(),
    });

  return {
    riskCounts: {
      high: dataHigh?.count,
      medium: dataMedium?.count,
      low: dataLow?.count,
    },
    isLoading: isLoadingLow || isLoadingMedium || isLoadingHigh,
  };
};
