import { useState } from "react";
import classNames from "classnames";
import { FaRegEye as EyeIcon } from "react-icons/fa6";
import { IconButton, Popover } from "@mui/material";

import Card from "features/ui/Card";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import Tooltip from "features/ui/Tooltip";

import WatchlistCreateAlertForm from "./WatchlistCreateAlertForm";

const ICON_SIZE = 22;
const TOOLTIP_TEXT = "Add to watchlist";
const ELEMENT_ID = "watchlist-create-alert-action";

interface Props {
  eventType: string;
  eventFilter: FilterGroupState;
}

const WatchlistCreateAlertAction = ({ eventType, eventFilter }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const tooltipContent = <div className="text-xs">{TOOLTIP_TEXT}</div>;

  return (
    <>
      <Tooltip content={tooltipContent} placement="bottom-end">
        <IconButton
          onClick={handleClick}
          data-testid="create-bookmark-action"
          size="small"
          className={classNames({ "!text-blue-500": isOpen })}
        >
          <EyeIcon size={ICON_SIZE} />
        </IconButton>
      </Tooltip>
      <Popover
        id={ELEMENT_ID}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Card>
          <WatchlistCreateAlertForm
            eventType={eventType}
            eventFilter={eventFilter}
            onClose={handleClose}
          ></WatchlistCreateAlertForm>
        </Card>
      </Popover>
    </>
  );
};

export default WatchlistCreateAlertAction;
