import Skeleton from "react-loading-skeleton";

import {
  useListSimilarVehicleFactors,
  useVehicle,
} from "shared/api/vehicles/hooks";
import { formatPercent, getTenantMileageUnit } from "shared/utils";

import Card from "features/ui/Card";
import InfoIcon from "features/ui/Icons/Info";

import SimilarityModalFactorCard from "./SimilarityModalFactorCard";

interface Props {
  currentVIN: string;
  similarVIN: string;
  failureModeID: string;
  failureModeName: string;
  similarity: number;
}

const SimilarityModalDetails = ({
  currentVIN,
  similarVIN,
  failureModeID,
  failureModeName,
  similarity,
}: Props) => {
  const { data: vehicle } = useVehicle({
    vin: similarVIN,
    mileageUnit: getTenantMileageUnit(),
  });
  const {
    data: { factors } = {},
    isLoading,
    error,
  } = useListSimilarVehicleFactors({
    failureModeID,
    currentVIN,
    similarVIN,
  });

  const similarFactorsText = `The combination of factors below are strongly predictive of
    ${failureModeName} failures. 
    Blue bars show the percentile of the factor value for the Current VIN relative to the population average.
    Grey bars show the percentile of the factor value for the Similar Failed VIN relative to the population average.
    Similar factors helps interpret, explain, and communicate vehicle risk.
  `;

  return (
    <div className="flex-1">
      <h4>
        <span className="font-bold text-gray-500">{similarVIN}</span>
        <span> - </span>
        <span>{formatPercent(similarity)} Match</span>
      </h4>
      <div>
        <h5 className="text-lg text-gray-600 col-span-3 my-3">
          Similar Vehicle
        </h5>
      </div>
      <Card classNames="text-sm">
        <div className="flex flex-row flex-nowrap">
          <span className="text-gray-500 w-24">Make</span>
          <span className="ml-2 flex-1">{vehicle?.vehicleMake || "-"}</span>
        </div>
        <div className="flex flex-row flex-nowrap">
          <span className="text-gray-500 w-24">Model</span>
          <span className="ml-2 flex-1">{vehicle?.vehicleModel || "-"}</span>
        </div>
        <div className="flex flex-row flex-nowrap">
          <span className="text-gray-500 w-24">Model Year</span>
          <span className="ml-2 flex-1">
            {vehicle?.vehicleModelYear || "-"}
          </span>
        </div>
        <div className="flex flex-row flex-nowrap">
          <span className="text-gray-500 w-24">Engine</span>
          <span className="ml-2 flex-1">{vehicle?.engineModel || "-"}</span>
        </div>
        <div className="flex flex-row flex-nowrap">
          <span className="text-gray-500 w-24">Fleet</span>
          <span className="ml-2 flex-1">{vehicle?.fleet || "-"}</span>
        </div>
      </Card>
      <div>
        <h5 className="text-lg text-gray-600 col-span-3 my-3">
          Similar Factors &nbsp;
          <InfoIcon text={similarFactorsText} />
        </h5>
      </div>
      {isLoading && <Skeleton height={128} className="mb-3" count={3} />}
      <div className="grid grid-cols-2 gap-4">
        {factors?.map((factor) => (
          <SimilarityModalFactorCard {...factor} key={factor.name} />
        ))}
        {error && <span className="text-gray-400">No factors available.</span>}
      </div>
      {factors && (
        <div className="mt-4 w-full flex flex-row flex-nowrap justify-end items-center space-x-2">
          <div className="flex flex-row flex-nowrap items-center">
            <span className="h-1 w-6 mr-2 rounded bg-blue-300" />
            <span>Current VIN</span>
          </div>
          <div className="flex flex-row flex-nowrap items-center">
            <span className="h-1 w-6 mr-2 rounded bg-gray-600" />
            <span>Similar VIN</span>
          </div>
          <div className="flex flex-row flex-nowrap items-center">
            <span className="h-1 w-6 mr-2 rounded bg-green-300" />
            <span>Average in Population</span>
          </div>
        </div>
      )}
    </div>
  );
};
export default SimilarityModalDetails;
