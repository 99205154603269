import { useListSimilarVehicles } from "shared/api/failureModes/hooks";

interface Props {
  currentVin: string;
  failureModeId: string;
  onClick: (failureModeId: string) => void;
}

const SimilarVehicleColumn = ({
  currentVin,
  failureModeId,
  onClick,
}: Props) => {
  const { data = [] } = useListSimilarVehicles({
    currentVIN: currentVin,
    failureModeID: failureModeId,
  });

  const handleClick = () => {
    if (data.length > 0) {
      onClick(failureModeId);
    }
  };

  return (
    <div className="flex-1 w-48 truncate cursor-pointer" onClick={handleClick}>
      {data.length > 0 && "View"}
    </div>
  );
};

export default SimilarVehicleColumn;
