import { useState } from "react";

import { EventTypeEnum } from "shared/types";

import { TOP_CONTRIBUTORS_TAB_KEY } from "pages/constants";
import {
  getDefaultByGroupBySelectOption,
  useGroupBy,
  useTopContributorsChartSettings,
} from "pages/hooks";
import TopContributorsGroupBySelector from "pages/shared/TopContributorsGroupBySelector";
import { SignalEventsAnalyticsProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";

import TopChart from "./TopChart";
import TopContributorsTable from "./TopContributorsTable";

export const DEFAULT_GROUP_BY_ATTRIBUTE = "signalEventID";

export const SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY =
  "signalEventsAnalyticsTopContributorsChartOptions";
// We manage the state of the group by options with a separate key from the rest of
// the chart options because they are not part of the chart options, and are set through
// a different mechanism.
export const SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY =
  "signalEventsAnalyticsTopContributorsGroupByOptions";

const TopContributors = ({
  signalEventsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
}: SignalEventsAnalyticsProps) => {
  const {
    actions,
    selectedOptions,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
    handleSelectedOptionsChange,
  } = useTopContributorsChartSettings(
    signalEventsFiltersFilterSortState,
    SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
    EventTypeEnum.SIGNAL_EVENT
  );

  const signalEventsFilters = signalEventsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  const {
    groupBySelectOptions,
    selectedGroupByAttribute,
    handleGroupByAttributeChange,
  } = useGroupBy(
    EventTypeEnum.SIGNAL_EVENT,
    signalEventsFiltersFilterSortState,
    DEFAULT_GROUP_BY_ATTRIBUTE,
    SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
    TOP_CONTRIBUTORS_TAB_KEY
  );

  const [generalFilters, setGeneralFilters] = useState<
    FilterGroupState | undefined
  >();

  const resetState = () => {
    handleGroupByAttributeChange(
      getDefaultByGroupBySelectOption(
        groupBySelectOptions,
        DEFAULT_GROUP_BY_ATTRIBUTE
      )
    );
    signalEventsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <TopContributorsGroupBySelector
        groupBySelectOptions={groupBySelectOptions}
        selectedGroupByAttribute={selectedGroupByAttribute}
        setSelectedGroupByAttribute={handleGroupByAttributeChange}
      />
      <TopChart
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        signalEventsFilters={signalEventsFilters}
        filters={generalFilters}
        onSignalEventsFiltersChange={
          signalEventsFiltersFilterSortState?.manageFilterChange
        }
        onVehiclesFiltersChange={
          vehiclesFiltersFilterSortState?.manageFilterChange
        }
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleSelectedOptionsChange}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
        onBadRequest={resetState}
      />
      <TopContributorsTable
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        signalEventsFilters={signalEventsFilters}
        onFiltersUpdated={setGeneralFilters}
        onBadRequest={resetState}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
      />
    </>
  );
};

export default TopContributors;
