import { useContext } from "react";

import { VehicleOptionsAttributeContext } from "shared/contexts/VehicleOptionsAttributesContextWrapper";
import { VEHICLES_OPTIONS_GENERIC_FILTER } from "shared/filterDefinitions";

import { UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

const useVehicleOptionsSchema = (): UseSchema => {
  const { attributes } = useContext(VehicleOptionsAttributeContext);

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(
    VEHICLES_OPTIONS_GENERIC_FILTER,
    attributeMap
  );

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const schema = getSchemaFromAttributes(attributes, getSchemaEntry);

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    getDisplayLabel,
    attributeMap,
    attributes,
    getSchemaEntry,
    getSchemaForAccessor,
  };
};
export default useVehicleOptionsSchema;
