interface Props {
  question: string;
  text: string;
  onTextChange: (text: string) => void;
}

const FeedbackQuestion = ({ question, text, onTextChange }: Props) => (
  <div className="flex flex-col items-center transition duration-50 rounded w-full h-60 bg-white">
    <div className="rounded-t w-full p-2 bg-white text-left">{question}</div>
    <hr className="w-11/12" />
    <textarea
      placeholder="Share your feedback"
      value={text}
      onChange={(e) => onTextChange(e.target.value)}
      className="flex-grow rounded-b w-full h-full pt-4 p-2 focus:outline-none bg-white"
    />
  </div>
);

export default FeedbackQuestion;
