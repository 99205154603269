import { useLocation } from "react-router-dom";

import StatefulTabs from "features/ui/StatefulTabs";

import { routes } from "services/routes";

import {
  ISSUES_TAB_KEY,
  ISSUES_TAB_TITLE,
  SUGGESTED_ISSUES_TAB_KEY,
  SUGGESTED_ISSUES_TAB_TITLE,
} from "./constants";
import IssuesPage from "./IssuesPage";
import SuggestedIssuesPage from "./SuggestedIssuesPage";

const IssuesTabs = () => {
  const { pathname } = useLocation();
  const defaultTab = pathname === routes.suggestedIssues ? 1 : 0;

  const tabs = [
    {
      key: ISSUES_TAB_KEY,
      title: ISSUES_TAB_TITLE,
      content: (
        <div className="mt-4">
          <IssuesPage />
        </div>
      ),
      testId: "issues_tab",
    },
    {
      key: SUGGESTED_ISSUES_TAB_KEY,
      title: SUGGESTED_ISSUES_TAB_TITLE,
      content: (
        <div className="mt-4">
          <SuggestedIssuesPage />
        </div>
      ),
      testId: "suggested_issues_tab",
    },
  ];

  return <StatefulTabs tabs={tabs} defaultTab={defaultTab} />;
};

export default IssuesTabs;
