import qs from "qs";

import { CountResponse } from "shared/api/api";
import { UseAPIState } from "shared/api/hooks";
import { CountRequestWithVehiclesFilter } from "shared/api/vehicles/api";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { getFiltersKey } from "features/ui/Filters/utils";
import TableCount from "features/ui/Table/TableCount";

interface Props {
  filterState?: FilterGroupState;
  vehicleFilterState?: FilterGroupState;
  entityName: string;
  pageKey?: string;
  vehiclesPageKey?: string;
  dataFetchHook: (
    args: CountRequestWithVehiclesFilter
  ) => UseAPIState<CountResponse>;
  route?: string;
}

const CountWithLink = ({
  filterState,
  entityName,
  pageKey,
  vehiclesPageKey,
  vehicleFilterState,
  dataFetchHook,
  route,
}: Props) => {
  const filterQuery = getFiltersQuery(filterState);
  const vehiclesFilterQuery = getFiltersQuery(vehicleFilterState);

  const { isLoading, data, error } = dataFetchHook({
    filter: filterQuery,
    vehiclesFilter: vehiclesFilterQuery,
    analytics: true,
  });

  return (
    <TableCount
      count={data?.count}
      isLoading={isLoading}
      error={!!error}
      entityName={entityName}
      extraClasses="font-normal mb-0.5"
      link={
        route && pageKey && vehiclesPageKey
          ? {
              pathname: route,
              search: qs.stringify(
                {
                  [getFiltersKey(pageKey)]: filterQuery,
                  [getFiltersKey(vehiclesPageKey)]: vehiclesFilterQuery,
                },
                { arrayFormat: "indices" }
              ),
            }
          : undefined
      }
    />
  );
};

export default CountWithLink;
