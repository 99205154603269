import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import {
  CartesianGrid,
  LineChart as LC,
  Legend,
  Line,
  LineProps,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from "recharts";

import { areArraysEqual, isNumeric } from "shared/utils";

import { DEFAULT_R_AXIS_WIDTH } from "pages/VINView/Events/constants";

import {
  AXIS_TEXT_COLOR,
  AXIS_TOOLTIP_FONT_SIZE,
  DEFAULT_Y_AXIS_ID,
  DEFAULT_Y_AXIS_ID_R,
  LABEL_COLOR,
  LINE_COLOR,
  REFERENCE_LINE_COLOR,
  REFERENCE_LINE_OPACITY,
  REFERENCE_LINE_WIDTH,
  TIMESTAMP_X_AXIS_KEY,
} from "features/ui/charts/constants";
import {
  DataElement,
  MarginProps,
  ReferenceElement,
  YAxisLine,
  ZoomProps,
  ZoomXState,
} from "features/ui/charts/types";
import {
  formatXAxisProps,
  getYAxisDomainDefault,
  LineChartXAxisProps,
} from "features/ui/charts/utils";

import { useZoom } from "./hooks";
import LineChartLegend from "./LineChartLegend";

export interface LineChartProps {
  data?: DataElement[];
  yAxisLines: YAxisLine[];
  xAxisKey: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  yAxisLabelRight?: string;
  references?: ReferenceElement[];
  height: number | string;
  width: number | string;
  yAxisProps?: YAxisProps;
  xAxisProps?: LineChartXAxisProps;
  tooltipProps?: TooltipProps<any, any>;
  margin?: MarginProps;
  hoveredLine?: string;
  connectNulls?: boolean;
  syncId?: number | string;
  keepYAxisDomainOnZoom?: boolean;
  hideLegend?: boolean;
  coloredYAxisLabels?: boolean;
  toggleableLines?: boolean;
}

const getOpacityForLine = (yAxisLine: YAxisLine, hoveredLine?: string) =>
  yAxisLine.alwaysFullOpacity || !hoveredLine || hoveredLine === yAxisLine.key
    ? 1
    : 0.3;

const DEFAULT_LINE_TYPE: LineProps["type"] = "monotone";
const DOT_STROKE_WIDTH = 3;

const LineChart = (lineChartData: LineChartProps & ZoomProps) => {
  const [cursorInsideChart, setCursorInsideChart] = useState(false);
  const {
    data,
    yAxisLines,
    xAxisKey,
    xAxisLabel,
    yAxisLabel,
    yAxisLabelRight,
    references,
    height = 300,
    width = "100%",
    yAxisProps,
    xAxisProps,
    tooltipProps,
    margin = {},
    hoveredLine,
    connectNulls = false,
    syncId,
    enableZoom = false,
    keepYAxisDomainOnZoom = false,
    hideLegend = false,
    onMouseMove,
    cursorX,
    zoomReferenceAreaOverride,
    hideZoomOutControl = false,
    coloredYAxisLabels = false,
    toggleableLines = false,
    onZoom,
    onZoomOut,
  } = lineChartData;

  const [shownLineKeys, setShownLineKeys] = useState<string[]>(
    yAxisLines.map((x) => x.key)
  );

  useEffect(() => {
    if (
      !areArraysEqual(
        shownLineKeys,
        yAxisLines.map(({ key }) => key)
      )
    ) {
      setShownLineKeys(yAxisLines.map(({ key }) => key));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yAxisLines]);

  const minXVal =
    data && data.length > 0 && xAxisKey === TIMESTAMP_X_AXIS_KEY
      ? data[0][TIMESTAMP_X_AXIS_KEY]
      : undefined;
  const maxXVal =
    data && data.length > 0 && xAxisKey === TIMESTAMP_X_AXIS_KEY
      ? data[data.length - 1][TIMESTAMP_X_AXIS_KEY]
      : undefined;

  const [xValues, setXValues] = useState({ minX: minXVal, maxX: maxXVal });

  useEffect(() => {
    if (xAxisKey !== TIMESTAMP_X_AXIS_KEY) {
      return;
    }

    const timestamps =
      (data?.map((x) => x[TIMESTAMP_X_AXIS_KEY]) as number[]) || [];
    setXValues({
      minX: Math.min(...timestamps),
      maxX: Math.max(...timestamps),
    });
  }, [data, xAxisKey]);

  const xAxisPropsWithTicks: XAxisProps | undefined = useMemo(
    () =>
      formatXAxisProps(xAxisKey, data, xAxisProps, xValues.minX, xValues.maxX),
    [data, xAxisKey, xAxisProps, xValues.maxX, xValues.minX]
  );

  const handleOnZoom = (e: ZoomXState) => {
    const { left, right } = e;
    setXValues({ minX: left, maxX: right });
    onZoom && onZoom(e);
  };

  const handleOnZoomOut = () => {
    setXValues({ minX: minXVal, maxX: maxXVal });
    onZoomOut && onZoomOut();
  };

  const {
    isZoomedIn,
    handleZoom,
    handleMouseDown,
    handleMouseMove: handleMouseMoveZoom,
    zoomReferenceArea,
    zoomYAxisDomain,
    zoomXAxisDomain,
    resetZoomButton,
  } = useZoom({
    ...lineChartData,
    zoomOutControlMarginSide: yAxisLabelRight
      ? DEFAULT_R_AXIS_WIDTH
      : undefined,
    onZoom: handleOnZoom,
    onZoomOut: handleOnZoomOut,
  });

  const handleMouseMove = (e?: any) => {
    onMouseMove && onMouseMove(e);
    handleMouseMoveZoom && handleMouseMoveZoom(e);
    setCursorInsideChart(true);
  };

  const [activeLabel, setActiveLabel] = useState("");

  const defaultYAxisDomain = getYAxisDomainDefault(
    yAxisLines.length > 0 ? yAxisLines[0] : undefined,
    data
  );
  const defaultYAxisDomainR = getYAxisDomainDefault(
    yAxisLines.length > 1 ? yAxisLines[1] : undefined,
    data
  );

  const yAxisDomain =
    enableZoom && isZoomedIn && !keepYAxisDomainOnZoom
      ? zoomYAxisDomain
      : yAxisProps?.domain
        ? yAxisProps.domain
        : defaultYAxisDomain;

  const yAxisDomainR =
    enableZoom && isZoomedIn && !keepYAxisDomainOnZoom
      ? zoomYAxisDomain
      : yAxisProps?.domain
        ? yAxisProps.domain
        : defaultYAxisDomainR;

  const xAxisDomain =
    enableZoom && isZoomedIn
      ? zoomXAxisDomain
      : xAxisProps?.domain
        ? xAxisProps.domain
        : ["auto", "auto"];

  const isCategorical = yAxisProps?.type === "category";

  const showZoomResetButton = enableZoom && isZoomedIn && !hideZoomOutControl;

  return (
    <div className="relative w-full">
      {showZoomResetButton && resetZoomButton}
      <ResponsiveContainer height={height} width={width}>
        <LC
          className={classNames({
            "select-none": enableZoom,
          })}
          data={data}
          margin={{
            top: margin.top || 5,
            right: margin.right || (yAxisLabelRight ? 30 : 0),
            bottom: margin.bottom || 15,
            left: margin.left || (xAxisLabel ? 30 : 0),
          }}
          syncId={syncId}
          syncMethod="value"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleZoom}
          onMouseEnter={() => setCursorInsideChart(true)}
          onMouseLeave={() => setCursorInsideChart(false)}
        >
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} />
          {yAxisLines.map((yAxisLine) => {
            const {
              label,
              key,
              color,
              width,
              dashed,
              dot,
              yAxisId,
              type,
              data,
              dataKey,
            } = yAxisLine;
            return (
              <Line
                type={type || DEFAULT_LINE_TYPE}
                yAxisId={yAxisId || DEFAULT_Y_AXIS_ID}
                name={label}
                key={key}
                dataKey={dataKey || key}
                stroke={color || LINE_COLOR}
                strokeWidth={width || 1}
                visibility={shownLineKeys.includes(key) ? "visible" : "hidden"}
                strokeDasharray={dashed ? "2 2" : undefined}
                isAnimationActive={false}
                opacity={getOpacityForLine(yAxisLine, hoveredLine)}
                connectNulls={connectNulls}
                activeDot={{
                  onMouseOver: () => setActiveLabel(label),
                  visibility: shownLineKeys.includes(key)
                    ? "visible"
                    : "hidden",
                }}
                dot={
                  dot
                    ? {
                        stroke: color || LINE_COLOR,
                        strokeWidth: DOT_STROKE_WIDTH,
                        visibility: shownLineKeys.includes(key)
                          ? "visible"
                          : "hidden",
                        r: 1,
                        strokeDasharray: "",
                      }
                    : false
                }
                data={data}
              />
            );
          })}
          {zoomReferenceAreaOverride || zoomReferenceArea}

          {cursorX && (
            <line
              x1={cursorX}
              x2={cursorX}
              y1={0}
              y2={isNumeric(height) ? (height as number) - 40 : height}
              stroke="#ccc"
              strokeDasharray="5 5"
            />
          )}
          {references?.map(
            ({ xAxisValue, color, label, ...otherProps }, refI) => (
              <React.Fragment key={refI}>
                (
                <ReferenceLine
                  x={xAxisValue}
                  stroke={color || REFERENCE_LINE_COLOR}
                  yAxisId={DEFAULT_Y_AXIS_ID}
                  strokeWidth={REFERENCE_LINE_WIDTH}
                  opacity={REFERENCE_LINE_OPACITY}
                  {...otherProps}
                />
                ) (
                <Line
                  name={label}
                  dataKey={`${label}-${refI}`}
                  stroke={color || REFERENCE_LINE_COLOR}
                  yAxisId={DEFAULT_Y_AXIS_ID}
                />
                )
              </React.Fragment>
            )
          )}
          <YAxis
            key={yAxisLabel}
            scale={!isCategorical ? "sequential" : undefined}
            tick={{ fill: AXIS_TEXT_COLOR, fontSize: AXIS_TOOLTIP_FONT_SIZE }}
            orientation="left"
            label={{
              value: yAxisLabel,
              angle: -90,
              position: "outsideLeft",
              textAnchor: "middle",
              fill:
                coloredYAxisLabels &&
                yAxisLines.length > 0 &&
                yAxisLines[0].color
                  ? yAxisLines[0].color
                  : LABEL_COLOR,
              dx: -30,
            }}
            {...yAxisProps}
            domain={!isCategorical ? yAxisDomain : undefined}
            yAxisId={DEFAULT_Y_AXIS_ID}
          />
          {yAxisLabelRight && (
            <YAxis
              key={yAxisLabelRight}
              scale="sequential"
              width={40}
              tick={{
                fill: AXIS_TEXT_COLOR,
                fontSize: AXIS_TOOLTIP_FONT_SIZE,
              }}
              type="number"
              label={{
                value: yAxisLabelRight,
                angle: -90,
                position: "outsideRight",
                textAnchor: "middle",
                fill:
                  coloredYAxisLabels &&
                  yAxisLines.length > 1 &&
                  yAxisLines[1].color
                    ? yAxisLines[1].color
                    : LABEL_COLOR,
                dx: 30,
              }}
              {...yAxisProps}
              domain={yAxisDomainR}
              yAxisId={DEFAULT_Y_AXIS_ID_R}
              orientation="right"
            />
          )}
          <XAxis
            label={
              xAxisLabel
                ? {
                    value: xAxisLabel,
                    position: "middle",
                    dy: 15,
                    fill: LABEL_COLOR,
                  }
                : undefined
            }
            tick={{ fill: AXIS_TEXT_COLOR, fontSize: AXIS_TOOLTIP_FONT_SIZE }}
            type="number"
            dataKey={xAxisKey}
            minTickGap={20}
            allowDataOverflow={isZoomedIn}
            {...xAxisPropsWithTicks}
            scale={xAxisProps?.scale || "sequential"}
            domain={xAxisDomain}
          />
          {yAxisLines.length > 0 && !hideLegend && (
            <Legend
              content={
                <LineChartLegend
                  toggleableLines={toggleableLines}
                  shownLineKeys={shownLineKeys}
                />
              }
              onClick={({ dataKey }) => {
                const key = dataKey as string;
                const newShownKeys = shownLineKeys.includes(key)
                  ? shownLineKeys.filter((x) => x !== key)
                  : [...shownLineKeys, key];
                setShownLineKeys(newShownKeys);
              }}
              align="left"
              verticalAlign="top"
              wrapperStyle={{ top: 0, paddingBottom: 5 }}
            />
          )}
          <Tooltip
            contentStyle={{ fontSize: AXIS_TOOLTIP_FONT_SIZE }}
            labelStyle={{ fontSize: AXIS_TOOLTIP_FONT_SIZE }}
            {...tooltipProps}
            cursor={false}
            isAnimationActive={false}
            content={
              tooltipProps?.content
                ? (payload) =>
                    customTooltipComponentWithActiveLabel(
                      tooltipProps.content as React.ElementType,
                      payload,
                      activeLabel
                    )
                : undefined
            }
            // when user goes outside the chart, we hide the tooltip, otherwise we let recharts handle it
            active={cursorInsideChart ? undefined : false}
          />
        </LC>
      </ResponsiveContainer>
    </div>
  );
};

const customTooltipComponentWithActiveLabel = (
  content: React.ElementType,
  payload: any,
  activeLabel: number | string
) => {
  const TooltipComponent = content;
  return <TooltipComponent activeLabel={activeLabel} {...payload} />;
};

export default LineChart;
