import { FiHome as HomeIcon } from "react-icons/fi";
import {
  BreadcrumbComponentType,
  BreadcrumbMatch,
} from "use-react-router-breadcrumbs";

export const NEW_RESOURCE_DEFAULT_BREADCRUMB_TEXT = "New";

interface UrlDecodedProps {
  match: BreadcrumbMatch;
}

export const UrlDecodedFleet = ({ match }: UrlDecodedProps) => (
  <>{decodeURIComponent(match.params.fleetName || "")}</>
);

export const UrlDecodedVIN = ({ match }: UrlDecodedProps) => (
  <>{decodeURIComponent(match.params.vin || "")}</>
);

export const Home: BreadcrumbComponentType<any> = () => <HomeIcon />;
