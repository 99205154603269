import { ServiceRecord } from "shared/api/serviceRecords/api";
import { EventTypeEnum } from "shared/types";

import ClaimDetail from "./ClaimDetail";
import CustomRecordDetail from "./CustomRecordDetail";
import GenericDetail from "./GenericDetail";

export type RecordProps = {
  serviceRecord: ServiceRecord;
  onClose?: () => void;
};

const EventDetail = ({ serviceRecord, onClose }: RecordProps) => {
  switch (serviceRecord.sourceType) {
    case EventTypeEnum.CLAIM:
      return <ClaimDetail serviceRecord={serviceRecord} onClose={onClose} />;
    case EventTypeEnum.INSPECTION:
    case EventTypeEnum.REPAIR:
      return <GenericDetail serviceRecord={serviceRecord} onClose={onClose} />;
    default:
      return (
        <CustomRecordDetail serviceRecord={serviceRecord} onClose={onClose} />
      );
  }
};

export default EventDetail;
