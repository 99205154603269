import { promptNames } from "duck/graph/nodes/types";
import { AgentNodes, getAgentNodes } from "duck/graph/nodes/utils";
import { DuckGraphParams } from "duck/graph/types";
import { NodeNames } from "duck/graph/utils";

import getTools from "./tools/getTools";

const getNodes = (params: DuckGraphParams): Promise<AgentNodes> =>
  // We deliberately do not send a route value to getAgentNodes because
  // we do not yet know what the route would be because we don't have
  // an issue ID yet. Instead, we will set the route value later, after the agent
  // has provided an issue ID. The agent will be able to get the issue ID from various sources,
  // including the pathname,  the user's utterance, message history, or searching the vectorstore.
  getAgentNodes({
    params,
    getTools,
    promptName: promptNames.ISSUE_DETAILS_AGENT,
    name: NodeNames.ISSUE_DETAILS,
  });

export default getNodes;
