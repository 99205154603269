import { Link } from "react-router-dom";

import { articles } from "./constants";

const HelpArticles = () => (
  <div className="pr-8 pl-4">
    <p>
      A collection of bite-sized articles to help Viaduct users with common
      tasks.
    </p>
    <div className="pt-2 pl-5">
      <ul className="list-disc" data-testid="help-articles-list">
        {articles.map(({ title, url }) => (
          <li key={url} className="m-1">
            <Link
              to={url}
              target="_blank"
              className="text-metabase-blue hover:underline"
            >
              {title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default HelpArticles;
