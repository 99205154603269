import FailureModeEventsTable from "pages/FailureModes/FailureMode/EventsTab/EventsTable";
import EventsTimeline from "pages/FailureModes/FailureMode/EventsTab/EventsTimeline";
import VehicleFilters from "pages/Vehicles/VehicleFilters";

import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";

interface Props {
  failureModeID: string;
}

const EventsTab = ({ failureModeID }: Props) => {
  const pageKey = `failure_mode_events_vehicles_${failureModeID}`;

  const vehiclesFiltersFilterSortState = useFilterSortState({
    pageKey,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(pageKey),
  });

  return (
    <>
      <VehicleFilters
        vehiclesFilterSortState={vehiclesFiltersFilterSortState}
        hideCollectionFilter
      />

      <EventsTimeline
        failureModeID={failureModeID}
        vehicleFilterState={vehiclesFiltersFilterSortState.filters}
      />
      <FailureModeEventsTable
        failureModeID={failureModeID}
        vehicleFilters={vehiclesFiltersFilterSortState.filters}
      />
    </>
  );
};

export default EventsTab;
