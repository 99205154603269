import Skeleton from "react-loading-skeleton";

import { FailureMode, RiskModel } from "shared/api/failureModes/api";
import { useFailureModeRiskModel } from "shared/api/failureModes/hooks";
import {
  currencyUnitToCurrencySign,
  formatNumber,
  formatPercent,
} from "shared/utils";

import APIError from "features/ui/APIError";
import MetricCard from "features/ui/MetricCard";

interface Props {
  failureMode: FailureMode;
}

const atLeastOneMetricHasData = (riskModel: RiskModel): boolean => {
  const listOfMetricValues = [
    riskModel.nominalTruePositive,
    riskModel.nominalFalseNegative,
    riskModel.nominalFalsePositive,
    riskModel.nominalTrueNegative,
    riskModel.AUC,
    riskModel.expectedCost,
    riskModel.expectedCostSaved,
  ];

  return listOfMetricValues.some((v) => v);
};

const EmptyData = ({ name }: { name: string }) => (
  <div className="py-4 text-gray-400 text-sm">
    No Performance data available for Failure Mode {name}.
  </div>
);

const PerformanceTab = ({ failureMode }: Props) => {
  const {
    data: riskModel,
    isLoading,
    error,
  } = useFailureModeRiskModel({
    id: failureMode.ID as string,
  });

  if (error?.response?.status === 404) {
    return <EmptyData name={failureMode.name} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (isLoading || !riskModel) {
    return <Skeleton />;
  }

  if (!atLeastOneMetricHasData(riskModel) && !riskModel.optimalPMInterval) {
    return <EmptyData name={failureMode.name} />;
  }

  return (
    <>
      {atLeastOneMetricHasData(riskModel) && (
        <div>
          <div className="mb-12">
            <div className="flex items-center">
              <div className="w-24 h-12"></div>
              <div className="w-52 h-12 text-center">
                Predicted <br /> Positive
              </div>
              <div className="w-52 h-12 text-center">
                Predicted <br /> Negative
              </div>
              <div className="w-52 h-12 ml-8"></div>
            </div>
            <div className="flex items-center">
              <div className="w-24 h-24 text-center leading-[6rem]">
                Positive
              </div>
              <div className="w-52 h-24 text-center leading-[6rem] text-3xl bg-positive-green">
                {riskModel.nominalTruePositive
                  ? formatNumber(riskModel.nominalTruePositive)
                  : "N/A"}
              </div>
              <div className="w-52 h-24 text-center leading-[6rem] text-3xl bg-negative-red">
                {riskModel.nominalFalseNegative
                  ? formatNumber(riskModel.nominalFalseNegative)
                  : "N/A"}
              </div>
              <div className="w-52 h-24 ml-8">
                <MetricCard
                  value={
                    riskModel.nominalRecall
                      ? formatPercent(riskModel.nominalRecall)
                      : "N/A"
                  }
                  title="Nominal Recall"
                />
              </div>
            </div>
            <div className="flex items-center">
              <div className="w-24 h-24 text-center leading-[6rem]">
                Negative
              </div>
              <div className="w-52 h-24 text-3xl text-center leading-[6rem] bg-negative-red">
                {riskModel.nominalFalsePositive
                  ? formatNumber(riskModel.nominalFalsePositive)
                  : "N/A"}
              </div>
              <div className="w-52 h-24 text-3xl text-center leading-[6rem] bg-positive-green">
                {riskModel.nominalTrueNegative
                  ? formatNumber(riskModel.nominalTrueNegative)
                  : "N/A"}
              </div>
              <div className="w-52 h-24 ml-8"></div>
            </div>
            <div className="flex items-center mt-8">
              <div className="w-24 h-24"></div>
              <div className="w-52 h-24">
                <MetricCard
                  value={
                    riskModel.nominalPrecision
                      ? formatPercent(riskModel.nominalPrecision)
                      : "N/A"
                  }
                  title="Nominal Precision"
                />
              </div>
              <div className="w-52 h-24"></div>
              <div className="w-52 h-24 ml-8">
                <MetricCard
                  value={riskModel.AUC ? formatPercent(riskModel.AUC) : "N/A"}
                  title="Area Under Curve"
                />
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="w-[22rem] h-28 mr-12">
              <MetricCard
                value={`${currencyUnitToCurrencySign(riskModel.currencyCode)}${formatNumber(riskModel.expectedCost)}`}
                title="Expected Cost per Vehicle"
                subtitle=" "
              />
            </div>
            <div className="w-[22rem] h-28">
              <MetricCard
                value={calculateExpectedSavingPercentage(
                  riskModel.expectedCostSaved,
                  riskModel.expectedCostDenominator
                )}
                title="Cost Savings per Vehicle"
                subtitle="*Relative to baseline"
              />
            </div>
          </div>
        </div>
      )}
      {riskModel.optimalPMInterval !== null && (
        <div className="flex mt-8">
          <div className="w-[22rem] h-28 mr-12">
            <MetricCard
              value={riskModel.optimalPMInterval}
              title="Optimal PM Interval"
            />
          </div>
        </div>
      )}
    </>
  );
};

const calculateExpectedSavingPercentage = (
  expectedCostSaved: number | null,
  expectedCostDenominator: number | null
) => {
  if (!expectedCostSaved || !expectedCostDenominator) return "N/A";

  return formatPercent(expectedCostSaved / expectedCostDenominator);
};

export default PerformanceTab;
