import React from "react";
import IframeResizer from "iframe-resizer-react";

import Title from "features/ui/Title";

interface Props {
  title?: string;
  url: string;
}

// Consider moving URL generation back
const MetabaseEmbedding = ({ url, title }: Props) => (
  <div className="relative flex-column justify-center min-h-full">
    {title && (
      <div className="flex justify-between mb-5 px-8">
        <Title text={title} />
      </div>
    )}
    <IframeResizer
      title="Dashboard"
      onInit={console.log}
      src={url}
      frameBorder={0}
      allowFullScreen
    />
  </div>
);

export default MetabaseEmbedding;
