import React from "react";

import { pluralize } from "shared/utils";

import DropdownSelect from "features/ui/DropdownSelect";

import { getRelatedSignalEventsTimePeriods, getSelectedPeriod } from "./utils";

interface Props {
  timePeriod: number;
  updateTimePeriod: (period: number) => void;
}

const SEClaimsTimePeriodDropdown = ({
  timePeriod,
  updateTimePeriod,
}: Props) => {
  const allPeriods = getRelatedSignalEventsTimePeriods().map((period) => ({
    id: period.id,
    value: `${period.id} ${pluralize("day", period.id)}`,
    testId: `period-${period.id}`,
  }));
  const selectedValue = getSelectedPeriod(timePeriod)?.value as number;
  const selectedPeriod = `${selectedValue} ${pluralize("day", selectedValue)}`;

  let label = `Time period used to calculate related signal events & claims (${selectedPeriod})`;

  return (
    <DropdownSelect
      label={label}
      options={allPeriods}
      onSelect={(val) => updateTimePeriod(val.id)}
      testId="se-claims-time-period-dropdown"
    />
  );
};

export default SEClaimsTimePeriodDropdown;
