import { useCallback, useEffect, useMemo, useState } from "react";
import { MdPersonOutline as PersonIcon } from "react-icons/md";

import { PermissionEntry } from "shared/api/api";
import { PermissionID } from "shared/types";

import Input from "features/ui/Input";
import Label from "features/ui/Label";
import Table, { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import PermissionAccess from "./PermissionAccess";
import PermissionActions from "./PermissionActions";
import { isValidEmail } from "./utils";

interface UserFieldProps {
  permission: PermissionEntry;
  myEmail: string;
  onUpdateEmail: (id: string, email: string) => void;
}

const UserField = ({ permission, myEmail, onUpdateEmail }: UserFieldProps) => {
  const { ID, access, originalAccess, email } = permission;
  const [userEmail, setUserEmail] = useState(email);

  useEffect(() => {
    if (!email) {
      setUserEmail("");
    }
  }, [email]);

  if (!ID) {
    return (
      <Input
        label=""
        onChange={({ target: { value } }) => {
          setUserEmail(value);
          if (isValidEmail(value)) {
            onUpdateEmail(ID, value);
          }
        }}
        type="email"
        value={userEmail}
        placeholder="email@company.com"
        testId="user-permission-email"
      />
    );
  }

  const myEmailText = email === myEmail ? `${email} (Me)` : email;

  const newUserEntry = ID === email;
  const permissionChanged = ID && email && access !== originalAccess;

  return (
    <span className="flex items-center">
      <PersonIcon size="2em" className="m-1.5" />
      {myEmailText}
      {newUserEntry && <span className="ml-2 text-green-500">New</span>}
      {!newUserEntry && permissionChanged && (
        <span className="ml-2 text-orange-500">Updated</span>
      )}
    </span>
  );
};

interface Props {
  generalAccess: PermissionID;
  canEdit: boolean;
  myEmail: string;
  userPermissions: PermissionEntry[];
  onUpdateEmail: (id: string, email: string) => void;
  onUpdatePermission: (id: string, access: PermissionID) => void;
  onAddPermission: () => void;
  onRemovePermission: (id: string) => void;
}
const UserPermissionsTable = ({
  generalAccess,
  canEdit,
  myEmail,
  userPermissions,
  onUpdateEmail,
  onAddPermission,
  onUpdatePermission,
  onRemovePermission,
}: Props) => {
  const schemaItems: (SchemaEntry | boolean | undefined)[] = [
    {
      label: "User",
      accessor: "userField",
      dataType: DataType.JSX,
    },
    {
      label: "Permission",
      accessor: "userAccess",
      dataType: DataType.JSX,
    },
    canEdit && {
      label: "Action",
      accessor: "userActions",
      dataType: DataType.JSX,
      align: "center",
    },
  ];

  const schema = schemaItems.filter(Boolean) as SchemaEntry[];

  const formatRow = useCallback(
    (permissionEntry: PermissionEntry) => ({
      userField: (
        <UserField
          permission={permissionEntry}
          myEmail={myEmail}
          onUpdateEmail={onUpdateEmail}
        />
      ),
      userAccess: (
        <PermissionAccess
          permission={permissionEntry}
          canEdit={canEdit}
          generalAccess={generalAccess}
          onUpdatePermission={onUpdatePermission}
        />
      ),
      userActions: (
        <PermissionActions
          permission={permissionEntry}
          permissions={userPermissions}
          onAddPermission={onAddPermission}
          onRemovePermission={onRemovePermission}
          permissionType="user"
        />
      ),
    }),
    [
      canEdit,
      generalAccess,
      myEmail,
      onAddPermission,
      onRemovePermission,
      onUpdateEmail,
      onUpdatePermission,
      userPermissions,
    ]
  );

  const formattedData = useMemo(
    () => userPermissions.filter((x) => x.shown).map(formatRow),
    [formatRow, userPermissions]
  );

  return (
    <div data-testid="individual-permissions-table">
      <Label text="Individual Permissions" />
      <Table schema={schema} data={formattedData} dense />
    </div>
  );
};

export default UserPermissionsTable;
