import {
  APIListValuesRequest,
  APIListValuesResponse,
  getFetcher,
  ListAttributesRequest,
} from "shared/api/api";
import { clientV1 } from "shared/api/axios";
import { createURL } from "shared/api/utils";

export interface WorkOrder {
  ID: string;
  VIN: string;
  alternativeStatus: string | null;
  completedBy: string | null;
  costLabor: number | null;
  costOther: number | null;
  costRepair: number | null;
  costTotal: number | null;
  createdAt: string | null;
  createdBy: string | null;
  currencyCode: string | null;
  date: string;
  delayTime: number | null;
  downHours: number | null;
  downtime: number | null;
  facility: string | null;
  inServiceDate: string | null;
  lineStatus: string | null;
  notes: string | null;
  outOfServiceDate: string | null;
  partsQuantityCharged: number | null;
  planned: boolean | null;
  primaryMeter: number | null;
  primaryMeterType: string | null;
  redTag: string | null;
  secondaryMeter: number | null;
  secondaryMeterType: string | null;
  status: string | null;
  updatedAt: string | null;
  updatedBy: string | null;
}

const WORK_ORDERS_BASE_ROUTE = "workOrders";

export const listWorkOrdersValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listWorkOrdersValuesRequestURI(args));

const listWorkOrdersValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([WORK_ORDERS_BASE_ROUTE, "values", fieldName]),
    params,
  });

export const listWorkOrdersAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([WORK_ORDERS_BASE_ROUTE, "attributes"]),
    params,
  });
