import classNames from "classnames";
import { AiOutlineZoomOut as ZoomOutIcon } from "react-icons/ai";

import Button from "features/ui/Button";

const LABEL = "Zoom out";
export const WHEN_AT_BOTTOM_MARGIN_PX = 50;
const WHEN_NOT_AT_BOTTOM_MARGIN_PX = 85;

interface Props {
  isZoomedIn: boolean;
  handleZoomOut: () => void;
  marginSide?: number;
  atBottom?: boolean;
}

const ResetZoomButton = ({
  isZoomedIn,
  handleZoomOut,
  marginSide = 0,
  atBottom = true,
}: Props) => (
  <Button
    variant="outlined"
    label={LABEL}
    size="small"
    disabled={!isZoomedIn}
    className={classNames({
      invisible: !isZoomedIn,
    })}
    startIcon={<ZoomOutIcon />}
    onClick={handleZoomOut}
    style={{
      position: "absolute",
      right: marginSide + 10,
      bottom: atBottom
        ? WHEN_AT_BOTTOM_MARGIN_PX
        : WHEN_NOT_AT_BOTTOM_MARGIN_PX,
      zIndex: 10,
    }}
  />
);

export default ResetZoomButton;
