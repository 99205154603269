import { APIFilterOp } from "shared/api/utils";

import { SelectOption } from "features/ui/Select";

import { FilterOperator, FilterType } from "./types";

export const FILTER_OPERATOR_TO_API_FILTER: Record<
  FilterOperator,
  APIFilterOp
> = {
  [FilterOperator.IN]: "in",
  [FilterOperator.NOT_IN]: "nin",
  [FilterOperator.GREATER_OR_EQUAL]: "gte",
  [FilterOperator.GREATER_THAN]: "gt",
  [FilterOperator.LESS_OR_EQUAL]: "lte",
  [FilterOperator.LESS_THAN]: "lt",
  [FilterOperator.CONTAINS]: "like",
  [FilterOperator.NOT_CONTAINS]: "nlike",
  [FilterOperator.STARTS_WITH]: "startswith",
  [FilterOperator.NOT_STARTS_WITH]: "nstartswith",
  [FilterOperator.BETWEEN]: "between",
  // Do not use these two in the UI as they are not handled in FilterTypeWrap.tsx meaning they are not exposed in the UI at all.
  // These can still be used in the API though, so we allow them to be used for creating resources, but convert it to "in"
  // operator before displaying / using in the UI in filterBuilderQueryToFilterBuilderState
  [FilterOperator.EQUALS]: "eq",
  [FilterOperator.NOT_EQUALS]: "neq",
  // TODO these are actually handled elsewhere as unique cases
  [FilterOperator.NOT_FILTERED]: "isn",
  [FilterOperator.IS_EMPTY]: "is",
  [FilterOperator.IS_NOT_EMPTY]: "isn",
  [FilterOperator.IS_TRUE]: "eq",
  [FilterOperator.IS_FALSE]: "eq",
  [FilterOperator.IN_LAST]: "inLast",
  [FilterOperator.RELATES]: "occurs",
};

export const API_FILTER_TO_OPERATOR: Record<APIFilterOp, FilterOperator> = {
  in: FilterOperator.IN,
  nin: FilterOperator.NOT_IN,
  gte: FilterOperator.GREATER_OR_EQUAL,
  lte: FilterOperator.LESS_OR_EQUAL,
  gt: FilterOperator.GREATER_THAN,
  lt: FilterOperator.LESS_THAN,
  is: FilterOperator.IS_EMPTY,
  isn: FilterOperator.IS_NOT_EMPTY,
  like: FilterOperator.CONTAINS,
  nlike: FilterOperator.NOT_CONTAINS,
  eq: FilterOperator.EQUALS,
  neq: FilterOperator.NOT_EQUALS,
  inLast: FilterOperator.IN_LAST,
  between: FilterOperator.BETWEEN,
  startswith: FilterOperator.STARTS_WITH,
  nstartswith: FilterOperator.NOT_STARTS_WITH,
  occurs: FilterOperator.RELATES,
  noccurs: FilterOperator.RELATES,
};

export const GT_LT_GTE_LTE_OPERATORS = [
  FilterOperator.GREATER_THAN,
  FilterOperator.GREATER_OR_EQUAL,
  FilterOperator.LESS_THAN,
  FilterOperator.LESS_OR_EQUAL,
];

export const OPERATORS_MAP: Record<FilterType, FilterOperator[][]> = {
  string: [
    [FilterOperator.NOT_FILTERED],
    [FilterOperator.IN, FilterOperator.NOT_IN],
    [
      FilterOperator.CONTAINS,
      FilterOperator.NOT_CONTAINS,
      FilterOperator.STARTS_WITH,
      FilterOperator.NOT_STARTS_WITH,
    ],
    [FilterOperator.IS_EMPTY, FilterOperator.IS_NOT_EMPTY],
  ],
  duration: [
    [FilterOperator.NOT_FILTERED],
    GT_LT_GTE_LTE_OPERATORS,
    [FilterOperator.IS_EMPTY, FilterOperator.IS_NOT_EMPTY],
  ],
  number: [
    [FilterOperator.NOT_FILTERED],
    [FilterOperator.IN, FilterOperator.NOT_IN],
    GT_LT_GTE_LTE_OPERATORS,
    [FilterOperator.BETWEEN],
    [FilterOperator.IS_EMPTY, FilterOperator.IS_NOT_EMPTY],
  ],
  date: [
    [FilterOperator.NOT_FILTERED],
    [FilterOperator.IN, FilterOperator.NOT_IN],
    GT_LT_GTE_LTE_OPERATORS,
    [FilterOperator.BETWEEN, FilterOperator.IN_LAST],
    [FilterOperator.IS_EMPTY, FilterOperator.IS_NOT_EMPTY],
  ],
  boolean: [
    [FilterOperator.NOT_FILTERED],
    [FilterOperator.IS_TRUE, FilterOperator.IS_FALSE],
    [FilterOperator.IS_EMPTY, FilterOperator.IS_NOT_EMPTY],
  ],
  relates: [
    [FilterOperator.NOT_FILTERED],
    [FilterOperator.IN],
    [FilterOperator.STARTS_WITH],
    [FilterOperator.IS_NOT_EMPTY],
  ],
};

type FilterOperatorToString = {
  [key in FilterOperator]: string;
};

export const OPERATOR_TO_SIGN: FilterOperatorToString = {
  [FilterOperator.NOT_FILTERED]: "",
  [FilterOperator.IN]: "is any of",
  [FilterOperator.NOT_IN]: "is not any of",
  [FilterOperator.BETWEEN]: "is between",
  [FilterOperator.GREATER_OR_EQUAL]: ">=",
  [FilterOperator.GREATER_THAN]: ">",
  [FilterOperator.LESS_OR_EQUAL]: "<=",
  [FilterOperator.LESS_THAN]: "<",
  [FilterOperator.IS_EMPTY]: "is empty",
  [FilterOperator.IS_NOT_EMPTY]: "is not empty",
  [FilterOperator.CONTAINS]: "contains any of",
  [FilterOperator.NOT_CONTAINS]: "does not contain any of",
  [FilterOperator.IN_LAST]: "in last",
  [FilterOperator.STARTS_WITH]: "starts with any of",
  [FilterOperator.NOT_STARTS_WITH]: "does not start with any of",
  [FilterOperator.IS_TRUE]: "is",
  [FilterOperator.IS_FALSE]: "is",
  [FilterOperator.EQUALS]: "=",
  [FilterOperator.NOT_EQUALS]: "!=",
  [FilterOperator.RELATES]: "",
};

// This is used in FiltersOverviews to display the filter operator
export const OPERATOR_TO_WORDS: FilterOperatorToString = {
  ...OPERATOR_TO_SIGN,
  [FilterOperator.GREATER_OR_EQUAL]: "is greater than or equal to",
  [FilterOperator.GREATER_THAN]: "is greater than",
  [FilterOperator.LESS_OR_EQUAL]: "is less than or equal to",
  [FilterOperator.LESS_THAN]: "is less than",
  [FilterOperator.IS_EMPTY]: "is",
  [FilterOperator.IS_NOT_EMPTY]: "is not",
  [FilterOperator.EQUALS]: "equals",
  [FilterOperator.NOT_EQUALS]: "does not equal",
};

// Default will be cleanString(filterOperator) if not specified
// This is used when displaying filter operator in dropdowns
export const OPERATOR_TO_LABEL: Partial<FilterOperatorToString> = {
  [FilterOperator.NOT_FILTERED]: "is not filtered",
  [FilterOperator.IN]: "is any of",
  [FilterOperator.NOT_IN]: "is not any of",
  [FilterOperator.CONTAINS]: "contains any of",
  [FilterOperator.NOT_CONTAINS]: "does not contain any of",
  [FilterOperator.BETWEEN]: "is between (inclusive)",
  [FilterOperator.IN_LAST]: "is within the last (time period)",
  [FilterOperator.STARTS_WITH]: "starts with any of",
  [FilterOperator.NOT_STARTS_WITH]: "does not start with any of",
  [FilterOperator.GREATER_OR_EQUAL]: "is greater than or equal to",
  [FilterOperator.GREATER_THAN]: "is greater than",
  [FilterOperator.LESS_OR_EQUAL]: "is less than or equal to",
  [FilterOperator.LESS_THAN]: "is less than",
};

export const OPERATOR_TO_LABEL_OVERRIDE_BY_FILTER_TYPE: Partial<
  Record<FilterType, Partial<FilterOperatorToString>>
> = {
  date: {
    [FilterOperator.GREATER_THAN]: "is after",
    [FilterOperator.GREATER_OR_EQUAL]: "is on or after",
    [FilterOperator.LESS_THAN]: "is before",
    [FilterOperator.LESS_OR_EQUAL]: "is on or before",
  },
};

export const DEFAULT_FILTER_TYPE: FilterType = "string";

export const DEFAULT_OPERATOR_PER_TYPE: Record<FilterType, FilterOperator> = {
  string: FilterOperator.NOT_FILTERED,
  duration: FilterOperator.NOT_FILTERED,
  number: FilterOperator.NOT_FILTERED,
  date: FilterOperator.NOT_FILTERED,
  boolean: FilterOperator.NOT_FILTERED,
  relates: FilterOperator.NOT_FILTERED,
};

/**
 * When pasting a list of values, we split on this delimiter(s) when using "is any of" filter.
 * "\n" => new line
 * "\t" => tab
 * " " => space
 * "," => comma
 */
export const FILTER_PASTE_DELIMITER: RegExp = /\n|\t| |,/;

export const OPERATORS_ALLOW_APPLY_EVEN_IF_EMPTY: FilterOperator[] = [
  FilterOperator.NOT_FILTERED,
  FilterOperator.IS_EMPTY,
  FilterOperator.IS_NOT_EMPTY,
  FilterOperator.IS_TRUE,
  FilterOperator.IS_FALSE,
];

// this is what's actually sent to the API
export const MAX_FILTER_ID = "max";
export const MIN_FILTER_ID = "min";

export const MAX_FILTER_SELECT_OPTION: SelectOption = {
  id: MAX_FILTER_ID,
  value: "Highest value",
};

export const MIN_FILTER_SELECT_OPTION: SelectOption = {
  id: MIN_FILTER_ID,
  value: "Lowest value",
};

export const MAX_FILTER_SELECT_OPTION_DATE: SelectOption = {
  id: MAX_FILTER_ID,
  value: "Most Recent",
};

export const MIN_FILTER_SELECT_OPTION_DATE: SelectOption = {
  id: MIN_FILTER_ID,
  value: "Oldest",
};
