import classNames from "classnames";

import { LegendConfig } from "features/ui/charts/types";

interface Props {
  legendConfig: LegendConfig;
}

const CustomLegend = ({ legendConfig: { description, labels } }: Props) => (
  <div
    className="recharts-legend-wrapper"
    style={{
      height: "auto",
      left: "50px",
      marginLeft: "50px",
    }}
  >
    {description && (
      <h6 className="text-gray-400 text-xs max-w-3xl">{description}</h6>
    )}
    <div className="inline-flex gap-5 items-center">
      {labels.map(({ label, shape, color, dotted }) => (
        <span key={label} className="inline-flex gap-2 items-center">
          {(!shape || shape === "square") && (
            <span className="h-5 w-1" style={{ backgroundColor: color }} />
          )}
          {shape === "line" && (
            <span
              className={classNames({
                "h-3 w-6": !dotted,
                "border-dotted border-t-2 h-0 w-6": dotted,
              })}
              style={{
                backgroundColor: !dotted ? color : undefined,
                borderColor: dotted ? color : undefined,
              }}
            />
          )}
          {shape && shape === "circle" && (
            <span
              className="w-5 h-5"
              style={{
                backgroundColor: color,
                borderRadius: "50%",
              }}
            />
          )}
          {shape && shape === "triangle" && (
            <span
              className="w-0 h-0"
              style={{
                borderLeft: "0.5em solid transparent",
                borderRight: "0.5em solid transparent",
                borderTop: "0em",
                borderBottom: `1em solid ${color}`,
              }}
            />
          )}
          <span className="text-gray-400 text-xs">{label}</span>
        </span>
      ))}
    </div>
  </div>
);

export default CustomLegend;
