import { useContext, useEffect, useRef } from "react";
import {
  DuckMessagesContext,
  DuckMessage as DuckMessageType,
} from "duck/context/DuckMessagesContextWrapper";
import { Stack } from "@mui/material";

import DuckLoadingAnimation from "./DuckLoadingAnimation";
import DuckMessage from "./DuckMessage";
import DuckPendingActionAvailable from "./DuckPendingActionAvailable";

interface DuckMessagesProps {
  loading: boolean;
  pendingAction: boolean;
  setPendingAction: (pendingAction: boolean) => void;
  updateLocation: () => void;
}

const DuckMessages = ({
  loading,
  pendingAction,
  setPendingAction,
  updateLocation,
}: DuckMessagesProps) => {
  const { messages } = useContext(DuckMessagesContext);

  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);

  const scrollToNewMessage = () => {
    if (!messagesContainerRef.current || !lastMessageRef.current) {
      return;
    }
    const containerRect = messagesContainerRef.current.getBoundingClientRect();
    const lastMessageRect = lastMessageRef.current.getBoundingClientRect();
    const scrollTop =
      lastMessageRect.top -
      containerRect.top +
      messagesContainerRef.current.scrollTop;
    messagesContainerRef.current.scrollTo({
      top: scrollTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToNewMessage();
  }, [messages, loading]);

  return (
    <Stack
      ref={messagesContainerRef}
      spacing={1}
      sx={{
        bgcolor: "white",
        width: "100%",
        flexGrow: 1,
        border: 1,
        borderColor: "grey.300",
        overflowY: "auto",
        padding: 1,
        height: "100%",
      }}
    >
      {messages.map((message: DuckMessageType, ix: number) => (
        <div key={ix} ref={ix === messages.length - 1 ? lastMessageRef : null}>
          <DuckMessage message={message} />
        </div>
      ))}

      {loading && (
        <Stack
          spacing={1}
          sx={{
            bgcolor: "white",
            width: "100%",
            border: 0,
            borderColor: "white",
            padding: 0,
          }}
        >
          <DuckLoadingAnimation />
        </Stack>
      )}

      {pendingAction && (
        <DuckPendingActionAvailable
          setPendingAction={setPendingAction}
          updateLocation={updateLocation}
        />
      )}
    </Stack>
  );
};

export default DuckMessages;
