import CollectionsTable from "pages/Collections/CollectionsTable";

import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title/Title";

const Collections = () => (
  <>
    <PageHeaderWrapper>
      <Title text="Collections" />
    </PageHeaderWrapper>

    <CollectionsTable />
  </>
);

export default Collections;
