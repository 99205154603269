import { useContext } from "react";
import { DuckMessagesContext } from "duck/context/DuckMessagesContextWrapper";
import { GiFairyWand } from "react-icons/gi";
import { Button, Stack } from "@mui/material";

import {
  DUCK_ACTION_DISCARDED_MESSAGE,
  DUCK_ACTION_PERFORMED_MESSAGE,
  DUCK_DO_IT_FOR_ME,
} from "./constants";

interface DuckPendingActionAvailableProps {
  setPendingAction: (pendingAction: boolean) => void;
  updateLocation: () => void;
}

const DuckPendingActionAvailable = ({
  setPendingAction,
  updateLocation,
}: DuckPendingActionAvailableProps) => {
  const { addMessage } = useContext(DuckMessagesContext);

  const handleDoItClick = () => {
    updateLocation();
    setPendingAction(false);
    addMessage(DUCK_ACTION_PERFORMED_MESSAGE);
  };

  const handleNoThanksClick = () => {
    setPendingAction(false);
    addMessage(DUCK_ACTION_DISCARDED_MESSAGE);
  };

  return (
    <Stack alignItems="center" direction="column">
      <p className="pb-4">
        You can try this on your own, or you can click the{" "}
        <span className="font-bold">{DUCK_DO_IT_FOR_ME}</span> button to let me
        do it for you.
      </p>
      <Button
        variant="contained"
        color="primary"
        onClick={handleDoItClick}
        size="small"
      >
        <Stack alignItems="center" direction="row" spacing={0.5}>
          <GiFairyWand />
          <div>{DUCK_DO_IT_FOR_ME}</div>
        </Stack>
      </Button>

      <Button
        variant="text"
        color="secondary"
        size="small"
        onClick={handleNoThanksClick}
      >
        No thanks
      </Button>
    </Stack>
  );
};

export default DuckPendingActionAvailable;
