import { MODEL_NAMES, ModelSpec } from "duck/graph/types";

export const GPT4O_MODEL_SPEC: ModelSpec = {
  modelName: MODEL_NAMES.GPT4O,
  temperature: 0.2,
  modelKwargs: {
    seed: 22,
  },
};

export const GPT4OMINI_MODEL_SPEC: ModelSpec = {
  modelName: MODEL_NAMES.GPT4O_MINI,
  temperature: 0.2,
  modelKwargs: {
    seed: 22,
  },
};

export const LC_ENDPOINT = process.env.REACT_APP_LANGCHAIN_ENDPOINT;
export const LC_API_KEY = process.env.REACT_APP_LANGCHAIN_API_KEY;
export const LC_PROJECT_NAME = process.env.REACT_APP_LANGCHAIN_PROJECT;
export const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
export const DOCUMENTS_SOURCE = "Confluence";
export const DOCUMENT_RETRIEVAL_K = 5;
export const DOCUMENT_RETRIEVAL_DISTANCE_THRESHOLD = 2;
export const ATTRIBUTE_RETRIEVAL_K = 5;
export const ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD = 2;
export const ATTRIBUTE_SOURCES = {
  Claim: "v1/claims/attributes",
  Vehicle: "v1/vehicles/attributes",
  SignalEventOccurrences: "v1/signalEvents/occurrences/attributes",
  VehicleECUs: "v1/vehicleECUs/attributes",
  Issues: "v1/issues/attributes",
  SuggestedIssues: "v1/suggestedIssues/attributes",
  CustomRecords: "v1/customRecords/attributes",
  CustomSignalEvents: "v1/customSignalEvents/attributes",
  Dealers: "v1/dealers/attributes",
} as const;

export const ATTRIBUTE_SOURCE_KEYS = {
  Claim: "Claim",
  Vehicle: "Vehicle",
  SignalEventOccurrences: "SignalEventOccurrences",
  VehicleECUs: "VehicleECUs",
  Issues: "Issues",
  SuggestedIssues: "SuggestedIssues",
  CustomRecords: "CustomRecords",
  CustomSignalEvents: "CustomSignalEvents",
  Dealers: "Dealers",
} as const;

export const SCREENCAPTURE_ELEMENT_ID = "duck-screenshot-element";
