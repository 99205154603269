import { Dispatch, SetStateAction } from "react";

import {
  ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_KEY,
  BY_VEHICLES_AGE_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "pages/constants";
import { ByVehicleAgeData } from "pages/types";

import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { UseFilterSortState } from "features/ui/Filters/types";
import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import { INSPECTION_ANALYTICS_TABS, INSPECTIONS_TAB_KEY } from "./constants";
import AssociatedSignalEvents from "./tabPages/AssociatedSignalEvents";
import AssociatedVehicles from "./tabPages/AssociatedVehicles";
import ByVehicleAge from "./tabPages/ByVehicleAge";
import Inspections from "./tabPages/Inspections";
import TopContributors from "./tabPages/TopContributors";

export interface InspectionAnalyticsProps {
  inspectionsFiltersFilterSortState: UseFilterSortState;
  vehiclesFiltersFilterSortState?: UseFilterSortState;
}

export interface InspectionsFiltersProps {
  inspectionsFilters?: FilterGroupState;
  vehiclesFilters?: FilterGroupState;
  onBadRequest: () => void;
}

export interface InspectionAnalyticsTabsProps
  extends InspectionsFiltersProps,
    InspectionAnalyticsProps {
  queryParamsToPersist?: string[];
}

export interface InspectionsByVehicleAgeChartProps
  extends InspectionsFiltersProps {
  byVehicleAgeData: ByVehicleAgeData;
  actions: ChartAction[];
  selectedOptions: SelectedChartOptions[];
  setSelectedOptions: Dispatch<SetStateAction<SelectedChartOptions[]>>;
}

export interface InspectionsByVehicleAgeTableProps
  extends InspectionsFiltersProps {
  byVehicleAgeData: ByVehicleAgeData;
}

const InspectionAnalyticsTabs = ({
  inspectionsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
  queryParamsToPersist,
  onBadRequest,
}: InspectionAnalyticsTabsProps) => {
  const inspectionsFilters = inspectionsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  const tabsItems: (Tab | boolean | undefined)[] = [
    {
      key: INSPECTIONS_TAB_KEY,
      title: INSPECTION_ANALYTICS_TABS[INSPECTIONS_TAB_KEY],
      content: (
        <Inspections
          inspectionsFilters={inspectionsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: BY_VEHICLES_AGE_TAB_KEY,
      title: INSPECTION_ANALYTICS_TABS[BY_VEHICLES_AGE_TAB_KEY],
      content: (
        <ByVehicleAge
          inspectionsFilters={inspectionsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: TOP_CONTRIBUTORS_TAB_KEY,
      title: INSPECTION_ANALYTICS_TABS[TOP_CONTRIBUTORS_TAB_KEY],
      content: (
        <TopContributors
          inspectionsFiltersFilterSortState={inspectionsFiltersFilterSortState}
          vehiclesFiltersFilterSortState={vehiclesFiltersFilterSortState}
        />
      ),
    },
    {
      key: ASSOCIATED_VEHICLES_TAB_KEY,
      title: INSPECTION_ANALYTICS_TABS[ASSOCIATED_VEHICLES_TAB_KEY],
      content: (
        <AssociatedVehicles
          inspectionsFilters={inspectionsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
      title: INSPECTION_ANALYTICS_TABS[ASSOCIATED_SIGNAL_EVENTS_TAB_KEY],
      content: (
        <AssociatedSignalEvents
          inspectionsFiltersFilterSortState={inspectionsFiltersFilterSortState}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return (
    <StatefulTabs tabs={tabs} queryParamsToPersist={queryParamsToPersist} />
  );
};

export default InspectionAnalyticsTabs;
